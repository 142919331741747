import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ConsentRequestDialog.scss";
import {
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { SendConsentFromContact, SendConsentFromLead } from "../../Services";
import { showError, showSuccess } from "../../Helper";
import btnLoader from "../../assets/images/GIF_images/btn-loader.gif";
import { ShareMethodsDataEnum } from "../../Enums";

export const ConsentRequestDialog = ({
  onClose,
  open,
  onSave,
  activeRecipient,
  recipientType,
}) => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const [recipientMappedData, setRecipientMappedData] = useState({
    Email: null,
    SMS: null,
  });
  const [selected, setSelected] = useState({
    shareVia: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const isCardDisabled = (cardInfo) => !recipientMappedData[cardInfo?.key];

  const getCardStatusClass = (cardInfo) => {
    let cardClass = "primary";

    if (isCardDisabled(cardInfo)) cardClass = "disabled";
    else if (cardInfo?.id == selected.shareVia?.id) cardClass = "active";
    return cardClass;
  };

  const cardClickHandler = (cardInfo) => {
    if (isCardDisabled(cardInfo))
      setSelected((prevState) => ({
        ...prevState,
        shareVia: null,
      }));
    else
      setSelected((prevState) => ({
        ...prevState,
        shareVia: cardInfo,
      }));
  };

  const sendConsentFromContact = async () => {
    setIsLoading(true);
    const res = await SendConsentFromContact({
      contactId: activeRecipient?.id,
      consentSentBy: selected.shareVia?.id,
    });
    if (res === true) {
      showSuccess(t(`${translationPath}sent-Succssfuly`));
      onSave();
    }
    else showError(t(`${translationPath}sending-failed`));
    setIsLoading(false);
  };

  const sendConsentFromLead = async () => {
    setIsLoading(true);
    const res = await SendConsentFromLead({
      leadId: activeRecipient?.id,
      consentSentBy: selected.shareVia?.id,
    });
    if (res === true) {
      showSuccess(t(`${translationPath}sent-Succssfuly`));
      onSave();
    }
    else showError(t(`${translationPath}sending-failed`));
    setIsLoading(false);
  };

  const mapActiveRecipientData = () => {
    if (recipientType === 'Contact') {
      setRecipientMappedData({
        Email: activeRecipient.email_address || null,
        SMS: activeRecipient.mobile?.phone || null,
      });
    } else if (recipientType === 'Lead') {
      setRecipientMappedData({
        Email: activeRecipient?.contact_name?.email_address || null,
        SMS: activeRecipient?.contact_name?.mobile || null,
      }); 
    }
  };

  const saveHandler = () => {
    if (recipientType === 'Contact') sendConsentFromContact();
    if (recipientType === 'Lead') sendConsentFromLead();
  };

  useEffect(() => {
    if (activeRecipient) mapActiveRecipientData();
  }, [activeRecipient]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        className="ConsentRequestDialog"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}Ask-For-Consent`)}</span>
          <span className="fz-14">{`${
            selected.shareVia ? ` (Via ${selected.shareVia?.key} )` : ""
          }`}</span>
        </DialogTitle>
        <DialogContent>
          <div className="MethodBanner gap-16 py-3">
          {Object.values(ShareMethodsDataEnum)
            .filter( method =>  method.key !== "WhatsApp").map((item) => (
              <div
                className={`MethodBanner_card ${getCardStatusClass(item)}`}
                key={item.key}
                id={`MethodBanner_card-${item.id}`}
                onClick={() => cardClickHandler(item)}
              >
                <span className={`mdi ${item.mdiIconClass || ""}`}></span>
                <span className="fw-simi-bold fz-16">
                  {t(`${translationPath}${item.title}`)}
                </span>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonBase
            onClick={() => onClose()}
            className={"btns theme-solid bg-cancel"}
          >
            {t(`${translationPath}cancel`)}
          </ButtonBase>
          <ButtonBase
            className={"btns theme-solid px-3"}
            onClick={saveHandler}
            disabled={!selected.shareVia || isLoading}
          >
            {isLoading ? (
              <img
                src={btnLoader}
                className="ml-1 dark-loader"
                width="12"
                height="12"
              />
            ) : null}
            {t(`${translationPath}send-request`)}
          </ButtonBase>
        </DialogActions>
      </Dialog>
    </div>
  );
};

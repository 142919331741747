import React from "react";

const AlertIcon = ({ fill, ...restProps }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.247.865A9.408 9.408 0 0 0 5 2.325 9.623 9.623 0 0 0 2.325 5 9.402 9.402 0 0 0 .929 8.705c-.105.683-.106 1.903-.002 2.578a9.292 9.292 0 0 0 1.836 4.334c.3.394 1.104 1.212 1.504 1.53 2.048 1.628 4.712 2.321 7.25 1.886a9.096 9.096 0 0 0 4.953-2.563c2.104-2.104 3.06-5.086 2.563-7.987a9.307 9.307 0 0 0-1.887-4.216c-.52-.657-1.456-1.499-2.196-1.976A9.476 9.476 0 0 0 11.312.934C10.79.857 9.733.821 9.247.865m1.903 1.716a7.464 7.464 0 0 1 4.151 2.118 7.458 7.458 0 0 1 2.123 4.184c.08.511.08 1.723 0 2.234a7.47 7.47 0 0 1-2.123 4.184 7.455 7.455 0 0 1-4.184 2.123c-.511.08-1.723.08-2.234 0a7.458 7.458 0 0 1-4.184-2.123 7.46 7.46 0 0 1-2.117-4.151 9.461 9.461 0 0 1-.051-1.867 7.55 7.55 0 0 1 .556-2.206A7.528 7.528 0 0 1 9.083 2.55c.471-.06 1.602-.043 2.067.031M9.757 5.879a.885.885 0 0 0-.559.595c-.021.079-.03.794-.024 1.934.009 1.778.011 1.811.081 1.942.164.307.417.464.745.464s.581-.157.745-.464c.071-.132.072-.153.072-2.017 0-1.863-.001-1.884-.072-2.017-.199-.372-.639-.567-.988-.437m0 6.669c-.492.175-.72.703-.494 1.144a.829.829 0 0 0 1.463.046c.077-.131.091-.192.091-.405 0-.211-.014-.273-.09-.403-.202-.343-.618-.507-.97-.382"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
};

export default AlertIcon;


import React, { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ButtonBase } from "@material-ui/core";
import moment from "moment";
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  GlobalHistory,
  bottomBoxComponentUpdate,
  havePermissionToViewDetails,
  havePermissionToEditInformation,
  getSideMenuStatus,
  getSideMenuComponent,
  returnPropsByPermissions,
} from "../../../Helper";
import {
  ActionsButtonsComponent,
  ViewTypes,
  Spinner,
  PermissionsComponent,
  DialogComponent,
  SelectComponet 
} from "../../../Components";
import {
  ActionsEnum,
  ViewTypesEnum,
  TableActions,
  ActionsButtonsEnum,
  FormsIdsEnum,
  TableFilterTypesEnum,
  TableFilterOperatorsEnum,
  ArrayOFSearchableFormFields,
  AgentRoleEnum,
  ListingAgentIdsEnum,
  DateFilterTypesEnum,
  SearchCriteriaEnum,
} from "../../../Enums";
import {
  CardDetailsComponent,
  UnitsCardsComponent,
  UnitsSalesTableComponent,
} from "../UnitsSalesView/UnitsSalesUtilities";
import {
  UnitsAdvanceSearchTest,
  GetAllSearchableFormFieldsByFormId,
  GetAllFormFieldsByFormId,
  GetLookupItems,
  GetUnitDetails,
  IsNeedApprovalMessageInMarketing  , 
  GetAllRolesByUserId  , GetAllSystemTemplateByCategoryId
} from "../../../Services";
import { AdvanceSearch } from "../../../SharedComponents/AdvanceSearch/AdvanceSearch";
import { AdvanceSearchSecondVersion } from "../../../SharedComponents/AdvanceSearchSecondVersion";
import { PaginationComponent } from "../../../Components/PaginationComponent/PaginationComponent";
import { UnitMapper } from "../UnitsSalesView/UnitMapper";
import { ActiveItemActions } from "../../../store/ActiveItem/ActiveItemActions";
import { useTitle } from "../../../Hooks";
import { GlobalOrderFilterActions } from "../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { GlobalAdvanceSearchActions } from "../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions";
import { ResaleUnitsPermissions  , UnitsSalesPermissions} from "../../../Permissions";
import { UnitsSalesTableHeaderData } from "../UnitsSalesView/UnitsSalesUtilities/UnitsSalesTableComponent/UnitsSalesTableHeaderData";
import { UnitsVerticalTabsData } from "../Common/OpenFileView/OpenFileUtilities/OpenFileData/UnitsVerticalTabsData";
import { ActivitiesManagementDialog } from "../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog";
import { useScrollToElement } from "../../../Hooks/useScrollToElement";
import { useSuccessDialog } from "../../../SharedComponents";
import { OwnerDetailsDialog } from "../UnitsSalesView/UnitsSalesUtilities/Dialogs";
import { ResaleUnitsFilterComponent } from './ResaleUnitsFilters/ResaleUnitsFilterComponent' ; 
import { UnitTemplateDialogPreviewDialog } from "../UnitsView/UnitsProfileManagementView/Dialogs/UnitTemplateDialog/UnitTemplateDialogPreviewDialog";



const generateUniqueKey = () => parseInt(Math.random() * 1000);
const parentTranslationPath = "UnitsView";
const translationPath = "";

export const ResaleUnitsView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [templates, setTemplates] = useState([]);
  const [activeTemplateId, setActiveTemplateId] = useState(null);
  const [isPropasalDialogOpen, setIsPropasalDialogOpen] = useState(false);
  const [createdDateFilter , setCreatedDateFilter ] =  useState(null) ; 


  const [ownerDetailsDialog, setOwnerDetailsDialog] = useState({
    isOpen: false,
    titleText: "",
  });
  const [templatesProposal, setTemplatesProposal] = useState([]);
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
    selectedDateType: 1,
  };
  const [isLoadingAllUnits, setIsLoadingAllUnits] = useState(false);
  const [resaleFilters , setResaleFilters] = useState({});
  const [sortByDefaultFilter , setSortByDefaultFilter] = useState({filterBy :'createdOn' , orderBy : 2});

  const [sortByFilter , setSortByFilter] = useState(sortByDefaultFilter);
  const [leadOwnerDetails, setLeadOwnerDetails] = useState({});
  const { isOpen } = useSuccessDialog();
  const [addActivity, setAddActivity] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [displyOpenFileButton, setDisplyOpenFileButton] = useState(false);
  const [displyMatchingButton, setDisplyMatchingButton] = useState(false);
  const [unitsBulkStatusUpdateIsOpen, setUnitsBulkStatusUpdateIsOpen] =
    useState(false);
  const [unitsIdUpdateArray, setUnitsIdUpdateArray] = useState([]);
  const [viewType, setViewType] = useState("");
  const [isPublishedUnits, setIsPublishedUnits] = useState(false);
  const [isAvailableUnits, setIsAvailableUnits] = useState(false);
  const [isPublishedFilterDisabled, setIsPublishedFilterDisabled] =
    useState(false);
  const [isStatusFilterDisabled, setIsStatusFilterDisabled] = useState(false);
 const [isClearSortBy , setIsClearSortBy] = useState(false);
  const [actionButtonsKey, setActionButtonsKey] = useState(generateUniqueKey());

  const dispatch = useDispatch();
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const advanceSearch = useSelector(
    (state) => state.GlobalAdvanceSearchReducer
  );
  const [unitsTableFilter, setUnitsTableFilter] = useState(
    (advanceSearch && advanceSearch.UnitResaleFilter) || null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isAllFormFieldsLoading, setIsAllFormFieldsLoading] = useState(false);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [savedRoles, setSavedRoles] = useState({});
  const [needsApprovalDialog, setNeedsApprovalDialog] = useState(false);
  const [typeOfRequest, setTypeOfRequest] = useState(false);
  const [activeActionType, setActiveActionType] = useState(
    (localStorage.getItem("ViewType") &&
      JSON.parse(localStorage.getItem("ViewType")).unitResaleView) ||
      ViewTypesEnum.cards.key
  );

  const [isExpanded, setIsExpanded] = useState(
    activeActionType === ViewTypesEnum.cardsExpanded.key
  );

  const [, setOrderByToggler] = useState(false);
  const [activeSelectedAction, setActiveSelectedAction] = useState("");
  const [unitStatus, setUnitStatus] = useState(
    orderFilter.UnitsResalesStatus || [0]
  );
  const [unitsPortal, setUnitsPortal] = useState(
    orderFilter.PublishResalePortal || [0]
  );
  const [activeCard, setActiveCard] = useState(null);
  // const [activeCardId, setActiveCardId] = useState(null);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isAdvanceSearchCleared, setIsAdvanceSearchCleared] = useState(false);
  const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: (orderFilter?.unitsResalesFilter?.filterBy),
    orderBy: (orderFilter?.unitsResalesFilter?.orderBy),
  });

  const [PortalData, setPortalData] = useState(null);
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy?.filterBy
      ? selectedOrderBy
      : { filterBy: "createdOn", orderBy: 2 }
  );
  const [checkedDetailedCards, setCheckedDetailedCards] = useState([]);
  const [detailsUnitsList, setDetailsUnitsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));

  const { setHash } = useScrollToElement(!!detailsUnitsList.result.length);
  const [searchableFormFields, setSearchableFormFields] = useState([]);
  const [searchedItem, setSearchedItem] = useState("");
  const searchTimer = useRef(null);
  const [allFormFields, setAllFormFields] = useState([]);
  const [dateFilter, setDateFilter] = useState(
      dateRangeDefault
  );
  const [searchData, setSearchData] = useState([]);
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [checkedCards, setCheckedCards] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex:
      JSON.parse(localStorage.getItem("unitsSalesCurrentPageIndex")) || 0,
    search: "",
  });
  const [isFirst, setFirst] = useState(false);
  const [isFirst1, setFirst1] = useState(false);
  const [unitPublishStatus, setUnitPublishStatus] = useState(
    orderFilter?.UnitResalesPublishStatusFilter || 0
  );

  const [selectFieldsOnAdvanceSearch, setSelectFieldsOnAdvanceSearch] =
    useState([]);
  const [filterValuesFromAdvanceSearch, setFilterValuesFromAdvanceSearch] =
    useState(null);
  useTitle(t(`${translationPath}units`));
  const unitOperationSource = {
    sale: {
      key: 1,
    },
    lease: {
      key: 2,
    },
  };

  const saleListingAgentRole = () => {
    const saleListingAgentRoleIsExist =
      loginResponse &&
      loginResponse.roles &&
      loginResponse.roles.length &&
      loginResponse.roles.filter(
        (x) => x.rolesName === "Sale Listing Agent" || x.rolesName === "admin"
      );
    if (
      saleListingAgentRoleIsExist.length === 1 &&
      saleListingAgentRoleIsExist[0].rolesName ===
        AgentRoleEnum.SaleListingAgent.name
    )
      return true;
    return false;
  };

  const searchHandler = (data) => {
    const newValue = [
      {
        key: "Address",
        title: "Address",
        value: data,
      },
    ];
    if (searchTimer.current) clearTimeout(searchTimer.current);
    if (data === "") {
      const index = searchData.findIndex((item) => item.key === "Address");
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem("UnitResaleFilter");
    } else {
      searchTimer.current = setTimeout(() => {
        setSearchData([...newValue]);
        setFilterSearchDto({
          Address: [
            {
              value: data,
            },
          ],
        });
      }, 1300);
    }
  };
  const resaleChangeFilters =( key , filterObj , locationList  , searchType ) => 
    {
      let newFilter = { ...resaleFilters} ;
      if(!key)
      {
        if(locationList)
          {
            locationList.map((item)=> {
              delete newFilter[item.key] ;
            })
          }
      }
      else if(filterObj)
      {
        newFilter[key] = [{searchType :  searchType || 1 , value : (filterObj) || null}] ; 

      }
      else if(!filterObj)
        delete newFilter[key] ;

      setResaleFilters(newFilter) ; 
  
    };
    

  const onTypeChanged = useCallback(
    (activeType) => {
      const ViewTypeData = JSON.parse(localStorage.getItem("ViewType"));
      if (ViewTypeData) {
        ViewTypeData.unitResaleView = activeType;
        localStorage.setItem("ViewType", JSON.stringify(ViewTypeData));
      } else
        localStorage.setItem(
          "ViewType",
          JSON.stringify({ unitResaleView: activeType })
        );

      setActiveActionType(activeType);
      setIsExpanded(activeType === ViewTypesEnum.cardsExpanded.key);
      sideMenuIsOpenUpdate(false);
      sideMenuComponentUpdate(null);
    },
    [setActiveActionType]
  );
  const onPageIndexChanged = (pageIndex) => {
    localStorage.setItem(
      "unitsResaleCurrentPageIndex",
      JSON.stringify(pageIndex)
    );
    setFilter((item) => ({ ...item, pageIndex }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const getCommaSeperatedIds = (IdsArray) => {
    let IdsString = IdsArray.reduce(
      (result, item) => result + item.id + ",",
      ""
    );
    return IdsString.slice(0, IdsString.length - 1);
  };

  useEffect(() => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitsResaleDate: dateFilter || dateRangeDefault,
      })
    );
  }, [dateFilter]);

  const restorePageIndex = useCallback(() => {
    const currentPageIndex = localStorage.getItem("unitsResaleCurrentPageIndex");

    if (currentPageIndex) {
      const pageIndex = JSON.parse(currentPageIndex);
      setFilter((item) => ({ ...item, pageIndex }));
    }
  }, []);

  useEffect(() => {
    restorePageIndex();
  }, []);

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const filterOnChange = (event, newValue) => {
    const emptyKeyIndex = newValue.findIndex((item) => !item.value);
    if (!searchInputValue && emptyKeyIndex !== -1) {
      newValue.splice(emptyKeyIndex, 1);
      return;
    }
    if (emptyKeyIndex !== -1) newValue[emptyKeyIndex].value = searchInputValue;
    if (
      filterSearchDto &&
      Object.keys(filterSearchDto).length > 0 &&
      newValue.length === 0
    ) {
      onPageIndexChanged(0);
      setFilterSearchDto(null);
    }
    if (!(newValue && newValue.length))
      localStorage.removeItem("UnitResaleFilter");
    setSearchData([...newValue]);
  };
  const getAllSearchableFormFieldsByFormId = useCallback(async () => {
    const result = await GetAllSearchableFormFieldsByFormId(
      FormsIdsEnum.units.id
    );
    if (
      !(result && result.data && result.data.ErrorId) &&
      result &&
      Array.isArray(result)
    ) {
      const res = result
        .filter(
          (item) =>
            item.isSearchable &&
            item.formFieldId !==
              ListingAgentIdsEnum.RentListingAgent.formFieldId
        )
        .map((item) => ({
          key: item.searchableKey || item.displayPath,
          title: item.formFieldTitle,
        }))
        .concat(ArrayOFSearchableFormFields);
      setSearchableFormFields([{ key: "Address", title: "Address" }, ...res]);
    } else setSearchableFormFields([]);
  }, []);

  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.units.id);
    if (
      !((result && result.data && result.data.ErrorId) || !result) &&
      Array.isArray(result)
    ) {
      const list = result
        .filter(
          (e) =>
            e.formFieldId !==
              ListingAgentIdsEnum.RentListingAgent.formFieldId &&
            e.formFieldName !== "amenities" &&
            e.formFieldName !== "fitting_and_fixtures" &&
            e.formFieldName !== "unit_model" &&
            e.formFieldName !== "rent_price_fees" &&
            e.formFieldName !== "selling_price_agency_fee" &&
            e.formFieldName !== "primary_view" &&
            e.formFieldName !== "secondary_view" &&
            e.formFieldName !== "owner"
        )
        .map((field) => ({
          id: field.formFieldId || null,
          key: field.formFieldKey || null,
          isDate: field.uiWidgetType === "alt-date" || false,
          label:
            (field.formFieldTitle === "Lead Owner"
              ? (field.formFieldTitle = "Unit Owner")
              : field.formFieldTitle &&
                field.formFieldTitle.replace("*", "")) || "",
          input: field.displayPath || "",
          isNumber:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey ===
                "currency") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "decimal") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "size"),
          isSortable: true,
          searchableKey: field.searchableKey,
          data: JSON.parse(field.propertyJson).schema,
          fieldType:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema.uiType) ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).uiSchema["ui:widget"]) ||
            (field.uiWidgetType === null && "text"),
        }));

      setAllFormFields(list);
    } else setAllFormFields([]);
    setIsAllFormFieldsLoading(false);
  }, []);

  const searchAdvanceSearch = (newFilter) => {
    const searchObj = newFilter ? { ...newFilter } : null;
    if (!(newFilter && newFilter.length))
      localStorage.removeItem("UnitResaleFilter");

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        UnitResaleFilter: searchObj || null,
      })
    );
    setUnitsTableFilter(searchObj);
  };
  const GetSideMenuStatus = useCallback(() => {
    const isMenuOpen = getSideMenuStatus();
    const activeComponentOpen =
      getSideMenuComponent() && getSideMenuComponent().type;

    if (!isMenuOpen) setAdvanceSearchBtn(true);
    else if (activeComponentOpen === AdvanceSearch) setAdvanceSearchBtn(false);
  });

  const onAdvanceSearchClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (advanceSearchBtn) {
        sideMenuIsOpenUpdate(true);
        setAdvanceSearchBtn(false);
      } else {
        sideMenuIsOpenUpdate(false);
        setAdvanceSearchBtn(true);
      }

      sideMenuComponentUpdate(
        <AdvanceSearchSecondVersion
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onFilterValuesChanged={onFilterValuesChanged}
          localStorageKey="UnitResaleFilter"
          isAdvanceSearchCleared={isAdvanceSearchCleared}
          setIsAdvanceSearchCleared={setIsAdvanceSearchCleared}
          setOrderBy={setOrderBy}
          searchCriteriaTypeId={SearchCriteriaEnum.Unit.typeId}
          setIsAdvanceSearchActive={setIsAdvanceSearchActive}
          fields={[...UnitsSalesTableHeaderData, ...allFormFields]}
          selectFieldsOnAdvanceSearch={selectFieldsOnAdvanceSearch}
          setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
          setFilterValuesFromAdvanceSearch={setFilterValuesFromAdvanceSearch}
          filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
          searchAdvanceSearch={searchAdvanceSearch}
          unitType={"sale"}
        />
      );
    },
    [
      isAllFormFieldsLoading,
      allFormFields,
      advanceSearchBtn,
      isAdvanceSearchCleared,
      selectFieldsOnAdvanceSearch,
    ]
  );


  const checkDuplicatedUnitsButton = useCallback(() => {
    GlobalHistory.push(`/home/units-sales/duplicated-units`);
  });

  const onClearedAllFiltersClick = useCallback(() => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setActionButtonsKey(generateUniqueKey());
    setOrderBy({ filterBy: "createdOn", orderBy: 2 });
    setSearchData([]);
    setDateFilter(dateRangeDefault);
    setUnitPublishStatus(0);
    setUnitStatus([]);
    setUnitsPortal([]);
    setIsClearFiltersClicked(true);
    setIsAdvanceSearchCleared(true);

    setUnitsTableFilter(null);
    setFilterSearchDto(null);
    sideMenuIsOpenUpdate(false);
    setIsAdvanceSearchActive(false);
    setFilterValuesFromAdvanceSearch();
    setSelectFieldsOnAdvanceSearch([]);
    onActionButtonChanged(ActionsButtonsEnum[1].id);
    setIsStatusFilterDisabled(false);
    setResaleFilters({})

    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitsResalesStatus: [],
        PublishResalePortal: [],
        UnitResalesPublishStatusFilter: 0,
        UnitResaleFilter: {
          filterBy: null,
          orderBy: null,
        },
      })
    );

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        UnitResaleFilter: null,
      })
    );
  }, []);

  const getUnitsData = useCallback(
    async (f) => {
      if(returnPropsByPermissions(ResaleUnitsPermissions.ViewResaleUnits.permissionsId)){
        setIsLoadingAllUnits(true);
        const index = unitStatus.findIndex((el) => el === 0);
        if (index !== -1) unitStatus.splice(index, 1);
        if (unitStatus.length === 0) unitStatus.push(0);

        const indexPortal = unitsPortal.findIndex((el) => el === 0);
        if (indexPortal !== -1) unitsPortal.splice(indexPortal, 1);
        if (unitsPortal.length === 0) unitsPortal.push(0);

        const localFilterDto = f || filterSearchDto || {};

        const filterCreatedByRole = loginResponse.roles.find(
          (role) => role.rolesName === "Filter Created By"
        );
        const filterListingAgentRole = loginResponse.roles.find(
          (role) => role.rolesName === "Filter Listing Agent"
        );
        const filterDubaiTeamRole = loginResponse.roles.find(
          (role) => role.rolesName === "Filter Dubai Team"
        );
        if (filterCreatedByRole) {
          localFilterDto.createdById = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: loginResponse.userId,
            },
          ];
        }
        if (filterListingAgentRole) {
          localFilterDto.listing_agent_id = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: loginResponse.userId,
            },
          ];
        }
        if (filterDubaiTeamRole) {
          localFilterDto.city = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: "dubai",
            },
          ];
        }

        if (unitStatus && unitStatus.length >= 1 && unitStatus[0] !== 0) {
          localFilterDto.status = unitStatus.map((item) => ({
            searchType: TableFilterOperatorsEnum.equal.key,
            value: (item || "").toLowerCase(),
          }));
        }

        if (unitsPortal && unitsPortal.length >= 1 && unitsPortal[0] !== 0) {
          localFilterDto.PortalId = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: `${unitsPortal}`,
            },
          ];
        }
        if (unitPublishStatus) {
          if (unitPublishStatus === 1) {
            localFilterDto.publish_status = [
              {
                searchType: TableFilterOperatorsEnum.equal.key,
                value: true,
              },
            ];
          } else if (unitPublishStatus === 2) {
            localFilterDto.publish_status = [
              {
                searchType: TableFilterOperatorsEnum.equal.key,
                value: false,
              },
            ];
          }
        }

        if (unitsTableFilter) {
          Object.values(unitsTableFilter)
            .filter((item) => item.searchableKey || item.displayPath)
            .map((item) => {
              if (localFilterDto[item.searchableKey || item.displayPath]) {
                localFilterDto[item.searchableKey || item.displayPath].push({
                  searchType: item.operator,
                  value: item.value,
                });
              } else if (item.value) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: item.value,
                  },
                ];
              } else if (
                !item.value &&
                (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
                  item.operator === TableFilterOperatorsEnum.isBlank.key)
              ) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: null,
                  },
                ];
              }
              return undefined;
            });
        }

        if (Object.values(localFilterDto).length)
          setIsAdvanceSearchActive(true);
        else setIsAdvanceSearchActive(false);

        let body = {
          criteria: {...localFilterDto , ...resaleFilters , 
          sale_type :[{searchType: 1, value: "Resale"}] 
          },
          ...orderBy,
          OperationType: "Sale",
        };

        setIsDatePickerChanged(false);
        setIsClearFiltersClicked(false);


        if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
          const fromDate = moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss");
          const toDate = moment(dateFilter.endDate).format(
            "YYYY-MM-DDTHH:mm:ss"
          );

          if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.CreatedOnDate.key
          ) {
            body.fromDate = fromDate;
            body.toDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.UpdatedOnDate.key
          ) {
            body.updatedFromDate = fromDate;
            body.updatedToDate = toDate;
          } else if (
            dateFilter.selectedDateType === DateFilterTypesEnum.ListingDate.key
          ) {
            body.fromListingDate = fromDate;
            body.toListingDate = toDate;
          } else if (
            dateFilter.selectedDateType === DateFilterTypesEnum.PublishDate.key
          ) {
            body.fromPublishDate = fromDate;
            body.toPublishDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.ExpiredListingDate.key
          ) {
            body.fromExpiryListingDate = fromDate;
            body.toExpiryListingDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.SaleTransactedDate.key
          ) {
            body.fromTransactedDate = fromDate;
            body.toTransactedDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.SaleReservedTransactionDate.key
          ) {
            body.fromReservedDate = fromDate;
            body.toReservedDate = toDate;
          }
          else if ( dateFilter.selectedDateType ===  DateFilterTypesEnum.HandoverDate.key)
            { 
               const filterUpdate = { ...resaleFilters , 
                handover_date_from : [{searchType: 5, value: fromDate}] , 
                handover_date_to :[{searchType: 6, value: toDate }]
                }

               body = { ...body , 
                criteria: {...localFilterDto , ...filterUpdate },
              
              };
      

            }
            
        }

        const res = await UnitsAdvanceSearchTest(filter, body);
        if (!(res && res.status && res.status !== 200) && res && res.result) {
          const result = ((res && res.result) || []).map(
            (item) =>
              item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit)
          );
          setDetailsUnitsList({
            result: result,
            totalCount: (res && res.totalCount) || 0,
          });
          setIsLoadingAllUnits(false);
        } else {
          setDetailsUnitsList({
            result: [],
            totalCount: 0,
          });
          setIsLoadingAllUnits(false);
        }}
    },
    [
      dateFilter,
      filter,
      filterSearchDto,
       orderBy,
      unitStatus,
      unitsPortal,
      unitPublishStatus,
     unitsTableFilter,
     resaleFilters
      
    ]
  );
  const searchClicked = async () => {
    localStorage.setItem("UnitResaleFilter", JSON.stringify(searchData));
    setFilterSearchDto(
      searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType:
                TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
    );
    onPageIndexChanged(0);
  };

  const searchchachedClickedWithoutFilter = async (data) => {
    if (data.length === 0) return;
    const oldfilter = data.reduce((total, item) => {
      if (total[item.key]) {
        total[item.key].push({
          searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
          value: item.value,
        });
      } else {
        total[item.key] = [
          {
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          },
        ];
      }
      return total;
    }, {});
    getUnitsData(oldfilter);
  };
  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (actionEnum === TableActions.openFile.key) {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}&operationType=${item.operationType}`
        );
      }
      if (actionEnum === TableActions.addActivity.key) {
        setAddActivity(true);
        setActiveItem(item);
      }
    },
    [dispatch]
  );
  const displayedLabel = (option) => `${option.title}: ${searchInputValue}`;
  const disabledOptions = (option) => option.disabledOnSelect;
  const chipsLabel = (option) => `${option.title}: ${option.value}`;
  const inputValueChanged = (event, newInputValue) => {
    setSearchInputValue(newInputValue);
  };

  const onActionButtonChanged = (activeAction) => {
    setActiveSelectedAction(activeAction);
    setCheckedCards([]);
    setCheckedDetailedCards([]);
    setUnitsIdUpdateArray([]);

    if (activeAction === ActionsButtonsEnum[17].id) {
      changePublishStatus(0);
      setUnitPublishStatus(0);
      onFilterStatusChange(["Sale", "Draft", "ReservedSale"]);
    } else if (activeAction === ActionsButtonsEnum[18].id) {
      changePublishStatus(0);
      setUnitPublishStatus(0);
      onFilterStatusChange(["Available", "Sale", "ReservedSale"]);
    } else if (activeAction === ActionsButtonsEnum[19].id) {
      onFilterStatusChange(["Available"]);
      changePublishStatus(2);
      setUnitPublishStatus(2);
    } else if (activeAction === ActionsButtonsEnum[25].id) {
      onFilterStatusChange(["Available"]);
      changePublishStatus(2);
      setUnitPublishStatus(2);
    } else if (activeAction === ActionsButtonsEnum[20].id) {
      onFilterStatusChange([]);
      changePublishStatus(1);
      setUnitPublishStatus(1);
    }
  };

  const getActiveUserRoles = async () => {
    setIsLoading(true);
    const response = await GetAllRolesByUserId(loginResponse.userId, 1, 30);
    if (!(response && response.status && response.status !== 200)) {
      setSavedRoles(response);
    }
    setIsLoading(false);
  };

  const isNeedApprovalMessageInMarketing = async (isPublishAction) => {
    const body = {
      userBranchId: loginResponse?.branchId,
      isPublishAction,
      roleIds: savedRoles?.result?.map((item) => item.rolesId),
      userId: loginResponse?.userId,
    };
    try {
      const response = await IsNeedApprovalMessageInMarketing(body);

      if (!(response && response.status && response.status !== 200)) {
        return response;
      }
      return false;
    } catch (error) {
      console.error("API call failed:", error);
      return false;
    }
  };

  const onActionsButtonClicked = useCallback((activeAction) => {
    if (activeAction === ActionsButtonsEnum[1].id)
      GlobalHistory.push("/home/units-sales/add?formType=1");

    if (activeAction === ActionsButtonsEnum[16].id)
      setIsPropasalDialogOpen(true);
  }, []);

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      setHash(activeData.id);
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      event.stopPropagation();
      if (actionEnum === ActionsEnum.folder.key) {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}&operationType=${activeData.operationType}`
        );
      } else if (actionEnum === ActionsEnum.matching.key) {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${
            activeData.unitTypeId
          }&id=${activeData.id}&operationType=${
            activeData.operationType
          }&matching=${true}`
        );
      }
    },
    [dispatch]
  );
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitResaleFilter: {
          filterBy: selectedOrderBy?.filterBy,
          orderBy: selectedOrderBy?.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setOrderByToggler(false);
  };
  const cardCheckboxClicked = useCallback((itemIndex, element) => {
    setCheckedDetailedCards((items) => {
      const index = items.findIndex((item) => item === itemIndex);
      if (index !== -1) items.splice(index, 1);
      else items.push(itemIndex);
      return [...items];
    });
    setCheckedCards((items) => {
      const index = items.findIndex((item) => item.id === element.id);
      if (index !== -1) items.splice(index, 1);
      else items.push(element);
      return [...items];
    });
    setUnitsIdUpdateArray((items) => {
      if (items.includes(element.id)) {
        return items.filter((item) => item !== element.id);
      } else {
        return [...items, element.id];
      }
    });
  }, []);

  const getAllSystemTemplateByCategoryId = useCallback(async () => {
    //setloadings(true);
    const res = await GetAllSystemTemplateByCategoryId(8);
    if (!(res && res.status && res.status !== 200)) {
      const mappedTempatesData =
        res &&
        res.map((item) => ({
          systemTemplateName: item.systemTemplateName,
          systemTemplateId: item.systemTemplateId,
        }));
      setTemplatesProposal(mappedTempatesData || []);
    } else setTemplatesProposal([]);
    // setloadings(false);
  }, [filter]);

  useEffect(() => {
    localStorage.setItem("bulk-assign-ids", JSON.stringify(checkedCards));
  }, [checkedCards]);
  const reloadData = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
    setCheckedDetailedCards([]);
    setCheckedCards([]);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  }, [getUnitsData]);

  const getUnitDetails = async (unitId) => {
    try {
      const res = await GetUnitDetails(unitId);
      setLeadOwnerDetails(res.leadOwner);
      return res.leadOwner;
    } catch (error) {
      console.error("Error fetching unit details:", error);
    }
  };

  const onCardClick = useCallback(
    (item, selectedIndex) => async (event) => {
      event.stopPropagation();
      event.preventDefault();
      const selectedleadOwnerDetails = await getUnitDetails(item.id);
      setActiveCard(item);
      if (getSideMenuStatus()) setAdvanceSearchBtn(true);
      dispatch(ActiveItemActions.activeItemRequest(item));
      const activeData = detailsUnitsList.result[selectedIndex];
      const ownerMobileNumber = {
        iconClasses: "mdi mdi-point-of-sale",
        title: "owner-mobile-number",
        value: !selectedleadOwnerDetails?.phone
          ? "N/A"
          : selectedleadOwnerDetails?.phone,
      };

      const details = [
        ...activeData.details.slice(0, 1),
        ownerMobileNumber,
        ...activeData.details.slice(1),
      ];

      const updatedActiveData = {
        ...activeData,
        details,
      };

      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={updatedActiveData}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          reloadData={reloadData}
          displyOpenFileButton={displyOpenFileButton}
          displyMatchingButton={displyMatchingButton}
          showQuickTransaction={![6, 13, 14].includes(activeData.status)}
          onClickActiveData={() => {
            onOpenDialogHandler("Seller Information");
          }}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [
      detailedCardSideActionClicked,
      detailsUnitsList.result,
      reloadData,
      advanceSearchBtn,
    ]
  );

  const changePublishStatus = (value) => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitResalesPublishStatusFilter: value,
      })
    );
  };

  const checkBulkDesabled = (enums) => {
    if (
      enums === ActionsButtonsEnum[4].id ||
      enums === ActionsButtonsEnum[17].id ||
      enums === ActionsButtonsEnum[18].id ||
      enums === ActionsButtonsEnum[19].id ||
      enums === ActionsButtonsEnum[20].id ||
      enums === ActionsButtonsEnum[25].id
    )
      return !(checkedCards && checkedCards.length >= 2);

    return false;
  };

  const onSelectClicked = useCallback(
    (row, rowIndex) => {
      setCheckedDetailedCards((items) => {
        const index = items.findIndex((item) => item === rowIndex);
        if (index !== -1) items.splice(index, 1);
        else items.push(rowIndex);
        return [...items];
      });

      setUnitsIdUpdateArray((items) => {
        if (items.includes(row.id)) {
          return items.filter((item) => item !== row.id);
        } else {
          return [...items, row.id];
        }
      });

      setCheckedCards((items) => {
        const index = items.findIndex((item) => item.id === row.id);
        if (index !== -1) items.splice(index, 1);
        else items.push(row);
        return [...items];
      });
    },
    [checkedCards]
  );

  const getIsSelected = useCallback(
    (row) =>
      checkedCards &&
      checkedCards.findIndex((item) => item.id === row.id) !== -1,
    [checkedCards]
  );

  const focusedRowChanged = useCallback(
    async (rowIndex) => {
      if (rowIndex !== -1) {
        const activeData = detailsUnitsList.result[rowIndex];

        sideMenuComponentUpdate(
          <CardDetailsComponent
            activeData={detailsUnitsList.result[rowIndex]}
            cardDetailsActionClicked={detailedCardSideActionClicked}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            reloadData={reloadData}
            getUnitsData={getUnitsData}
            displyOpenFileButton={displyOpenFileButton}
            displyMatchingButton={displyMatchingButton}
            showQuickTransaction={![6, 13, 14].includes(activeData.status)}
            onClickActiveData={() => {
              onOpenDialogHandler("Seller Information");
            }}
          />
        );
        sideMenuIsOpenUpdate(true);
      } else {
        sideMenuComponentUpdate(<></>);
        sideMenuIsOpenUpdate(false);
      }
    },
    [detailedCardSideActionClicked, detailsUnitsList.result, reloadData]
  );

  const onFilterValuesChanged = (newValue) => {
    setUnitsTableFilter(newValue);
  };

  useEffect(() => {
    getAllSearchableFormFieldsByFormId();
  }, [getAllSearchableFormFieldsByFormId]);
  useEffect(() => {
    if (
      returnPropsByPermissions(
        UnitsSalesPermissions.ViewAndSearchInSaleUnits.permissionsId
      )
    ) {
      const currentPageIndex = localStorage.getItem(
        "unitsResaleCurrentPageIndex"
      );
      const pageIndex = JSON.parse(currentPageIndex);
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={pageIndex || filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={detailsUnitsList.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  const onFilterStatusChange = (value) => {
    setUnitStatus(value);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitsResaleStatus: value,
      })
    );
  };

  const changePublishPortal = (value) => {
    setUnitsPortal(value);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        PublishResalePortal: value,
      })
    );
  };

  useEffect(() => {
    const data = localStorage.getItem("UnitResaleFilter");
    if (data) {
      const saleSearchFilter = JSON.parse(data);
      setSearchData(saleSearchFilter);
      if (saleSearchFilter && saleSearchFilter.length)
        searchchachedClickedWithoutFilter(saleSearchFilter);
    } else getUnitsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirst1) {
      if (searchData && searchData.length === 0) {
        localStorage.removeItem("UnitResaleFilter");
        setSearchedItem("");
      } else localStorage.setItem("UnitResaleFilter", JSON.stringify(searchData));
    } else setFirst1(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  useEffect(() => {
    if (!isFirst) setFirst(true);
    else {
      const data = localStorage.getItem("UnitResaleFilter");
      if (data) {
        const dataConvert = JSON.parse(data);
        if (dataConvert && dataConvert.length)
          searchchachedClickedWithoutFilter(dataConvert);
      } else if (searchData && searchData.length === 0) getUnitsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterSearchDto,
    filter,
    unitsTableFilter,
    searchData,
    resaleFilters ,
    dateFilter ,
    orderBy
  ]);

  const getLookupItem = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    const res = await GetLookupItems(1, 25, "PortalName");
    if (!(res && res.data && res.data.ErrorId)) {
      setPortalData(res && res.result);
    } else setPortalData([]);
  }, []);

  useEffect(() => {
    getLookupItem();
    getActiveUserRoles();
  }, []);

  useEffect(() => {
    if (isFirst && isDatePickerChanged) {
      const searchDataFilter = searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType:
                TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {});
      getUnitsData(searchDataFilter);
    }
  }, [dateFilter, isDatePickerChanged, searchData]);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);
  
  useEffect(() => {
    getAllSystemTemplateByCategoryId();
  }, [getAllSystemTemplateByCategoryId]);

  useEffect(() => {
    GetSideMenuStatus();
  }, [GetSideMenuStatus]);

  useEffect(() => {
    setDisplyOpenFileButton(
      havePermissionToViewDetails(UnitsVerticalTabsData.sale)
    );
    setDisplyMatchingButton(
      havePermissionToEditInformation(
        UnitsSalesPermissions.ViewUnitMatching.permissionsId
      )
    );
  }, [isFirst]);

  const onCloseDialogHandler = () => {
    setOwnerDetailsDialog({
      ...ownerDetailsDialog,
      isOpen: false,
      titleText: "",
    });
  };

  const onUnitsBulkCloseDialogHandler = () => {
    setUnitsBulkStatusUpdateIsOpen(false);
  };
  const getIsActionDisabled = () => {
    const isPropsalUnitsUnchecked =
      (activeSelectedAction === "send-Proposal" && checkedCards?.length == 0);
    return isPropsalUnitsUnchecked;
  };

  const onOpenDialogHandler = (titleText) => {
    setOwnerDetailsDialog({
      ...ownerDetailsDialog,
      isOpen: true,
      titleText,
    });
  };

  const onFilterChanged = useCallback(
    (activeSelectedAction) => {
      if (
        activeSelectedAction === ActionsButtonsEnum[17].id &&
        ((unitStatus.length === 3 && unitStatus.includes("Available")) ||
          unitStatus.length !== 3)
      ) {
        onActionButtonChanged(ActionsButtonsEnum[1].id);
        setActionButtonsKey(generateUniqueKey());
      } else if (
        activeSelectedAction === ActionsButtonsEnum[18].id &&
        ((unitStatus.length === 3 && unitStatus.includes("Draft")) ||
          unitStatus.length !== 3)
      ) {
        onActionButtonChanged(ActionsButtonsEnum[1].id);
        setActionButtonsKey(generateUniqueKey());
      } else if (
        activeSelectedAction === ActionsButtonsEnum[19].id &&
        ((unitStatus.length === 1 && !unitStatus.includes("Available")) ||
          unitStatus.length !== 1 ||
          +unitPublishStatus !== 2)
      ) {
        onActionButtonChanged(ActionsButtonsEnum[1].id);
        setActionButtonsKey(generateUniqueKey());
      } else if (
        activeSelectedAction === ActionsButtonsEnum[25].id &&
        ((unitStatus.length === 1 && !unitStatus.includes("Available")) ||
          unitStatus.length !== 1 ||
          +unitPublishStatus !== 2)
      ) {
        onActionButtonChanged(ActionsButtonsEnum[1].id);
        setActionButtonsKey(generateUniqueKey());
      } else if (
        activeSelectedAction === ActionsButtonsEnum[20].id &&
        +unitPublishStatus !== 1
      ) {
        onActionButtonChanged(ActionsButtonsEnum[1].id);
        setActionButtonsKey(generateUniqueKey());
      }
    },
    [activeSelectedAction, unitStatus, unitPublishStatus]
  );

  useEffect(() => {
    onFilterChanged(activeSelectedAction);
  }, [activeSelectedAction, unitStatus, unitPublishStatus]);

  return (
    <>
      <div className="view-wrapper resale-view">
        {!isOpen && <Spinner isActive={isLoadingAllUnits} />}
        <div className="d-flex-column">
          <div className="header-section">
            <div className="filter-section fa-start">
            <div className="section  " style={{ flexDirection: "column" }}>
            <ActionsButtonsComponent
                  permissionsList={Object.values(ResaleUnitsPermissions)}
                  addPermissionsId={
                    ResaleUnitsPermissions.AddResaleUnits.permssionsId
                  }
                  selectPermissionsId={[
                    ResaleUnitsPermissions.AddResaleUnits.permissionsId,
                    ResaleUnitsPermissions.SendProposal.permissionsId,
                    ResaleUnitsPermissions.QuickTransaction.permissionsId
                  ]}
                  onActionsButtonClicked={onActionsButtonClicked}
                  onActionButtonChanged={onActionButtonChanged}
                  enableSendProposal={returnPropsByPermissions(
                    ResaleUnitsPermissions.SendProposal.permissionsId
                  )}
                  isDisabled={
                   (activeSelectedAction ===  'add' || !activeSelectedAction  ? false : (activeTemplateId === null || getIsActionDisabled())) 
                  // (activeTemplateId === null || getIsActionDisabled())
                  }
                  enableMakeTransaction={returnPropsByPermissions(ResaleUnitsPermissions.QuickTransaction.permissionsId)}
                  wrapperClasses="wrap-flex"
                  separateMakeTransactionButton={returnPropsByPermissions(ResaleUnitsPermissions.QuickTransaction.permissionsId)}
                />
                <div className="mt-2">  
               
                {activeSelectedAction === "send-Proposal" && (
                   <PermissionsComponent
                   permissionsList={Object.values(ResaleUnitsPermissions)}
                   permissionsId={
                     ResaleUnitsPermissions.SendProposal.permissionsId
                   }
                  >
                   <SelectComponet
                   data={templatesProposal}
                   keyLoopBy="systemTemplateId"
                   valueInput="systemTemplateId"
                   textInput="systemTemplateName"
                   value={activeTemplateId}
                   emptyItem={{
                     value: null,
                     text: "select-template",
                     isDisabled: false,
                   }}
                   keyValue="actionsbuttons"
                   idRef="contactsActionsRef"
                   onSelectChanged={(value) => {
                     setActiveTemplateId(value);
                   }}
                   themeClass="theme-action-buttons"
                   translationPath={translationPath}
                   translationPathForData={translationPath}
                   wrapperClasses="bg-secondary c-white mx-2"
                   parentTranslationPath={parentTranslationPath}
                 />
                 </PermissionsComponent>
                )}
                </div>
              </div>

              <PermissionsComponent
                permissionsList={Object.values(ResaleUnitsPermissions)}
                permissionsId={
                  ResaleUnitsPermissions.ViewResaleUnits.permissionsId
                }
              >
                <div className="section autocomplete-section">
                  <div className="d-flex-column px-2 w-100 p-relative">
                    <ResaleUnitsFilterComponent
                       isClearFiltersClicked={isClearFiltersClicked}
                      resaleChangeFilters={(key , value , locationList ,  searchType )=> resaleChangeFilters(key , value , locationList , searchType)}
                      dateFilter={dateFilter}
                      setDateFilter={setDateFilter}
                      setSortByFilter={setSortByFilter}
                      setOrderBy={setOrderBy}
                      setIsClearSortBy={setIsClearSortBy}
                      isClearSortBy={isClearSortBy}
                      
                    />
                  </div>
                  <div className="view-search-wrapper">
                    <ViewTypes
                      onTypeChanged={onTypeChanged}
                      initialActiveType={activeActionType}
                      className="mb-3"
                    />
                    <span className="advance-search">
                      <ButtonBase
                        onClick={onAdvanceSearchClick}
                        id="AdvanceSearch_bbt"
                        disabled={isAllFormFieldsLoading}
                        className={`btns theme-solid ${
                          isAdvanceSearchActive &&
                          !isAllFormFieldsLoading &&
                          "is-active-filter"
                        }`}
                      >
                        <span
                          className={`${
                            advanceSearchBtn
                              ? "mdi mdi-arrow-expand-left"
                              : "mdi mdi-arrow-expand-right"
                          } mr-1`}
                        />
                        {t(`${translationPath}advance-search`)}
                      </ButtonBase>
                    </span>
                    <ButtonBase
                      onClick={onClearedAllFiltersClick}
                      id="onClearedAllFiltersref"
                      disabled={isAllFormFieldsLoading}
                      className="btns theme-solid bg-danger clear-all-btn"
                    >
                      <span className="mdi mdi-filter-remove m-1" />
                      {t(`${translationPath}clear-filters`)}
                    </ButtonBase>
                  </div>
                </div>
              </PermissionsComponent>
            </div>
          </div>
          {activeActionType !== ViewTypesEnum.tableView.key && (
            <>
              <div className="body-section">
                <PermissionsComponent
                  permissionsList={Object.values(ResaleUnitsPermissions)}
                  permissionsId={
                    ResaleUnitsPermissions.ViewResaleUnits.permissionsId
                  }
                >
                  <UnitsCardsComponent
                    data={detailsUnitsList}
                    isExpanded={isExpanded}
                    onCardClicked={onCardClick}
                    onFooterActionsClicked={detailedCardSideActionClicked}
                    checkedDetailedCards={checkedDetailedCards}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onCardCheckboxClick={cardCheckboxClicked}
                    activeCard={activeCard}
                    withCheckbox={activeSelectedAction === "send-Proposal"}
                    displyOpenFileButton={displyOpenFileButton}
                    displyMatchingButton={displyMatchingButton}
                  />
                </PermissionsComponent>
              </div>
            </>
          )}
          {activeActionType === ViewTypesEnum.tableView.key && (
             <PermissionsComponent
             permissionsList={Object.values(ResaleUnitsPermissions)}
             permissionsId={
               ResaleUnitsPermissions.ViewResaleUnits.permissionsId
             }
           >
              <UnitsSalesTableComponent
                detailsUnitsList={detailsUnitsList}
                tableActionClicked={tableActionClicked}
                onPageIndexChanged={onPageIndexChanged}
                onPageSizeChanged={onPageSizeChanged}
                unitsTableFilter={unitsTableFilter}
                onFilterValuesChanged={onFilterValuesChanged}
                filter={filter}
                parentTranslationPath={parentTranslationPath}
                focusedRowChanged={focusedRowChanged}
                onSelectClicked={onSelectClicked}
                setCheckedCards={setCheckedCards}
                checkedCardsIds={checkedCards}
                getIsSelected={getIsSelected}
                activeSelectedAction={activeSelectedAction}
                setOrderBy={setOrderBy}
                isClearFiltersClicked={isClearFiltersClicked}
                setIsClearFiltersClicked={setIsClearFiltersClicked}
                displyOpenFileButton={displyOpenFileButton}
                displyMatchingButton={displyMatchingButton}
                localStorageKey="UnitResaleFilter"
                setIsClearSortBy={setIsClearSortBy}
                isClearSortBy={isClearSortBy}
              />
            </PermissionsComponent>
          )}
          
        </div>
        {addActivity && (
          <ActivitiesManagementDialog
            open={addActivity}
            onSave={() => {
              setAddActivity(false);
            }}
            close={() => {
              setAddActivity(false);
            }}
            actionItemId={activeItem?.id}
            translationPath={""}
            parentTranslationPath={"ActivitiesView"}
          />
        )}
      </div>

      {ownerDetailsDialog.isOpen && (
        <DialogComponent
          isOpen={ownerDetailsDialog.isOpen}
          onCloseClicked={onCloseDialogHandler}
          titleText={ownerDetailsDialog.titleText}
          maxWidth="lg"
          dialogContent={
            <OwnerDetailsDialog
              data={leadOwnerDetails}
              translationPath={parentTranslationPath}
            />
          }
        />
      )}
      {isPropasalDialogOpen && (
        <UnitTemplateDialogPreviewDialog
          activeItem={activeTemplateId}
          isOpen={isPropasalDialogOpen}
          isOpenChanged={() => {
            setIsPropasalDialogOpen(false);
          }}
          unitIDsList={getCommaSeperatedIds(checkedCards)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}

      
    </>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import {
    GetParams, showError, showSuccess, returnPropsByPermissions
} from '../../../../Helper';
import { TableActions } from '../../../../Enums';
import { HistoryTabsComponent } from '../../../../Components/HistoryComponent/HistoryTabsComponent';

import { GetAllAgentByRotationSchemeServices, SetAgentInRotationSchemeAsActiveOrInActive } from '../../../../Services/RotaionSchemaService/RotationSchemaService';
import { Spinner, Tables, SwitchComponent } from '../../../../Components';

import { RotationSchemaPermissions } from '../../../../Permissions';

import { AssignAgentDialog } from './AssignAgentDialog';
import { DeleteAssignedAgent } from './DeleteAssignedAgent';
import './AssignAgent.scss';

export const AssignAgentTable = () => {
    const parentTranslationPath = 'Agents';
    const translationPath = '';
    const { t } = useTranslation(parentTranslationPath);
    const [activeItem, setActiveItem] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [isActiveAgentStatus, setIsActiveAgentStatus] = useState(true);
    const [activeAgent, setActiveAgent] = useState(null);
    const [isOpenHistoryDialog, setIsOpenHistoryDialog] = useState(false);

    const [RotationAgents, setRotationAgents] = useState({
        result: [],
        totalCount: 0
    });
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [filter, setFilter] = useState({
        // filterBy: '',
        // orderBy: 1,
        // eslint-disable-next-line radix
        pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
        pageIndex: 0,
        search: '',
        agentId: ''
    });

    const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
    };
    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };

    const tableActionClicked = useCallback((actionEnum, item) => {
        setActiveItem(item);
        if (actionEnum === TableActions.editText.key)
            setOpenDialog(true);

        if (actionEnum === TableActions.deleteText.key)
            setOpenDeleteDialog(true);

        if (actionEnum === TableActions.viewHistory.key)
            setIsOpenHistoryDialog(true);
    }, []);

    const getAllAgent = useCallback(async () => {
        setIsLoading(true);
        const res = await GetAllAgentByRotationSchemeServices({ id: GetParams('id'), pageSize: 300, pageIndex: filter.pageIndex + 1 });
        if (!(res && res.status && res.status !== 200))
            setRotationAgents({ result: res.result, totalCount: res.totalCount });
        else
            setRotationAgents({ result: [], totalCount: 0 });

        setIsLoading(false);
    }, [filter]);

    const setAgentInRotationSchemeAsActiveOrInActive = useCallback(async () => {
        setIsLoading(true);
        const agentId = activeAgent && activeAgent.agentId || null;
        const schemeId = GetParams('id') || null;
        const res = await SetAgentInRotationSchemeAsActiveOrInActive(agentId, schemeId, isActiveAgentStatus);
        if (!(res && res.status && res.status !== 200)) {
            getAllAgent();
            showSuccess(t(`${translationPath}agent-status-edit-successfully`));
        } else {
            showError(t(`${translationPath}failed-to-edit-agent-status`));
            setIsLoading(false);
        }
        setActiveAgent(null);
        setIsActiveAgentStatus(true);
    }, [activeAgent, isActiveAgentStatus]);

    useEffect(() => {
        getAllAgent();
    }, [getAllAgent]);
    useEffect(() => {
        if (activeAgent && activeAgent.agentId) setAgentInRotationSchemeAsActiveOrInActive();
    }, [setAgentInRotationSchemeAsActiveOrInActive]);

    return (

      <div className='assign-agent-wrapper m-3'>
        <Spinner isActive={isLoading} />
        <ButtonBase
          className='btns theme-solid px-3 m-3'
          onClick={() => { setOpenDialog(true); }}
          id='bbt-new-assign-agent'
        >
          <span className='mdi mdi-plus' />
          <span>{t(`${translationPath}new-assign-agent`)}</span>
        </ButtonBase>
        {
                returnPropsByPermissions(RotationSchemaPermissions.ManageAgentHistory.permissionsId) &&
                (
                <div className='d-flex-v-center-h-end mb-2'>
                  <span className='completed-history-wrapper'>
                    <ButtonBase onClick={() => setIsOpenHistoryDialog(true)} className='btns c-black-light history-button'>
                      <span className='mdi mdi-clock-time-four-outline' />
                      {t`${translationPath}history`}
                    </ButtonBase>
                  </span>
                </div>
                )
            }
        <Tables
          data={RotationAgents.result || []}
          headerData={[
                    {
                        id: 1,
                        label: 'agentName',
                        input: 'agentName',
                    },
                    {
                        id: 2,
                        label: 'overAll-schema-capacity',
                        input: 'agentLeadCapacityInRotation',
                    },
                    {
                        id: 3,
                        label: 'overAll-schema-status',
                        input: 'agentStatus',
                    },
                    {
                        id: 4,
                        label: 'lead-capacity/monthly',
                        input: 'monthlyCapacity',
                    },
                    {
                        id: 5,
                        label: 'lead-capacity/weekly',
                        input: 'weeklyCapacity',
                    },
                    {
                        id: 6,
                        label: 'lead-capacity/daily',
                        input: 'dailyCapacity',
                    },
                    {
                        id: 7,
                        label: `${t('agent-status')}`,
                        input: 'isActive',
                        component: (item, index) => (
                          <>
                            <SwitchComponent
                              idRef={`isActiveRef${index + 1}`}
                              isChecked={item.isActive || false}
                              isDisabled={!(returnPropsByPermissions(RotationSchemaPermissions.ActiveDeactiveAgents.permissionsId))}
                              labelClasses='px-0'
                              onChangeHandler={(e, checkedValue) => {
                                        setActiveAgent(item);
                                        setIsActiveAgentStatus(checkedValue);
                                    }}
                              themeClass='theme-line'
                              labelValue={(item.isActive && `${t('Active')}`) || `${t('Deactive')}`}
                              parentTranslationPath={parentTranslationPath}
                              translationPath={translationPath}
                            />

                          </>
                        )
                    },
                    // {
                    //     id: 8,
                    //     label: 'number-of-leads',
                    //     input: 'numberOfLeads',
                    // },
                    // {
                    //     id: 9,
                    //     label: 'number-of-active-leads',
                    //     input: 'numberOfActiveLeads',
                    // },
                    // {
                    //     id: 10,
                    //     label: 'monthlyLeads',
                    //     input: 'monthlyLeads',
                    // },
                    // {
                    //     id: 11,
                    //     label: 'monthlyActiveLeads',
                    //     input: 'monthlyActiveLeads',
                    // },
                    // {
                    //     id: 12,
                    //     label: 'weeklyLeads',
                    //     input: 'weeklyLeads',
                    // },
                    // {
                    //     id: 13,
                    //     label: 'weeklyActiveLeads',
                    //     input: 'weeklyActiveLeads',
                    // },
                    // {
                    //     id: 13,
                    //     label: 'dailyLeads',
                    //     input: 'dailyLeads',
                    // },
                    // {
                    //     id: 14,
                    //     label: 'dailyActiveLeads',
                    //     input: 'dailyActiveLeads',
                    // },

                ]}
          defaultActions={[
                    {
                        enum: TableActions.editText.key,
                    },
                    {
                        enum: TableActions.deleteText.key,
                    },

                ]}
          actionsOptions={{
                    onActionClicked: tableActionClicked,
                }}
          setSortBy={setSortBy}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(RotationAgents && RotationAgents.totalCount) || 0}
        />

        {openDialog && (
        <AssignAgentDialog
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isOpen={openDialog}
          currentAssignedAgent={RotationAgents.result}
          data={RotationAgents.result || []}
          reloadData={() => {
                        getAllAgent();
                        setOpenDialog(false);
                        setActiveItem(null);
                    }}
          activeItem={activeItem}
                    // isLoading={isLoading}
          isClose={() => {
                        setOpenDialog(false);
                        setActiveItem(null);
                    }}
        />
            )}
        {openDeleteDialog && (
        <DeleteAssignedAgent
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          isOpen={openDeleteDialog}
          agentId={activeItem && activeItem.agentId}
          isClose={() => {
                        setActiveItem(null);
                        setOpenDeleteDialog(false);
                    }}
          reloadData={() => {
                        getAllAgent();
                        setActiveItem(null);
                        setOpenDeleteDialog(false);
                    }}
        />
            )}

        {isOpenHistoryDialog && (
        <HistoryTabsComponent
          isOpen={isOpenHistoryDialog}
          isOpenChanged={() => setIsOpenHistoryDialog(false)}
          typeId='manageAgents'
          rotationSchemeId={(+GetParams('id'))}
        />
            )}
      </div>
    );
};

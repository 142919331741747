import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { ButtonBase } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  AutocompleteComponent,
  DataFileAutocompleteComponent,
  Tables,
} from "../../../../../Components";
import {
  CheckLeadAssociatedWithPendingTransaction,
  GetAllLeadsByContactId,
  GetContacts,
  leadPost,
  GetUnitTransactionTenant,
  AddOfferLetterTenantDetails
} from "../../../../../Services";
import { getErrorByName, showError, showSuccess, showWarn } from "../../../../../Helper";
import { ContactsDialog } from "../../../FormBuilder/Dialogs/ContactsDialog";
import { TransactionCreateLeadDialog } from "../../../../../SharedComponents/UnitTransactionLeadCreateDialog/TransactionCreateLeadDialog";
import { TransactionUpdateContactDialog } from "../../../../../SharedComponents/UnitTransactionUpdateContactDialog/UnitTransactionUpdateContactDialog";
import { CONTACTS, LEADS } from "../../../../../config/pagesName";
import {
  LeadsClassTypesEnum,
  LeadTypeIdEnum,
  TableActions,
  FormsIdsEnum,
  ScopeDocumentEnum,
  UnitsStatusEnum,
} from "../../../../../Enums";

export const TenantSelection = ({
  // unitTransactionId,
  unitId,
  selected,
  onSelectedChanged,
  parentTranslationPath,
  unitData,
}) => {
  const unitParentTranslationPath = "UnitsStatusManagementView";
  const unitTranslationPath = "";
  const translationPath = "TenantSelection.";
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [localIsSubmitted, setLocalIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState({
    contact: false,
    lead: false,
  });
  const loginResponse = useSelector(
    (loginUser) => loginUser.login.loginResponse
  );

  const [localSelected, setLocalSelected] = useState({
    contact: null,
    lead: null,
  });
  const [data, setData] = useReducer(reducer, {
    leads: {
      result: [],
      totalCount: 0,
    },
    contacts: {
      result: [],
      totalCount: 0,
    },
  });
  const [createLeadData, setCreateLeadData] = useReducer(reducer, {
    mediaDetail: null,
    methodOfContact: null,
  });
  const [isDialogOpen, setIsDialogOpen] = useReducer(reducer, {
    CreateLead: false,
    CreateContact: false,
    UpdateLead: false,
    UpdateContact: false,
  });
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const getContacts = async (searchValue) => {
    setIsLoading((items) => ({ ...items, contact: true }));
    const response = await GetContacts({
      ...filter,
      search: searchValue,
      isAdvance: false,
    });
    if (!(response && response.status && response.status !== 200)) {
      setData({ id: "contacts", value: response });
    } else {
      setData({
        id: "contacts",
        value: {
          result: [],
          totalCount: 0,
        },
      });
    }
    setIsLoading((items) => ({ ...items, contact: false }));
  };

  const getLeads = async (contactId) => {
    const agentId = selected.tenantAgent?.id || null;
    setIsLoading((items) => ({ ...items, lead: true }));
    const response = await GetAllLeadsByContactId(
      {
        pageIndex: 0,
        pageSize: 1000,
        leadClass: LeadsClassTypesEnum.tenant.key,
        leadStatus: 457, //todo:: add enum
        referredTo: agentId,
      },
      contactId
    );
    if (!(response && response.status && response.status !== 200)) {
      setData({ id: "leads", value: response });
    } else {
      setData({
        id: "leads",
        value: {
          result: [],
          totalCount: 0,
        },
      });
    }
    setIsLoading((items) => ({ ...items, lead: false }));
  };

  const checkIsLeadAssociated = async (leadId) => {
    const response = await CheckLeadAssociatedWithPendingTransaction(leadId);
    if (!(response && response.status && response.status !== 200)) {
      if (response.isPendingTransaction) {
        showWarn(response.message);
        return true;
      }
      else return false;
    }
  }

  const isLeadSelectDisabled = () => {
    const isOneContactInTable = selected.contacts?.length > 0;
    const isContactFieldEmpty = !localSelected.contact;
    return isOneContactInTable || isContactFieldEmpty;
  }

  const isContactSelectDisabled = () => {
    const isOneContactInTable = selected.contacts?.length > 0;
    return isOneContactInTable;
  }

  const localSchema = Joi.object({
    contact: Joi.object()
      .required()
      .messages({
        "object.base": t(`${translationPath}contact-is-required`),
      }),
    lead: Joi.object()
      .required()
      .messages({
        "object.base": t(`${translationPath}lead-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(localSelected);

  const contactAddHandler = () => {
    localStorage.setItem("current", JSON.stringify({ itemId: CONTACTS }));
    setIsDialogOpen({id:'CreateContact', value: true})
  };

  const deleteContact = (_, index) => {
    const localContacts = [...selected.contacts];
    localContacts.splice(index, 1);
      onSelectedChanged({ id: "contacts", value: localContacts });
  };

  const tableActionClicked = useCallback(
    (actionEnum, item, index) => {
      if (actionEnum === TableActions.deleteText.key)
        deleteContact(item, index);
    },
    [deleteContact]
  );

  const generateContactItem = () => {
    return {
      contactId: localSelected.contact?.contactsId,
      leadId: localSelected.lead?.leadId,
      contactName:
        localSelected.contact?.contact.company_name ||
        (localSelected.contact?.contact.first_name &&
          `${localSelected.contact?.contact.first_name} ${localSelected.contact?.contact.last_name}`) ||
        "N/A",
      email:
        localSelected.contact?.contact?.email_address?.email ||
        localSelected.contact?.contact?.company_website ||
        "N/A",
      phone:
        localSelected.contact?.contact?.mobile?.phone ||
        localSelected.contact?.contact?.landline_number?.phone ||
        "N/A",
      ownershipPercentage: 0,
    };
  };


  const addOfferLetterTenantDetails = async (contactsList) => {

    const tenantContactId = contactsList?.length > 0 ? contactsList[0]?.contactId : null;
    const body = {
      unitId,
      tenantContactId,
    }
     await AddOfferLetterTenantDetails(body);

  }

  const addContactToTable = (event) => {
    setLocalIsSubmitted(true);

    if (
      selected.contacts.findIndex(
        (items) =>
          items.contactId === localSelected.contact?.contactsId &&
          items.leadId === localSelected.lead?.leadId
      ) !== -1
    ) {
      showError(t(`${translationPath}cannot-add-same-contact-and-lead-twice`));
      return;
    }

    const selectedContactItem = generateContactItem();
    const selectedContactsClone = [...selected.contacts];
    selectedContactsClone.push(selectedContactItem);

    addOfferLetterTenantDetails(selectedContactsClone);
    onSelectedChanged({ id: "contacts", value: selectedContactsClone });

    setLocalSelected({
      contact: null,
      lead: null,
    });

    setLocalIsSubmitted(false);


  };

  const addContactClickHanlder = () => {
    if (localSchema?.error?.message) {
      showError(localSchema.error.message);
      return;
    }
    const isIndividualContact =
      localSelected.contact?.contact.contact_type_id ===
        FormsIdsEnum.contactsIndividual.id
        ? true
        : false;

    if (isIndividualContact) setIsDialogOpen({id:'UpdateContact', value: true});
    else addContactToTable();
  };

  const mapContactsData = (response) => {
    const mappedContact = {
      contactId: response.contactId,
      contactName: response.contactName,
      leadId: response.tenantId,
      email: response.email,
      phone: response.phone,
      ownershipPercentage: response.ownershipPercentage,
    }

    onSelectedChanged({
      id: "contacts",
      value: [mappedContact]
    });
  }

  const getUnitTransactionTenant = async () => {
    const res = await GetUnitTransactionTenant(unitId);
    if (!(res && res.status && res.status !== 200)) {
      if(res) mapContactsData(res);
    }
  }

  const transactionDocumentsHandler = (documents) => {
    let mappedSelectedTransactionDocuments = [];

    documents &&
      documents.categoryFiles &&
      documents.categoryFiles.forEach((categoryItem) => {
        categoryItem &&
          categoryItem.files.forEach((file) =>
            mappedSelectedTransactionDocuments.push({
              scopeId: documents.scopeId,
              scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
              categoryId: categoryItem.categoryId,
              fileId: file.fileId,
              fileName: file.fileName,
              isLandlordOfferLatter: false,
              isTenantOfferLatter: false,
            })
          );
      });

    onSelectedChanged({
      id: "contactDocuments",
      value: [
        ...(selected.contactDocuments || []),
        ...mappedSelectedTransactionDocuments,
      ],
    });
  };
  const getLeadJson = () => {
    const lead = {
      data_completed: 0,
      status: {
        lookupItemId: 457,
        lookupItemName: 'Open',
      },
      lead_stage: {
        lookupItemId: 20985,
        lookupItemName: 'New',
      },
      operation_type: {
        lookupItemId: 492,
        lookupItemName: 'Rent',
      },
      referredby: {
        id: loginResponse?.userId,
        name: loginResponse?.fullName,
        phone: loginResponse?.phoneNumber,
        email: loginResponse?.email,
        userName: loginResponse?.userName,
      },
      contact_name: {
        id: localSelected.contact?.contactsId,
        name: localSelected.contact?.contact?.company_name ||
          `${localSelected.contact?.contact?.first_name} ${localSelected.contact?.contact?.last_name}`,
        phone: localSelected.contact?.contact?.mobile?.phone || null,
        type: localSelected.contact?.contact?.contact_type_id,
      },
      propertyunit_type: unitData?.unit_type ? [unitData.unit_type] : [],

      country: unitData?.country,
      city: unitData?.city,
      bedrooms:
        unitData?.bedrooms
          ? [unitData.bedrooms.toString()]
          : ["Any"],
      bathrooms:
        unitData?.bathrooms
          ? [unitData.bathrooms.toString()]
          : ["Any"],
      referredto: selected.tenantAgent || {},
      lead_type_id: LeadTypeIdEnum.Seeker.leadTypeId,
      method_of_contact: createLeadData?.methodOfContact,
      media_detail: createLeadData?.mediaDetail,
    };
    return lead;
  };


  const createLeadSaveHandler = async () => {
    setIsLoading((items) => ({ ...items, lead: true }));

    const leadJson = {
      leadJson: {
        lead: getLeadJson(),
      },
    };
    const res = await leadPost(leadJson);
    if (!(res && res.status && res.status !== 200)) {
      getLeads(localSelected.contact?.contactsId);
      setIsLoading((items) => ({ ...items, lead: false }));
      showSuccess(t(`${translationPath}leadCreatedSuccessfully-leadId`));
      localStorage.setItem("AgentInformation", null);
    } else {
      setIsLoading((items) => ({ ...items, lead: false }));
      showError(t(`${translationPath}lead-creation-failed`));
    }
  };

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );

  useEffect(() => {
    if(unitData){
      const isAvailableStatus = unitData.lease_status === UnitsStatusEnum.Available.name;
      if(!isAvailableStatus && selected?.contacts?.length == 0) getUnitTransactionTenant();
    }
  }, [unitData]);

  return (
    <>
      <div className="b-bottom py-2 mb-3">
        <div className="d-flex fj-start w-50">
          <div className="w-33 mt-2 pt-1">
            <span className="fw-simi-bold">
              {t(`${translationPath}Contact-selection*`)}
            </span>
          </div>
          <div className="w-75 mt-2 mb-1 pt-1">
            <AutocompleteComponent
              idRef="contactRef"
              inputPlaceholder={t(`select`)}
              selectedValues={localSelected.contact}
              multiple={false}
              isDisabled={isContactSelectDisabled()}
              data={data.contacts?.result || []}
              displayLabel={(option) =>
                ((option?.contact?.first_name || option?.contact?.last_name) &&
                  `${option?.contact?.first_name} ${option?.contact?.last_name}`) ||
                option?.contact?.company_name ||
                option.name ||
                ""
              }
              onOpen={() => {
                if (data.contacts?.result?.length == 0) getContacts();
              }}
              onInputChange={(_, value) => {
                if (searchTimer) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  if (value) getContacts(value);
                }, 1200);
              }}
              withoutSearchButton
              helperText={getErrorByName(localSchema, "contact").message}
              error={getErrorByName(localSchema, "contact").error}
              isWithError
              withLoader
              isLoading={isLoading?.contact}
              isSubmitted={localIsSubmitted}
              onChange={(_, newValue) => {
                if (newValue && newValue.contactsId)
                  getLeads(newValue.contactsId);
                else if (
                  localSelected.lead ||
                  (data.leads.result && data.leads.result.length > 0)
                ) {
                  setData({
                    id: "leads",
                    value: {
                      result: [],
                      totalCount: 0,
                    },
                  });
                }
                setLocalSelected((items) => ({
                  contact: newValue,
                  lead: null,
                }));
              }}
              filterOptions={(options) => options}
            />
          </div>
        </div>
        <div className="d-flex fj-start w-50">
          <div className="w-33 pt-1"></div>
          <div className="w-75 mb-1 pt-1">
            <ButtonBase
              className="d-flex-default fa-center pointer"
              onClick={contactAddHandler}
              disabled={isContactSelectDisabled()}
            >
              <span className="icons b-icon i-plus mb-1 mr-1"></span>
              <span className="fz-12 fw-simi-bold light-text">
                {t(`${translationPath}Add-new-contact`)}
              </span>
            </ButtonBase>
          </div>
        </div>
        <div className="d-flex fj-start w-50 mt-2">
          <div className="w-33 mt-2 pt-1">
            <span className="fw-simi-bold">{t(`${translationPath}lead*`)}</span>
          </div>
          <div className="w-75 mt-2 mb-1 pt-1">
            <DataFileAutocompleteComponent
              idRef="leadRef"
              inputPlaceholder={t(`select`)}
              selectedValues={localSelected.lead}
              multiple={false}
              isDisabled={isLeadSelectDisabled()}
              data={data.leads?.result || []}
              displayLabel={(option) =>
                option?.lead?.contact_name?.name ||
                option?.lead?.company_name ||
                option.name ||
                ""
              }
              renderFor="lead"
              withoutSearchButton
              helperText={getErrorByName(localSchema, "lead").message}
              error={getErrorByName(localSchema, "lead").error}
              isWithError
              withLoader
              onOpen={() => {
                if (data.leads?.result?.length == 0) getLeads();
              }}
              onInputChange={(_, value) => {
                if (searchTimer) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  if (value) getLeads(value);
                }, 1200);
              }}
              isLoading={isLoading.lead}
              isSubmitted={localIsSubmitted}
              onChange={async (_, newValue) => {

                const isLeadAssociated = newValue ? await checkIsLeadAssociated(newValue.leadId) : null;

                const leadNewValue = !isLeadAssociated ? newValue : null;
                setLocalSelected((items) => ({ ...items, lead: leadNewValue }));
              }}
            />
          </div>
        </div>
        <div className="d-flex fj-start w-50">
          <div className="w-33 pt-1"></div>
          <div className="w-75 mb-1 pt-1">
            <ButtonBase
              className="d-flex-default fa-center pointer"
              onClick={() => {
                setIsDialogOpen({id:'UpdateLead', value: true});
              }}
              disabled={isLeadSelectDisabled()}
            >
              <span className="icons b-icon i-plus mb-1 mr-1"></span>
              <span className="fz-12 fw-simi-bold light-text">
                {t(`${translationPath}Add-new-lead`)}
              </span>
            </ButtonBase>
          </div>
        </div>
        <div className="d-flex fj-end my-2">
          <ButtonBase
            className="btns theme-propx solid mr-0"
            onClick={addContactClickHanlder}
            disabled={isContactSelectDisabled()}
          >
            {t(`${translationPath}Add-to-list`)}
          </ButtonBase>
        </div>
      </div>
      <Tables
        data={selected.contacts}
        headerData={[
          {
            id: 1,
            label: "contact-id",
            input: "contactId",
          },
          {
            id: 2,
            label: "contact-name",
            input: "contactName",
          },
          {
            id: 3,
            label: "email",
            input: "email",
          },
          {
            id: 4,
            label: "phone",
            input: "phone",
          },
          {
            id: 5,
            label: "lead-id",
            input: "leadId",
          },
        ]}
        defaultActions={[
          {
            enum: TableActions.deleteText.key,
          },
        ]}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        activePage={filter.pageIndex}
        parentTranslationPath={unitParentTranslationPath}
        translationPath={unitTranslationPath}
        totalItems={selected.contacts?.length || 0}
      />
      {(isDialogOpen.CreateContact || isDialogOpen.CreateLead) && (
        <ContactsDialog
          open={isDialogOpen.CreateContact || isDialogOpen.CreateLead}
          onSave={() => {
            if (isDialogOpen.CreateContact) {
              setIsDialogOpen({id:'CreateContact', value: false})
              getContacts();
            }
            if (isDialogOpen.CreateLead) {
              setIsDialogOpen({id:'CreateLead', value: false});
              getLeads();
            }
          }}
          closeDialog={() => {
            setIsDialogOpen({id:'CreateLead', value: false});
            setIsDialogOpen({id:'CreateContact', value: false})
          }}
        />
      )}
      {isDialogOpen.UpdateLead && (
        <TransactionCreateLeadDialog
          isOpen={isDialogOpen.UpdateLead}
          onSaveHandler={() => {
            createLeadSaveHandler();
            setIsDialogOpen({id:'UpdateLead', value: false});
          }}
          onCloseHandler={() => {
            setIsDialogOpen({id:'UpdateLead', value: false});
          }}
          createLeadData={createLeadData}
          setCreateLeadData={setCreateLeadData}
        />
      )}
      {isDialogOpen.UpdateContact && localSelected.contact && (
        <TransactionUpdateContactDialog
          isOpen={isDialogOpen.UpdateContact}
          onCloseHandler={(documents) => {
            setIsDialogOpen({id:'UpdateContact', value: false});
            transactionDocumentsHandler(documents);
          }}
          addContactToTable={addContactToTable}
          contactData={localSelected.contact?.contact}
          contactId={localSelected.contact?.contactsId}
        />
      )}
    </>
  );
};

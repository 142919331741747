import React from 'react';
import { ActivitiesRelatedToActivitiesTabelType } from './ActivitiesRelatedToActivitiesTabelType.Enum';
import moment from 'moment';

export const ActivitiesTabelHeaderData = [
  {
    id: 1,
    label: 'activity-name',
    input: 'search',
    isSortable: true,
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    component: (item) => <span>{item?.activityTypeName}</span>,
  },
  {
    id: 2,
    label: 'category',
    input: 'categoryName',
    isSortable: true,
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
  },
  {
    id: 3,
    label: 'RelatedtoUnit',
    input: 'isRelatedToUnit',
    component: (item) => (
      <>
        {(item &&
          item.relatedTo.findIndex(
            (data) =>
              data.relatedToId ===
                ActivitiesRelatedToActivitiesTabelType.unit.key && (
                <span className='mdi mdi-check-circle-outline  check-true' />
              )
          ) === -1 && (
            <span className='mdi mdi-alpha-x-circle-outline check-false' />
          )) || <span className='mdi mdi-check-circle-outline  check-true' />}
      </>
    ),
    withSelectFilter: true,
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
  },
  {
    id: 4,
    label: 'RelatedtoLead',
    input: 'isRelatedToLead',
    component: (item) => (
      <>
        {(item &&
          item.relatedTo.findIndex(
            (data) =>
              data.relatedToId ===
                ActivitiesRelatedToActivitiesTabelType.lead.key && (
                <span className='mdi mdi-check-circle-outline  check-true' />
              )
          ) === -1 && (
            <span className='mdi mdi-alpha-x-circle-outline check-false' />
          )) || <span className='mdi mdi-check-circle-outline  check-true' />}
      </>
    ),
    withSelectFilter: true,
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
  },
  {
    id: 5,
    label: 'RelatedtoContact',
    input: 'isRelatedToContact',
    component: (item) => (
      <>
        {(item &&
          item.relatedTo.findIndex(
            (data) =>
              data.relatedToId ===
                ActivitiesRelatedToActivitiesTabelType.contact.key && (
                <span className='mdi mdi-check-circle-outline  check-true' />
              )
          ) === -1 && (
            <span className='mdi mdi-alpha-x-circle-outline check-false' />
          )) || <span className='mdi mdi-check-circle-outline  check-true' />}
      </>
    ),
    withSelectFilter: true,
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
  },
  {
    id: 6,
    label: 'RelatedtoWorkOrder',
    input: 'isRelatedToWorkOrder',
    component: (item) => (
      <>
        {(item &&
          item.relatedTo.findIndex(
            (data) =>
              data.relatedToId ===
                ActivitiesRelatedToActivitiesTabelType.workOrder.key && (
                <span className='mdi mdi-check-circle-outline  check-true' />
              )
          ) === -1 && (
            <span className='mdi mdi-alpha-x-circle-outline check-false' />
          )) || <span className='mdi mdi-check-circle-outline  check-true' />}
      </>
    ),
    withSelectFilter: true,
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
  },
  {
    id: 7,
    label: 'RelatedtoPortfolio',
    input: 'isRelatedToPortfolio',
    component: (item) => (
      <>
        {(item &&
          item.relatedTo.findIndex(
            (data) =>
              data.relatedToId ===
                ActivitiesRelatedToActivitiesTabelType.portfolio.key && (
                <span className='mdi mdi-check-circle-outline  check-true' />
              )
          ) === -1 && (
            <span className='mdi mdi-alpha-x-circle-outline check-false' />
          )) || <span className='mdi mdi-check-circle-outline  check-true' />}
      </>
    ),
    withSelectFilter: true,
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
  },
  {
    id: 8,
    label: 'RelatedtoMaintenanceContract',
    input: 'isRelatedToMaintinanceContract',
    component: (item) => (
      <>
        {(item &&
          item.relatedTo.findIndex(
            (data) =>
              data.relatedToId ===
                ActivitiesRelatedToActivitiesTabelType.maintenanceContract
                  .key && (
                <span className='mdi mdi-check-circle-outline  check-true' />
              )
          ) === -1 && (
            <span className='mdi mdi-alpha-x-circle-outline check-false' />
          )) || <span className='mdi mdi-check-circle-outline  check-true' />}
      </>
    ),
    withSelectFilter: true,
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
  },
  // {
  //   id: 9,
  //   label: 'Expired-Period',
  //   isDefaultFilterColumn: true,
  //   input: 'expiredPeriod',
  //   isSortable: true
  // },
  {
    id: 11,
    label: 'rating',
    input: 'activityRateName',
    isHiddenFilter: true,
  },
  {
    id: 12,
    label: 'isForMobile',
    input: 'isForMobile',
    component: (item) => (
      <>
        {item?.isForMobile === true ? (
          <span className='mdi mdi-check-circle-outline  check-true' />
        ) : (
          <span className='mdi mdi-alpha-x-circle-outline check-false' />
        )}
      </>
    ),
    isDefaultFilterColumn: true,
    withSelectFilter: true,
    isHiddenFilter: false,
  },
  {
    id: 13,
    label: 'withReminder',
    component: (item) => (
      <>
        {(item && item.withReminder && (
          <span className='mdi mdi-check-circle-outline  check-true' />
        )) || <span className='mdi mdi-alpha-x-circle-outline check-false' />}
      </>
    ),
    isHiddenFilter: true,
  },
  {
    id: 14,
    label: 'withDuration',
    component: (item) => (
      <>
        {(item && item.withDuration && (
          <span className='mdi mdi-check-circle-outline  check-true' />
        )) || <span className='mdi mdi-alpha-x-circle-outline check-false' />}
      </>
    ),
    isHiddenFilter: true,
  },
  {
    id: 15,
    label: 'withDateTime',
    component: (item) => (
      <>
        {(item && item.withDateTime && (
          <span className='mdi mdi-check-circle-outline  check-true' />
        )) || <span className='mdi mdi-alpha-x-circle-outline check-false' />}
      </>
    ),
    isHiddenFilter: true,
  },
  {
    id: 16,
    label: 'followUpRequired',
    component: (item) => (
      <>
        {(item && item.followUpRequired && (
          <span className='mdi mdi-check-circle-outline  check-true' />
        )) || <span className='mdi mdi-alpha-x-circle-outline check-false' />}
      </>
    ),
    isHiddenFilter: true,
  },
  {
    id: 17,
    label: 'createdByRoles',
    component: (item) =>
      (
        <span>
          {item.createActivityTypeRoles.map((el) => `${el.roleName} , `)}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 18,
    label: 'assignedByRoles',
    component: (item) =>
      (
        <span>
          {item.assignedToActivityTypeRoles.map((el) => `${el.roleName} , `)}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 19,
    label: 'prerequisiteActivities',
    component: (item) =>
      (
        <span>
          {item.activityTypeActivityTypePrerequisites.map(
            (el) => `${el.activityTypePrerequisiteName} , `
          )}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 20,
    label: 'createdByNotificationBy',
    component: (item) =>
      (
        <span>
          {item.activityTypeTemplateRecipientTypes.map((el) => {
            if (el.recipientTypeName === 'CreatedBy')
              return `${el.activityTypeTemplateRecipientTypeSources.map(
                (e) => `${e.notifyByName}  `
              )}`;
          })}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 21,
    label: 'createdByTemplate',
    component: (item) =>
      (
        <span>
          {item.activityTypeTemplateRecipientTypes.map((el) => {
            if (el.recipientTypeName === 'CreatedBy')
              return `${el.templateName}  `;
          })}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 22,
    label: 'assignToNotificationBy',
    component: (item) =>
      (
        <span>
          {item.activityTypeTemplateRecipientTypes.map((el) => {
            if (el.recipientTypeName === 'Assign to')
              return `${el.activityTypeTemplateRecipientTypeSources.map(
                (e) => `${e.notifyByName}  `
              )}`;
          })}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 23,
    label: 'assignToTemplate',
    component: (item) =>
      (
        <span>
          {item.activityTypeTemplateRecipientTypes.map((el) => {
            if (el.recipientTypeName === 'Assign to')
              return `${el.templateName} `;
          })}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 24,
    label: 'lineManagerNotificationBy',
    component: (item) =>
      (
        <span>
          {item.activityTypeTemplateRecipientTypes.map((el) => {
            if (el.recipientTypeName === 'LineManager')
              return `${el.activityTypeTemplateRecipientTypeSources.map(
                (e) => `${e.notifyByName} `
              )}`;
          })}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 25,
    label: 'lineManagerTemplate',
    component: (item) =>
      (
        <span>
          {item.activityTypeTemplateRecipientTypes.map((el) => {
            if (el.recipientTypeName === 'LineManager')
              return `${el.templateName} `;
          })}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 26,
    label: 'teamManagerNotificationBy',
    component: (item) =>
      (
        <span>
          {item.activityTypeTemplateRecipientTypes.map((el) => {
            if (el.recipientTypeName === 'TeamManager')
              return `${el.activityTypeTemplateRecipientTypeSources.map(
                (e) => `${e.notifyByName}  `
              )}`;
          })}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 27,
    label: 'teamManagerTemplate',
    component: (item) =>
      (
        <span>
          {item.activityTypeTemplateRecipientTypes.map((el) => {
            if (el.recipientTypeName === 'TeamManager')
              return `${el.templateName}  `;
          })}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 28,
    label: 'teamLeadNotificationBy',
    component: (item) =>
      (
        <span>
          {item.activityTypeTemplateRecipientTypes.map((el) => {
            if (el.recipientTypeName === 'TeamLead')
              return `${el.activityTypeTemplateRecipientTypeSources.map(
                (e) => `${e.notifyByName} `
              )}`;
          })}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 29,
    label: 'teamLeadTemplate',
    component: (item) =>
      (
        <span>
          {item.activityTypeTemplateRecipientTypes.map((el) => {
            if (el.recipientTypeName === 'TeamLead')
              return `${el.templateName} `;
          })}
        </span>
      ) || <span />,
    isHiddenFilter: true,
  },
  {
    id: 31,
    label: 'SLA-expiration-period',
    input: 'slaExpirationPeriod',
    isDefaultFilterColumn: false,
    isHiddenFilter: true,
  },
  {
    id: 32,
    label: 'SLAAppliedFor',
    input: 'slaAppliedFor',
    component: (item) => (
      <>
        {item && item.slaAppliedForString && (
          <span className=''>{item.slaAppliedForString}</span>
        )}
      </>
    ),
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    withSelectFilter: true,
    optionFilterList: [
      { key: 1, value: 'Only For Bulk Leads' },
      { key: 2, value: 'Only For Fresh Leads' },
      { key: 3, value: 'For Both Bulk And Fresh Leads' },
    ],
  },
  {
    id: 34,
    label: 'SLAExpirationPeriodComparisonDate',
    input: 'slaExpirationPeriodComparisonDate',
    component: (item) => (
      <>
        {item && item.slaExpirationPeriodComparisonDateString && (
          <span className=''>
            {item.slaExpirationPeriodComparisonDateString}
          </span>
        )}
      </>
    ),
    withSelectFilter: true,
    optionFilterList: [
      { key: 1, value: 'ActivityDate' },
      { key: 2, value: 'CreationDate' },
    ],
    isHiddenFilter: false,
  },
  {
    id: 35,
    label: 'last-activity-use',
    isDate: true,
    input: 'LastUsedDate',
    isSortable: true,
    isDefaultFilterColumn: true,
    component: (item) => (
      <span>
        {item.lastUsedDate
          ? moment(item.lastUsedDate).format('YYYY-MM-DD')
          : ''}
      </span>
    ),
  },
];

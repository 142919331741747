import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import { Spinner } from "../../../../../Components";
import { GetGeographicCriteriaWithScores } from "../../../../../Services";
import { ReactComponent as Minimize } from "../../../../../assets/images/defaults/minimize.svg";

export const CityGeographicCriteria = ({
  parentTranslationPath,
  translationPath,
  cityGeographicCriteriaWithScores,
  setCityGeographicCriteriaWithScores,
  districtGeographicCriteriaWithScores,
  setDistrictGeographicCriteriaWithScores,
  setActiveCity,
  setActiveCommunity,
  setActiveDistrict,
  propertyRatingLocationTypeScoreId,
  activeCity,
  setActiveSubCommunity,
  displayAllReletedLocation,
  setDisplayAllReletedLocation,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [isLoading, setIsLoading] = useState(false);
  const [displayAllCityDistrictList, setDisplayAllCityDistrictList] = useState(
    []
  );

  const GetGeographicCriteriaWithScoresAPI = useCallback(async (search) => {
    setIsLoading(true);
    const res = await GetGeographicCriteriaWithScores({
      parentLookupItemId: search,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      const districts =
        (res &&
          res.locationTypeScores &&
          res.locationTypeScores.length > 0 &&
          res.locationTypeScores[0] &&
          res.locationTypeScores[0].geographicCriteriaScores &&
          res.locationTypeScores[0].geographicCriteriaScores.map((el) => ({
            ...el,
            propertyRatingLocationTypeScoreId:
              propertyRatingLocationTypeScoreId,
          }))) ||
        [];
      let newList = [...districtGeographicCriteriaWithScores, ...districts];

      let filterArray =
        newList &&
        newList.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.lookupsId === value.lookupsId)
        );
      setDistrictGeographicCriteriaWithScores([...filterArray]);
    } else {
      setDistrictGeographicCriteriaWithScores([]);
    }
    setIsLoading(false);
  });

  useEffect(() => {
    if (activeCity && activeCity.lookupsId) {
      setActiveDistrict(null);
      setActiveSubCommunity(null);
      setActiveCommunity(null);
      GetGeographicCriteriaWithScoresAPI(activeCity.lookupsId);
      setDisplayAllReletedLocation((e) => ({
        ...e,
        displayAllDistrictCommunities: null,
        displayAllCommunitySubCommunities: null,
      }));
    }
  }, [activeCity]);

  useEffect(() => {
    if (
      displayAllReletedLocation &&
      displayAllReletedLocation.displayAllCityDistricts
    ) {
      const selectedCity = cityGeographicCriteriaWithScores.filter(
        (item) =>
          item.lookupsId ===
          displayAllReletedLocation.displayAllCityDistricts.id
      );
      setDisplayAllCityDistrictList([...selectedCity]);
    } else setDisplayAllCityDistrictList([]);
  }, [displayAllReletedLocation, cityGeographicCriteriaWithScores]);

  return (
    <>
      <Spinner isActive={isLoading} isAbsolute />
      <div>
        <span className="fw-bold"> {t(`${translationPath}City`)} </span>
      </div>
      <div className="w-100 mt-2 mb-2 sores-part">
        {displayAllReletedLocation &&
          !displayAllReletedLocation.displayAllCityDistricts &&
          cityGeographicCriteriaWithScores &&
          cityGeographicCriteriaWithScores.map((item, index) => (
            <div
              className="d-flex-inline b-bottom mt-3 mb-3"
              key={`CityCardItemRef${item.lookupsId + 1}`}
              onClick={() => setActiveCity(item)}
            >
              <div className="city-location">
                {" "}
                <div className="d-flex-inline">
                  <span className="fw-bold">{item.lookupItemName}</span>
                </div>
                <div className="d-flex-inline">
                  <Rating
                    size="large"
                    name={`CityItemRef${item.lookupTypeName}-${item.lookupsId}}`}
                    max={10}
                    value={item.score}
                    precision={1}
                    onChange={(event, newValue) => {
                      GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                      let updatelist = [...cityGeographicCriteriaWithScores];
                      const itemIndex = updatelist.indexOf(
                        updatelist.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex !== -1)
                        updatelist[itemIndex] = {
                          ...updatelist[itemIndex],
                          score: newValue ||0 ,
                        };

                      setCityGeographicCriteriaWithScores([...updatelist]);
                    }}
                  />
                  <span
                    onClick={() => {
                      const displayAllCityDistrict =
                        displayAllReletedLocation &&
                        displayAllReletedLocation.displayAllCityDistricts
                          ? null
                          : { id: item.lookupsId, isSelected: true };
                      if (setDisplayAllReletedLocation)
                        setDisplayAllReletedLocation((e) => ({
                          ...e,
                          displayAllCityDistricts: displayAllCityDistrict,
                        }));
                    }}
                  >
                    <Minimize />
                  </span>
                </div>
              </div>
            </div>
          ))}
        {displayAllReletedLocation &&
          displayAllReletedLocation.displayAllCityDistricts &&
          displayAllReletedLocation.displayAllCityDistricts.isSelected &&
          displayAllCityDistrictList.map((item, index) => (
            <div
              className="d-flex-inline b-bottom mt-3 mb-3"
              key={`CityCardItemRef${item.lookupsId + 1}`}
              onClick={() => setActiveCity(item)}
            >
              <div className="city-location">
                {" "}
                <div className="d-flex-inline">
                  <span>{item.lookupItemName}</span>
                </div>
                <div className="d-flex-inline">
                  <Rating
                    size="large"
                    name={`CityItemRef${item.lookupTypeName}-${item.lookupsId}}`}
                    max={10}
                    value={item.score}
                    precision={1}
                    onChange={(event, newValue) => {
                      GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                      let updatelist = [...cityGeographicCriteriaWithScores];
                      const itemIndex = updatelist.indexOf(
                        updatelist.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex !== -1)
                        updatelist[itemIndex] = {
                          ...updatelist[itemIndex],
                          score: newValue ||0,
                        };
                      setCityGeographicCriteriaWithScores([...updatelist]);
                    }}
                  />
                  <span
                    onClick={() => {
                      setDisplayAllReletedLocation((e) => ({
                        ...e,
                        displayAllCityDistricts: null,
                      }));
                    }}
                  >
                    <Minimize />
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

import React, {
  useCallback,
  useEffect,
  useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import {
  UnitStatusFilter,
  UnitTypeFilter,
  ListingAgentFilter,
  CompletionStatusFilter,
  SortByFilter,
  UnitRefNumberFilter,
  UnitNumberFilter,
  LocationFilter,
  CreatedDateFilter,
  PriceFilter  , 
  SizeFilter , 
  BedroomsFilter , 
  BathroomsFilter  , 
  PropertiesFilter 
} from "../ResaleUnitsFilters";

const parentTranslationPath = "UnitsView";
const translationPath = "";

export const ResaleUnitsFilterComponent = ({
  isClearFiltersClicked,
  setResaleFilters,
  resaleFilters,
  resaleChangeFilters,
  dateFilter , 
  setDateFilter ,
  setSortByFilter ,
  setOrderBy ,
  orderBy ,
  setIsClearSortBy,
  isClearSortBy ,
  
}) => {
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
    selectedDateType: 1,
  };

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const selectedDefault = {
    unitStatus : null  , 
    unitType :  null , 
    sortBy : null  ,
    completionStatus : null ,
    listingAgent : null , 
    propertyName: null , 
    unitRefNo : null ,
    unitNumber : null , 
    price : {
      from : null , 
      to:null 
    } , 
    size : {
      from : null , 
      to: null 
    } , 
    bedrooms : {
      from : null , 
      to: null 
    } , 
    bathrooms : {
      from : null , 
      to: null 
    } 
  };
  const { t } = useTranslation(parentTranslationPath);
  const [selected, setSelected] = useReducer(reducer,selectedDefault) ; 
     
  useEffect(() => {
    if (isClearFiltersClicked && isClearFiltersClicked) {
     // setFilterCriteria({});
      setSelected({ id: "edit", value: selectedDefault });
    }
  }, [isClearFiltersClicked]);

  useEffect(() => {
    
      setSelected({ id: "sortBy", value: null });
  }, [isClearSortBy]);


  return (
    <div className="px-4 d-flex-column-center mb-3 resale-filters">
      <div className="px-4 d-flex-center">
        <UnitStatusFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          translationPathForData={translationPath}
          selected={selected}
          setSelected={setSelected}
        />
        <UnitTypeFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          selected={selected}
          setSelected={setSelected}
        />
        <ListingAgentFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          selected={selected}
          setSelected={setSelected}
        />
        <CompletionStatusFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          selected={selected}
          setSelected={setSelected}
        />
        <CreatedDateFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <div className="px-4 d-flex-center">
      <SortByFilter
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          selected={selected}
          setSelected={setSelected}
          setOrderBy={setOrderBy}
        />
      <PropertiesFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          selected={selected}
          setSelected={setSelected}
        />
        <UnitRefNumberFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          selected={selected}
          setSelected={setSelected}
        />
        <UnitNumberFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <div className="px-4 d-flex-center">
        <PriceFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          selected={selected}
          setSelected={setSelected}
        />
          <SizeFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          selected={selected}
          setSelected={setSelected}
        />
         <BedroomsFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          selected={selected}
          setSelected={setSelected}
        />
         <BathroomsFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      
      <div className="px-4 d-flex-center w-100">
        <LocationFilter
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isClearFiltersClicked={isClearFiltersClicked}
        />
      </div>
      <div className="px-4 d-flex-center w-100">
       
      </div>
      
    </div>
  );
};

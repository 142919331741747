import React, { useRef, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../../../../../../SharedComponents";
import { Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Inputs, Tables } from "../../../../../../../Components";
import { PendingDeactivationTabs } from "../PendingDeactivationTabs";
import {
  getDownloadableLink,
  getFirstLastNameLetters,
  showError,
  showSuccess,
} from "../../../../../../../Helper";
import { ApproveUserDeActivation } from "../../../../../../../Services";
import { UserDeActivationRejectDialog } from "../../../Dialogs";

export const PendingDeactivationView = ({
  parentTranslationPath,
  translationPath,
  usersData,
  totalCount,
  setFilter,
  filter,
  reloadData,
  onPageSizeChanged,
  onPageIndexChanged,
  setActiveView,
  setDeActiveUserId
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const approveUserDeActivation = async (userId) => {
    const res = await ApproveUserDeActivation(userId);
    if (!(res && res.status && res.status !== 200)) {
      reloadData();
      showSuccess(t(`${translationPath}deactivate-approval-success`));
    } else showError(t(`${translationPath}deactivate-approval-failed`));
  };


  const getActionButtons = (item) => {
    const actionsList = [];

    const rejectionAction = (
      <Button
        className="btns propx-action-btn theme-propx outlined danger"
        onClick={() => {
          setActiveItem(item);
          setIsRejectDialogOpen(true);
        }}
      >
        {t(`${translationPath}reject`)}
      </Button>
    );

    const approvalAction = (
      <Button
        className="btns propx-action-btn theme-propx outlined gold"
        onClick={() => (item?.id ? approveUserDeActivation(item?.id) : null)}
      >
        {t(`${translationPath}confirm`)}
      </Button>
    );
 
    const completeAction = (
      <Button
        className="btns propx-action-btn theme-propx outlined gold"
        onClick={() => (item?.id ? approveUserDeActivation(item?.id) : null)}
      >
        <span className="fz-14 fw-simi-bold light-text ml-1">
          {t(`${translationPath}complete`)}
        </span>
      </Button>
    );

    const manageAction = (
      <span className="d-flex-center fj-start pointer px-3 py-2" onClick={() => {
        setActiveView(PendingDeactivationTabs.UnitListing.key);
        setDeActiveUserId(item.id);
      }}>
        <span className="icons b-icon i-users-edit"></span>
        <span className="fz-14 fw-simi-bold light-text ml-1">
          {t(`${translationPath}manage`)}
        </span>
      </span>
    );
    
    if (item?.showCompleteButton)
      actionsList.push(completeAction);
    else if (!item?.showConfirmButton && !item?.showRejectButton)
      actionsList.push(manageAction);
    else {
      if (item?.showRejectButton) actionsList.push(rejectionAction);
      if (item?.showConfirmButton) actionsList.push(approvalAction);
    }

    return (
      <span className="d-flex-center fj-start">{actionsList.map((item) => item)}</span>
    );
  };

  return (
    <div>
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"PendingDeactivation"}
          subTitle={"Pending-dectivation-heading"}
          wrapperClasses={"px-4 w-50"}
          headerTitleClasses={"fz-16 mb-1"}
          subTitleClasses={"fz-14 m-0 ml-0"}
        />

        <div className="w-50 px-4 d-flex-v-center-h-between">
          <div className="w-100 d-flex-column fa-end mr-3">
            <div className="w-75 d-flex fj-end mr-3">
              <Inputs
                idRef="user-nameRef"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.userName || ''}
                onInputChanged={(event) => {
                  const userName = event?.target?.value;
                    setFilter((items) => ({ ...items, 
                      userName: userName || null,
                      userId: null,
                    }));
                }}
                inputPlaceholder={t(`${translationPath}name`)}
              />
              <Inputs
                idRef="platform-nameRef"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                onKeyUp={(event) => {
                  const platformName = event?.target?.value;
                    setFilter({
                      ...filter,
                      platformName: platformName || null,
                    });
                }}
                inputPlaceholder={t(`${translationPath}application`)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="propx-view-container sm  m-3">
        <Tables
          data={usersData || []}
          headerData={[
            {
              id: 1,
              label: "user",
              component: (item) =>
                (item?.name ? (
                  <div className="d-flex-v-center">
                    <div className="campaign-team-f1">
                      <Avatar
                        className="avatars-wrapper team-avatar fz-12 sm-theme"
                        src={
                          item?.profileImage
                            ? getDownloadableLink(item?.profileImage)
                            : ""
                        }
                      >
                        {getFirstLastNameLetters(item?.name || "")}
                      </Avatar>
                    </div>
                    <div className="d-flex-column">
                      <span className="fz-12 fw-simi-bold">{item?.name || "-"}</span>
                      <span className="fz-10 text-tertiary">
                        {item?.email || "-"}
                      </span>
                    </div>
                  </div>
                ) : (
                  "-"
                )) || "",
            },
            {
              id: 2,
              label: "leads",
              input: "leads",
            },
            {
              id: 3,
              label: "units",
              input: "units",
            },
            {
              id: 4,
              label: "activation-date",
              input: "activationDate",
              component: (item) =>
                (
                  <span>
                    {item.activationDate
                      ? moment(item.activationDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 5,
              label: "last-activity-date",
              input: "lastActivityDate",
              component: (item) =>
                (
                  <span>
                    {item.lastActivityDate
                      ? moment(item.lastActivityDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 6,
              label: "actions",
              component: (item) => getActionButtons(item),
            },
          ]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          totalItems={totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          defaultActions={[]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      {isRejectDialogOpen && (
        <UserDeActivationRejectDialog
          activeItem={activeItem}
          isDialogOpen={isRejectDialogOpen}
          setIsDialogOpen={setIsRejectDialogOpen}
          reloadData={reloadData}
          onCloseClicked={() => {
            setIsRejectDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsRejectDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};

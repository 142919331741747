import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    collapsed: {
      width: "100%",
      paddingRight: "16px",
      margin: "32px 0 16px",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    expandedSnackBar: {
      flexWrap: "wrap",
      alignItems: "center",
      margin: "32px 0",
      display: "flex",
      width: "100%",
      padding: "16px 16px 16px 7px",
      borderRadius: "12px",
      border: `1px solid ${theme.palette.border.main}`,
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      justifyContent: "space-between",
    },
    buttonsContainer: {
      display: "flex",
      gap: "8px",
      height: "36px",

      "& button": {
        minHeight: "36px !important",
      },
    },
    closeButton: (props) => ({
      padding: "8px",
      borderRadius: "8px",
      "&:hover": {
        background: theme.palette.background.primary_hover,
      },
    }),
    outerIconBorder: {
      cursor: "pointer",
      border: "1px solid #fbeae9",
      borderRadius: "24px",
      width: "38px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    innerIconBorder: {
      border: "1px solid #f4c0bd",
      borderRadius: "24px",
      width: "28px",
      height: "28px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    closeIcon: {
      alignSelf: "center",
    },
    alertContainer: {
      flexWrap: "wrap",
      alignItems: "center",
      gap: "7px",
      display: "flex",
    },
    text: {
      textAlign: "center",
      color: theme.palette.text.secondary,

      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
    },
    secondaryText: {
      cursor: "pointer",
      textAlign: "center",
      color: theme.palette.text.tertiary,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    root: {
      border: `1px solid ${theme.palette.border.secondary}`,
    },
  };
});

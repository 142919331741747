import React, { useState, useContext } from "react";
import { Box, Popover } from "@material-ui/core";
import clsx from "clsx";

import { CustomSelect, CustomButton } from "../../../Components";
import { useSelectedTheme } from "../../../Hooks";
import { FilterIcon, FilterEqualIcon, CloseXIcon } from "../../../assets/icons";
import {
  NewTableFilterOperatorsEnum,
  LeadsClassTypesEnum,
} from "../../../Enums";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";

import useStyles from "./styles";

const CustomTableHeader = ({ params, title }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const { advancedSearchBody, setAdvancedSearchBody } =
    useContext(ContactLayoutContext);

  const key = params?.field || params?.colDef?.field;

  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setInputValue("");
    setSelectedValue("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box className={styles.headerCell}>
        {title}
        <FilterIcon
          onClick={handleClick}
          width="16"
          height="16"
          fill={
            Object.keys(advancedSearchBody?.criteria || [])
              ?.map((str) => str?.toLowerCase())
              ?.some(
                (element) =>
                  key.toLowerCase() === element ||
                  key.toLowerCase().includes(element)
              )
              ? palette.border.brandSolid
              : palette.text.tertiary
          }
        />
      </Box>

      <Popover
        elevation={0}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handlePopoverClick}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box className={styles.contactPopupContainerStyle}>
          <Box className={styles.popupHeader}>
            <h4 className={styles.h4}>{title}</h4>

            <CloseXIcon
              width="20"
              height="20"
              fill={palette.foreground.disabled}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>

          <Box className={styles.customInput}>
            <CustomSelect
              variant="custom"
              onValueChange={(value) => {
                setSelectedValue(value);
              }}
              emptyItem={{
                id: 0,
                name: "Select",
                isDisabled: true,
              }}
              defaultValue={1}
              options={Object.values(NewTableFilterOperatorsEnum(palette.text))}
              IconComponent={<FilterEqualIcon />}
              value={selectedValue}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />

            <input
              className={clsx(styles.BasicInput, {
                [styles.placeholder]: !inputValue,
              })}
              type="text"
              placeholder="Enter contact name"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Box>

          <Box className={styles.actions}>
            <CustomButton
              boxShadow="xs"
              size="popupAction"
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              disabled={!(selectedValue && inputValue?.trim()?.length > 0)}
              boxShadow="xs"
              size="popupAction"
              variant="contained"
              color="primary"
              onClick={() => {
                const field = params?.field || params?.colDef?.field;

                setAdvancedSearchBody((prevBody) => {
                  if (field === "leadTypes") {
                    const foundKey = Object.values(LeadsClassTypesEnum).find(
                      (type) => type.name === inputValue
                    )?.key;

                    return {
                      ...prevBody,
                      LeadClasses: foundKey ? [foundKey] : [],
                    };
                  } else {
                    const criteria = { ...prevBody.criteria };

                    // Update the existing array if the key exists, otherwise create a new array
                    if (criteria[field]) {
                      criteria[field] = [
                        { searchType: selectedValue, value: inputValue },
                      ];
                    } else {
                      criteria[field] = [
                        { searchType: selectedValue, value: inputValue },
                      ];
                    }

                    return {
                      ...prevBody,
                      criteria,
                    };
                  }
                });

                handleClose();
              }}
            >
              Confirm
            </CustomButton>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default CustomTableHeader;

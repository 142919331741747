import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { styled, Icon } from "@material-ui/core";

import "./BasicTable.scss";
// import { FilterIcon, UploadIcon, CloseXIcon } from "../../../assets/icons";

const BasicTable = ({
  columns,
  rowsData,
  setActiveItem,
  pageSize,

  filterColumnsApplied,
  setFilterColumnsApplied,
}) => {
  // const DropdownIcon = "icon-down-arrow";

  const handleOnRowClick = (params) => {
    const slider = document.getElementsByClassName("MuiDataGrid-window")[0];

    if (!slider) return;

    let mouseDown = false;
    let startX;
    let scrollLeft;

    const startDragging = (e) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const stopDragging = () => {
      mouseDown = false;
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", stopDragging);
      window.removeEventListener("mouseleave", stopDragging);
    };

    const handleMouseMove = (e) => {
      if (!mouseDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    };

    slider.addEventListener("mousedown", startDragging);
    window.addEventListener("mouseup", stopDragging);
    window.addEventListener("mouseleave", stopDragging);
    window.addEventListener("mousemove", handleMouseMove);

    // Clean up the event listeners
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", stopDragging);
      window.removeEventListener("mouseleave", stopDragging);
    };
  };

  const handleOnRowDoubleClick = (params) => {
    setActiveItem(params?.row);
  };

  const handleOnCellEnter = (params, event) => {
    if (params.field === "leadTypes" && params.formattedValue === "1,2,3,4") {
      event.target.classList.add("expanded-cell");
    }
  };

  const handleOnCellLeave = (params, event) => {
    if (params.field === "leadTypes" && params.formattedValue === "1,2,3,4") {
      event.target.classList.remove("expanded-cell");
    }
  };

  return (
    <div
      style={{
        height: Math.min(rowsData?.length * 100 + 100, 800),
        width: "100%",
        overflowX: "auto",
        cursor: "grab",
      }}
    >
      <StyledDataGrid
        checkboxSelection
        pageSize={pageSize}
        pagination
        onCellClick={(params, event) => {
          if (params.field === "fullName") event.stopPropagation();
        }}
        style={{ direction: "ltr" }}
        rowHeight={84}
        onRowClick={handleOnRowClick}
        onRowDoubleClick={handleOnRowDoubleClick}
        onCellLeave={handleOnCellLeave}
        onCellEnter={handleOnCellEnter}
        rows={rowsData || []}
        columns={columns || []}
        hideFooterPagination={true}
        hideFooter={true}
        disableSelectionOnClick
        // components={{
        //   ColumnSortedAscendingIcon: () => (
        //     <Icon
        //       className={DropdownIcon}
        //       style={{
        //         cursor: "pointer",
        //         color: "#344054 !important",
        //         fontSize: "20px",
        //       }}
        //     />

        //     // <UploadIcon width="20" height="20" fill="red" />
        //   ),
        //   ColumnSortedDescendingIcon: () => (
        //     <UploadIcon width="20" height="20" fill="blue" />
        //   ),
        // }}
        filterModel={
          undefined
          //   {
          //   items: [
          //     {
          //       columnField: "fullName",
          //       operatorValue: "contains",
          //       value: "samer",
          //     },
          //   ],
          // }
        }
      />
    </div>
  );
};

const customCheckbox = (theme) => ({
  "& .MuiCheckbox-root svg": {
    width: 20,
    height: 20,
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.border.border_disabled}`,
    borderRadius: 6,
  },
  "& .MuiCheckbox-root svg path": {
    display: "none",
  },
  "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
    border: "none",
    background: theme.palette.background.brand_solid,
  },
  "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
    position: "absolute",
    display: "table",
    border: "2px solid #fff",
    borderTop: 0,
    borderLeft: 0,
    transform: "rotate(45deg) translate(-50%,-50%)",
    opacity: 1,
    transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
    content: '""',
    top: "50%",
    left: "39%",
    width: 5,
    height: 9,
    padding: 0,
  },
  "& .MuiCheckbox-root.Mui-checked:hover": {
    boxShadow: "none",
  },
  "& .MuiIconButton-colorPrimary:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiCheckbox-root.Mui-checked:not :hover": {
    boxShadow: "none",
  },
  "& .MuiDataGrid-cell": {
    lineHeight: "24px !important",
  },
});

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  borderRadius: "0 0 8px 8px",
  padding: "2px",
  color: theme.palette.text.primary,
  fontWeight: 500,
  fontFamily: "Inter",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.background.secondary,
    lineHeight: "20px",
    color: theme.palette.text.tertiary_hover,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: theme.palette.text.tertiary_hover,
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  "& .MuiTablePagination-input": {
    display: "block !important",
  },
  ...customCheckbox(theme),
}));

export default BasicTable;

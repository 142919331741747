import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Inputs } from "../../../../Components";

export const SizeFilter = ({
  resaleChangeFilters,
  parentTranslationPath,
  translationPath,
  selected , 
  setSelected ,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  return (
    <div className="w-50 px-2">
      <fieldset className="styled-fieldset d-flex-column-v-center">
        <legend className="styled-legend">{t(`${translationPath}size`)}</legend>
        <div className="w-100 m-1 px-2">
          <Inputs
            idRef="sizeFromRef"
            labelValue={t(`${translationPath}from`)}
            type={"number"}
            value={(selected?.size?.from) || ''}
            withNumberFormat
            onKeyUp={(e) => {
              const { value } = e.target;
              let size = null ; 
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                const sizeFromKey = "builtup_area_sqft_from";
                const sizeFromValue =
                  value || value == 0 ? value.replace(/,/g, "") : null;
                const searchType = 3;

                resaleChangeFilters(
                  sizeFromKey,
                  sizeFromValue,
                  false,
                  searchType
                );
                size = { from : sizeFromValue , to :(selected?.size?.to)} ;
                setSelected({id :'size'  , value : size })
              }, 600);

            }}
            min={0}
          />
        </div>
        <div className="w-100 m-1 px-2">
          <Inputs
            idRef="sizeToRef"
            labelValue={t(`${translationPath}to`)}
            type={"number"}
            value={(selected?.size?.to) || ''}
            withNumberFormat
            onKeyUp={(e) => {
              const { value } = e.target;
              let size =  null ; 
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                const sizeToKey = "builtup_area_sqft_to";
                const sizeToValue =
                  value || value == 0 ? value.replace(/,/g, "") : null;
                const searchType = 4;
                resaleChangeFilters(sizeToKey, sizeToValue, false, searchType);
                size = { from : (selected?.size?.from) , to :sizeToValue} ;
                setSelected({id :'size'  , value : size });
              }, 600);
            }}
            min={0}
          />
        </div>
      </fieldset>
    </div>
  );
};

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    pageTitleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      width: '100%',
      justifyContent: 'space-between',
    },
    titleStyles: {
      color: theme.palette.text.primary,
      fontSize: '30px',
      lineHeight: '38px',
      fontWeight: 600,
      marginBottom: '4px',
    },
    subtitleStyles: {
      color: theme.palette.text.tertiary,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    divider: {
      marginTop: "20px",
      marginBottom: "32px",
      backgroundColor: theme.palette.border.secondary,
    },
    pageTitleChildren: {
      '& > *:not(:last-child)': {
        [theme.breakpoints.down('380')]: {
          marginBottom: theme.spacing(1.5),
        },
      },

      display: 'flex',
      gap: '12px',

      '& .MuiButtonBase-root': {
        height: 'fit-content',
      },

      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
    },
  };
});

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, Divider } from '@material-ui/core';
import useStyles from './styles';

function PageTitle({ title, subTitle, children }) {
  const styles = useStyles();

  return (
    <>
      <section className={clsx(styles.pageTitleWrapper)}>
        <div>
          <Typography
            className={styles.titleStyles}
            variant='h4'
            component='h1'
            gutterBottom
          >
            {title}
          </Typography>
          <Typography className={styles.subtitleStyles} variant='subtitle2'>
            {subTitle}
          </Typography>
        </div>
        <div className={clsx(styles.pageTitleChildren)}>{children}</div>
      </section>
      <Divider className={clsx(styles.divider)} light />
    </>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

PageTitle.defaultProps = {
  title: 'Title',
  subTitle: 'Sub Title',
};

export default PageTitle;

import React, { useEffect, useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Inputs, AutocompleteComponent } from '../../../../../Components';
import { GetTransactionUnitDetails } from '../../../../../Services';
import { GetParams, getErrorByName } from '../../../../../Helper';
import { PageHeading } from '../../../../../SharedComponents/index.jsx';

export const UnitInformationComponent = ({
  state,
  schema,
  onStateChanged,
}) => {
  const parentTranslationPath = 'UnitsStatusManagementView';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);


  return (
    <div className='UnitTransactionsView  px-4 py-3'>
      <PageHeading
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        headerTitle={'unit-info'}
        subTitle={"unit-transaction-heading"}
        headerTitleClasses={"fz-20 mb-3"}
      />
      <div className='d-flex'>
        <Inputs
          idRef='unitNumberRef'
          labelValue='unitNumber'
          wrapperClasses='mr-3'
          value={state.unitNumber}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = event.target.value;
            onStateChanged({ id: 'unitNumber', value: value });
          }}
          helperText={getErrorByName(schema, 'unitNumber').message}
          error={getErrorByName(schema, 'unitNumber').error}
        // isDisabled={isReadOnly}
        />
        <Inputs
          idRef='numberOfBedroomsRef'
          labelValue='numberOfBedrooms'
          wrapperClasses='mr-3'
          value={state.numberOfBedrooms}
          type='number'
          min={0}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = event.target.value;
            if (value < 0) value = 0;
            onStateChanged({ id: 'numberOfBedrooms', value: value });
          }}
          helperText={getErrorByName(schema, 'numberOfBedrooms').message}
          error={getErrorByName(schema, 'numberOfBedrooms').error}
        // isDisabled={isReadOnly}
        />
        <Inputs
          idRef='numberOfBathroomsRef'
          labelValue='numberOfBathrooms'
          wrapperClasses=''
          value={state.numberOfBathrooms}
          type='number'
          min={0}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = event.target.value;
            if (value < 0) value = 0;
            onStateChanged({ id: 'numberOfBathrooms', value: value });
          }}
          helperText={getErrorByName(schema, 'numberOfBathrooms').message}
          error={getErrorByName(schema, 'numberOfBathrooms').error}
        // isDisabled={isReadOnly}
        />
      </div>
      <div className='d-flex w-50'>
        <Inputs
          idRef='builtUpAreaSizeRef'
          labelValue='builtUpAreaSize'
          wrapperClasses='my-2 mr-2'
          value={state.builtUpAreaSize}
          type='number'
          min={0}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = event.target.value;
            if (value < 0) value = 0;
            onStateChanged({ id: 'builtUpAreaSize', value: value });
          }}
          helperText={getErrorByName(schema, 'builtUpAreaSize').message}
          error={getErrorByName(schema, 'builtUpAreaSize').error}
        // isDisabled={isReadOnly}
        />
        {state.listingAgent &&
         <AutocompleteComponent
          idRef='listing-agentfilterRef'
          labelValue='listing-agent'
          selectedValues={state.listingAgent}
          data={[]}
          wrapperClasses='w-min-unset m-2'
          multiple={false}
          displayLabel={(option) => option?.listingAgentName || ''}
          withoutSearchButton
          isWithError
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />}
        {state.leadOwner &&
        <AutocompleteComponent
          idRef='cityfilterRef'
          labelValue='lead-owner'
          selectedValues={state.leadOwner}
          data={[]}
          wrapperClasses='w-min-unset m-2'
          multiple={false}
          displayLabel={(option) => option?.leadOwnerName || ''}
          withoutSearchButton
          isWithError
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />}
      </div>
    </div>
  );
};

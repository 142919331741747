import React from 'react';
const ShareDocumentLogHeader = [
  {
    id: 1,
    label: 'NO.',
    input: 'shareDocumentLogId',
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 2,
    label: 'document-source',
    input: 'documentSource',
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 3,
    label: 'document-category',
    input: 'documentCategory',
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 4,
    label: 'document-name',
    input: 'documentName',
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 5,
    label: 'sender-name',
    input: 'senderName',
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 6,
    label: 'send-via',
    input: 'sendVia',
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 7,
    label: 'receiver-name',
    input: 'receiverName',
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
    component: (item) => (
      <span>
        {item?.receiverName.split(',').map((el, index) => (
          <span key={index} className='table-capsule mr-1 fw-simi-bold'>
            {el.trim() || ''}
          </span>
        ))}
      </span>
    ),
  },
  {
    id: 8,
    label: 'shared-time',
    input: 'sharedTime',
    isSortable: true,
    isHiddenFilter: false,
    isDate: true,
  },
  {
    id: 13,
    label: 'is-deleted',
    input: 'isDeleted',
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
    component: (item) => (
      <span>{item?.isDeleted === false ? 'NO ' : 'YES'}</span>
    ),
  },
];

export default ShareDocumentLogHeader;

import React, { useState, useEffect, useCallback, useContext } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
import {
  PageTitle,
  CustomButton,
  SummaryCardList,
  SummaryCardListSkeleton,
  ContactProfile,
  ContactCardSkeleton,
  ContactTableSkeleton,
  CustomSnackbar,
  ContactCard,
  BasicTable,
  TableColumnsFilterComponent,
  CustomPagination,
  VIEW_MODES,
  CustomTableHeader,
  ContactsFilterBar,
} from "../../../Components";
import { useSelectedTheme, useTitle, useTranslate } from "../../../Hooks";
import { newContactsTableHeaderData } from "./NewContactsTableHeaderData";
import { FormsIdsEnum, ColumnsFilterPagesEnum } from "../../../Enums";
import { GetAllFormFieldsByFormId } from "../../../Services";
import { TableColumnsFilterActions } from "../../../store/TableColumnsFilter/TableColumnsFilterActions";
import { flattenObject } from "../../../Helper";

import { QuickFilterSection } from "./UI";

// Icons
import { UploadIcon, PlusIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

function NewContactsView() {
  const styles = useStyles();

  const { translate } = useTranslate("NewContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const { isDarkMode } = useSelector((state) => state.theme);

  const { contactsData, pagination, setPagination, isLoading } =
    useContext(ContactLayoutContext);

  const [filterItems, setFilterItems] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const flattenRows = (rows) => rows.map((row) => flattenObject(row));
  const flattenedRows = flattenRows([...contactsData?.result]);

  useTitle(translate("CONTACTS_PAGE_TITLE"));

  const [isExpanded, setIsExpanded] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [filterColumnsApplied, setFilterColumnsApplied] = useState(undefined);
  const [viewMode, setViewMode] = useState(VIEW_MODES.GRID);

  /////////////////////////////////////////////////////////

  const dispatch = useDispatch();
  const [allFormFields, setAllFormFields] = useState([]);
  const [tableColumns, setTableColumns] = useState(
    newContactsTableHeaderData(isDarkMode)
  );
  const [updateSelectedColumnItems, setUpdateSelectedColumnItems] =
    useState(tableColumns);
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    newContactsTableHeaderData(isDarkMode)
      .filter((item) => item.isDefaultFilterColumn)
      .map((column) => column.id)
  );

  const advanceSearch = useSelector(
    (state) => state.GlobalAdvanceSearchReducer
  );

  const tableColumnsFilterResponse = useSelector(
    (state) => state.TableColumnsFilterReducer
  );

  const getAllFormFieldsByFormId = useCallback(async () => {
    Promise.all([
      await GetAllFormFieldsByFormId(FormsIdsEnum.contactsIndividual.id),
      await GetAllFormFieldsByFormId(FormsIdsEnum.contactsCorporate.id),
    ])
      .then((result) => {
        if (Array.isArray(result[0]) && Array.isArray(result[1])) {
          const concantinateFields = result[0]
            .concat(result[1])
            .filter(
              (field, index, array) =>
                array.findIndex(
                  (element) => element.formFieldKey === field.formFieldKey
                ) === index
            );
          const list = concantinateFields
            .filter(
              (e) =>
                e.formFieldName !== "company_logoimage" &&
                e.formFieldName !== "contact_image" &&
                e.formFieldName !== "contact_classifications" &&
                e.formFieldName !== "contact_preference" &&
                e.formFieldName !== "map" &&
                e.formFieldName !== "contacts_person"
            )
            .map((field) => ({
              ...field,
              id: field.formFieldId || null,
              key: field.formFieldKey || null,
              isDate: field.uiWidgetType === "alt-date" || false,
              disableColumnMenu: true,
              minWidth: 190,
              headerName:
                (field.formFieldTitle &&
                  field.formFieldTitle.replace("*", "")) ||
                "",
              field: field.displayPath || "",
              fieldType:
                field?.uiWidgetType === "alt-date"
                  ? "datePicker"
                  : field?.uiWidgetType,
              isNumber:
                (field.propertyJson &&
                  JSON.parse(field.propertyJson).schema &&
                  field.propertyJson &&
                  JSON.parse(field.propertyJson).schema.specialKey ===
                    "currency") ||
                (field.propertyJson &&
                  JSON.parse(field.propertyJson).schema &&
                  field.propertyJson &&
                  JSON.parse(field.propertyJson).schema.specialKey ===
                    "decimal") ||
                (field.propertyJson &&
                  JSON.parse(field.propertyJson).schema &&
                  field.propertyJson &&
                  JSON.parse(field.propertyJson).schema.specialKey === "size"),
              isSortable: true,
              searchableKey: field.searchableKey,
              renderHeader: (params) => (
                <CustomTableHeader
                  params={undefined}
                  title={
                    (field.formFieldTitle &&
                      field.formFieldTitle.replace("*", "")) ||
                    ""
                  }
                />
              ),
            }));
          setAllFormFields(list);
        } else setAllFormFields([]);
      })
      .catch(() => {
        setAllFormFields([]);
      });
  }, []);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    setTableColumns([
      ...newContactsTableHeaderData(isDarkMode).filter(
        (item) =>
          selectedTableFilterColumns.findIndex(
            (element) => element === item.id
          ) !== -1
      ),
      ...allFormFields
        .filter(
          (item) =>
            selectedTableFilterColumns.findIndex(
              (element) => element === item.formFieldId
            ) !== -1
        )
        .map((field) => ({
          id: field.formFieldId || null,
          key: field.formFieldKey || null,
          isDate: field.uiWidgetType === "alt-date" || false,
          disableColumnMenu: true,
          minWidth: 190,
          headerName:
            (field.formFieldTitle && field.formFieldTitle.replace("*", "")) ||
            "",
          field: field.displayPath || "",
          isNumber:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey ===
                "currency") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "decimal") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "size"),
          isSortable: true,
          searchableKey: field.searchableKey,
          renderHeader: (params) => (
            <CustomTableHeader
              params={undefined}
              title={
                (field.formFieldTitle &&
                  field.formFieldTitle.replace("*", "")) ||
                ""
              }
            />
          ),
        })),
    ]);
  }, [
    allFormFields,
    selectedTableFilterColumns,
    // contactTableFilter
  ]);

  useEffect(() => {
    if (!isLoading) {
      setIsFirstLoad(false); // Update the state after the first load
    }
  }, [isLoading]);

  return (
    <>
      <PageTitle
        title={translate("CONTACTS_PAGE_TITLE")}
        subTitle={translate("CONTACTS_PAGE_SUBTITLE")}
      >
        <CustomButton
          boxShadow="xs"
          size="lg"
          variant="outlined"
          color="secondary"
          startIcon={
            <UploadIcon
              width="20"
              height="20"
              fill={palette.button.secondary_fg}
            />
          }
        >
          {translate("IMPORT")}
        </CustomButton>
        <CustomButton
          boxShadow="xs"
          size="lg"
          variant="contained"
          color="primary"
          startIcon={
            <PlusIcon width="20" height="20" fill={palette.button.primary_fg} />
          }
        >
          {translate("ADD_NEW")}
        </CustomButton>
      </PageTitle>

      {isLoading && isFirstLoad ? (
        <SummaryCardListSkeleton numberOfCards={5} />
      ) : (
        <SummaryCardList
          cardTitles={[
            translate("24H_NEW_CONTACTS"),
            translate("24H_NEW_LEADS"),
            translate("OPPORTUNITY_CONTACT"),
            translate("MY_OPPORTUNITY_CONTACT"),
            translate("PSI_OPPORTUNITY"),
          ]}
        />
      )}

      <CustomSnackbar
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        primaryMessage={translate("DUPLICATE_CONTACTS_TEXT", { number: "22" })}
        secondaryMessage={translate("PROCEED_ALERT_TEXT")}
      />

      {/* <Box className={styles.selectContainer}>
        <TableColumnsFilterComponent
          columns={newContactsTableHeaderData(isDarkMode)
            .concat(
              allFormFields.filter(
                (item) =>
                  newContactsTableHeaderData(isDarkMode).findIndex(
                    (element) => element.fieldKey === item.formFieldKey
                  ) === -1
              )
            )
            .map((item) => ({
              key: item.formFieldId || item.id,
              value:
                (item.formFieldTitle && item.formFieldTitle.replace("*", "")) ||
                item.label,
            }))}
          isLoading={isLoading}
          selectedColumns={selectedTableFilterColumns}
          onSelectedColumnsChanged={(newValue) => {
            setSelectedTableFilterColumns(newValue);
            let localTableColumnsFilterResponse = tableColumnsFilterResponse;

            if (localTableColumnsFilterResponse) {
              localTableColumnsFilterResponse[
                ColumnsFilterPagesEnum.contacts.key
              ] = newValue;
            } else {
              localTableColumnsFilterResponse = {
                [ColumnsFilterPagesEnum.contacts.key]: newValue,
              };
            }

            dispatch(
              TableColumnsFilterActions.TableColumnsFilterRequest(
                localTableColumnsFilterResponse
              )
            );
          }}
          parentTranslationPath={"ContactsView"}
          translationPath=""
        />
      </Box> */}

      <QuickFilterSection
        setUpdateSelectedColumnItems={setUpdateSelectedColumnItems}
        viewMode={viewMode}
        onChangeViewMode={setViewMode}
        allFormFields={newContactsTableHeaderData(isDarkMode).concat(
          allFormFields.filter(
            (item) =>
              newContactsTableHeaderData(isDarkMode).findIndex(
                (element) =>
                  element?.fieldKey === item?.formFieldKey ||
                  element?.fieldKey === item?.displayPath
              ) === -1
          )
        )}
        tableColumns={updateSelectedColumnItems}
      />
      
      <ContactsFilterBar
        viewMode={viewMode}
        filterItems={filterItems}
        setFilterItems={setFilterItems}
        tableColumns={updateSelectedColumnItems}
        data={flattenedRows || []}
        columnsDetails={updateSelectedColumnItems}
      />
      {viewMode === VIEW_MODES.TABLE && (
        <Box style={{ marginBottom: "50px" }}>
          {isLoading ? (
            <ContactTableSkeleton rowsNum={12} />
          ) : contactsData?.result?.length > 0 ? (
            <BasicTable
              filterColumnsApplied={filterColumnsApplied}
              setFilterColumnsApplied={setFilterColumnsApplied}
              pageSize={pagination.itemsPerPage}
              rowsData={flattenedRows || []}
              setActiveItem={setActiveItem}
              columns={updateSelectedColumnItems || []}
            />
          ) : (
            <></>
          )}
        </Box>
      )}

      {viewMode === VIEW_MODES.GRID && (
        <Box className={styles.gridContainer}>
          {isLoading
            ? Array.from({ length: 25 }).map((_, index) => (
                <ContactCardSkeleton index={index} />
              ))
            : contactsData.result?.map((item) => (
                <ContactCard item={item} setActiveItem={setActiveItem} />
          ))}
        </Box>
      )}

      <ContactProfile
        activeItem={activeItem}
        open={activeItem?.contactId || activeItem?.id ? true : false}
        onClose={() => setActiveItem({})}
      />

      <CustomPagination
        totalItems={contactsData.totalCount}
        itemsPerPage={pagination.itemsPerPage}
        onPageChange={(page) =>
          setPagination((prev) => ({ ...prev, currentPage: page }))
        }
        onItemsPerPageChange={(items) =>
          setPagination((prev) => ({ ...prev, itemsPerPage: items }))
        }
        isLoading={isLoading}
      />
    </>
  );
}

export default NewContactsView;

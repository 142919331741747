import React from 'react';
import './PageHeading.scss';
import { useTranslation } from 'react-i18next';
 export const PageHeading = ({parentTranslationPath, translationPath, headerTitle, subTitle,
  subTitleClasses, headerTitleClasses, wrapperClasses , titleWithPercentage
}) => {
  const { t } = useTranslation(parentTranslationPath);
  
  return (
    <div className={`PageHeading mb-3 ${wrapperClasses || ''}`}>
      {
        !titleWithPercentage ?  
        <h5 className={`fw-simi-bold m-0 mb-1 ${headerTitleClasses || ''}`}>{headerTitle? t(`${translationPath}${headerTitle}`) : ''}</h5> 
        :
        (
        <div className='title-with-chip mb-2'>
         <h5 className={`fw-simi-bold m-0 mb-1 header-title`}>{headerTitle? t(`${translationPath}${headerTitle}`) : ''}</h5> 

         <span className='title-chip'> { titleWithPercentage }</span>
        </div>)}
      <p className={`text-tertiary heading-subtitle ${subTitleClasses || ''}`}>{subTitle? t(`${translationPath}${subTitle}`) : ''}</p>
    </div>

  );
};
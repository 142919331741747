import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  autoCompleteWrapper: {
    "& .MuiAutocomplete-endAdornment": {
      top: "25%",
    },
    "& .MuiAutocomplete-input": {
      paddingRight: "40px !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.primary,
    },

    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.primary,
    },

    "& .MuiAutocomplete-inputRoot": {
      padding: "10px 14px !important",
      borderColor: "red",
    },
  },
  placeholder: {
    cursor: "pointer",
    "&::placeholder": {
      color: theme.palette.text.placeholder,
    },
  },
  selected: {
    fontWeight: 500,
  },

  dropdownIcon: {
    cursor: "pointer",
    color: theme.palette.foreground.quarterary,
    fontSize: "20px",
  },

  inputIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.foreground.quarterary,
  },

  iconVariant: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: theme.spacing(3),
    },
  },

  noBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

import React from 'react';
import { LeadsPoolFilter } from './LeadsPoolFilter';
import { LeadsPoolTable } from './LeadsPoolTable';
import '../LeadsPool.scss';
import LeadsPoolProvider from '../LeadsPoolContext';

const parentTranslationPath = 'LeadsPool';
const translationPath = '';

export const LeadsPoolView = () => {
  return (
    <LeadsPoolProvider>
      <div className='view-wrapper'>
        <div className='d-flex-column'>
          <div className='header-section'>
            <LeadsPoolFilter
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
        </div>
        <div className='body-section'>
          <LeadsPoolTable
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
      </div>
    </LeadsPoolProvider>
  );
};

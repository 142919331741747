import React from "react";
import PropTypes from "prop-types";

import { useIsDesktop } from "../../../Hooks";

// styles
import useStyles from "./styles";

function LayoutContent({ children }) {
  const styles = useStyles();
  //

  const { isDesktop } = useIsDesktop();

  return (
    <main
      className={styles.root}
      style={!isDesktop ? { padding: "32px 16px" } : {}}
    >
      {children}
    </main>
  );
}

LayoutContent.propTypes = {
  children: PropTypes.node,
};

LayoutContent.defaultProps = {
  children: null,
};

export default LayoutContent;

import {
  PropertiesRoutes,
  ContactsRoutes,
  HomeRoutes,
  FormBuilderRoutes,
  LookupsRoutes,
  RolesRoutes,
  UserDataViewRoutes,
  LeadsRoutes,
  UnitsRoutes,
  ContactsLeaseRoutes,
  ContactsSalesRoutes,
  PropertiesLeaseRoutes,
  PropertiesSalesRoutes,
  UnitsLeaseRoutes,
  UnitsSalesRoutes,
  LeadsSalesRoutes,
  LeadsleaseRoutes,
  PortfolioRoutes,
  WorkOrdersRoutes,
  IncidentsRoutes,
  OperatingCostsRoutes,
  AssetsViewRoutes,
  MaintenanceContractsRoutes,
  SalesTransactionsRoutes,
  LeasingTransactionsRoutes,
  InvoicesRoutes,
  ContactsRoutesPropertyManagement,
  UnitsPropertyManagement,
  LeadsPropertyManagmentRoutes,
  TemplatesRoutes,
  ContactsCrmRoutes,
  PropertiesCrmRoutes,
  GalleryCityRoutes,
  GalleryDistrictRoutes,
  GalleryCommunityRoutes,
  GallerySubCommunityRoutes,
  AgentsRotationCriteriaRoutes,
  CRMReportsRoutes,
  CountryRoutes,
  DistrictsRoutes,
  CitiesRoutes,
  CommunitiesRoutes,
  SubCommunitiesRoutes,
  DashboardRoutes,
  BranchesRoutes,
  ActivitiesMortgageRoutes,
  LeadsMortgageRoutes,
  ReportsBuilderRoutes,
  ClientsSegmentationRoutes,
  PoliciesRoutes,
  ZeroMatchingInquiresRoutes,
  ListingShortageInquiresRoutes,
  ZeroMatchingLeaseInquiresRoutes,
  MergeLogsRoutes,
  LeadOwnerRoutes,
  LeadOwnerActivityRoutes,
  LeadOwnerAdminAssignAgentRoutes,
  LeadOwnerAssignAgentRoutes,
  LeadOwnerUnitSaleRoutes,
  LeadOwnerUnitLeaseRoutes,
  LeadOwnerLeadsRoutes,
  ActivitiesCommunicationLogsRoutes,
  PortalsRoutes,
  CampaignRequestsRoutes,
  KenbanBoardRoutes,
  LandmarksLocationsRoutes,
  TransactionConfigurationRoutes,
  OwnerRotationRoutes,
  TaskConfigurationRoutes,
  LearningAndDevelopmentRoutes,
  PropertyRatingRoutes ,
  ConvoloAgentsRoutes , 
  ResaleUnitsRoutes  , 
  InquiryRotationRoutes ,
} from '../HomeRoutes';

export const BreadCrumbRoutes = [
  ...PropertiesRoutes,
  ...LeadsRoutes,
  ...UnitsRoutes,
  ...ContactsRoutes,
  ...HomeRoutes,
  ...FormBuilderRoutes,
  ...LookupsRoutes,
  ...RolesRoutes,
  ...UserDataViewRoutes,
  ...ContactsLeaseRoutes,
  ...ContactsSalesRoutes,
  ...PropertiesLeaseRoutes,
  ...PropertiesSalesRoutes,
  ...UnitsLeaseRoutes,
  ...UnitsSalesRoutes,
  ...LeadsSalesRoutes,
  ...LeadsleaseRoutes,
  ...PortfolioRoutes,
  ...WorkOrdersRoutes,
  ...IncidentsRoutes,
  ...OperatingCostsRoutes,
  ...AssetsViewRoutes,
  ...MaintenanceContractsRoutes,
  ...SalesTransactionsRoutes,
  ...LeasingTransactionsRoutes,
  ...InvoicesRoutes,
  ...ContactsRoutesPropertyManagement,
  ...UnitsPropertyManagement,
  ...LeadsPropertyManagmentRoutes,
  ...TemplatesRoutes,
  ...ContactsCrmRoutes,
  ...PropertiesCrmRoutes,
  ...GalleryCityRoutes,
  ...GalleryDistrictRoutes,
  ...GalleryCommunityRoutes,
  ...GallerySubCommunityRoutes,
  ...AgentsRotationCriteriaRoutes,
  ...CRMReportsRoutes,
  ...CountryRoutes,
  ...DistrictsRoutes,
  ...CitiesRoutes,
  ...CommunitiesRoutes,
  ...SubCommunitiesRoutes,
  ...DashboardRoutes,
  ...BranchesRoutes,
  ...ActivitiesMortgageRoutes,
  ...LeadsMortgageRoutes,
  ...ReportsBuilderRoutes,
  ...ClientsSegmentationRoutes,
  ...PoliciesRoutes,
  ...ZeroMatchingInquiresRoutes,
  ...ListingShortageInquiresRoutes,
  ...ZeroMatchingLeaseInquiresRoutes,
  ...MergeLogsRoutes,
  ...LeadOwnerRoutes,
  ...LeadOwnerActivityRoutes,
  ...LeadOwnerAdminAssignAgentRoutes,
  ...LeadOwnerAssignAgentRoutes,
  ...LeadOwnerUnitSaleRoutes,
  ...LeadOwnerUnitLeaseRoutes,
  ...LeadOwnerLeadsRoutes,
  ...ActivitiesCommunicationLogsRoutes,
  ...PortalsRoutes,
  ...CampaignRequestsRoutes,
  ...KenbanBoardRoutes,
  ...LandmarksLocationsRoutes,
  ...TransactionConfigurationRoutes,
  ...OwnerRotationRoutes,
  ...TaskConfigurationRoutes,
  ...LearningAndDevelopmentRoutes,
  ...PropertyRatingRoutes ,
  ...ConvoloAgentsRoutes  , 
  ...ResaleUnitsRoutes  ,
  ...InquiryRotationRoutes
];

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteComponent,
  Inputs,
  UploaderComponentCircular,
} from '../../../../../../../Components';

import { UploaderComponent } from '../../UploaderComponent/UploaderComponent';
import {
  DefaultImagesEnum, UploaderThemesEnum, CityTypeIdEnum, SubCommunityTypeIdEnum, CommunityTypeIdEnum, DistrictTypeIdEnum
} from '../../../../../../../Enums';
import { getErrorByName } from '../../../../../../../Helper';

export const BranchTabComponent = ({
  parentTranslationPath,
  translationPath,
  state,
  isSubmitted,
  addressList,
  allUsers,
  isLoading,
  schema,
  addressLoadings,
  searchHandler,
  selected,
  setSelected,
  setState,
  address,
  setAddress,
  getSubCommunityLookUps,
  getCommunityLookUps,
  getDistrictLookUps,
  getCitiesLookups,
  getLookupsOfParent,
}) => {

  const { t } = useTranslation(parentTranslationPath)
  return (
    <>
      <div className='dialog-content-wrapper'>
        <UploaderComponentCircular
          idRef='branchLogoIdRef'
          circleDefaultImage={DefaultImagesEnum.corporate.defaultImg}
          initUploadedFiles={
            (state &&
              state.branchLogoId && [
                { uuid: state.branchLogoId, fileName: 'branch-logo' },
              ]) ||
            []
          }
          uploadedChanged={(files) =>
            setState({
              id: 'branchLogoId',
              value: (files.length > 0 && files[0].uuid) || null,
            })}
        />
        <div className='dialog-content-item item-wrapper'>
          <Inputs
            idRef='branchNameoIdRef'
            labelValue='branch-name'
            isWithError
            labelClasses='Requierd-Color'
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            helperText={
              getErrorByName(schema, 'branchName').message
            }
            error={getErrorByName(schema, 'branchName').error}
            value={state.branchName || null}
            onInputChanged={(event) =>
              setState({
                id: 'branchName',
                value: event.target.value,
              })}
          />
        </div>
        <div className='dialog-content-item item-wrapper'>
          <Inputs
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            idRef='branchNumberIdRef'
            withNumberFormat
            labelValue='branch-number'
            value={state.branchNumber || null}
            onInputChanged={(event) => {
              const value = event.target && event.target.value;
              const parsedValue = value && parseInt(value);
              setState({
                id: 'branchNumber',
                value: parsedValue,
              });
            }}
          />
        </div>
        <div className='dialog-content-item'>
          <AutocompleteComponent
            selectedValues={address?.country}
            labelValue={t(`${translationPath}branch-country`)}
            multiple={false}
            idRef='branch-countryIdRef'
            withoutSearchButton
            isLoading={addressLoadings.countries}
            value={ address?.country?.lookupItemName || null}
            data={addressList && addressList.countries || []}
            displayLabel={(option) => option && option.lookupItemName}
            getOptionSelected={(option) => option.lookupItemId === address?.country?.lookupItemId}
            onChange={(event, value) => {
              const countryId = value && value.lookupItemId;

              setState({ id: 'edit', value: {
                ...state,
                branchCountryId: countryId,
                branchCityId: null,
                branchDistrictId: null,
                branchCommunityId: null,
                branchSubCommunityId: null,
              }})

                setAddress({
                  country: value,
                  city: null,
                  district: null,
                  community: null,
                  subCommunity: null
                });
                if(countryId) getCitiesLookups(countryId);
            }}
          />
        </div>
        <div className='dialog-content-item'>
          <AutocompleteComponent
            selectedValues={address?.city}
            labelValue={t(`${translationPath}branch-city`)}
            multiple={false}
            isDisabled={!(address?.country)}
            withoutSearchButton
            idRef='branch-cityIdRef'
            isLoading={addressLoadings.cities}
            value={ address?.city?.lookupItemName || null}
            data={addressList && addressList.cities || []}
            displayLabel={(option) => option && option.lookupItemName}
            getOptionSelected={(option) => option.lookupItemId === address?.city?.lookupItemId}
            onChange={(event, value) => {
              const cityId = value && value.lookupItemId;
              setState({ id: 'edit', value: {
                ...state,
                branchCityId: cityId,
                branchDistrictId: null,
                branchCommunityId: null,
                branchSubCommunityId: null,
              }})

                setAddress({
                  ...address,
                  city: value,
                  district: null,
                  community: null,
                  subCommunity: null
                });
                if(cityId) getDistrictLookUps(cityId);

            }}
          />
        </div>
        <div className='dialog-content-item'>
          <AutocompleteComponent
            idRef='DistrictRef'
            labelValue={t(`${translationPath}branch-district`)}
            selectedValues={address?.district}
            isLoading={addressLoadings.districts}
            value={ address?.district?.lookupItemName || null}
            data={addressList && addressList.districts || []}
            multiple={false}
            isDisabled={!(address?.city)}
            displayLabel={(option) => (option && option.lookupItemName) || ''}
            getOptionSelected={(option) => option.lookupItemId === address?.district?.lookupItemId}
            withoutSearchButton
            onChange={(event, value) => {
              const districtId = value && value.lookupItemId;
              setState({ id: 'edit', value: {
                ...state,
                branchDistrictId: districtId,
                branchCommunityId: null,
                branchSubCommunityId: null,
              }})

                setAddress({
                  ...address,
                  district: value,
                  community: null,
                  subCommunity: null
                });
                if(districtId) getCommunityLookUps(districtId);
            }}
          />
        </div>
        <div className='dialog-content-item'>
          <AutocompleteComponent
            idRef='branch-communitieRef'
            labelValue={t(`${translationPath}branch-communities`)}
            selectedValues={address?.community}
            isLoading={addressLoadings.communities}
            value={ address?.community?.lookupItemName || null}
            data={addressList && addressList.communities || []}
            multiple={false}
            isDisabled={!(address?.district)}
            displayLabel={(option) => (option && option.lookupItemName) || ''}
            getOptionSelected={(option) => option.lookupItemId === address?.community?.lookupItemId}
            withoutSearchButton
            onChange={(event, value) => {
              const communityId = value && value.lookupItemId;
              setState({ id: 'edit', value: {
                ...state,
                branchCommunityId: communityId,
                branchSubCommunityId: null,
              }})

                  setAddress({
                    ...address,
                    community: value,
                    subCommunity: null
                  });
                 if(communityId) getSubCommunityLookUps(communityId)
            }}
          />
        </div>
        <div className='dialog-content-item'>
          <AutocompleteComponent
            idRef='SubCommunitiesRef'
            labelValue={t(`${translationPath}branch-sub-communities`)}
            selectedValues={address?.subCommunity}
            isLoading={addressLoadings.subCommunities}
            value={ address?.subCommunity?.lookupItemName || null}
            data={addressList && addressList.subCommunities || []}
            multiple={false}
            isDisabled={!(address?.community)}
            displayLabel={(option) => (option && option.lookupItemName) || ''}
            getOptionSelected={(option) => option.lookupItemId === address?.subCommunity?.lookupItemId}
            withoutSearchButton
            onChange={(event, value) => {
              const subCommunityId = value && value.lookupItemId;
              setAddress({ ...address,  subCommunity: value });
              setState({ id: 'branchSubCommunityId', value: subCommunityId });
            }}
          />
        </div>
        <div className='dialog-content-item'>
          <Inputs
            withNumberFormat
            idRef='maximumNumberOfUsersRef'
            labelValue='maximum-number-of-users'
            value={state.maximumNumberOfUsers || null}
            onInputChanged={(event) => {
              const value = event.target && event.target.value;
              const parsedValue = value && parseInt(value);
              setState({
                id: 'maximumNumberOfUsers',
                value: parsedValue,
              });
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>

        <div className='dialog-content-item'>
          <Inputs
            labelValue='licenseNumber'
            isSubmitted={isSubmitted}
            idRef='licenseNumberRef'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={state.licenseNumber || null}
            onInputChanged={(event) =>
              setState({
                id: 'licenseNumber',
                value: event.target.value,
              })}
          />
        </div>
        <div className='dialog-content-item'>
          <Inputs
            labelValue='brokerageRegistrationNumber'
            isSubmitted={isSubmitted}
            idRef='brokerageRegistratiRef'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={state.brokerageRegistrationNumber || null}
            onInputChanged={(event) =>
              setState({
                id: 'brokerageRegistrationNumber',
                value: event.target.value,
              })}
          />
        </div>
        <div className='dialog-content-item'>
          <Inputs
            labelValue='branchStreet'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={state.branchStreet || null}
            idRef='branchStreetiRef'
            isSubmitted={isSubmitted}
            onInputChanged={(event) =>
              setState({
                id: 'branchStreet',
                value: event.target.value,
              })}
          />
        </div>
        <div className='dialog-content-item'>
          <Inputs
            labelValue='branchEmail'
            idRef='branchEmailRef'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={state.branchEmail || null}
            isSubmitted={isSubmitted}
            onInputChanged={(event) =>
              setState({
                id: 'branchEmail',
                value: event.target.value,
              })}
          />
        </div>
        <div className='dialog-content-item'>
          <Inputs
            labelValue='branchWebsite'
            idRef='branchWebsiteRef'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={state.branchWebsite || null}
            isSubmitted={isSubmitted}
            onInputChanged={(event) =>
              setState({
                id: 'branchWebsite',
                value: event.target.value,
              })}
          />
        </div>
        <div className='dialog-content-item'>
          <Inputs
            labelValue='poBox'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={state.poBox || null}
            idRef='poBoxRef'
            isSubmitted={isSubmitted}
            onInputChanged={(event) =>
              setState({
                id: 'poBox',
                value: event.target.value,
              })}
          />
        </div>
        <div className='dialog-content-item'>
          <Inputs
            labelValue='companyTelephoneNo'
            idRef='companyTelephoneNoRef'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={state.companyTelephoneNo || null}
            isSubmitted={isSubmitted}
            onInputChanged={(event) =>
              setState({
                id: 'companyTelephoneNo',
                value: event.target.value,
              })}
          />
        </div>
        <div className='dialog-content-item'>
          <Inputs
            labelValue='Property'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            idRef='propertyBoxRef'
            value={state.property || null}
            isSubmitted={isSubmitted}
            onInputChanged={(event) =>
              setState({
                id: 'property',
                value: event.target.value,
              })}
          />
        </div>
        <div className='dialog-content-item'>
          <Inputs
            labelValue='unit'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={state.unit || null}
            idRef='unitBoxRef'
            isSubmitted={isSubmitted}
            onInputChanged={(event) =>
              setState({
                id: 'unit',
                value: event.target.value,
              })}
          />
        </div>
        <div className='dialog-content-item'>
          <Inputs
            labelValue='floor'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={state.floor || null}
            isSubmitted={isSubmitted}
            idRef='floorxRef'
            onInputChanged={(event) =>
              setState({
                id: 'floor',
                value: event.target.value,
              })}
          />
        </div>
        <div className='dialog-content-item'>
          <AutocompleteComponent
            idRef='userRef'
            selectedValues={selected.user}
            labelClasses='Requierd-Color'
            labelValue={t(`${translationPath}user`)}
            onInputKeyUp={searchHandler}
            data={(allUsers && allUsers.result) || []}
            multiple={false}
            isLoading={isLoading}
            displayLabel={(option) => (option && option.fullName) || ''}
            chipsLabel={(option) => (option && option.fullName) || ''}
            withoutSearchButton
            helperText={isSubmitted && getErrorByName(schema, 'userId').message || ''}
            error={isSubmitted && getErrorByName(schema, 'userId').error || ''}
            onChange={(event, newValue) => {
              setState({
                id: 'userId',
                value: newValue && newValue.id || '',
              })
              setSelected({
                id: 'user',
                value: newValue || '',
              })
            }}
          />
        </div>
        <div className='w-100 mb-3 document-images-wrapper'>
          <UploaderComponent
            idRef='documentsFooterImageIdrRef'
            labelValue='documents-footer-image'
            multiple
            accept='image/*'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            viewUploadedFilesCount
            initUploadedFiles={
              (
                state && state.documentsFooterImageId && (
                  [{ uuid: state.documentsFooterImageId }]
                ) || []
              )
            }
            uploadedChanged={(files) => {
              setState({
                id: 'documentsFooterImageId',
                value:
                  (files && files.length &&
                    files[0].uuid) ||
                  null,
              });
            }}
            uploaderTheme={UploaderThemesEnum.GalleryShow.key}
            isOneFile
          />
          <UploaderComponent
            idRef='documentsHeaderImageIdsUploaderRef'
            labelValue='documents-header-image'
            multiple
            accept='image/*'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            viewUploadedFilesCount
            initUploadedFiles={
              (
                state && state.documentsHeaderImageId && (
                  [{ uuid: state.documentsHeaderImageId }]
                ) || []
              )
            }
            uploadedChanged={(files) => {
              setState({
                id: 'documentsHeaderImageId',
                value:
                  (files && files.length &&
                    files[0].uuid) ||
                  null,
              });
            }}
            uploaderTheme={UploaderThemesEnum.GalleryShow.key}
            isOneFile
          />
        </div>
      </div>
    </>
  )
}

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MUIButton from '@material-ui/core/Button';

// styles
import useStyles from './styles';

function CustomButton({ boxShadow, size, variant, children, startIcon, endIcon, color, ...restProps }) {

  let elevation = 0;
  switch (boxShadow) {
    case 'none': elevation = 0; break;
    case 'xs': elevation = 1; break;
    case 'sm': elevation = 2; break;
    case 'md': elevation = 3; break;
    case 'lg': elevation = 4; break;
    case 'xl': elevation = 5; break;
    case '2xl': elevation = 6; break;
    case '3xl': elevation = 7; break;
    default: elevation = 0; break;
  }

  const sizeStyles = {
    sm: { padding: '8px 12px', fontSize: '14px', minHeight: '36px', gap: '4px', lineHeight: '20px' },
    md: { padding: '10px 14px', fontSize: '14px', minHeight: '40px', gap: '4px', lineHeight: '20px' },
    lg: { padding: '10px 16px', fontSize: '16px', minHeight: '44px', gap: '6px', lineHeight: '24px' },
    xl: { padding: '12px 18px', fontSize: '16px', minHeight: '48px', gap: '6px', lineHeight: '24px' },
    '2xl': { padding: '16px 22px', fontSize: '18px', minHeight: '60px', gap: '10px', lineHeight: '28px' },
    popupAction: { padding: '0px 16px', fontSize: '16px', minHeight: '40px', gap: '6px', lineHeight: '24px', width: '130px' },

  };

  const styles = useStyles({
    elevation,
    sizeStyles: sizeStyles[size],
    color,
    variant,
  });

  return (
    <MUIButton
      className={clsx(styles.root)}
      disableRipple
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      {...restProps}
    >
      {children}
    </MUIButton>
  );
}

CustomButton.propTypes = {
  boxShadow: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl']),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
  variant: PropTypes.oneOf(['contained', 'text', 'outlined']),
  ...MUIButton.propTypes,
};

CustomButton.defaultProps = {
  size: 'sm',
  boxShadow: 'none',
  variant: 'contained',
  color: 'primary',
};

export default CustomButton;

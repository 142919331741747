import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  DialogComponent,
  PermissionsComponent,
  Tables,
} from "../../../../Components";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import { GetTrackedTransactionDocuments } from "../../../../Services";
import { TableActions } from "../../../../Enums";
import UnitDocumentsFileUploader from "./UnitDocumentsFileUploader";
import ContactDocumentsFileUploader from "./ContactDocumentsFileUploader";
import moment from "moment";
import {
  getDownloadableLink,
  returnPropsByPermissions,
} from "../../../../Helper";
import { AMLViewPermissions } from "../../../../Permissions";

function UnitAndContactDocumentsDialog({
  isOpen,
  setIsOpenDialog,
  setDocumentsShowDialogIsOpen,
  AMLTransactions,
  translationPath,
  parentTranslationPath,
  IDS,
  activeItem,
  setActiveItem,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [isUnitDialogUploaderOpen, setIsUnitDialognitUploaderOpen] =
    useState(false);
  const [isContactDialogUploaderOpen, setIsContactDialogUploaderOpen] =
    useState(false);
  const [documents, setDocuments] = useReducer(reducer, {
    contactDocuments: [],
    unitDocuments: [],
  });

  const GetTrackedTransactionDocumentsAPICall = async () => {
    setIsLoading(true);
    const res = await GetTrackedTransactionDocuments(IDS);

    if (!(res && res.status && res.status !== 200)) {
      setDocuments({
        id: "edit",
        value: {
          contactDocuments: res?.contactDocuments,
          unitDocuments: res?.unitDocuments,
        },
      });
    } else {
      setDocuments({
        id: "edit",
        value: {
          contactDocuments: [],
          unitDocuments: [],
        },
      });
    }
    setIsLoading(false);
  };

  const getTableActionsWithPermissions = () => {
    const actionsList = [];
    if (
      returnPropsByPermissions(
        AMLViewPermissions?.DownloadDocument?.permissionsId
      )
    )
      actionsList.push({
        enum: TableActions.downloadText.key,
      });
    return actionsList;
  };

  const onCloseClicked = () => {
    setDocumentsShowDialogIsOpen(false);
  };

  const onSaveClicked = async () => {
    setDocumentsShowDialogIsOpen(false);
  };

  const uploadUnitDocumentsDialogOpenHandler = () => {
    setIsUnitDialognitUploaderOpen(true);
  };

  const uploadUnitDocumentsDialogCloseHandler = () => {
    setIsUnitDialognitUploaderOpen(false);
  };

  const uploadContactDocumentsDialogOpenHandler = () => {
    setIsContactDialogUploaderOpen(true);
  };

  const uploadontactDocumentsDialogCloseHandler = () => {
    setIsContactDialogUploaderOpen(false);
  };

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions?.downloadText?.key) {
      try {
        const e = getDownloadableLink(item.fileId);
        window.open(e);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  }, []);

  useEffect(() => {
    GetTrackedTransactionDocumentsAPICall();
  }, [isUnitDialogUploaderOpen, isContactDialogUploaderOpen]);

  return (
    <>
      <DialogComponent
        titleText={t("documents")}
        saveText={t("confirm")}
        saveType="button"
        maxWidth="md"
        onSaveClicked={() => {
          onSaveClicked();
        }}
        onCloseClicked={() => {
          onCloseClicked();
        }}
        onCancelClicked={() => {
          onCloseClicked();
        }}
        isOpen={isOpen}
        dialogContent={
          <>
            <div className="mt-2">
              <div className="w-50">
                <div className="d-flex-column mt-2">
                  <h4 className="mb-2">
                    <b>{t("contact")}</b>
                  </h4>
                  <PermissionsComponent
                    permissionsList={Object.values(AMLViewPermissions)}
                    permissionsId={
                      AMLViewPermissions?.UploadDocument?.permissionsId
                    }
                  >
                    <ButtonBase
                      id="uploadContactDocumentsRef"
                      className="MuiButtonBase-root btns theme-solid w-50 mb-2"
                      onClick={() => uploadContactDocumentsDialogOpenHandler()}
                    >
                      <span>+ {t("upload-documents")}</span>
                    </ButtonBase>
                  </PermissionsComponent>
                </div>
              </div>

              <div>
                <Tables
                  data={documents?.contactDocuments || []}
                  headerData={[
                    {
                      id: 1,
                      label: "category",
                      component: (item) => {
                        return (
                          <>
                            <div className="flex-column">
                              <h6>
                                <b>{item?.categoryName}</b>
                              </h6>
                              <p>{item?.fileName}</p>
                            </div>
                          </>
                        );
                      },
                    },
                    {
                      id: 2,
                      label: "create-date",
                      component: (item) => {
                        return (
                          <>
                            <div className="flex-column">
                              <h6>
                                <b>
                                  {(item.createdOn &&
                                    moment(item.createdOn).format(
                                      "DD/MM/YYYY - hh:mm A"
                                    )) ||
                                    "N/A"}
                                </b>
                              </h6>
                              <p>{item?.createdBy}</p>
                            </div>
                          </>
                        );
                      },
                    },
                  ]}
                  defaultActions={getTableActionsWithPermissions()}
                  actionsOptions={{
                    onActionClicked: tableActionClicked,
                  }}
                  activePage={0}
                  itemsPerPage={150}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </div>

            <div className="mt-2">
              <div className="w-50">
                <div className="d-flex-column mt-2">
                  <h4 className="mb-2">
                    <b>{t("unit")}</b>
                  </h4>
                  <PermissionsComponent
                    permissionsList={Object.values(AMLViewPermissions)}
                    permissionsId={
                      AMLViewPermissions?.UploadDocument?.permissionsId
                    }
                  >
                    <ButtonBase
                      id="uploadUnitDocumentsRef"
                      className="MuiButtonBase-root btns theme-solid  w-50 mb-2"
                      onClick={() => uploadUnitDocumentsDialogOpenHandler()}
                    >
                      <span>+ {t("upload-documents")}</span>
                    </ButtonBase>
                  </PermissionsComponent>
                </div>
              </div>

              <div>
                <Tables
                  data={documents.unitDocuments || []}
                  headerData={[
                    {
                      id: 1,
                      label: "category",
                      component: (item) => {
                        return (
                          <>
                            <div className="flex-column">
                              <h6>
                                <b>{item?.categoryName}</b>
                              </h6>
                              <p>{item?.fileName}</p>
                            </div>
                          </>
                        );
                      },
                    },
                    {
                      id: 2,
                      label: "create-date",
                      component: (item) => {
                        return (
                          <>
                            <div className="flex-column">
                              <h6>
                                <b>
                                  {(item.createdOn &&
                                    moment(item.createdOn).format(
                                      "DD/MM/YYYY - hh:mm A"
                                    )) ||
                                    "N/A"}
                                </b>
                              </h6>
                              <p>{item?.createdBy}</p>
                            </div>
                          </>
                        );
                      },
                    },
                  ]}
                  defaultActions={getTableActionsWithPermissions()}
                  actionsOptions={{
                    onActionClicked: tableActionClicked,
                  }}
                  activePage={0}
                  itemsPerPage={150}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </div>

            {isUnitDialogUploaderOpen && (
              <UnitDocumentsFileUploader
                AMLTransactions={AMLTransactions}
                unitId={activeItem?.unitId}
                isOpen={isUnitDialogUploaderOpen}
                onCloseClicked={uploadUnitDocumentsDialogCloseHandler}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                IDS={IDS}
              />
            )}
            {isContactDialogUploaderOpen && (
              <ContactDocumentsFileUploader
                AMLTransactions={AMLTransactions}
                contactId={AMLTransactions?.contactId}
                isOpen={isContactDialogUploaderOpen}
                onCloseClicked={uploadontactDocumentsDialogCloseHandler}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                IDS={IDS}
              />
            )}
          </>
        }
        setIsOpenDialog={setIsOpenDialog}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
    </>
  );
}

export default UnitAndContactDocumentsDialog;

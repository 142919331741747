import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ButtonBase } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import {
  ConvertJsonV2Component,
  Spinner,
  TabsComponent,
  PermissionsComponent,
} from '../../../../../../Components';
import {
  unitDetailsPut,
  unitDetailsGet,
  GetAllFormFieldTabsByFormId,
  GetAllUnitLeadOwners,
} from '../../../../../../Services';
import {
  bottomBoxComponentUpdate,
  FormErrorsHandler,
  formItemsBuilder,
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
} from '../../../../../../Helper';
import { LookupsRules } from '../../../../../../Rule';
import { UnitsOperationTypeEnum } from '../../../../../../Enums';

import { UnitsSalesPermissions } from '../../../../../../Permissions';
import { SellerInfo } from '../../Common/SellerInfo/SellerInfo';
import { PropertyInfo } from './PropertyInfo/PropertyInfo';
import { FieldsNeedingApprovalDialog } from './FieldsNeedingApprovalDialog';

export const UnitInformationComponent = ({
  viewType,
  parentTranslationPath,
  translationPath,
  save,
  isDetailsDialog,
  isActiveToSave,
  isOpen,
  isOpenChanged,
  onSave,
  isOpenInsideForm,
  sensitiveFieldsForUser,
  unitWithPolicy,
  isLoadingUnitData,
  isLoadingDetailsDialog,
  unitActiveTab,
  isSensitiveLoading,
  unitEmptyFields,
  reloadData,
  unitOpertaionTypeValue,
  initialData,
  pendingUserFields,
  setIsSensitiveLoading,
  fieldsNeedsApproval,
}) => {
  const { t } = useTranslation(parentTranslationPath, 'Shared');
  const isForLease = false;
  const location = useLocation();
  const [pendingFieldsUpdated, setPendingFieldsUpdated] = useState(false);
  const [activeItem, setActiveItem] = useState({
    id: null,
    userTypeId: null,
  });

  const [reload, setReload] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [hiddenTabByOperationType, setHiddenTabByOperationType] =
    useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [formData, setFormData] = useState([]);
  const [newErrorsList, setNewErrorsList] = useState([]);
  const [enumsInitFinished, setEnumsInitFinished] = useState(false);
  const [errors, setErrors] = useState([]);
  const [unitInitDetails, setUnitInitDetails] = useState({});
  const [unitDetails, setUnitDetails] = useState({});

  const [isNeedApprovalDialogOpen, setIsNeedApprovalDialogOpen] =
    useState(false);
  const [changedValues, setChangedValues] = useState({});
  const [originalVales, setOriginalVales] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [formAndTabs, setFormAndTabs] = useState([]);
  const [leadOwner, setLeadOwner] = useState(null);
  const [unitPropertyId, setUnitPropertyId] = useState(null);
  const [leaseLeadOwner, setLeaseLeadOwner] = useState(null);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [unitLeadReferredById] = useState(
    loginResponse && loginResponse.userId
  );

  const onTabChanged = (e, newTap) => {
    setEnumsInitFinished(false);
    setActiveTab(newTap);
  };

  const getAllFormFieldTabsByFormId = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllFormFieldTabsByFormId({ formId: 14 });
    if (!(result && result.status && result.status !== 200))
      setFormAndTabs(result[0] || []);
    else setFormAndTabs([]);
    setIsLoading(false);
  }, []);

  const getUnitDetails = useCallback(async () => {
    // setIsLoading(true);
    // const unitDetailsRes = await unitDetailsGet({ id: activeItem.id });
    // if (!(unitDetailsRes && unitDetailsRes.status && unitDetailsRes.status !== 200)) {
    //   if (unitDetailsRes && unitDetailsRes.unit)
    //     setUnitInitDetails(JSON.parse(JSON.stringify(unitDetailsRes)));
    //   setUnitDetails(unitDetailsRes);
    // } else {
    //   setUnitInitDetails({});
    //   setUnitDetails({});
    // }
    // setIsLoading(false);
  }, [activeItem]);

  const getUnitLeadOwner = useCallback(async (id) => {
    const res = await GetAllUnitLeadOwners(id);
    if (!(res && res.status && res.status !== 200)) {
      setLeadOwner(res.leadOwner);
      setLeaseLeadOwner(res.leaseLeadOwner);
    } else {
      setLeadOwner(null);
      setLeaseLeadOwner(null);
    }
  }, []);

  const dataHandler = useCallback(() => {
    if ((formData.length === 0 && isSensitiveLoading) || pendingFieldsUpdated)
      setFormData(
        formItemsBuilder(
          unitDetails.unit,
          formAndTabs,
          sensitiveFieldsForUser,
          undefined,
          undefined,
          undefined,
          pendingUserFields
        )
      );
  }, [
    unitDetails.unit,
    formAndTabs,
    sensitiveFieldsForUser,
    isSensitiveLoading,
    pendingUserFields,
  ]);

  const cancelHandler = () => {
    GlobalHistory.goBack();
    // GlobalHistory.push(`/home/units-sales/view`)
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const saveHandler = async () => {
    setIsSubmitted(true);
    if (onSave) onSave(false);
    if (errors && errors.length > 0) {
      const firstErrorTapIndex = newErrorsList.findIndex(
        (item) =>
          item.findIndex((element) => element.field.id === errors[0].key) !== -1
      );

      if (firstErrorTapIndex !== -1) {
        setActiveTab(
          (hiddenTabByOperationType !== null &&
            firstErrorTapIndex >= hiddenTabByOperationType[0] &&
            activeTab >= hiddenTabByOperationType[1] &&
            firstErrorTapIndex + 1) ||
            firstErrorTapIndex
        );
      }
      showError(errors[0].message);
      return;
    }

    const totalItems = formData.reduce(
      (total, items) => total + items.length,
      0
    );
    const totalValues = Object.values(unitDetails.unit).filter(
      (item) =>
        item !== null &&
        item !== '' &&
        item !== undefined &&
        item !== ' ' &&
        !(Object.keys(item).length === 0 && item.constructor === Object)
    ).length;
    const totalValues2 = totalValues - 2;
    const total = (totalValues2 / totalItems) * 100;
    const dataCompleted = Math.round(total);
    unitDetails.unit.data_completed = dataCompleted > 100 ? 100 : dataCompleted;
    const newOwners = [];
    if (
      unitDetails &&
      unitDetails.unit &&
      (!unitDetails.unit.owner || !unitDetails.unit.owner.length) &&
      (unitDetails.unit.lease_lead_owner || unitDetails.unit.lead_owner)
    )
      newOwners.push(
        (unitDetails && unitDetails.unit.lead_owner) ||
          unitDetails.unit.lease_lead_owner
      );
    const unitUpdated = { ...unitDetails.unit, owner: newOwners };
    const unitUpdateWithNewOwners = { ...unitDetails, unit: unitUpdated };

    if (fieldsNeedsApproval && fieldsNeedsApproval.length > 0) {
      const newChangedValues = {};
      const originalUnitFields = {};
      Object.entries(initialData).forEach(([key, value]) => {
        if (!isEqual(value, unitDetails.unit[key])) {
          if (key === 'builtup_area_sqft' || key === 'builtup_area_sqm') {
            newChangedValues.builtup_area_sqft =
              unitDetails.unit.builtup_area_sqft;
            newChangedValues.builtup_area_sqm =
              unitDetails.unit.builtup_area_sqm;
            originalUnitFields.builtup_area_sqft =
              initialData.builtup_area_sqft;
            originalUnitFields.builtup_area_sqm = initialData.builtup_area_sqm;
          } else if (
            (key === 'unit_type' || key === 'sale_type') &&
            (initialData[key] === null || initialData[key] === undefined)
          ) {
            originalUnitFields[key] = { lookupItemName: null };
            newChangedValues[key] = unitDetails.unit[key];
          } else {
            newChangedValues[key] = unitDetails.unit[key];
            originalUnitFields[key] = value || null;
          }
        }
      });
      if (Object.keys(newChangedValues).length > 0) {
        setIsNeedApprovalDialogOpen(true);
        setOriginalVales(originalUnitFields);
        setChangedValues(newChangedValues);
      }
    }

    const updatedUnitLeadOwnerId =
      unitDetails &&
      unitDetails.unit &&
      unitDetails.unit.lead_owner &&
      unitDetails.unit.lead_owner.id;
    const updatedUnitLeaseLeadOwnerId =
      unitDetails &&
      unitDetails.unit &&
      unitDetails.unit.lease_lead_owner &&
      unitDetails.unit.lease_lead_owner.id;
    const unitLeadOwnerId = leadOwner && leadOwner.contactId;
    const unitLeaseLeadOwnerId = leaseLeadOwner && leaseLeadOwner.contactId;

    const referredById =
      !(
        updatedUnitLeadOwnerId === unitLeadOwnerId || !updatedUnitLeadOwnerId
      ) ||
      !(
        updatedUnitLeaseLeadOwnerId === unitLeaseLeadOwnerId ||
        !updatedUnitLeaseLeadOwnerId
      )
        ? unitLeadReferredById
        : null;

    setIsLoading(true);
    unitDetails.unit = { ...unitDetails.unit, ...initialData };
    fieldsNeedsApproval.forEach((filed) => {
      if (initialData[filed] === undefined) {
        unitDetails.unit[filed] = undefined;
        unitUpdateWithNewOwners.unit[filed] = undefined;
      }
    });
    const putResponse = await unitDetailsPut({
      id: activeItem.id,
      body: {
        unitJson: newOwners.length ? unitUpdateWithNewOwners : unitDetails,
        rowVersion: unitDetails.rowVersion,
      },
      referredById,
    });
    if (!(putResponse && putResponse.status && putResponse.status !== 200)) {
      showSuccess(t(`${translationPath}unit-updated-successfully`));
      setReload(true);
      if (reloadData) reloadData();
      setIsLoading(false);
      if (isOpenChanged) {
        isOpenChanged();
      }
      // cancelHandler();
    } else {
      setIsLoading(false);
      let errorMsg =
        putResponse &&
        putResponse.data &&
        putResponse.data.Message &&
        putResponse.data.Message.split(':') &&
        putResponse.data.Message.split(':').pop();
      showError(
        `${t(
          `${translationPath}${errorMsg.substring(
            0,
            errorMsg.lastIndexOf(' ')
          )}`
        )}${errorMsg.substring(errorMsg.lastIndexOf(' '), errorMsg.length)}`
      );
    }
  };
  const onLoadingsChanged = (value, key) => {
    setLoadings((items) => {
      const itemIndex = items.findIndex((item) => item.key === key);
      if (value) {
        const addItem = {
          key,
          value,
        };
        if (itemIndex !== -1) items[itemIndex] = addItem;
        else items.push(addItem);
      } else if (itemIndex !== -1) items.splice(itemIndex, 1);
      return [...items];
    });
  };
  const onItemChanged =
    (item, index) => (newValue, itemIndex, itemKey, parentItemKey) => {
      setFormData((elements) => {
        if (parentItemKey) {
          if (itemIndex !== undefined) {
            elements[
              (hiddenTabByOperationType !== null &&
                activeTab >= hiddenTabByOperationType[0] &&
                activeTab >= hiddenTabByOperationType[1] &&
                activeTab + 1) ||
                activeTab
            ][itemIndex][parentItemKey][itemKey] = newValue;
          } else {
            elements[
              (hiddenTabByOperationType !== null &&
                activeTab >= hiddenTabByOperationType &&
                activeTab + 1) ||
                activeTab
            ][index][parentItemKey][itemKey] = newValue;
          }
        } else if (itemIndex) {
          elements[
            (hiddenTabByOperationType !== null &&
              activeTab >= hiddenTabByOperationType &&
              activeTab + 1) ||
              activeTab
          ][itemIndex][itemKey] = newValue;
        } else {
          elements[
            (hiddenTabByOperationType !== null &&
              activeTab >= hiddenTabByOperationType[0] &&
              activeTab >= hiddenTabByOperationType[1] &&
              activeTab + 1) ||
              activeTab
          ][index][itemKey] = newValue;
        }
        return [...elements];
      });
    };
  const onValueChanged = (item) => (newValue, itemIndex, itemKey) => {
    setIsLoading(true);
    setUnitDetails((items) => {
      if (itemKey) items.unit[itemKey] = newValue;
      else if ((itemIndex || itemIndex === 0) && itemIndex !== -1) {
        items.unit[
          formData[
            (hiddenTabByOperationType !== null &&
              activeTab >= hiddenTabByOperationType[0] &&
              activeTab >= hiddenTabByOperationType[1] &&
              activeTab + 1) ||
              activeTab
          ][itemIndex].field.id
        ] = newValue;
      } else items.unit[item.field.id] = newValue;
      return { ...items };
    });
    setIsLoading(false);
  };

  const lookupInit = useCallback(() => {
    setIsLoading(true);
    const haveHideTab =
      hiddenTabByOperationType &&
      unitDetails &&
      unitDetails.unit &&
      unitDetails.unit.operation_type &&
      unitDetails.unit.operation_type &&
      unitDetails.unit.operation_type &&
      unitDetails.unit.operation_type.lookupItemName === 'Sale' &&
      activeTab === 4
        ? true
        : false;

    const result = LookupsRules(
      !haveHideTab
        ? formData[
            (hiddenTabByOperationType !== null &&
              activeTab >= hiddenTabByOperationType[0] &&
              activeTab >= hiddenTabByOperationType[1] &&
              activeTab + 1) ||
              activeTab
          ]
        : formData[5],
      unitDetails.unit,
      onLoadingsChanged,
      sensitiveFieldsForUser
    );
    setFormData((items) => {
      items.splice(
        !haveHideTab
          ? (hiddenTabByOperationType !== null &&
              activeTab >= hiddenTabByOperationType[0] &&
              activeTab >= hiddenTabByOperationType[1] &&
              activeTab + 1) ||
              activeTab
          : hiddenTabByOperationType[0] + 1,
        1,
        result
      );

      return [...items];
    });
    setIsLoading(false);
  }, [
    formData,
    hiddenTabByOperationType,
    activeTab,
    unitDetails.unit,
    errors,
    formAndTabs,
    sensitiveFieldsForUser,
  ]);

  useEffect(() => {
    if (formData.length > 0 && unitDetails.unit) {
      const newErrorsListUnitForm = hiddenTabByOperationType
        ? formData.filter(function (item, index) {
            return !hiddenTabByOperationType.includes(index);
          })
        : [...formData];
      setNewErrorsList(newErrorsListUnitForm);
      setErrors(FormErrorsHandler(newErrorsListUnitForm, unitDetails.unit));
    }
  }, [unitDetails, formData, hiddenTabByOperationType, pendingUserFields]);

  useEffect(() => {
    if (!enumsInitFinished && formData.length > 0) {
      setEnumsInitFinished(true);
      lookupInit();
    }
  }, [
    activeTab,
    enumsInitFinished,
    formData.length,
    lookupInit,
    sensitiveFieldsForUser,
  ]);
  useEffect(() => {
    if (unitDetails.unit) dataHandler();
  }, [
    unitDetails.unit,
    dataHandler,
    sensitiveFieldsForUser,
    isSensitiveLoading,
    pendingUserFields,
  ]);

  useEffect(() => {
    if (formAndTabs.length > 0) getUnitDetails();
  }, [formAndTabs, getUnitDetails]);
  useEffect(() => {
    if (activeItem) getAllFormFieldTabsByFormId();
  }, [activeItem, getAllFormFieldTabsByFormId]);
  useEffect(() => {
    if (isDetailsDialog) {
      const obj = JSON.parse(localStorage.getItem('current'));
      setActiveItem({
        id: obj.id,
        userTypeId: obj.type,
      });
    } else {
      setActiveItem({
        id: GetParams('id'),
        userTypeId: GetParams('formType'),
      });
    }
    getUnitLeadOwner(GetParams('id'));
  }, [location]);

  useEffect(() => {
    if (save && isActiveToSave) saveHandler();
  }, [save]);

  useEffect(() => {
    if (unitWithPolicy && unitWithPolicy.unit) {
      setIsLoading(true);
      setUnitInitDetails(JSON.parse(JSON.stringify(unitWithPolicy)));
      setUnitDetails(unitWithPolicy);
      setUnitPropertyId(unitWithPolicy.unit?.property_name?.id);
      setIsLoading(false);
    } else {
      setUnitInitDetails({});
      setUnitDetails({});
    }
  }, [unitWithPolicy]);

  useEffect(() => {
    if (!isOpen)
      bottomBoxComponentUpdate(
        <div className='d-flex-v-center-h-end flex-wrap w-75'>
          <ButtonBase
            className='btns theme-transparent mb-2'
            onClick={cancelHandler}
          >
            <span>{t('Shared:cancel')}</span>
          </ButtonBase>
          <PermissionsComponent
            permissionsList={Object.values(UnitsSalesPermissions)}
            permissionsId={UnitsSalesPermissions.EditUnitDetails.permissionsId}
          >
            <ButtonBase className='btns theme-solid mb-2' onClick={saveHandler}>
              <span>{t('Shared:save ')}</span>
            </ButtonBase>
          </PermissionsComponent>
        </div>
      );
  }, [saveHandler, t]);
  useEffect(() => {
    if (
      unitDetails.unit &&
      unitDetails.unit.operation_type &&
      unitDetails.unit.operation_type.lookupItemId ===
        UnitsOperationTypeEnum.sale.key
    )
      setHiddenTabByOperationType([4, 6]);
    else if (
      unitDetails.unit &&
      unitDetails.unit.operation_type &&
      unitDetails.unit.operation_type.lookupItemId ===
        UnitsOperationTypeEnum.rent.key
    )
      setHiddenTabByOperationType([3, 5]);
    else setHiddenTabByOperationType(null);
  }, [unitDetails]);

  // useEffect(
  //   () => () => {
  //     bottomBoxComponentUpdate(null);
  //   },
  //   []
  // );
  useEffect(() => {
    if (
      unitDetails.unit &&
      unitDetails.unit.operation_type &&
      unitDetails.unit.operation_type.lookupItemId ===
        UnitsOperationTypeEnum.sale.key
    )
      setHiddenTabByOperationType([4, 6]);
    else if (
      unitDetails.unit &&
      unitDetails.unit.operation_type &&
      unitDetails.unit.operation_type.lookupItemId ===
        UnitsOperationTypeEnum.rent.key
    )
      setHiddenTabByOperationType([3, 5]);
    else setHiddenTabByOperationType(null);
  }, [unitDetails]);

  return (
    <div className='units-information-wrapper childs-wrapper b-0'>
      <Spinner isActive={isLoading || isLoadingUnitData} isAbsolute />
      <TabsComponent
        data={formAndTabs}
        labelInput='tab'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-curved'
        currentTab={activeTab}
        hiddenTabIndexes={
          (hiddenTabByOperationType !== null && hiddenTabByOperationType) || []
        }
        onTabChanged={onTabChanged}
      />
      <div className='tabs-content-wrapper'>
        {formData &&
          formData[activeTab] &&
          formData[
            (hiddenTabByOperationType !== null &&
              (activeTab >= hiddenTabByOperationType[0] ||
                activeTab >= hiddenTabByOperationType[1]) &&
              activeTab + 1) ||
              activeTab
          ]
            .filter(
              (item) =>
                viewType === 1 ||
                (viewType === 2 &&
                  ((unitEmptyFields &&
                    unitEmptyFields.unit &&
                    unitEmptyFields.unit[item.field.id] === null) ||
                    (unitEmptyFields &&
                      unitEmptyFields.unit &&
                      unitEmptyFields.unit[item.field.id] === undefined) ||
                    (unitEmptyFields &&
                      unitEmptyFields.unit &&
                      unitInitDetails.unit[item.field.id] === '')))
            )
            .map((item, index) => (
              <ConvertJsonV2Component
                key={`form${index + 1}-${activeTab}`}
                item={item}
                allItems={
                  formData[
                    (hiddenTabByOperationType !== null &&
                      (activeTab >= hiddenTabByOperationType[0] ||
                        activeTab >= hiddenTabByOperationType[1]) &&
                      activeTab + 1) ||
                      activeTab
                  ]
                }
                allItemsValues={unitDetails.unit}
                itemValue={
                  unitDetails.unit[item.field.id] === 0
                    ? '0'
                    : unitDetails.unit[item.field.id]
                }
                isSubmitted={isSubmitted}
                onItemChanged={onItemChanged(item, index)}
                onValueChanged={onValueChanged(item, index)}
                helperText={
                  (errors.find((element) => element.key === item.field.id) &&
                    errors.find((element) => element.key === item.field.id)
                      .message) ||
                  ''
                }
                error={
                  errors.findIndex(
                    (element) => element.key === item.field.id
                  ) !== -1
                }
                isLoading={
                  loadings.findIndex(
                    (element) => element.key === item.field.id && element.value
                  ) !== -1
                }
                onLoadingsChanged={onLoadingsChanged}
                setIsLoading={setIsLoading}
                reload={reload}
                save={save}
                isOpenInsideForm={isOpenInsideForm}
                sensitiveFieldsForUser={sensitiveFieldsForUser}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                itemType={{ type: 'unit', name: 'saleUnit' }}
                unitOpertaionTypeValue={unitOpertaionTypeValue}
              />
            ))}
      </div>
      <div className='information-container'>
        {leadOwner && (
          <SellerInfo
            leadOwnerData={leadOwner}
            unitDetails={unitInitDetails}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
        {unitPropertyId && (
          <PropertyInfo
            propertyId={unitPropertyId}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
      </div>
      {isNeedApprovalDialogOpen && (
        <FieldsNeedingApprovalDialog
          isDialogOpen={isNeedApprovalDialogOpen}
          setIsDialogOpen={setIsNeedApprovalDialogOpen}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          loginResponse={loginResponse}
          unitDetails={unitDetails}
          activeItem={activeItem}
          changedValues={changedValues}
          reloadData={reloadData}
          isForLease={isForLease}
          originalVales={originalVales}
          setPendingFieldsUpdated={setPendingFieldsUpdated}
        />
      )}
    </div>
  );
};

UnitInformationComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  viewType: PropTypes.number.isRequired,
  save: PropTypes.bool,
  isDetailsDialog: PropTypes.bool,
  isActiveToSave: PropTypes.bool,
  isOpen: PropTypes.bool,
  isOpenChanged: PropTypes.func,
  onSave: PropTypes.func,
};

UnitInformationComponent.defaultProps = {
  save: false,
  isDetailsDialog: false,
  isActiveToSave: false,
  isOpen: false,
  isOpenChanged: undefined,
  onSave: undefined,
};

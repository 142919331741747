import { ButtonBase } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  TabsComponent,
  RadiosGroupComponent,
  Spinner,
  CompletedDataComponent,
  SelectComponet,
  CollapseComponent,
} from '../../../../Components';
import {
  ActionsEnum,
  UnitsStatusEnum,
  FormsIdsEnum,
  UnitsOperationTypeEnum,
} from '../../../../Enums';
import {
  bottomBoxComponentUpdate,
  getIsAllowedPermission,
  GetParams,
  GlobalHistory,
  sideMenuComponentUpdate,
} from '../../../../Helper';
import {
  GetAllTemplatesByUnitId,
  unitDetailsGet,
  UnitsAdvanceSearchTest,
  GetSensitiveFieldsForUser,
  GetSuitableTemplateIdForUnit,
  GetFieldsNeedingApprovalForChange,
  GetPendingUserFields,
} from '../../../../Services';
import { UnitsVerticalTabsData } from '../../Common/OpenFileView/OpenFileUtilities/OpenFileData';
import { ContactsActionDialogsComponent } from '../../ContactsView/ContactsUtilities/ContactsActionDialogsComponent/ContactsActionDialogsComponent';
import { UnitMapper } from '../UnitSalesMapper';
import { CardDetailsComponent } from '../UnitsSalesUtilities';
import { PermissionDeniedLayout } from '../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout';
import { PermissionsComponent } from '../../../../Components/PermissionsComponent/PermissionsComponent';
import { UnitsSalesPermissions } from '../../../../Permissions/Sales/UnitsSalesPermissions';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { UnitTemplateDialogPreviewDialog } from '../../UnitsView/UnitsProfileManagementView/Dialogs/UnitTemplateDialog/UnitTemplateDialogPreviewDialog';

const parentTranslationPath = 'UnitsProfileManagementView';
const translationPath = '';

export const UnitsSalesProfileManagementView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [
    displayDetailsToUserDependsOnPolicy,
    setDisplayDetailsToUserDependsOnPolicy,
  ] = useState(null);

  const location = useLocation();
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  
  const [isLoading, setIsLoading] = useState(false);
  const [unitData, setUnitData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [viewType, setViewType] = useState(1);
  const [operationType, setOperationType] = useState(0);
  const [unitEmptyFields, setUnitEmptyFields] = useState(null);
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState([]);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [activeTemplateId, setActiveTemplateId] = useState(-1);
  const [activeTemplateName, setActiveTemplateName] = useState(null);
  const [isOpenTemplateDialog, setIsOpenTemplateDialog] = useState(false);
  const [isOpenContactsActionDialog, setIsOpenContactsActionDialog] =
    useState(false);
  const [templateFile, setTemplateFile] = useState(null);
  const [dialogEnum, setDialogEnum] = useState(null);
  const [isSensitiveLoading, setIsSensitiveLoading] = useState(0);
  const [SensitiveLoading, setSensitiveLoading] = useState(false);
  const [unitWithPolicy, setUnitWithPolicy] = useState(null);
  const [sensitiveFieldsForUser, setSensitiveFieldsForUser] = useState([]);
  const [templatesProposal, setTemplatesProposal] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [pendingUserFields, setPendingUserFields] = useState(null);
  const [fieldsNeedsApproval, setFieldsNeedsApproval] = useState([]);
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 9999,
  });

  const [filterBy, setFilterBy] = useState({
    formType: GetParams('formType'),
    id: +GetParams('id'),
  });
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  useEffect(() => {
    setActiveTab(GetParams('matching') === 'true' ? 14 : 0);
  }, []);

  const userHasPolicyToDisplayData = useCallback(async (unitId) => {
    setIsLoading(true);
    const isForLog = true;

    const body = {
      OperationType: 'Sale',
      criteria: {
        unitId: [{ searchType: 1, value: unitId }],
      },
      filterBy: 'createdOn',
      orderBy: 2,
    };

    const res = await UnitsAdvanceSearchTest(
      { pageIndex: 0, pageSize: 2, isForLog },
      body
    );
    if (!(res && res.status && res.status !== 200)) {
      if (res && res.totalCount > 0) {
        setDisplayDetailsToUserDependsOnPolicy('allowed');
        if (
          res &&
          res.result &&
          res.result.length &&
          res.result[0] &&
          res.result[0].unitJson
        ) {
          const unitJson = JSON.parse(
            res && res.result && res.result.length && res.result[0].unitJson
          );
          setOperationType(
            unitJson.unit &&
              unitJson.unit.operation_type &&
              unitJson.unit.operation_type.lookupItemId
          );
          const unitDatails = UnitMapper({ ...unitJson, ...res.result[0] });
          localStorage.setItem(
            'unitModelRelatedData',
            JSON.stringify(unitDatails)
          );
          setUnitEmptyFields(
            JSON.parse(
              res && res.result && res.result.length && res.result[0].unitJson
            )
          );
          dispatch(ActiveItemActions.activeItemRequest(unitDatails));
          setUnitData(unitDatails);
          setUnitWithPolicy(unitJson);
        }
      } else {
        setUnitWithPolicy(null);
        setUnitData(null);
        setUnitEmptyFields(null);
        setDisplayDetailsToUserDependsOnPolicy('notAllowed');
      }
    } else {
      setUnitWithPolicy(null);
      setUnitData(null);
      setUnitEmptyFields(null);
      setDisplayDetailsToUserDependsOnPolicy('notAllowed');
    }

    setIsLoading(false);
  });
  const GetSensitiveFieldsForUserAPI = useCallback(async () => {
    setSensitiveLoading(true);
    const unitId = +GetParams('id');
    const allSensitiveFields = await GetSensitiveFieldsForUser(
      FormsIdsEnum.units.id,
      { key: 'unit', id: activeItem && activeItem.id }
    );

    if (
      !(
        allSensitiveFields &&
        allSensitiveFields.status &&
        allSensitiveFields.status !== 200
      )
    ) {
      setSensitiveFieldsForUser(allSensitiveFields);
      setIsSensitiveLoading(1);
    } else {
      setSensitiveFieldsForUser([]);
      setIsSensitiveLoading(2);
    }

    setSensitiveLoading(false);
  }, []);

  useEffect(() => {
    const unitId = +GetParams('id');
    if (unitId && activeTab === 0) userHasPolicyToDisplayData(unitId);
  }, [activeTab]);

  useEffect(() => {
    const unitId = +GetParams('id');
    if (
      unitId &&
      displayDetailsToUserDependsOnPolicy === 'allowed' &&
      activeTab === 0
    )
      GetSensitiveFieldsForUserAPI();
  }, [activeTab, displayDetailsToUserDependsOnPolicy]);

  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };
  useEffect(() => {
    setFilterBy({
      formType: GetParams('formType'),
      id: (GetParams('id') && +GetParams('id')) || null,
    });
  }, [location]);

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      event.stopPropagation();
      if (actionEnum === ActionsEnum.reportEdit.key) {
        GlobalHistory.push(
          `/home/units-sales/edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
        );
      } else if (actionEnum === ActionsEnum.folder.key) {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
        );
      }
    },
    []
  );
  const getUnitById = useCallback(
    async (isForLog) => {
      // setIsLoading(true);
      // const res = await unitDetailsGet({ id: +filterBy.id, isForLog });
      // if (!(res && res.status && res.status !== 200)) {
      //   const unitDatails = UnitMapper(res);
      //   localStorage.setItem('unitModelRelatedData', JSON.stringify(unitDatails));
      //   dispatch(ActiveItemActions.activeItemRequest(unitDatails));
      //   setUnitData(unitDatails);
      // } else setUnitData(null);
      // setIsLoading(false);
    },
    [dispatch, displayDetailsToUserDependsOnPolicy]
  );
  const getAllTemplatesByCategoriesId = useCallback(async () => {
    const res = await GetAllTemplatesByUnitId(
      filterBy.id,
      UnitsOperationTypeEnum.sale.key
    );
    if (!((res && res.data && res.data.ErrorId) || !res))
      setTemplates(res || []);
    else setTemplates([]);
  }, [activeItem, filter]);

  const reloadData = () => {
    userHasPolicyToDisplayData(+GetParams('id'));
    GetSensitiveFieldsForUserAPI();
    getPendingUserFields();
  };

  useEffect(() => {
    if (unitData !== null) {
      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={unitData}
          from={2}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          parentTranslationPath='UnitsView'
          translationPath={translationPath}
          reloadData={() => getUnitById()}
        />
      );
      // sideMenuIsOpenUpdate(true);
    } else sideMenuComponentUpdate(null);
  }, [unitData, detailedCardSideActionClicked]);
  useEffect(() => {
    if (
      activeItem &&
      activeItem.unitStatusDetails &&
      activeItem.unitStatusDetails.key &&
      Object.values(UnitsStatusEnum).filter(
        (item) =>
          item.key === activeItem.unitStatusDetails.key &&
          (item.category || item.saleCategory)
      ).length > 0
    )
      getAllTemplatesByCategoriesId();
  }, [activeItem, getAllTemplatesByCategoriesId]);

  useEffect(() => {
    if (filterBy.id && displayDetailsToUserDependsOnPolicy === 'allowed') {
    }
    // getUnitById(true);
  }, [filterBy.id, displayDetailsToUserDependsOnPolicy]);

  const GetSuitableTemplateIdForUnitApi = useCallback(async () => {
    const res = await GetSuitableTemplateIdForUnit(
      filterBy.id,
      1,
      10000,
      UnitsOperationTypeEnum.sale.key
    );
    if (!((res && res.data && res.data.ErrorId) || !res))
      setTemplatesProposal((res && res.result) || []);
    else setTemplatesProposal([]);
  }, [filterBy.id]);

  useEffect(() => {
    GetSuitableTemplateIdForUnitApi();
  }, [GetSuitableTemplateIdForUnitApi]);

  const getFieldsNeedingApproval = async () => {
    const userRoleIds = loginResponse?.roles.map((item) => item?.rolesId);

    const res = await GetFieldsNeedingApprovalForChange({ userRoleIds });
    if (!(res && res.status && res.status !== 200)) {
      setFieldsNeedsApproval(res);
    } else setFieldsNeedsApproval([]);

  };

  const getPendingUserFields = async () => {
    const unitId = activeItem?.id;
    const res = await GetPendingUserFields(unitId);
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setPendingUserFields(res.pendingFields)
      return res;
    }
  };

  useEffect(() => {
    getFieldsNeedingApproval();
    getPendingUserFields();
  }, []);

  useEffect(() => {
    if (unitWithPolicy && fieldsNeedsApproval) {
      const { unit } = unitWithPolicy;
      const details = {
        unit_number: unit?.unit_number,
        unit_type: unit?.unit_type,
        property_name: unit?.property_name,
        bedrooms: unit?.bedrooms,
        bathrooms: unit?.bathrooms,
        sale_type: unit?.sale_type,
        builtup_area_sqft : unit?.builtup_area_sqft,
        builtup_area_sqm : unit?.builtup_area_sqm
      };

      const fieldsNeedsApprovalWithUnderscore = fieldsNeedsApproval.map(
        (field) => field.replaceAll('-', '_')
      );

      const requiredFields = [
        'unit_number',
        'unit_type',
        'property_name',
        'bedrooms',
        'bathrooms',
        'sale_type',
        'builtup_area_sqm',
        'builtup_area_sqft'
      ];

      const filteredFields = fieldsNeedsApprovalWithUnderscore.filter((field) =>
        requiredFields.includes(field)
      );
      const neededFields = {};
      filteredFields.forEach((field) => {
        neededFields[field] = details[field];
      });
      setInitialData(neededFields);
    }
  }, [unitWithPolicy, fieldsNeedsApproval]);

  // useEffect(()=>{
  //   bottomBoxComponentUpdate(<div>hello</div>)
  // },[])
  return (
    <div className='units-profile-wrapper view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      {displayDetailsToUserDependsOnPolicy === 'allowed' && (
        <div className='d-flex-v-center-h-between flex-wrap'>
          <div className='d-inline-flex-v-center flex-wrap'>
            <div className='d-inline-flex-v-center'>
              <PermissionsComponent
                permissionsList={Object.values(UnitsSalesPermissions)}
                permissionsId={
                  UnitsSalesPermissions.ViewUnitTemplates.permissionsId
                }
              >
                <SelectComponet
                  data={templates}
                  keyLoopBy='systemTemplateId'
                  valueInput='systemTemplateId'
                  textInput='systemTemplateName'
                  value={activeTemplateId}
                  keyValue='actionsbuttons'
                  idRef='contactsActionsRef'
                  onSelectChanged={(value) => {
                    if (
                      !getIsAllowedPermission(
                        Object.values(UnitsSalesPermissions),
                        loginResponse,
                        UnitsSalesPermissions.OpenUnitTemplates.permissionsId
                      )
                    )
                      return;
                    setActiveTemplateId(value);
                    if (value !== -1) {
                      const itemIndex = templates.findIndex(
                        (item) => item.systemTemplateId === value
                      );
                      if (itemIndex !== -1) {
                        setActiveTemplateName(
                          templates[itemIndex].systemTemplateName
                        );
                      }
                      setIsOpenTemplateDialog(true);
                    }
                  }}
                  themeClass='theme-action-buttons'
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  wrapperClasses='bg-secondary c-white mx-2'
                  parentTranslationPath={parentTranslationPath}
                  emptyItem={{
                    value: -1,
                    text: 'template',
                    isHiddenOnOpen: true,
                  }}
                />
                <SelectComponet
                  data={templatesProposal}
                  keyLoopBy='systemTemplateId'
                  valueInput='systemTemplateId'
                  textInput='systemTemplateName'
                  value={activeTemplateId}
                  keyValue='actionsbuttons'
                  idRef='contactsActionsRef'
                  onSelectChanged={(value) => {
                    if (
                      !getIsAllowedPermission(
                        Object.values(UnitsSalesPermissions),
                        loginResponse,
                        UnitsSalesPermissions.OpenUnitTemplates.permissionsId
                      )
                    )
                      return;
                    setActiveTemplateId(value);
                    if (value !== -1) {
                      const itemIndex = templates.findIndex(
                        (item) => item.systemTemplateId === value
                      );
                      if (itemIndex !== -1) {
                        setActiveTemplateName(
                          templates[itemIndex].systemTemplateName
                        );
                      }
                      setIsOpenTemplateDialog(true);
                    }
                  }}
                  themeClass='theme-action-buttons'
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  wrapperClasses='bg-secondary c-white mx-2'
                  parentTranslationPath={parentTranslationPath}
                  emptyItem={{
                    value: -1,
                    text: 'templateProposal',
                    isHiddenOnOpen: true,
                  }}
                />
              </PermissionsComponent>

              <ButtonBase
                disabled={
                  activeItem &&
                  activeItem.unitStatusDetails &&
                  activeItem.unitStatusDetails.value &&
                  (activeItem.unitStatusDetails.value.toLowerCase() ===
                    'draft' ||
                    activeItem.unitStatusDetails.value.toLowerCase() ===
                      'available' || activeItem.unitStatusDetails.value.toLowerCase() === "pendin_reserve_sale" || activeItem.unitStatusDetails.value.toLowerCase() === "pendingsale" )
                }
                onClick={() =>
                  GlobalHistory.push(
                    `/home/units-sales/unit-profile-reservation?id=${activeItem.id}`
                  )
                }
                className={`body-status btns ${
                  (activeItem.unitStatusDetails &&
                    activeItem.unitStatusDetails.classes) ||
                  (activeItem.unitStatus && 'bg-waiting') ||
                  'bg-warning'
                } bg-secondary-light px-4`}
              >
                {(activeItem &&
                  activeItem.unitStatusDetails &&
                  activeItem.unitStatusDetails &&
                  activeItem.unitStatusDetails &&
                  activeItem.unitStatusDetails.name === 'Sale' &&
                  t(`${translationPath}${'Sold'}`)) ||
                  t(
                    `${translationPath}${
                      (activeItem &&
                        activeItem.unitStatusDetails &&
                        activeItem.unitStatusDetails.name) ||
                      ''
                    }`
                  ) ||
                  t(`Shared:${activeItem.unitStatus}`) ||
                  'N/A'}
              </ButtonBase>
              <ButtonBase
                disabled={
                  activeItem?.unitStatusDetails?.value?.toLowerCase() !==
                  'reserved-for-sale'
                }
                onClick={() =>
                  GlobalHistory.push(
                    `/home/units-sales/unit-status-management?id=${activeItem.id}&status=6&from=2&rowVersion=${undefined}&disabled=true&isForDeveloper=true`
                  )
                }
                className={`body-status btns bg-secondary c-white`}
              >
                developer transaction
              </ButtonBase>
            </div>
            {activeTab === 0 && (
              <div className='d-inline-flex'>
                <RadiosGroupComponent
                  idRef='viewDataRef'
                  data={[
                    {
                      key: 1,
                      value: 'all-data',
                    },
                    {
                      key: 2,
                      value: 'missing-data',
                    },
                  ]}
                  value={viewType}
                  labelValue='view'
                  labelInput='value'
                  valueInput='key'
                  themeClass='theme-line'
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  onSelectedRadioChanged={onViewTypeChangedHandler}
                />
              </div>
            )}

            {/* <div className='leadOwner'>
            <span>
              {' '}
              {t(`${translationPath}leadOwner`)}
              {' '}
              {' '}
              :
              {' '}

              <span className='leadName'>
                {(activeItem && activeItem.lead_owner ? `${activeItem.lead_owner.name || ''}    ${activeItem.lead_owner.phone ? `(${activeItem.lead_owner.phone})` : ''}` : 'N/A')}
              </span>

            </span>

          </div> */}
          </div>
          <div className='d-inline-flex'>
            {/* <ButtonBase
            disabled={activeItem && activeItem.matchingLeadsNumber === 0}
            className='btns c-black-light'
            onClick={() => setActiveTab(20)}
          >
            <span className={ActionsEnum.matching.icon} />
            <span>{activeItem && activeItem.matchingLeadsNumber}</span>
            <span className='px-1'>{t(`${translationPath}matching`)}</span>
          </ButtonBase> */}
            <PermissionsComponent
              permissionsList={Object.values(UnitsSalesPermissions)}
              permissionsId={UnitsSalesPermissions.ShareUnit.permissionsId}
            >
              <ButtonBase
                className='btns c-black-light'
                onClick={() => {
                  setShowPopover(true);
                }}
              >
                <span className='mdi mdi-share-outline px-1' />
                <span className='px-1'>{t(`${translationPath}share`)}</span>
                <CollapseComponent
                  onClickOutside={() => setShowPopover(false)}
                  isOpen={showPopover}
                  classes='popover-unit-share'
                  top={30}
                  component={
                    <div className='unit-model-items-wrapper'>
                      <ButtonBase
                        key='sms'
                        className='btns theme-transparent unit-model-item'
                        onClick={() => {
                          setShowPopover(false);
                          setDialogEnum('smsSolid');
                        }}
                      >
                        <span>sms</span>
                      </ButtonBase>
                      <ButtonBase
                        key='email'
                        className='btns theme-transparent unit-model-item'
                        onClick={() => {
                          setShowPopover(false);
                          setDialogEnum('emailSolid');
                        }}
                      >
                        <span>email</span>
                      </ButtonBase>

                      <ButtonBase
                        key='email'
                        className='btns theme-transparent unit-model-item'
                        onClick={() => {
                          setShowPopover(false);
                          setDialogEnum('whatsappSolid');
                        }}
                      >
                        <span>Whatsapp</span>
                      </ButtonBase>
                    </div>
                  }
                />
              </ButtonBase>
            </PermissionsComponent>
            <PermissionsComponent
              permissionsList={Object.values(UnitsSalesPermissions)}
              permissionsId={
                UnitsSalesPermissions.ViewUnitHistory.permissionsId
              }
            >
              <CompletedDataComponent
                typeId='unit'
                operationType='sale'
                completedData={activeItem && activeItem.progress}
                formType='sale'
                activeItem={activeItem}
              />
            </PermissionsComponent>
          </div>
        </div>
      )}
      {displayDetailsToUserDependsOnPolicy === 'allowed' && (
        <TabsComponent
          data={UnitsVerticalTabsData.sale}
          labelInput='label'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses='theme-solid'
          dynamicComponentProps={{
            propertyId:
              unitData && unitData.property_name && unitData.property_name.id,
            activeItem,
            viewType,
            unitId: filterBy.id,
            parentTranslationPath,
            translationPath,
            unitType: 'sale',
            sensitiveFieldsForUser,
            unitWithPolicy,
            isLoadingUnitData: isLoading,
            unitActiveTab: activeTab,
            isSensitiveLoading,
            unitEmptyFields,
            reloadData,
            taskRelatedTo: 'Unit',
            formType: 'unitSale',
            unitOpertaionTypeValue: operationType,
            initialData,
            pendingUserFields,
            fieldsNeedsApproval,
            setIsSensitiveLoading,
            getPendingUserFields,
          }}
          currentTab={activeTab}
          onTabChanged={onTabChanged}
        />
      )}
      <div className='tabs-content-wrapper'>
        {/* {activeTab === 20 && (
          <UnitProfileMatchingComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )} */}
      </div>
      {isOpenTemplateDialog && (
        <UnitTemplateDialogPreviewDialog
          activeItem={activeTemplateId}
          isOpen={isOpenTemplateDialog}
          unitID={+GetParams('id')}
          maintitleText={activeTemplateName}
          unitOwner={unitData?.lead_owner}
          leaseUnitOwner={unitData?.lease_lead_owner}
          isOpenChanged={() => {
            setIsOpenTemplateDialog(false);
            setActiveTemplateId(-1);
          }}
          templateName={activeTemplateName}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenContactsActionDialog && templateFile && (
        <ContactsActionDialogsComponent
          isOpen={isOpenContactsActionDialog}
          unitTemplateFile={templateFile}
          isOpenChanged={() => {
            setIsOpenContactsActionDialog(false);
            setTemplateFile(null);
          }}
          showlink
          actionEnum='emailSolid'
          item={null}
          translationPath=''
          parentTranslationPath='ContactsView'
        />
      )}
      <ContactsActionDialogsComponent
        isOpenChanged={() => setDialogEnum(null)}
        isOpen={dialogEnum !== null}
        translationPath=''
        parentTranslationPath='ContactsView'
        actionEnum={dialogEnum}
        unitItem={activeItem}
        showlink
      />
      {displayDetailsToUserDependsOnPolicy === 'notAllowed' && (
        <PermissionDeniedLayout />
      )}
    </div>
  );
};


import React   , {useState  , useCallback  , useRef} from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import { CheckboxesComponent ,  Inputs } from '../../../../../Components' ; 
import FilterIcon from '../../../../../assets/images/temps/Filter.svg';
import  ArrowDownIcon  from  '../../../../../assets/images/icons/arrow-down.svg' ; 
import { ContactClassificationMenu } from './ContactClassificationMenu'; 



  export const DeveloperContent = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
    setContactClassificationFilter,
    contactClassificationFilter ,
    setFilter , 
    setSearch ,
    search ,
    filter
   }) => { 
    const { t } = useTranslation(parentTranslationPath);
    const [anchorEl, setAnchorEl] = useState(null);
    const [timer, setTimer] = useState(null);
     


    const menuRef = useRef();
    const searchTimer = useRef(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseMenu = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setAnchorEl(null);
      }
    };

    return (
        <div className="px-4 mt-2 w-100">
          <div className='developer-section'>
            <div>
              <span> {t(`${translationPath}developer`)} </span>
            </div>
            <div className='w-100  mt-2 mb-2'>
              <div className='w-100'>
                <div className='developers w-100'>

                  <div className='w-100 d-flex d-flex-v-center-h-between b-bottom developer-rating' style={{ gap : '.5rem' }} >
                  <div className='d-inline-flex-v-center-h-end pl-4-reversed'>
                  <div className='d-inline-flex-center' style={{gap: '.3rem' }} ref={menuRef}>
                    <CheckboxesComponent
                          label={t(`${translationPath}classifications`)}
                          idRef={'classifications'}
                          singleChecked={(filter && filter.contactClassificationId)? true : false}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                           onSelectedCheckboxClicked={(e) => {
                            if(e.target.checked)
                             handleClick(e);
                           else  if(!e.target.checked){
                              setContactClassificationFilter(false);
                              setFilter((item)=> ({...item ,  contactClassificationId:null}));
                              

                            }

                        }}
                      />
                      
                     <img style={{  maxHeight : '14px' ,  width: '16px' }}  className='' alt='Filter' src={FilterIcon} />
                     <img style={{  maxHeight : '14px' ,  width: '16px'}}  className='' alt='ArrowDown' src={ArrowDownIcon} />
                     </div>
                     
                    </div>
                    <div className='d-inline-flex-v-center-h-end w-50'>
                     <Inputs
                      inputPlaceholder={t(`${translationPath}enter-developer-name`)}
                      idRef='contactSearch'
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      value={search}
                      onInputChanged={(e) => {
                        setSearch(e?.target?.value || "");
                      }}
                      onKeyUp={(event) => {
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        const newValue = event.target.value;
                        searchTimer.current = setTimeout(() => {
                          setFilter((f)=> ({...f , search : newValue}));
                        }, 700);
                      }}
                      onKeyDown={() => {
                        if (timer != null) clearTimeout(timer);
                      }}
                     />

                     </div>
                     <ContactClassificationMenu
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        parentTranslationPath={parentTranslationPath}
                        setContactClassificationFilter={setContactClassificationFilter}
                        setFilter={setFilter}
                        setAnchorEl={()=> setAnchorEl(null)}
                     />
                    </div>
                  </div>
                </div>
                <div className='developers w-100'>
                {
                state  && state.listOfDeveloperScore &&  state.listOfDeveloperScore.map((item, index) => 
                (
                  <div className='w-100 d-flex d-flex-v-center-h-between b-bottom developer-rating' key={`developerItemRef${item.contactId}`}>
                  <div> <span className='fw-bold'>  {item.contactName}  </span></div>
                   <div className='mb-2'> 
                  <Rating  
                    size="large"
                    name={`contactItem${item.contactId}-${item.contactName}}`}
                    max={10}
                    value={item.score}
                    precision={1}
                    onChange={(event, newValue) => {
                      let updatelist = [...state.listOfDeveloperScore];
                      const itemIndex = updatelist.indexOf(updatelist.find((f) => f.contactId ===  item.contactId));
                        if(itemIndex !== -1 )
                           updatelist[itemIndex] = { ...updatelist[itemIndex],score : newValue || 0};
                           setState({id : 'listOfDeveloperScore' , value : [...updatelist]});
                       }}
                   />
                   </div>   
                 </div>
                ))}
                </div>
              </div>
           </div>
          </div>
      );
  } ; 
  
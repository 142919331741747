import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import Joi from "joi";
import moment from "moment";
import xCloseIcon from "../../../../../assets/images/icons/x-close-dialog.svg";
import { DialogActions, DialogContent, ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { showError, getErrorByName } from "../../../../../Helper";
import {
  Inputs,
  DialogComponent,
  AutocompleteComponent,
  DatePickerComponent,
  SwitchComponent,
} from "../../../../../Components";
import { PageHeading } from "../../../../../SharedComponents";
import {
  lookupItemsGetId,
  GetUserByStaffCode,
  GetAgentLeadAuditor,
  OrganizationUserSearch,
  GetLookupItemsByLookupTypeName,
} from "../../../../../Services";
import { StaticLookupsIds } from "../../../../../assets/json/StaticLookupsIds";
import { DeActivationConfirmDialog } from "./DeActivationConfirmDialog";

export const DeactivateUserDialog = ({
  isDialogOpen,
  onClose,
  onSave,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const LeadOwnerRoleID = "23fabb33-dc64-4a0a-9f8d-08db4b1b2cba";

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [selected, setSelected] = useReducer(reducer, {
    staffCode: null,
    employeeName: null,
    jobTitle: null,
    reportsTo: null,
    gracePeriod: 0,
    exitDate: moment().format("YYYY-MM-DDTHH:mm:ss") || null,
    deactivationReason: null,
    reHigher: false,
    leadOwner: null,
    isSelectUserAllowed: false,
    specificUser: null,
  });
  const [data, setData] = useReducer(reducer, {
    jobTitles: [],
    systemUsers: {
      employees: [],
      specificUsers: [],
      reportsTo: [],
    },
    deactivationReasons: [],
    leadOwners: [],
    sttafCodeUser: null,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDeactivationConfirmOpen, setIsDeactivationConfirmOpen] =
    useState(false);

  const getJobTitles = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.JobTitle,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "jobTitles", value: res || [] });
    else setData({ id: "jobTitles", value: [] });
  };

  const getDeactivationReasons = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "deactivationReason",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "deactivationReasons", value: res?.result || [] });
    else setData({ id: "deactivationReasons", value: [] });
  };

  const getLeadOwners = async (searchedItem) => {
    const res = await OrganizationUserSearch({
      name: searchedItem || "",
      rolesIds: [LeadOwnerRoleID],
      pageSize: 10,
      pageIndex: 1,
      userStatusId: 2,
    });

    if (!(res && res.status && res.status !== 200))
      setData({ id: "leadOwners", value: res?.result || [] });
    else setData({ id: "leadOwners", value: [] });
  };

  const getSystemUsers = async (userType, searchedItem) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchedItem || "",
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "systemUsers", value: {
    ...(data.systemUsers || []),
     [userType]: res?.result}});
    else setData({ id: "systemUsers", value: [] });
  };

  const getAgentLeadAuditor = async (deactivatedUserId) => {
    const res = await GetAgentLeadAuditor(deactivatedUserId);
    if (!(res && res.status && res.status !== 200)){
      if(res.id) {
        const leadOwnerItem = { id: res.id, fullName: res.leadAuditorName };
        setSelected({ id: "leadOwner", value: leadOwnerItem });
      }
    }
  };

  const getUserByStaffCode = async (staffCode) => {
    const res = await GetUserByStaffCode((staffCode || null));
    if (!(res && res.status && res.status !== 200)){
      setData({ id: "sttafCodeUser", value: res });
      autoFillUserInfoFromStaffCode(res);
    } else {
      setData({ id: "sttafCodeUser", value: null });
      autoFillUserInfoFromStaffCode(null);
    }
  };

  const convertSelectedToBody = () => {
    const nextActionById = selected.specificUser?.id || selected.leadOwner?.id;

    const body = {
      staffId: selected.staffCode || null,
      employeeId: selected.employeeName?.id || null,
      jobTitleId: selected.jobTitle?.lookupItemId || null,
      reportToId: selected.reportsTo?.id || null,
      gracePeriod: selected.gracePeriod || 0,
      exitDate: selected.exitDate || null,
      deActivationReasonId: selected.deactivationReason?.lookupItemId || null,
      isRehireEligible: selected.reHigher || false,
      isLeadOwnerSelected: !selected.isSelectUserAllowed || false,
      nextActionById: nextActionById || null,
    };

    return body;
  };

  const autoFillUserInfoFromStaffCode = (data) => {
    if (data) {

      const jobTitle = data.designationId ?{
        lookupItemName: data.designation,
        lookupItemId: data.designationId,
      } : null;

      const employeeName = data.employeeId ? {
        fullName: data.employeeName,
        id: data.employeeId,
      } : null;

      const reportsTo = data.reportToId ?{
        fullName: data.reportTo,
        id: data.reportToId,
      } : null;

      setSelected({
        id: "edit", value: {
          ...selected,
          jobTitle,
          employeeName,
          reportsTo,
        }
      })
    } else setSelected({
      id: "edit", value: {
        ...selected,
        jobTitle: null,
        employeeName: null,
        reportsTo: null,
      }
    })
  };

  const employeeChangeHandler = (newValue) => {
    setSelected({
      id: "edit", value: {
        ...selected,
        employeeName: newValue,
        staffCode: null,
        staffID: null,
        reportsTo: null,
        jobTitle: null,
      }
    })
  }
  
  const saveHandler = () => {
    setIsSubmitted(true);

    if (schema.error) {
      showError(t("Shared:please-fix-all-errors"));
      return;
    }
    setIsDeactivationConfirmOpen(true);
  };

  const schema = Joi.object({
    deactivationReason: Joi.object()
      .required()
      .messages({
        "object.base": t(`${translationPath}deactivationReason-is-required`),
      }),
    reportsTo: Joi.object()
      .required()
      .messages({
        "object.base": t(`${translationPath}line-manager-is-required`),
      }),
    exitDate: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}exitDate-is-required`),
      }),
    staffCode: Joi.custom((value, helpers) => {
      if (!data.sttafCodeUser && selected.staffCode) return helpers.error('invalid-staffcode-msg');
      return value;
    })
      .messages({
        'invalid-staffcode-msg': `invalid-staffcode`,
      }),
      leadOwner: Joi.custom((value, helpers) => {
        if (!selected.leadOwner && !selected.specificUser) return helpers.error('next-action-by-msg');
        return value;
      })
      .messages({
        'next-action-by-msg': t(`${translationPath}next-action-by-user-is-required`),
      }),
      specificUser: Joi.custom((value, helpers) => {
        if (!selected.leadOwner && !selected.specificUser) return helpers.error('next-action-by-msg');
        return value;
      })
      .messages({
        'next-action-by-msg': t(`${translationPath}next-action-by-user-is-required`),
      })
    })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);


    useEffect(()=>{
      if(selected.employeeName) getAgentLeadAuditor(selected.employeeName.id);
    }, [selected.employeeName])

  return (
    <div>
      <DialogComponent
        isOpen={isDialogOpen}
        maxWidth="sm"
        dialogTitle={
          <div className="user-groups-view add-task-dialog pb-3 w-100">
            <div className="d-flex-v-center-h-between">
              <div className="fw-simi-bold">
                {t(`${translationPath}Deactivate-user`)}
              </div>
              <div className="xCloseIcon pointer" onClick={onClose}>
                <img src={xCloseIcon} />
              </div>
            </div>
          </div>
        }
        dialogContent={
          <div className="user-groups-view ">
            <DialogContent>
              <>
                <div className="d-flex fj-start b-bottom">
                  <div className="w-33 mt-2 pt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}staff-id`)}
                    </span>
                  </div>
                  <div className="w-75 mt-2 mb-1 pt-1">
                    <Inputs
                      idRef="staffCodeRef"
                      inputPlaceholder="enter"
                      value={selected.staffID || ""}
                      onInputChanged={(event)=>{
                        const staffID = event?.target?.value;
                        setSelected({ id: "staffID", value: staffID });
                      }}
                      onKeyUp={(event) => {
                        const staffCode = event?.target?.value;
                        if (searchTimer.current)
                          clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          setSelected({ id: "staffCode", value: staffCode });
                          getUserByStaffCode(staffCode);
                        }, 500);
                      }}
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, "staffCode").message}
                      error={getErrorByName(schema, "staffCode").error}
                      afterIconClasses={
                        selected.staffCode &&
                        (getErrorByName(schema, "staffCode").error ?
                          "mdi mdi-close-outline c-danger mt-1" :
                          "mdi mdi-check-outline c-success mt-1") ||
                        null
                      }
                      withLoader
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  </div>
                </div>
                <div className="d-flex fj-start b-bottom">
                  <div className="w-33 mt-2 pt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}employee-name`)}
                    </span>
                  </div>
                  <div className="w-75 mt-2 mb-1 pt-1">
                    <AutocompleteComponent
                      idRef="employee-nameRef"
                      inputPlaceholder={t(`${translationPath}select`)}
                      wrapperClasses="w-100 mb-2"
                      selectedValues={selected.employeeName || []}
                      data={data.systemUsers?.employees || []}
                      displayLabel={(option) =>
                        (option && option.fullName) ||
                        (option && option.firstName) ||
                        ""
                      }
                      multiple={false}
                      withoutSearchButton
                      onOpen={() => {
                        const userType = "employees";
                        if (data.systemUsers?.employees && data.systemUsers.employees.length == 0)
                        getSystemUsers(userType);
                      }}
                      onChange={(_, newValue) => {
                        employeeChangeHandler(newValue);
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        const userType = "employees";
                        if (searchTimer) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getSystemUsers(userType, value);
                        }, 1200);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex fj-start b-bottom">
                  <div className="w-33 mt-2 pt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}designation`)}
                    </span>
                  </div>
                  <div className="w-75 mt-2 mb-1 pt-1">
                    <AutocompleteComponent
                      idRef="designationRef"
                      inputPlaceholder={t(`${translationPath}select`)}
                      wrapperClasses="w-100 mb-2"
                      selectedValues={selected.jobTitle || []}
                      data={data.jobTitles || []}
                      displayLabel={(option) =>
                        (option.lookupItemName && option.lookupItemName) || ""
                      }
                      multiple={false}
                      withoutSearchButton
                      onOpen={() => {
                        if (data.jobTitles && data.jobTitles.length == 0)
                          getJobTitles();
                      }}
                      onChange={(_, newValue) => {
                        setSelected({ id: "jobTitle", value: newValue });
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex fj-start b-bottom">
                  <div className="w-33 mt-2 pt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}Line-manager`)}
                    </span>
                  </div>
                  <div className="w-75 mt-2 mb-1 pt-1">
                    <AutocompleteComponent
                      idRef="LineManagerRef"
                      inputPlaceholder={t(`${translationPath}select`)}
                      wrapperClasses="w-100 mb-2"
                      selectedValues={selected.reportsTo || []}
                      data={data.systemUsers?.reportsTo || []}
                      displayLabel={(option) =>
                        (option && option.fullName) ||
                        (option && option.firstName) ||
                        ""
                      }
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, "reportsTo").message}
                      error={getErrorByName(schema, "reportsTo").error}
                      multiple={false}
                      withoutSearchButton
                      onOpen={() => {
                        const userType = "reportsTo";
                        if (data.systemUsers?.reportsTo && data.systemUsers.reportsTo.length == 0)
                        getSystemUsers(userType);
                      }}
                      onChange={(_, newValue) => {
                        setSelected({ id: "reportsTo", value: newValue });
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        const userType = "reportsTo";
                        if (searchTimer) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getSystemUsers(userType, value);
                        }, 1200);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex fj-start b-bottom">
                  <div className="w-33 mt-2 pt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}grace-period*`)}
                    </span>
                  </div>
                  <div className="w-75 mt-2 mb-1 pt-1">
                    <Inputs
                      withNumberFormat
                      isAttachedInput
                      idRef="gracePeriodRef"
                      labelClasses="has-inside-label"
                      value={selected.gracePeriod || 0}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, "gracePeriod").message}
                      error={getErrorByName(schema, "gracePeriod").error}
                      endAdornment={
                        <span className="px-2 inner-span">
                          {t(`${translationPath}days`)}
                        </span>
                      }
                      type="number"
                      min={0}
                      max={+(selected.sellingPrice || 0)}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onKeyUp={(e) => {
                        const gracePeriod = e?.target?.value || 0;
                        if (Number.isNaN(gracePeriod)) gracePeriod = 0;

                        setSelected({ id: "gracePeriod", value: gracePeriod });
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex fj-start b-bottom">
                  <div className="w-33 mt-2 pt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}exit-date*`)}
                    </span>
                  </div>
                  <div className="w-75 mt-2 mb-1 pt-1 pb-2">
                    <DatePickerComponent
                      idRef="exit-dateRef"
                      placeholder="DD/MM/YYYY"
                      selectedValues={selected.exitDate || null}
                      onDateChanged={(newValue) => {
                        setSelected({
                          id: "exitDate",
                          value:
                            (newValue &&
                              moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                            null,
                        });
                      }}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, "exitDate").message}
                      error={getErrorByName(schema, "exitDate").error}
                    />
                  </div>
                </div>
                <div className="d-flex fj-start b-bottom">
                  <div className="w-33 mt-2 pt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}deactivation-reason*`)}
                    </span>
                  </div>
                  <div className="w-75 mt-2 mb-1 pt-1">
                    <AutocompleteComponent
                      idRef="deactivation-reasonRef"
                      inputPlaceholder={t(`${translationPath}select`)}
                      wrapperClasses="w-100 mb-2"
                      selectedValues={selected.deactivationReason || []}
                      data={data.deactivationReasons || []}
                      chipsLabel={(option) =>
                        (option.lookupItemName && option.lookupItemName) || ""
                      }
                      displayLabel={(option) =>
                        (option.lookupItemName && option.lookupItemName) || ""
                      }
                      multiple={false}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, "deactivationReason").message}
                      error={getErrorByName(schema, "deactivationReason").error}
                      withoutSearchButton
                      onOpen={() => {
                        if (
                          data.deactivationReasons &&
                          data.deactivationReasons.length == 0
                        )
                          getDeactivationReasons();
                      }}
                      onChange={(_, newValue) => {
                        setSelected({
                          id: "deactivationReason",
                          value: newValue,
                        });
                      }}
                    />
                  </div>
                </div>

                <SwitchComponent
                  idRef="rehigherRef"
                  themeClass="thick-theme"
                  isChecked={selected.reHigher || false}
                  labelValue={t(`${translationPath}rehigher-eligibility`)}
                  onChangeHandler={(_, isChecked) => {
                    setSelected({ id: "reHigher", value: isChecked });
                  }}
                />

                <PageHeading
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  headerTitle={"Next-action-by"}
                  subTitle={"Next-action-by-heading"}
                  wrapperClasses={"mt-4"}
                  headerTitleClasses={"m-0 fz-20 mb-1"}
                  subTitleClasses={"fz-12 m-0"}
                />
                <div className="d-flex fj-start b-bottom b-top mt-3">
                  <div className="w-33 mt-2 pt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}lead-owner`)}
                    </span>
                  </div>
                  <div className="w-75 mt-2 mb-1 pt-1">
                    <AutocompleteComponent
                      idRef="lead-ownerRef"
                      inputPlaceholder={t(`${translationPath}select`)}
                      wrapperClasses="w-100 mb-2"
                      selectedValues={selected.leadOwner || []}
                      data={data.leadOwners || []}
                      renderInput={(option) =>
                        (option && option.fullName) ||
                        (option && option.firstName) ||
                        ""
                      }
                      displayLabel={(option) =>
                        (option && option.fullName) ||
                        (option && option.firstName) ||
                        ""
                      }
                      multiple={false}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, "leadOwner").message}
                      error={getErrorByName(schema, "leadOwner").error}
                      withoutSearchButton
                      onOpen={() => {
                        if (data.leadOwners && data.leadOwners.length == 0)
                          getLeadOwners();
                      }}
                      isDisabled={selected.isSelectUserAllowed}
                      onChange={(_, newValue) => {
                        setSelected({
                          id: "leadOwner",
                          value: newValue,
                        });
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getLeadOwners(value);
                        }, 1200);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex fj-start b-bottom pb-2">
                  <div className="w-33 mt-2 pt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}specific-user`)}
                    </span>
                  </div>
                  <div className="w-75">
                    <SwitchComponent
                      idRef="allow-select-userRef"
                      themeClass="thick-theme"
                      isChecked={selected.isSelectUserAllowed || false}
                      labelValue={t(`${translationPath}allow-select-user`)}
                      onChangeHandler={(_, isChecked) => {
                        setSelected({
                          id: "edit",
                          value: {
                            ...selected,
                            leadOwner: null,
                            specificUser: null,
                            isSelectUserAllowed: isChecked,
                          },
                        });
                      }}
                    />
                    {selected.isSelectUserAllowed && (
                      <AutocompleteComponent
                        idRef="specific-userRef"
                        inputPlaceholder={t(`${translationPath}select`)}
                        wrapperClasses="w-100 mt-3 mb-2"
                        selectedValues={selected.specificUser || []}
                        data={data.systemUsers?.specificUsers || []}
                        displayLabel={(option) =>
                          (option && option.fullName) ||
                          (option && option.firstName) ||
                          ""
                        }
                        multiple={false}
                        isWithError
                        isSubmitted={isSubmitted}
                        helperText={getErrorByName(schema, "specificUser").message}
                        error={getErrorByName(schema, "specificUser").error}
                        withoutSearchButton
                        onOpen={() => {
                          const userType = "specificUsers";
                          if (data.systemUsers?.specificUsers && data.systemUsers.specificUsers.length == 0)
                           getSystemUsers(userType);
                        }}
                        onChange={(_, newValue) => {
                          setSelected({
                            id: "specificUser",
                            value: newValue,
                          });
                        }}
                        renderInput={(option) =>
                          (option && option.fullName) ||
                          (option && option.firstName) ||
                          ""
                        }
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          const userType = "specificUsers";

                          if (searchTimer) clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getSystemUsers(userType, value);
                          }, 1200);
                        }}
                      />
                    )}
                  </div>
                  {isDeactivationConfirmOpen && (
                    <DeActivationConfirmDialog
                      payload={convertSelectedToBody()}
                      isDialogOpen={isDeactivationConfirmOpen}
                      setIsDialogOpen={setIsDeactivationConfirmOpen}
                      reloadData={onSave}
                      onCloseClicked={() => {
                        setIsDeactivationConfirmOpen(false);
                      }}
                      onCancelClicked={() => {
                        setIsDeactivationConfirmOpen(false);
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  )}
                </div>
              </>
            </DialogContent>
            <DialogActions>
              <div className="d-flex-center fj-end py-0 pt-3">
                <ButtonBase
                  onClick={onClose}
                  className="btns theme-propx outlined"
                >
                  {t(`${translationPath}cancel`)}
                </ButtonBase>

                <ButtonBase
                  className="btns theme-propx solid mr-0"
                  onClick={saveHandler}
                >
                  {t(`${translationPath}Deactivate`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </div>
        }
      />
    </div>
  );
};

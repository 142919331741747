import React, { useState, useEffect, useRef } from "react";
import { TextField, Icon, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";

import { useSelectedTheme, useOutsideClick } from "../../../Hooks";
import { CloseXIcon } from "../../../assets/icons";

import useStyles from "./styles";

export default function CustomAutocomplete({
  variant = "default",
  LeftIcon = null,
  onChange,
  options = [],
  showDropdownIcon = true,
  showCloseIcon = true,
  hasNoBorder = false,
  open = false,
  DatePickerField = null,
}) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);
  const inputRef = useRef(null);

  const [selectedValue, setSelectedValue] = useState(null);
  const [isOpen, setIsOpen] = useState(open);

  const handleChange = (event, newValue) => {
    setSelectedValue(newValue.name);

    if (onChange) {
      onChange(newValue.name);
    }
  };

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
    iconRef,
  });

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <Box ref={dropdownRef}>
      <Autocomplete
        className={clsx(styles.autoCompleteWrapper, {
          [styles.iconVariant]: variant === "icon",
        })}
        id="Autocomplete"
        options={
          DatePickerField
            ? [{ name: "Date Picker", isDatePicker: true }]
            : options
        }
        open={isOpen}
        closeIcon={
          showCloseIcon ? (
            <CloseXIcon
              width="16"
              height="16"
              fill={palette.foreground.quarterary}
            />
          ) : null
        }
        popupIcon={
          showDropdownIcon ? (
            <Icon
              ref={iconRef}
              className={clsx("icon-down-arrow", styles.dropdownIcon)}
            />
          ) : null
        }
        onOpen={() => setIsOpen(true)}
        onClose={() => {
          if (open) setIsOpen(open);
          else setIsOpen(false);
        }}
        getOptionLabel={
          (option) => (option.isDatePicker ? "" : option.name) // Empty string for CustomInput option
        }
        style={{ width: 300 }}
        value={selectedValue}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select saved view"
            variant="outlined"
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              classes: {
                input: styles.placeholder,
              },

              className: clsx(params.InputProps.className, {
                [styles.selected]: selectedValue !== null,
                [styles.noBorder]: hasNoBorder,
              }),
              startAdornment: LeftIcon && variant === "icon" && LeftIcon,
            }}
          />
        )}
        renderOption={
          (option) => (option?.isDatePicker ? DatePickerField : option.name) // Render the option name if not the date picker
        }
        classes={{
          option: styles.option,
        }}
      />
    </Box>
  );
}

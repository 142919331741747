import React, { useRef, useReducer, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../../../../../SharedComponents";
import { Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  Inputs,
  Tables,
  AutocompleteComponent,
} from "../../../../../../Components";
import {
  getDownloadableLink,
  getFirstLastNameLetters,
  showError,
  showSuccess,
  GlobalHistory
} from "../../../../../../Helper";
import {
  OrganizationUserSearch,
  GetAllBranches,
  ApproveUserActivation,
} from "../../../../../../Services";
import {UserActivationRejectDialog} from '../../Dialogs';

export const PendingActivationView = ({
  parentTranslationPath,
  translationPath,
  usersData,
  totalCount,
  setFilter,
  filter,
  reloadData,
  onPageSizeChanged,
  onPageIndexChanged
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const typingTimer = useRef(null);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [selected, setSelected] = useReducer(reducer, {
    lineManager: null,
    branch: null,
  });
  const [data, setData] = useReducer(reducer, {
    systemUsers: [],
    branches: [],
  });
  const [activeItem, setActiveItem] = useState(null);

  const getSystemUsers = async (searchedItem) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchedItem || "",
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({ id: "systemUsers", value: localValue });
      } else setData({ id: "systemUsers", value: [] });
    }
  };

  const getBranches = async (branchName) => {
    const res = await GetAllBranches({
      pageSize: 10,
      pageIndex: 1,
      branchName,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "branches", value: res.result || [] });
    } else setData({ id: "branches", value: [] });
  };

  const approveUserActivation = async (userId) => {
    const res = await ApproveUserActivation(userId);
    if (!(res && res.status && res.status !== 200)) {
      reloadData();
      showSuccess(t(`${translationPath}activate-approval-success`));
    } else showError(t(`${translationPath}activate-approval-failed`));
  };



  return (
    <div className="w-80">
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"PendingActivation"}
          subTitle={"Pending-Activation-heading"}
          wrapperClasses={"px-4 w-50"}
          headerTitleClasses={"fz-16 mb-1"}
          subTitleClasses={"fz-14 m-0 ml-0"}
        />

        <div className="w-50 px-4 d-flex-v-center-h-between">
          <div className="w-100 d-flex-column mr-3">
            <div className="w-100 d-flex fj-end mr-3">
            <Inputs
                idRef="user-nameRef"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.userName || ''}
                onInputChanged={(event) => {
                  const userName = event?.target?.value;
                    setFilter((items) => ({ ...items, 
                      userName: userName || null,
                      userId: null,
                    }));
                }}
                inputPlaceholder={t(`${translationPath}name`)}
              />
            </div>
            <div className="w-100 d-flex fj-end mr-3">
              <AutocompleteComponent
                idRef="branchRef"
                inputPlaceholder={t(`${translationPath}branch`)}
                wrapperClasses="w-25 mr-2"
                value={selected.branch}
                data={data.branches || []}
                chipsLabel={(option) =>
                  (option.branchName && option.branchName) || ""
                }
                displayLabel={(option) =>
                  (option.branchName && option.branchName) || ""
                }
                multiple={false}
                withoutSearchButton
                onOpen={() => {
                  if (data.branches && data.branches.length == 0) getBranches();
                }}
                onChange={(event, newValue) => {
                  setSelected({ id: "branch", value: newValue });
                  setFilter((items) => ({
                    ...items,
                    branchId: newValue?.branchId || null,
                  }));
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (typingTimer.current) clearTimeout(typingTimer.current);
                  typingTimer.current = setTimeout(() => {
                    getBranches(value);
                  }, 700);
                }}
              />
              <AutocompleteComponent
                idRef="lineManagerRef"
                labelClasses="Requierd-Color"
                inputPlaceholder={t(`${translationPath}line-manager`)}
                selectedValues={selected.lineManager || []}
                wrapperClasses="w-25 mr-2"
                data={data.systemUsers || []}
                withoutSearchButton
                multiple={false}
                displayLabel={(option) => option?.fullName || ""}
                onChange={(event, newValue) => {
                  setSelected({
                    id: "lineManager",
                    value: newValue,
                  });
                  setFilter((items) => ({
                    ...items,
                    lineManagerId: newValue?.id || null,
                  }));
                }}
                onOpen={() => {
                  if (data.systemUsers && data.systemUsers.length == 0)
                    getSystemUsers();
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (typingTimer) clearTimeout(typingTimer.current);
                  typingTimer.current = setTimeout(() => {
                    getSystemUsers(value);
                  }, 1200);
                }}
                onKeyDown={() => {
                  setSelected({
                    id: "lineManager",
                    value: null,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="propx-view-container sm  m-3">
        <Tables
          data={usersData || []}
          headerData={[
            {
              id: 1,
              label: "user",
              component: (item) =>
                (item?.name ? (
                  <div className="d-flex-v-center">
                    <div className="campaign-team-f1">
                      <Avatar
                        className="avatars-wrapper team-avatar fz-12 sm-theme"
                        src={
                          item?.profileImage
                            ? getDownloadableLink(item?.profileImage)
                            : ""
                        }
                      >
                        {getFirstLastNameLetters(item?.name || "")}
                      </Avatar>
                    </div>
                    <div className="d-flex-column">
                      <span className="fz-12 fw-simi-bold">{item?.name || "-"}</span>
                      <span className="fz-10 text-tertiary">
                        {item?.email || "-"}
                      </span>
                    </div>
                  </div>
                ) : (
                  "-"
                )) || "",
            },
            {
              id: 2,
              label: "branch",
              input: "branch",
              component: (item) => <span>{item?.branch}</span> || "",
            },
            {
              id: 3,
              label: "Line-manager",
              input: "lineManager",
            },
            {
              id: 4,
              label: "Creation-date",
              input: "creationDate",
              component: (item) =>
                (
                  <span>
                    {item?.creationDate
                      ? moment(item?.creationDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 5,
              label: "Created-by",
              input: "createdByName",
            },
            {
              id: 6,
              label: "actions",
              component: (item) =>
                (
                  <span className="d-flex fj-start fa-center">
                    {item?.showRejectButton && (
                      <Button
                        className="btns propx-action-btn theme-propx outlined danger"
                        onClick={() => {
                          setActiveItem(item);
                          setIsRejectDialogOpen(true);
                        }}
                      >
                        {t(`${translationPath}reject`)}
                      </Button>
                    )}
                    {item?.showConfirmButton && (
                      <Button
                        className="btns propx-action-btn p-1 theme-propx outlined gold"
                        onClick={() =>
                          item?.id ? approveUserActivation(item?.id) : null
                        }
                      >
                        {t(`${translationPath}confirm`)}
                      </Button>
                    )}
                    <Button className="btns propx-action-btn p-1 theme-propx gold-text"
                        onClick={() =>
                          item.id
                            ? GlobalHistory.push(
                                `/home/Users/edit?id=${item.id}`
                              )
                            : null
                        }
                      >
                      {t(`${translationPath}show-details`)}
                    </Button>
                  </span>
                ) || "",
            },
          ]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          totalItems={totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          defaultActions={[]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      {isRejectDialogOpen && (
          <UserActivationRejectDialog
            activeItem={activeItem}
            isDialogOpen={isRejectDialogOpen}
            setIsDialogOpen={setIsRejectDialogOpen}
            reloadData={reloadData}
            onCloseClicked={() => {
              setIsRejectDialogOpen(false);
              setActiveItem(null);
            }}
            onCancelClicked={() => {
              setIsRejectDialogOpen(false);
              setActiveItem(null);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
    </div>
  );
};

import React, { useState, useReducer, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { PageHeading } from "../../../../../SharedComponents";
import { Spinner } from "../../../../../Components";
import { showSuccess, showError } from "../../../../../Helper";
import {
  GetAllFacilitiesAndAmenitiesScoresAPI,
  UpdatePropertyRatingFacilitiesAndAmenitiesScoresAPI,
} from "../../../../../Services";
import {
  FacilitiesComponent,
  AmenitiesComponent,
} from "../FacilitiesAndAmenities";

export const FacilitiesAndAmenitiesView = ({
  parentTranslationPath,
  translationPath,
  ratingPercentage,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [
    facilitiesAndAmenitiesRatingPercentage,
    setFacilitiesAndAmenitiesRatingPercentage,
  ] = useState(0) ; 


  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    updatePropertyRatingFacilitiesAndAmentiesDto: [],
  });

  const GetAllFacilitiesAndAmenitiesScores = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllFacilitiesAndAmenitiesScoresAPI();
    if (!((res && res.data && res.data.ErrorId) || !res))
      setState({ id: "edit", value: res });
    else setState({ id: "edit", value: null });
    setIsLoading(false);
  });

  const reloadData = () => {
    GetAllFacilitiesAndAmenitiesScores();
  };

  const saveHandler = async () => {
    setIsLoading(true);

    const res = await UpdatePropertyRatingFacilitiesAndAmenitiesScoresAPI(
      state
    );
    if (!((res && res.status && res.status !== 200) || !res)) {
      showSuccess(
        t(
          `${translationPath}facilities-and-amenities-scores-updated-successfully`
        )
      );
      reloadData();
    } else
      showError(
        t(`${translationPath}facilities-and-amenities-scores-updated-failed`)
      );

    setIsLoading(false);
  };

  useEffect(() => {
    reloadData();
    if(ratingPercentage)
      {
        const rating = ratingPercentage.find((f) => f.propertyRatingTabName === "Facilities & amenities");
        setFacilitiesAndAmenitiesRatingPercentage((rating && rating.score) || 0 ) ; 
      }
  }, [ratingPercentage]);

  return (
    <div className="w-100 mt-4">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"facilities-and-amenities-rating"}
          wrapperClasses={"px-4 pt-2 w-75"}
          headerTitleClasses={"fz-30"}
          subTitleClasses={"fz-12 m-0"}
          subTitle={""}
          titleWithPercentage={`${facilitiesAndAmenitiesRatingPercentage}%`}
        />

        <div className="w-25 px-4 d-flex-center fj-end">
          <Button
            className="btns theme-propx outlined"
            id="btn-cancel"
            onClick={() => {
              reloadData();
            }}
          >
            {t(`${translationPath}cancel`)}
          </Button>
          <Button
            className="btns theme-propx solid"
            id="btn-save"
            onClick={() => saveHandler()}
          >
            {t(`${translationPath}save-changes`)}
          </Button>
        </div>
      </div>
      <div className="property-rating-container">
        <div className="facilities-amenities-criteria">
          <div className="facilities-amenities-section b-bottom mb-2 mt-2">
            <FacilitiesComponent
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              state={state}
              setState={setState}
            />
          </div>
          <div className="facilities-amenities-section b-bottom mb-2 mt-2">
            <AmenitiesComponent
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              state={state}
              setState={setState}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { GetLostInquiryAgent } from "../../../Services";
import { useTranslation } from "react-i18next";
import { PaginationComponent, Spinner, Tables } from "../../../Components";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@material-ui/core";

export const LostInquiryAgentDialog = ({
  open,
  close,
  logId,
  translationPath,
  parentTranslationPath,
  inquiryStatusId
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const [data, setData] = useState({ result: [], totalCount: 0 });
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
  });
  const [isLoading, setIsLoading] = useState(false);

  const getLostInquiryAgent = async () => {    
    const body = {
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
      logId,
      inquiryStatusId
    };
    setIsLoading(true);
    const res = await GetLostInquiryAgent(body);
    if (!(res && res.status && res.status !== 200)) {
      setData({
        result: res?.result || [],
        totalCount: res?.totalCount || 0,
      });
    } else {
      setData({ result: [], totalCount: 0 });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (logId) getLostInquiryAgent(logId);
  }, [logId, filter]);
  const headerData = [
    {
      id: 2,
      label: "agent-name",
      input: "agentName",
    },
    {
      id: 3,
      label: "assigned-type",
      input: "assignedType",
    },
    {
      id: 4,
      label: "assigned-by",
      input: "assignedBy",
    },
  ];

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({
      ...item,
      pageIndex: 0,
      pageSize,
    }));
  };

  return (
    <div>
      <Spinner isActive={isLoading} />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={() => {
          close();
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      >
        <form noValidate>
          <DialogTitle>{t(`${translationPath}inquiry-agents`)}</DialogTitle>
          <DialogContent>
            <div>
              <Tables
                data={data?.result || []}
                headerData={headerData || []}
                defaultActions={[]}
                activePage={filter.pageIndex}
                itemsPerPage={filter.pageSize}
                totalItems={data.totalCount}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
               <PaginationComponent
                pageIndex={filter.pageIndex}
                pageSize={filter.pageSize}
                totalCount={data?.totalCount}
                onPageIndexChanged={onPageIndexChanged}
                onPageSizeChanged={onPageSizeChanged}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="btns theme-solid bg-cancel"
              onClick={() => close()}
            >
              {t(`${translationPath}cancel`)}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

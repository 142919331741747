import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useDebounce, useSelectedTheme } from "../../../Hooks";

// Icons
import { SearchIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

function CustomInput({
  debounceTime = 0,
  hasSearchIcon = true,
  onChange = undefined,
  handleClick = undefined,
  disabled = false,
  style = {},
  ...props
}) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const [inputValue, setInputValue] = useState(props.value || "");
  const debouncedValue = useDebounce(inputValue, debounceTime);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setInputValue(props.value || "");

    if (!props.value) {
      setIsFocused(false);
    }
  }, [props.value]);

  const isInitialRender = useRef(true); // Track if it's the initial render

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return; // Skip the effect on the initial render
    }

    if (debounceTime && onChange) {
      onChange({ target: { value: debouncedValue } });
    }
  }, [debouncedValue, debounceTime]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    if (!debounceTime && onChange) {
      onChange(event);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      style={style}
      className={clsx(styles.inputContainer, {
        [styles.inputContainerFocused]: isFocused,
      })}
    >
      {hasSearchIcon && (
        <SearchIcon
          width="20"
          height="20"
          fill={palette.foreground.quarterary}
        />
      )}

      <input
        type={props.type}
        placeholder={props.placeholder}
        value={inputValue}
        onChange={handleChange}
        onClick={handleClick}
        name={props.name}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  );
}

CustomInput.defaultProps = {
  disabled: false,
};

export default CustomInput;

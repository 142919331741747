import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    root: {
      padding: '32px',
      marginInline: 'auto',
      // maxInlineSize: '1440px',
      flex: '1 1 auto',
      inlineSize: '100%'
    }
  }
});

import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import FormattedMessage from "../../../../../../SharedComponents/FormattedComponent/FormattedMessage";
import { GetSensitiveFieldsForUser } from "../../../../../../Services";
import { useHistory } from "react-router-dom";
import {
  AgentTypesEnum,
  FormsIdsEnum,
  LeadOwnerDataKeys,
} from "../../../../../../Enums";
import "./OwnerDetailsDialog.Style.scss";
import { Spinner } from "../../../../../../Components";

const useStyles = makeStyles({
  parentList: {
    paddingTop: "0px",
    "& .MuiListItem-root": {
      paddingBottom: "0px",
    },
  },
});

const encryptionText = "********";

export function OwnerDetailsDialog({
  data,
  translationPath,
  leadOwnerDetails,
  unitId,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [sensitiveFieldsForUser, setSensitiveFieldsForUser] = useState([]);
  const [leadSensitiveFields, setLeadSensitiveFields] = useState([]);
  const [contactSensitiveFields, setContactSensitiveFields] = useState([]);
  const [unitSensitiveFields, setUnitSensitiveFields] = useState([]);
  const [dataWithSensitiveFields, setDataWithSensitiveFields] = useState({
    phone: encryptionText,
    email: encryptionText,
    name: encryptionText,
    whatapp: encryptionText,
  });
  const [isLoading, setIsLoading] = useState(false);

  const getSensitiveFieldsForUser = useCallback(
    async (formType, formId, sensitiveFieldsForUserObj) => {
      setIsLoading(true);
      const response = await GetSensitiveFieldsForUser(
        formId,
        sensitiveFieldsForUserObj
      );
      if (!(response && response.status && response.status !== 200)) {
        if (formType === "Contact") setContactSensitiveFields(response);
        if (formType === "Lead") setLeadSensitiveFields(response);
        if (formType === "unit") setUnitSensitiveFields(response);
      } else {
        if (formType === "Contact") setContactSensitiveFields([]);
        if (formType === "Lead") setLeadSensitiveFields([]);
        if (formType === "unit") setUnitSensitiveFields([]);
      }
      setIsLoading(false);
    },
    []
  );

  const checkIsUnitOwnerSensitive = () => {
    if (unitSensitiveFields.some((s) => s.key === "lead_owner")) return true;
    return false;
  };

  const checkIsFieldSensitive = (fieldKey) => {
    const isKeyFound = sensitiveFieldsForUser.find(
      (item) => fieldKey === item.key
    );
    const isFieldSensitive = isKeyFound ? true : false;
    return isFieldSensitive;
  };

  const HandleSensitiveFields = () => {
    const newLeadOwnerData = {
      name: data.contactName ? data.contactName : data.conpany_name,
      email: data.email ? data.email : data.general_email,
      phone: data.phone ? data.phone : data.mobile,
      whatapp: data.whatsAppNumber,
    };

    if (checkIsUnitOwnerSensitive()) {
      LeadOwnerDataKeys[leadOwnerDetails.contactsTypeId].forEach((item) => {
        const checkIsUnitOwnerSensitiveField =
          checkIsFieldSensitive("contact_name");
        const checkIsEmailSensitiveField = checkIsFieldSensitive(item.fieldkey);
        const checkIsWhatsAppNumberSensitiveField = checkIsFieldSensitive(
          item.fieldkey
        );
        const checkIsPhoneSensitiveField = checkIsFieldSensitive(item.fieldkey);
        if (checkIsUnitOwnerSensitive() && checkIsUnitOwnerSensitiveField) {
          newLeadOwnerData.name = "********";
        } else if (checkIsUnitOwnerSensitive()) {
          newLeadOwnerData.name = "********";
        } else {
          newLeadOwnerData.name = leadOwnerDetails.contactName;
        }
        if (checkIsEmailSensitiveField) {
          newLeadOwnerData.email = "********";
        } else {
          newLeadOwnerData.email = leadOwnerDetails.email
            ? leadOwnerDetails.email
            : leadOwnerDetails.general_email;
        }
        if (checkIsWhatsAppNumberSensitiveField) {
          newLeadOwnerData.whatapp = "********";
        } else {
          newLeadOwnerData.whatapp = leadOwnerDetails.whatsAppNumber;
        }
        if (checkIsPhoneSensitiveField) {
          newLeadOwnerData.phone = "********";
        } else {
          newLeadOwnerData.phone = leadOwnerDetails.phone
            ? leadOwnerDetails.phone
            : leadOwnerDetails.mobile;
        }
      });
      setDataWithSensitiveFields(newLeadOwnerData);
    } else {
      setDataWithSensitiveFields(newLeadOwnerData);
    }
  };

  useEffect(() => {
    const unitFormId = FormsIdsEnum.units.id;

    if (unitFormId) getSensitiveFieldsForUser("unit", unitFormId, unitId);

    const leadFormType = "Lead";
    const isOwnerLead =
    leadOwnerDetails&& leadOwnerDetails.leadClass === AgentTypesEnum.Seller.value ||
    leadOwnerDetails&& leadOwnerDetails.leadClass === AgentTypesEnum.Landlord.value;

    const leadFormId = isOwnerLead
      ? FormsIdsEnum.leadsOwner.id
      : FormsIdsEnum.leadsSeeker.id;

    if (leadFormId) getSensitiveFieldsForUser(leadFormType, leadFormId);

    const contactFormType = "Contact";
    const contactFormId = leadOwnerDetails&&leadOwnerDetails.contactsTypeId || 1;

    if (contactFormId)
      getSensitiveFieldsForUser(
        contactFormType,
        contactFormId,
        leadOwnerDetails
          ? { key: "contact", id: leadOwnerDetails&&leadOwnerDetails.contactId }
          : null
      );
  }, [leadOwnerDetails]);

  useEffect(() => {
    setSensitiveFieldsForUser([
      ...contactSensitiveFields,
      ...leadSensitiveFields,
      ...unitSensitiveFields,
    ]);
  }, [contactSensitiveFields, leadSensitiveFields, unitSensitiveFields]);

  useEffect(() => {
    if (leadOwnerDetails && sensitiveFieldsForUser) {
      HandleSensitiveFields();
    }
  }, [leadOwnerDetails, sensitiveFieldsForUser]);

  if (!data) {
    return <h1>Owner information is not available at this time.</h1>;
  }

  return (
    <Box>
      <Spinner isActive={isLoading} isAbsolute />
      <List aria-label="course details" className={classes.parentList}>
        <ListItem>
          <ListItemText
            primary={
              <Typography>
                <b>
                  <FormattedMessage
                    id="popup-owner-name"
                    path={translationPath}
                  />
                </b>
                <span
                  className="navigation-link"
                  onClick={() => {
                    if (dataWithSensitiveFields.name !== "********") {
                      history.push(
                        `/home/Contacts-CRM/contact-profile-edit?formType=1&id=${data?.contactId}`
                      );
                    }
                  }}
                >
                  {dataWithSensitiveFields && dataWithSensitiveFields.name}
                </span>
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography>
                <b>
                  <FormattedMessage id="popup-lead-id" path={translationPath} />
                </b>
                <span
                  className="navigation-link"
                  onClick={() => {
                    history.push(
                      `/home/lead-sales/lead-profile-edit?formType=1&id=${data?.leadId}&leadClass=${data?.leadClass}`
                    );
                  }}
                >
                  {data?.contactId}
                </span>
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography>
                <b>
                  <FormattedMessage
                    id="popup-owner-mobile-number"
                    path={translationPath}
                  />
                </b>{" "}
                {dataWithSensitiveFields && dataWithSensitiveFields.phone}
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography>
                <b>
                  <FormattedMessage
                    id="popup-owner-whatsApp-number"
                    path={translationPath}
                  />
                </b>{" "}
                {dataWithSensitiveFields && dataWithSensitiveFields.whatapp}
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography>
                <b>
                  <FormattedMessage id="popup-email" path={translationPath} />
                </b>{" "}
                {dataWithSensitiveFields && dataWithSensitiveFields.email}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
}

import { ConsentSentByEnum } from "./ConsentSentBy.Enum";

export const ShareMethodsDataEnum = {
    SMS: {
      id: ConsentSentByEnum.SMS.id,
      key: "SMS",
      title: "SMS",
      mdiIconClass: "mdi-send",
    },
    Email: {
      id: ConsentSentByEnum.Email.id,
      key: "Email",
      title: "Email",
      mdiIconClass: "mdi-email",
    },
    WhatsApp: {
      id: ConsentSentByEnum.WhatsApp.id,
      key: "WhatsApp",
      title: "WhatsApp",
      mdiIconClass: "mdi-whatsapp",
    },
  };
import React, { useEffect, useState } from "react";
import { DialogComponent } from "../../../../Components";
import { Button, DialogActions, withStyles } from "@material-ui/core";
import './FailedToPassDialog.scss'
import { LeadPoolErrorType } from "../../../../Enums/LeadPoolErrorType.enum";

const ConfirmButton = withStyles(() => ({
  root: {
    color: "#5283dd",
    backgroundColor: "#",
    border: "1px solid #5283dd",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(Button);

function FailedToPassDialog({
  t,
  isOpen,
  onClose,
  translationPath,
  parentTranslationPath,
  checkData
}) {
  const errorEnum = Object.values(LeadPoolErrorType)
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const errorObject = errorEnum?.find((error) => error?.key === checkData?.leadPoolErrorType);
    setErrorMessage(errorObject?.message || null);
  }, [checkData, errorEnum]);

  return (
    <React.Fragment>
      <DialogComponent
        isOpen={isOpen}
        titleText={t("failed-to-pass-dialog-title")}
        maxWidth={"sm"}
        dialogContent={
          <React.Fragment>
            <div className="mt-3 mb-1">
              <p className="failed-check-message-text">{t(errorMessage)}</p>
              <DialogActions>
                <ConfirmButton className="dialog-action-btn" onClick={onClose}>
                  {t(errorMessage === "LeadPoolCheckCapacityError" ? "ok" : "Shared:confirm")}
                </ConfirmButton>
              </DialogActions>
            </div>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}

export default FailedToPassDialog;

import React, {
  useState, useCallback, useEffect, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonBase } from '@material-ui/core';
import moment from 'moment';
import { Button } from 'react-bootstrap/lib/InputGroup';
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  GlobalHistory,
  bottomBoxComponentUpdate,
  havePermissionToViewDetails,
  havePermissionToEditInformation,
  getSideMenuStatus,
  getSideMenuComponent,
  returnPropsByPermissions,
} from '../../../Helper';
import {
  ActionsButtonsComponent,
  ViewTypes,
  AutocompleteComponent,
  SelectComponet,
  Spinner,
  Inputs,
  PermissionsComponent,
} from '../../../Components';
import {
  ActionsEnum,
  ViewTypesEnum,
  TableActions,
  ActionsButtonsEnum,
  FormsIdsEnum,
  UnitsFilterStatusEnum,
  TableFilterTypesEnum,
  TableFilterOperatorsEnum,
  ArrayOFSearchableFormFields,
  ListingAgentIdsEnum,
  DateFilterTypesEnum,
  SearchCriteriaEnum,
} from '../../../Enums';
import {
  CardDetailsComponent,
  UnitsCardsComponent,
  UnitsSalesTableComponent,
} from './UnitsSalesUtilities';
import {
  UnitsAdvanceSearchTest,
  GetAllSearchableFormFieldsByFormId,
  GetAllFormFieldsByFormId,
  GetLookupItems,
  GetUnitsRefNumbers
} from '../../../Services';
import { AdvanceSearchSecondVersion } from '../../../SharedComponents/AdvanceSearchSecondVersion';
import { ActivitiesManagementDialog } from '../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { AdvanceSearch } from '../../../SharedComponents/AdvanceSearch/AdvanceSearch';
import { PaginationComponent } from '../../../Components/PaginationComponent/PaginationComponent';
import { UnitsImportDialog } from './UnitsSalesUtilities/Dialogs/UnitsImportDialog/UnitsImportDialog';
import { UnitMapper } from './UnitMapper/UnitMapper';
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { useTitle } from '../../../Hooks';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { GlobalAdvanceSearchActions } from '../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { LeadOwnerUnitSalePermissions } from '../../../Permissions';
import { getIsAllowedPermission } from '../../../Helper/Permissions.Helper';
import { UnitsSalesTableHeaderData } from './UnitsSalesUtilities/UnitsSalesTableComponent/UnitsSalesTableHeaderData';
import { UnitsVerticalTabsData } from '../Common/OpenFileView/OpenFileUtilities/OpenFileData/UnitsVerticalTabsData';
import { useScrollToElement } from '../../../Hooks/useScrollToElement';
import { StaticLookupsIds } from '../../../assets/json/StaticLookupsIds';

const parentTranslationPath = 'UnitsView';
const translationPath = '';
export const UnitsSaleLeadOwner = () => {
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: 1
  };
  const { t } = useTranslation(parentTranslationPath);
  const [isLoadingAllUnits, setIsLoadingAllUnits] = useState(false);
  const [selectFieldsOnAdvanceSearch, setSelectFieldsOnAdvanceSearch] = useState([]);
  const [filterValuesFromAdvanceSearch, setFilterValuesFromAdvanceSearch] = useState(null);
  const [SearchedItemUnitReferenceNumber, setSearchedItemUnitReferenceNumber] = useState("");
  const [displyOpenFileButton, setDisplyOpenFileButton] = useState(false);
  const [displyMatchingButton, setDisplyMatchingButton] = useState(false);
  const dispatch = useDispatch();
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const advanceSearch = useSelector((state) => state.GlobalAdvanceSearchReducer);
  const [unitsTableFilter, setUnitsTableFilter] = useState(advanceSearch && advanceSearch.UnitsSaleLeadOwnerFilter || null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllFormFieldsLoading, setIsAllFormFieldsLoading] = useState(false);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const [addActivity, setAddActivity] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [activeActionType, setActiveActionType] = useState(
    localStorage.getItem('ViewType') &&
    JSON.parse(localStorage.getItem('ViewType')).unitsLeadsOwnerView ||
    ViewTypesEnum.cards.key
  );

  const [isExpanded, setIsExpanded] = useState(
    activeActionType === ViewTypesEnum.cardsExpanded.key
  );
  const [, setOrderByToggler] = useState(false);
  const [activeSelectedAction, setActiveSelectedAction] = useState('');
  const [unitStatus, setUnitStatus] = useState(orderFilter.UnitsLeadOwnerStatus || [0]);
  const [unitsPortal, setUnitsPortal] = useState(orderFilter.PublishSaleLeadOwnerPortal || [0]);
  const [activeCard, setActiveCard] = useState(null);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isAdvanceSearchCleared, setIsAdvanceSearchCleared] = useState(false);
  const [PortalData, setPortalData] = useState(null);
  const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: orderFilter && orderFilter.UnitsSaleLeadOwnerFilter
      && orderFilter.UnitsSaleLeadOwnerFilter.filterBy || null,
    orderBy: orderFilter && orderFilter.UnitsSaleLeadOwnerFilter
      && orderFilter.UnitsSaleLeadOwnerFilter.orderBy || null,
  });
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy ? selectedOrderBy : { filterBy: 'createdOn', orderBy: 2 }
  );
  const [checkedDetailedCards, setCheckedDetailedCards] = useState([]);
  const [detailsUnitsList, setDetailsUnitsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));
  const { setHash } = useScrollToElement(!!detailsUnitsList.result.length);
  const [searchableFormFields, setSearchableFormFields] = useState([]);
  const [searchedItem, setSearchedItem] = useState('');
  const searchTimer = useRef(null);
  const [allFormFields, setAllFormFields] = useState([]);
  const [dateFilter, setDateFilter] = useState(
    (orderFilter && orderFilter.UnitsLeadOwnerDate && orderFilter.UnitsLeadOwnerDate.startDate && orderFilter.UnitsLeadOwnerDate.endDat !== null) &&
    (
      {
        startDate: new Date(orderFilter && orderFilter.UnitsLeadOwnerDate && orderFilter.UnitsLeadOwnerDate.startDate || null),
        endDate: new Date(orderFilter && orderFilter.UnitsLeadOwnerDate && orderFilter.UnitsLeadOwnerDate.endDate || null),
        key: new Date(orderFilter && orderFilter.UnitsLeadOwnerDate && orderFilter.UnitsLeadOwnerDate.key || null),
        selectedDateType: orderFilter && orderFilter.UnitsLeadOwnerDate && orderFilter.UnitsLeadOwnerDate.selectedDateType || 1
      }) || dateRangeDefault
  );
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [checkedCards, setCheckedCards] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: JSON.parse(localStorage.getItem("leadOwnerUnitsaleCurrentPageIndex")) || 0,
    search: '',
  });
  const [isFirst, setFirst] = useState(false);
  const [isFirst1, setFirst1] = useState(false);
  const [unitPublishStatus, setUnitPublishStatus] = useState(orderFilter.UnitLeadOwnerPublishStatusFilter || 0);
  useTitle(t(`${translationPath}units`));

  const searchHandler = (data) => {
    const newValue = [{
      key: 'Address',
      title: 'Address',
      value: data
    }];
    if (searchTimer.current) clearTimeout(searchTimer.current);
    if (data === '') {
      const index = searchData.findIndex((item) => item.key === 'Address');
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem('UnitsSaleLeadOwnerFilter');
    } else {
      searchTimer.current = setTimeout(() => {
        setSearchData([...newValue]);
        setFilterSearchDto({
          Address:
            [
              {
                value: data
              }
            ]
        });
      }, 1300);
    }
  };
  const onTypeChanged = useCallback(
    (activeType) => {
      const ViewTypeData = JSON.parse(localStorage.getItem('ViewType'));
      if (ViewTypeData) {
        ViewTypeData.unitsLeadsOwnerView = activeType;
        localStorage.setItem('ViewType', JSON.stringify(ViewTypeData));
      } else localStorage.setItem('ViewType', JSON.stringify({ unitsLeadsOwnerView: activeType }));

      setActiveActionType(activeType);
      setIsExpanded(activeType === ViewTypesEnum.cardsExpanded.key);
      sideMenuIsOpenUpdate(false);
      sideMenuComponentUpdate(null);
    },
    [setActiveActionType]
  );

  const onPageIndexChanged = (pageIndex) => {
    localStorage.setItem("leadOwnerUnitsaleCurrentPageIndex", JSON.stringify(pageIndex));
    setFilter((item) => ({ ...item, pageIndex }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };

  const restorePageIndex = useCallback(() => {
    const currentPageIndex = localStorage.getItem("leadOwnerUnitsaleCurrentPageIndex");

    if (currentPageIndex) {
      const pageIndex = JSON.parse(currentPageIndex);
      setFilter((item) => ({ ...item, pageIndex }));
    }
  }, []);

  useEffect(() => {
    restorePageIndex();
  }, []);

  useEffect(() => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitsLeadOwnerDate: dateFilter || dateRangeDefault
      })
    );
  }, [dateFilter]);

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const filterOnChange = (event, newValue) => {
    const emptyKeyIndex = newValue.findIndex((item) => !item.value);
    if (!searchInputValue && emptyKeyIndex !== -1) {
      newValue.splice(emptyKeyIndex, 1);
      return;
    }
    if (emptyKeyIndex !== -1) newValue[emptyKeyIndex].value = searchInputValue;
    if (
      filterSearchDto &&
      Object.keys(filterSearchDto).length > 0 &&
      newValue.length === 0
    ) {
      onPageIndexChanged(0);
      setFilterSearchDto(null);
    }
    if (!(newValue && newValue.length))
      localStorage.removeItem('UnitsSaleLeadOwnerFilter');
    setSearchData([...newValue]);
  };
  const getAllSearchableFormFieldsByFormId = useCallback(async () => {
    const result = await GetAllSearchableFormFieldsByFormId(
      FormsIdsEnum.units.id
    );
    if (
      !(result && result.data && result.data.ErrorId) &&
      result &&
      Array.isArray(result)
    ) {
      const res = result.filter((item) => item.isSearchable && item.formFieldId !== ListingAgentIdsEnum.RentListingAgent.formFieldId)
        .map((item) => ({
          key: item.searchableKey || item.displayPath,
          title: item.formFieldTitle,
        })).concat(ArrayOFSearchableFormFields);
 
      setSearchableFormFields([{ key: "Address", title: "Address" }, { key: "isFavorite", title: "Is Favorite" },  ...res]);
    } else setSearchableFormFields([]);
  }, []);

  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.units.id);
    if (!((result && result.data && result.data.ErrorId) || !result) && Array.isArray(result)) {
      const list =
        result.filter((e) =>
          e.formFieldId !== ListingAgentIdsEnum.RentListingAgent.formFieldId &&
          e.formFieldName !== 'amenities' &&
          e.formFieldName !== 'fitting_and_fixtures' &&
          e.formFieldName !== 'unit_model' &&
          e.formFieldName !== 'rent_price_fees' &&
          e.formFieldName !== 'selling_price_agency_fee' &&
          e.formFieldName !== 'primary_view' &&
          e.formFieldName !== 'secondary_view' &&
          e.formFieldName !== 'owner').map((field) => ({
            id: field.formFieldId || null,
            key: field.formFieldKey || null,
            isDate: field.uiWidgetType === 'alt-date' || false,
            label: (field.formFieldTitle === 'Lead Owner' ? field.formFieldTitle = 'Unit Owner' : field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
            input: field.displayPath || '',
            isNumber:
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'size',
            isSortable: true,
            searchableKey: field.searchableKey,
            data: JSON.parse(field.propertyJson).schema,
            fieldType:
              (field.propertyJson && JSON.parse(field.propertyJson).schema.uiType) ||
              (field.propertyJson && JSON.parse(field.propertyJson).uiSchema['ui:widget']) ||
              (field.uiWidgetType === null && 'text'),

          }));

      setAllFormFields(list);
    } else setAllFormFields([]);
    setIsAllFormFieldsLoading(false);
  }, []);
  const getLookupItem = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    const res = await GetLookupItems(1 , 25 ,'PortalName' );
    if (!(res && res.data && res.data.ErrorId)) 
    {
      setPortalData((res && res.result))
    } else setPortalData([]);
  }, []);

  useEffect(() => {
    getLookupItem();
  }, []);

  const GetSideMenuStatus = useCallback(() => {
    const isMenuOpen = getSideMenuStatus();
    const activeComponentOpen = getSideMenuComponent() && getSideMenuComponent().type;

    if (!isMenuOpen) setAdvanceSearchBtn(true);
    else if (activeComponentOpen === AdvanceSearch) setAdvanceSearchBtn(false);
  });

  const searchAdvanceSearch = (newFilter) => {
    const searchObj = newFilter ? { ...newFilter } : null;
    if (!(newFilter && newFilter.length))
      localStorage.removeItem('UnitsSaleLeadOwnerFilter');

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        UnitsSaleLeadOwnerFilter: searchObj || null,
      })
    );
    setUnitsTableFilter(searchObj);

  };

  const GetAllUnitsRef = useCallback(async (search) => {
    setSearchedItemUnitReferenceNumber(true);
    const res = await GetUnitsRefNumbers({
      unitRefNo: search,
      pageIndex: 1,
      pageSize: 10,
      operationType: StaticLookupsIds.Sales
    });
    if (!(res && res.status && res.status !== 200))
      setData(res.result || [] );
    else setData([] );
   setSearchedItemUnitReferenceNumber(false);
  }, []);

  const onAdvanceSearchClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();

    if (advanceSearchBtn) {
      sideMenuIsOpenUpdate(true);
      setAdvanceSearchBtn(false);
    } else {
      sideMenuIsOpenUpdate(false);
      setAdvanceSearchBtn(true);
    }

    sideMenuComponentUpdate(
      <AdvanceSearchSecondVersion
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onFilterValuesChanged={onFilterValuesChanged}
        localStorageKey='UnitsSaleLeadOwnerFilter'
        isAdvanceSearchCleared={isAdvanceSearchCleared}
        setIsAdvanceSearchCleared={setIsAdvanceSearchCleared}
        setOrderBy={setOrderBy}
        searchCriteriaTypeId={SearchCriteriaEnum.Unit.typeId}
        setIsAdvanceSearchActive={setIsAdvanceSearchActive}
        fields={[...UnitsSalesTableHeaderData, ...allFormFields]}
        selectFieldsOnAdvanceSearch={selectFieldsOnAdvanceSearch}
        setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
        setFilterValuesFromAdvanceSearch={setFilterValuesFromAdvanceSearch}
        filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
        searchAdvanceSearch={searchAdvanceSearch}
        unitType={'sale'}
      />

    );
  }, [isAllFormFieldsLoading, allFormFields, advanceSearchBtn, isAdvanceSearchCleared, selectFieldsOnAdvanceSearch]);

  const onClearedAllFiltersClick = useCallback(() => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: 'createdOn', orderBy: 2 });
    setSearchData([]);
    setDateFilter(dateRangeDefault);
    setUnitPublishStatus(0);
    setUnitStatus([]);
    setUnitsPortal([0]);
    setIsClearFiltersClicked(true);
    setIsAdvanceSearchCleared(true);
    setUnitsTableFilter(null);
    setFilterSearchDto(null);
    sideMenuIsOpenUpdate(false);
    setIsAdvanceSearchActive(false);

    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitsLeadOwnerStatus: [],
        PublishSaleLeadOwnerPortal: [],
        UnitLeadOwnerPublishStatusFilter: 0,
        UnitsSaleLeadOwnerFilter: {
          filterBy: null,
          orderBy: null,
        },
      })
    );

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        UnitsSaleLeadOwnerFilter: null
      })
    );
  }, []);

  const SearchHandlerComponent = useCallback((key, title, data, delay) => {
    const newValue = [{ key, title, value: data }];
    if (searchTimer.current) clearTimeout(searchTimer.current);

    if (data === "") {
      const index = searchData.findIndex((item) => item.key === key);
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem("UnitSaleFilter");
    } else {
      searchTimer.current = setTimeout(() => {
        setSearchData([...newValue]);
        setFilterSearchDto({ [key]: [{ value: data }] });
      }, delay);
    }
  }, [searchData]);


  const getUnitsData = useCallback(
    async (f) => {
      setIsLoadingAllUnits(true);
      const index = unitStatus.findIndex((el) => el === 0);
      if (index !== -1) unitStatus.splice(index, 1);
      if (unitStatus.length === 0) unitStatus.push(0);

      const indexPortal = unitsPortal.findIndex((el) => el === 0);
      if (indexPortal !== -1) unitsPortal.splice(indexPortal, 1);
      if (unitsPortal.length === 0) unitsPortal.push(0);

      const localFilterDto = f || filterSearchDto || {};

      if (unitStatus && unitStatus.length >= 1 && unitStatus[0] !== 0) {
        localFilterDto.status = unitStatus.map((item) => ({
          searchType: TableFilterOperatorsEnum.equal.key,
          value: (item || '').toLowerCase(),
        }));
      }

      if (unitsPortal && unitsPortal.length >= 1 && unitsPortal[0] !== 0) {
        localFilterDto.PortalId = [{ searchType: TableFilterOperatorsEnum.equal.key, value: `${unitsPortal}` }]
      }

      if (unitPublishStatus) {
        if (unitPublishStatus === 1) {
          localFilterDto.publish_status = [{
            searchType: TableFilterOperatorsEnum.equal.key,
            value: true
          }];
        } else if (unitPublishStatus === 2) {
          localFilterDto.publish_status = [{
            searchType: TableFilterOperatorsEnum.equal.key,
            value: false
          }];
        }
      }

      if (unitsTableFilter) {
        Object.values(unitsTableFilter)
          .filter((item) => item.searchableKey || item.displayPath)
          .map((item) => {
            if (localFilterDto[item.searchableKey || item.displayPath]) {
              localFilterDto[item.searchableKey || item.displayPath].push({
                searchType: item.operator,
                value: item.value,
              });
            } else if (item.value) {
              localFilterDto[item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: item.value,
                },
              ];
            } else if (!item.value && (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
              item.operator === TableFilterOperatorsEnum.isBlank.key)) {
              localFilterDto[item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: null,
                },
              ];
            }
            return undefined;
          });
      }

      if (Object.values(localFilterDto).length) setIsAdvanceSearchActive(true);
      else setIsAdvanceSearchActive(false);
      const isLeadAuditorAdmin = loginResponse.roles.findIndex((role) => role.rolesName === 'admin');

      const body = {
        criteria: localFilterDto,
        ...orderBy,
        OperationType: 'Sale',
        isLeadAuditor: true,
        isAdminLeadAuditor: isLeadAuditorAdmin !== -1
      };

      setIsDatePickerChanged(false);

      if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
        const fromDate = moment(dateFilter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const toDate = moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss');

        if (dateFilter.selectedDateType === DateFilterTypesEnum.CreatedOnDate.key) {
          body.fromDate = fromDate;
          body.toDate = toDate;
        } else if (dateFilter.selectedDateType === DateFilterTypesEnum.UpdatedOnDate.key) {
          body.updatedFromDate = fromDate;
          body.updatedToDate = toDate;
        } else if (dateFilter.selectedDateType === DateFilterTypesEnum.ListingDate.key) {
          body.fromListingDate = fromDate;
          body.toListingDate = toDate;
        } else if (dateFilter.selectedDateType === DateFilterTypesEnum.PublishDate.key) {
          body.fromPublishDate = fromDate;
          body.toPublishDate = toDate;
        } else if (dateFilter.selectedDateType === DateFilterTypesEnum.ExpiredListingDate.key) {
          body.fromExpiryListingDate = fromDate;
          body.toExpiryListingDate = toDate;
        }
      }

      const res = await UnitsAdvanceSearchTest(filter, body);
      if (!(res && res.status && res.status !== 200) && res && res.result) {
        setDetailsUnitsList({
          result: ((res && res.result) || []).map((item) => item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit)),
          totalCount: (res && res.totalCount) || 0,
        });
        setIsLoadingAllUnits(false);
      } else {
        setDetailsUnitsList({
          result: [],
          totalCount: 0,
        });
        setIsLoadingAllUnits(false);
      }
    },
    [dateFilter, filter, filterSearchDto, orderBy, unitStatus, unitsPortal, unitPublishStatus, unitsTableFilter]
  );
  const searchClicked = async () => {
    localStorage.setItem('UnitsSaleLeadOwnerFilter', JSON.stringify(searchData));
    setFilterSearchDto(
      searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType:
                TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
    );
    onPageIndexChanged(0);
  };

  const searchchachedClickedWithoutFilter = async (data) => {
    if (data.length === 0) return;
    const oldfilter = data.reduce((total, item) => {
      if (total[item.key]) {
        total[item.key].push({
          searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
          value: item.value,
        });
      } else {
        total[item.key] = [
          {
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          },
        ];
      }
      return total;
    }, {});
    getUnitsData(oldfilter);
  };
  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (actionEnum === TableActions.openFile.key) {
        GlobalHistory.push(
          `/home/lead-owner-units-sale/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}&operationType=${item.operationType}`
        );
      }
      else if (actionEnum === TableActions.addActivity.key) {
        setAddActivity(true);
        setActiveItem(item);
      }
    },
    [dispatch]
  );
  const displayedLabel = (option) => `${option.title}: ${searchInputValue}`;
  const disabledOptions = (option) => option.disabledOnSelect;
  const chipsLabel = (option) => `${option.title}: ${option.value}`;
  const inputValueChanged = (event, newInputValue) => {
    setSearchInputValue(newInputValue);
  };
  const onActionButtonChanged = (activeAction) => {
    setActiveSelectedAction(activeAction);
    setCheckedCards([]);
  };
  const onActionsButtonClicked = useCallback((activeAction) => {
    if (activeAction === ActionsButtonsEnum[3].id) setIsOpenImportDialog(true);
    if (activeAction === ActionsButtonsEnum[1].id)
      GlobalHistory.push('/home/lead-owner-units-sale/add?formType=1');
    if (activeAction === ActionsButtonsEnum[4].id)
      GlobalHistory.push('/home/lead-owner-units-sale/unit-bulk-update');
  }, []);
  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      setHash(activeData.id);
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      event.stopPropagation();
      if (actionEnum === ActionsEnum.folder.key) {
        GlobalHistory.push(
          `/home/lead-owner-units-sale/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}&operationType=${activeData.operationType}`
        );
      } else if (actionEnum === ActionsEnum.matching.key) {
        GlobalHistory.push(
          `/home/lead-owner-units-sale/unit-profile-edit?formType=${activeData.unitTypeId
          }&id=${activeData.id}&operationType=${activeData.operationType
          }&matching=${true}`
        );
      }
    },
    [dispatch]
  );
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitsSaleLeadOwnerFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setOrderByToggler(false);
  };
  const cardCheckboxClicked = useCallback((itemIndex, element) => {
    setCheckedDetailedCards((items) => {
      const index = items.findIndex((item) => item === itemIndex);
      if (index !== -1) items.splice(index, 1);
      else items.push(itemIndex);
      return [...items];
    });
    setCheckedCards((items) => {
      const index = items.findIndex((item) => item.id === element.id);
      if (index !== -1) items.splice(index, 1);
      else items.push(element);
      return [...items];
    });
  }, []);
  useEffect(() => {
    localStorage.setItem('bulk-assign-ids', JSON.stringify(checkedCards));
  }, [checkedCards]);
  const reloadData = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  }, [getUnitsData]);
  const onCardClick = useCallback(
    (item, selectedIndex) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveCard(item);
      if (getSideMenuStatus()) setAdvanceSearchBtn(true);
      dispatch(ActiveItemActions.activeItemRequest(item));
      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={detailsUnitsList.result[selectedIndex]}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          reloadData={reloadData}
          displyOpenFileButton={displyOpenFileButton}
          displyMatchingButton={displyMatchingButton}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailedCardSideActionClicked, detailsUnitsList.result, reloadData, advanceSearchBtn]
  );

  const changePublishStatus = (value) => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitLeadOwnerPublishStatusFilter: value
      })
    );
  };

  const checkBulkDesabled = (enums) => {
    if (enums === ActionsButtonsEnum[4].id)
      return !(checkedCards && checkedCards.length >= 2);

    return false;
  };

  const onSelectClicked = useCallback(
    (row, rowIndex) => {
      setCheckedDetailedCards((items) => {
        const index = items.findIndex((item) => item === rowIndex);
        if (index !== -1) items.splice(index, 1);
        else items.push(rowIndex);
        return [...items];
      });

      setCheckedCards((items) => {
        const index = items.findIndex((item) => item.id === row.id);
        if (index !== -1) items.splice(index, 1);
        else items.push(row);
        return [...items];
      });
    },
    [checkedCards]
  );

  const getIsSelected = useCallback(
    (row) => checkedCards && checkedCards.findIndex((item) => item.id === row.id) !== -1,
    [checkedCards]
  );
  const focusedRowChanged = useCallback(
    (rowIndex) => {
      if (rowIndex !== -1) {
        sideMenuComponentUpdate(
          <CardDetailsComponent
            activeData={detailsUnitsList.result[rowIndex]}
            cardDetailsActionClicked={detailedCardSideActionClicked}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            reloadData={reloadData}
            getUnitsData={getUnitsData}
            displyOpenFileButton={displyOpenFileButton}
            displyMatchingButton={displyMatchingButton}
          />
        );
        sideMenuIsOpenUpdate(true);
      } else {
        sideMenuComponentUpdate(<></>);
        sideMenuIsOpenUpdate(false);
      }
    },
    [detailedCardSideActionClicked, detailsUnitsList.result, reloadData]
  );

  const onFilterValuesChanged = (newValue) => {
    setUnitsTableFilter(newValue);
  };

  useEffect(() => {
    getAllSearchableFormFieldsByFormId();
  }, [getAllSearchableFormFieldsByFormId]);
  useEffect(() => {
    if (returnPropsByPermissions(LeadOwnerUnitSalePermissions.ViewAndSearchInSaleUnits.permissionsId)) {
      const currentPageIndex = localStorage.getItem("leadOwnerUnitsaleCurrentPageIndex");
      const pageIndex = JSON.parse(currentPageIndex);
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={pageIndex || filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={detailsUnitsList.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  const changeActiveUnitStatus = (value) => {
    setUnitStatus(value);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitsLeadOwnerStatus: value
      })
    );
  };

  const changePublishPortal = (value) => {
    if (value[0] === 0) value.shift();
    setUnitsPortal(value);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        PublishSaleLeadOwnerPortal: value
      })
    );
  };

  useEffect(() => {
    const data = localStorage.getItem('UnitsSaleLeadOwnerFilter');
    if (data) {
      const leadOwnerSearchFilter = JSON.parse(data);
      setSearchData(leadOwnerSearchFilter);
      if (leadOwnerSearchFilter && leadOwnerSearchFilter.length)
        searchchachedClickedWithoutFilter(leadOwnerSearchFilter);
    } else getUnitsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirst1) {
      if (searchData && searchData.length === 0) {
        localStorage.removeItem('UnitsSaleLeadOwnerFilter');
        setSearchedItem('');
      } else
        localStorage.setItem('UnitsSaleLeadOwnerFilter', JSON.stringify(searchData));
    } else setFirst1(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  useEffect(() => {
    if (!isFirst)
      setFirst(true);
    else {
      const data = localStorage.getItem('UnitsSaleLeadOwnerFilter');
      if (data) {
        const dataConvert = JSON.parse(data);
        if (dataConvert && dataConvert.length)
          searchchachedClickedWithoutFilter(dataConvert);
      } else if (searchData && searchData.length === 0)
        getUnitsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterSearchDto,
    filter,
    unitStatus,
    unitsPortal,
    unitPublishStatus,
    orderBy,
    unitsTableFilter,
    searchData
  ]);

  useEffect(() => {
    if (isFirst && isDatePickerChanged) {

      const searchDataFilter = searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
      getUnitsData(searchDataFilter);
    }
  }, [dateFilter, isDatePickerChanged, searchData]);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    GetSideMenuStatus();
  }, [GetSideMenuStatus]);

  useEffect(() => {
    setDisplyOpenFileButton(havePermissionToViewDetails(UnitsVerticalTabsData.unitSaleLeadOwner));
    setDisplyMatchingButton(havePermissionToEditInformation(LeadOwnerUnitSalePermissions.ViewUnitMatching.permissionsId));
  }, [isFirst]);

  return (
    <div className='view-wrapper sale-view'>
      <Spinner isActive={isLoadingAllUnits} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section'>
              <ActionsButtonsComponent
                permissionsList={Object.values(LeadOwnerUnitSalePermissions)}
                addPermissionsId={
                  LeadOwnerUnitSalePermissions.AddNewUnit.permissionsId
                }
                selectPermissionsId={[
                  LeadOwnerUnitSalePermissions.ImportUnits.permissionsId,
                  LeadOwnerUnitSalePermissions.UnitBulkUpdate.permissionsId,
                  LeadOwnerUnitSalePermissions.AddNewUnit.permissionsId
                ]}
                checkDisable={checkBulkDesabled}
                onActionsButtonClicked={onActionsButtonClicked}
                onActionButtonChanged={onActionButtonChanged}
                enableBulk={getIsAllowedPermission(
                  Object.values(LeadOwnerUnitSalePermissions),
                  loginResponse,
                  LeadOwnerUnitSalePermissions.UnitBulkUpdate.permissionsId
                )}
                enableImport={getIsAllowedPermission(
                  Object.values(LeadOwnerUnitSalePermissions),
                  loginResponse,
                  LeadOwnerUnitSalePermissions.ImportUnits.permissionsId
                )}
              />

            </div>
            <PermissionsComponent
              permissionsList={Object.values(LeadOwnerUnitSalePermissions)}
              permissionsId={
                LeadOwnerUnitSalePermissions.ViewAndSearchInSaleUnits.permissionsId
              }
            >
              <div className='section autocomplete-section'>
                <div className='d-flex-column px-2 w-100 p-relative'>
                  <div className='d-flex-column w-100'>
                    <AutocompleteComponent
                      data={searchableFormFields.map((item) => ({
                        key: item.key,
                        title: item.title,
                      }))}
                      selectedValues={searchData}
                      parentTranslationPath={parentTranslationPath}
                      displayLabel={displayedLabel}
                      disabledOptions={disabledOptions}
                      onChange={filterOnChange}
                      searchClicked={searchClicked}
                      chipsLabel={chipsLabel}
                      getOptionSelected={(option) =>
                        searchData.findIndex(
                          (item) =>
                            item.key === option.key &&
                            item.value === searchInputValue
                        ) !== -1}
                      tagValues={searchData}
                      inputValue={searchInputValue}
                      onInputChange={inputValueChanged}
                      inputLabel='filter'
                      inputPlaceholder='search-units'
                    />
                  </div>
                  <div className='d-inline-flex pl-5-reversed'>
                    <div className='mt-2 mr-1-reversed'>
                      <SelectComponet
                        data={Object.values(UnitsFilterStatusEnum.sale)}
                        emptyItem={{
                          value: 0,
                          text: 'select-status',
                          isDisabled: true,
                          isHiddenOnOpen: true,
                        }}
                        value={unitStatus}
                        valueInput='value'
                        textInput='title'
                        multiple
                        onSelectChanged={changeActiveUnitStatus}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        idRef='Unit_Status'
                        startAdornment={(((unitStatus.findIndex((item) => item === 0)) === -1) &&
                          (<Button title='Remove Filter' className='mdi mdi-filter-off mr-4 c-warning' onClick={() => changeActiveUnitStatus([])} />)) || ''}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>

                    <div className='mt-2 mr-1-reversed'>
                    <SelectComponet
                        idRef='Unit_portal'
                        data={PortalData}
                        emptyItem={{
                          value: 0,
                          text: 'publish-portal',
                          isDisabled: true,
                          isHiddenOnOpen: true,
                        }}
                        value={unitsPortal}
                        valueInput='lookupItemId'
                        textInput='lookupItemName'
                        multiple
                        onSelectChanged={changePublishPortal}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        startAdornment={(((unitsPortal.findIndex((item) => item === 0)) === -1) &&
                          (<Button title='Remove Filter' className='mdi mdi-filter-off mr-4 c-warning' onClick={() => changePublishPortal([0])} />)) || ''}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>

                    <div className='mt-2 mr-1-reversed'>
                      <SelectComponet
                        data={[
                          { key: 1, name: 'published' },
                          { key: 2, name: 'unpublished' },
                        ]}
                        emptyItem={{ value: 0, text: 'select-unit-publish', isDisabled: false }}
                        value={unitPublishStatus || 0}
                        valueInput='key'
                        textInput='name'
                        onSelectChanged={(value) => {
                          changePublishStatus(value);
                          setUnitPublishStatus(value);
                        }}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        idRef='Select_Unit_Publish'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                    <div className='w-100 mt-2  '>
                      <Inputs
                        value={searchedItem}
                        onKeyUp={(e) => searchHandler(e.target.value)}
                        idRef='activitiesSearchRef'
                        labelClasses='mt-4'
                        onInputChanged={(e) => setSearchedItem(e.target.value)}
                        inputPlaceholder={t(`${translationPath}address-filter`)}
                      />
                    </div>

                    <div className='w-100 customDatePicker'>
                      <div className='date-type-select'>
                        <SelectComponet
                          data={[
                            { key: 1, value: `${translationPath}created-date` },
                            { key: 2, value: `${translationPath}update-on-date` },
                            { key: 4, value: `${translationPath}listing-date` },
                            { key: 5, value: `${translationPath}publish-date` },
                            { key: 6, value: `${translationPath}expired-listing-date` },
                          ]}
                          defaultValue={{ key: 1, value: `${translationPath}created-date` }}
                          value={dateFilter.selectedDateType || 1}
                          valueInput='key'
                          textInput='value'
                          isDisabled={isLoading}
                          onSelectChanged={(value) => {
                            setIsDatePickerChanged(true);
                            setDateFilter((f) => ({ ...f, selectedDateType: value }));
                          }}
                          wrapperClasses='w-auto'
                          themeClass='theme-transparent'
                          idRef='Date_Select'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <DateRangePickerComponent
                        onClearClicked={() => {
                          setDateFilter(dateRangeDefault);
                          setIsDatePickerChanged(true);
                        }}
                        onDialogClose={() => {
                          setIsDatePickerChanged(true);
                        }}
                        ranges={[dateFilter]}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(selectedDate) =>
                          setDateFilter((item) => ({
                            ...item,
                            startDate: selectedDate.selection && selectedDate.selection.startDate,
                            endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                            key: 'selection',
                          }))}
                          />
                          </div>
                        </div>
                        <div className="d-flex pl-5-reversed ">
                            <div className="mt-2 mr-1-reversed">
                            <AutocompleteComponent
                          inputPlaceholder={t(`${translationPath}Unit-Reference-Number`)}
                          data={data}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          withoutSearchButton
                          isLoading={SearchedItemUnitReferenceNumber}
                          displayLabel={(option) => option?.unitRefNo || ""}
                          onInputKeyUp={(event) => {
                            const { value } = event.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              GetAllUnitsRef(value);
                            }, 700);
                          }}
                          chipsLabel={(option) => option?.unitRefNo || ""}
                          multiple={false}
                          onChange={(_, newValue) => {
                            if (newValue) {
                              SearchHandlerComponent("unit_ref_no", "Unit Ref No.", (newValue && newValue.unitRefNo) || "", 1300) 
                            } else {
                              const index = searchData.findIndex(
                                (item) => item.key === "unit_ref_no"
                              );
                              if (index !== -1) searchData.splice(index, 1);
                              setFilterSearchDto(null);
                              localStorage.removeItem("UnitsSaleLeadOwnerFilter");
                              getUnitsData();
                            }
                          }}
                        />
                            </div>
                          </div>
                      </div>
                      <div className='view-search-wrapper'>
                  <ViewTypes onTypeChanged={onTypeChanged} initialActiveType={activeActionType} className='mb-3' />
                  <span className='advance-search'>
                    <ButtonBase
                      onClick={onAdvanceSearchClick}
                      id='AdvanceSearch_bbt'
                      disabled={isAllFormFieldsLoading}
                      className={`btns theme-solid ${isAdvanceSearchActive && !isAllFormFieldsLoading && 'is-active-filter'}`}
                    >
                      <span className={`${advanceSearchBtn ? 'mdi mdi-arrow-expand-left' : 'mdi mdi-arrow-expand-right'} mr-1`} />
                      {t(`${translationPath}advance-search`)}
                    </ButtonBase>
                  </span>
                  <ButtonBase
                    onClick={onClearedAllFiltersClick}
                    id='onClearedAllFiltersref'
                    disabled={isAllFormFieldsLoading}
                    className='btns theme-solid bg-danger clear-all-btn'
                  >
                    <span className='mdi mdi-filter-remove m-1' />
                    {t(`${translationPath}clear-filters`)}

                  </ButtonBase>
                </div>
              </div>
            </PermissionsComponent>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(LeadOwnerUnitSalePermissions)}
            permissionsId={
              LeadOwnerUnitSalePermissions.ViewAndSearchInSaleUnits.permissionsId
            }
          >
            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`${translationPath}units`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}
                  :
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='filterByRef'
                    data={[
                      { id: 'createdOn', filterBy: 'created-on' },
                      { id: 'updateOn', filterBy: 'last-updated' },
                      { id: 'LastUpdatedOwner', filterBy: 'last-updated-owner' },
                    ]}
                    value={selectedOrderBy.filterBy}
                    onSelectChanged={filterByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='filterBy'
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    emptyItem={{
                      value: null,
                      text: 'select-sort-by',
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    className='btns theme-solid'
                    id='action_apply'
                    onClick={orderBySubmitted}
                    disabled={
                      !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                    }
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>
        <PermissionsComponent
          permissionsList={Object.values(LeadOwnerUnitSalePermissions)}
          permissionsId={
            LeadOwnerUnitSalePermissions.ViewAndSearchInSaleUnits.permissionsId
          }
        >
          {activeActionType !== ViewTypesEnum.tableView.key && (
            <>
              <div className='body-section'>
                <UnitsCardsComponent
                  data={detailsUnitsList}
                  isExpanded={isExpanded}
                  onCardClicked={onCardClick}
                  onFooterActionsClicked={detailedCardSideActionClicked}
                  checkedDetailedCards={checkedDetailedCards}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onCardCheckboxClick={cardCheckboxClicked}
                  activeCard={activeCard}
                  withCheckbox={activeSelectedAction === 'bulk-update'}
                  displyOpenFileButton={displyOpenFileButton}
                  displyMatchingButton={displyMatchingButton}
                />
              </div>
            </>
          )}

          {activeActionType === ViewTypesEnum.tableView.key && (

            <UnitsSalesTableComponent
              detailsUnitsList={detailsUnitsList}
              tableActionClicked={tableActionClicked}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              unitsTableFilter={unitsTableFilter}
              onFilterValuesChanged={onFilterValuesChanged}
              filter={filter}
              parentTranslationPath={parentTranslationPath}
              focusedRowChanged={focusedRowChanged}
              onSelectClicked={onSelectClicked}
              setCheckedCards={setCheckedCards}
              checkedCardsIds={checkedCards}
              getIsSelected={getIsSelected}
              activeSelectedAction={activeSelectedAction}
              setOrderBy={setOrderBy}
              isClearFiltersClicked={isClearFiltersClicked}
              setIsClearFiltersClicked={setIsClearFiltersClicked}
              displyOpenFileButton={displyOpenFileButton}
              displyMatchingButton={displyMatchingButton}
              localStorageKey='UnitsSaleLeadOwnerFilter'
            />
          )}
        </PermissionsComponent>
      </div>
      <UnitsImportDialog
        isOpen={isOpenImportDialog}
        isOpenChanged={() => setIsOpenImportDialog(false)}
      />
      {
        addActivity && (
          <ActivitiesManagementDialog
            open={addActivity}
            onSave={() => {
              setAddActivity(false);
            }}
            close={() => {
              setAddActivity(false);

            }}
            actionItemId={activeItem?.id}
            translationPath={''}
            parentTranslationPath={'ActivitiesView'}
          />
        )
      }
    </div>
  );
};

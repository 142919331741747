import React, { useEffect, useState, useCallback } from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Tables, Spinner, PermissionsComponent } from '../../../Components';
import { GetAllExternalLinks } from '../../../Services';
import { TableActions } from '../../../Enums';
import { useTitle } from '../../../Hooks';

import { AdminExternalLinksPermissions } from '../../../Permissions';
import { returnPropsByPermissions } from '../../../Helper';
import { PermissionDeniedLayout } from '../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout';

import { ExternalLinksManangementDialog, DeleteDialog } from './Dialogs';

export const AdminExternalLinksView = () => {
    const parentTranslationPath = 'ExternalLinksView';
    const translationPath = '';
    const { t } = useTranslation(parentTranslationPath);
    useTitle(t(`${translationPath}admin-external-links`));

    const expression = /\bhttps?:\/\/(?:(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)(?:\/[+~%\/.\w_-]*\??(?:[-+=&;%@.\w_]*)#?[.!\/\\\w]*)?/g;
    const urlRegex = new RegExp(expression);
    const [allExternalLinks, setAllExternalLinks] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [havePermissionsToDisplayData, setHavePermissionsToDisplayData] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [filter, setFilter] = useState({
        pageIndex: 1,
        pageSize: 25,
    });

    const addNewHandler = () => {
        setIsDialogOpen(true);
    };

    const getAllExternalLinks = async () => {
        setIsLoading(true);
        if (returnPropsByPermissions(AdminExternalLinksPermissions.ViewExternalLinks.permissionsId)) {
            setHavePermissionsToDisplayData('allowed');

            const isAdmin = true;
            const body = {
                ...filter,
                orderBy: 1
            };
            const response = await GetAllExternalLinks(body, isAdmin);
            if (!(response && response.status && response.status !== 200))
                setAllExternalLinks(response || {});
            else
                setAllExternalLinks({});
        } else
            setHavePermissionsToDisplayData('notAllowed');

        setIsLoading(false);
    };

    const tableActionClicked = useCallback((actionEnum, item, focusedRow, event) => {
        event.stopPropagation();
        event.preventDefault();
        if (actionEnum === TableActions.deleteText.key) {
            setIsDeleteDialogOpen(true);
            setActiveItem(item);
        } else if (actionEnum === TableActions.editText.key) {
            setIsDialogOpen(true);
            setActiveItem(item);
        }
    }, []);

    const reloadData = () => {
        getAllExternalLinks();
    };

    const getDefaultActionsWithPermissions = () => {
        const list = [];
        if (returnPropsByPermissions(AdminExternalLinksPermissions.EditExternalLink.permissionsId)) {
            list.push({
                enum: TableActions.editText.key,
                isDisabled: false,
                externalComponent: null,
            });
        }
        if (returnPropsByPermissions(AdminExternalLinksPermissions.DeleteExternalLink.permissionsId)) {
            list.push({
                enum: TableActions.deleteText.key,
                isDisabled: false,
                externalComponent: null,
            });
        }
        return list;
    };

    useEffect(() => {
        getAllExternalLinks();
    }, []);

    return (
      <div className='m-3'>
        <Spinner isActive={isLoading} />
        <div className='filter-section my-3'>
          <div className='mx-3'>
            <PermissionsComponent
              permissionsList={Object.values(AdminExternalLinksPermissions)}
              permissionsId={AdminExternalLinksPermissions.AddNewExternalLink.permissionsId}
            >

              <ButtonBase className='btns theme-solid px-3' onClick={addNewHandler}>
                <span className='mdi mdi-plus' />
                {t(`${translationPath}add-new`)}
              </ButtonBase>

            </PermissionsComponent>
          </div>
        </div>

        {havePermissionsToDisplayData === 'notAllowed' && (<PermissionDeniedLayout />)}
        {
                havePermissionsToDisplayData === 'allowed' && (
                <Tables
                  data={allExternalLinks.result || []}
                  headerData={[
                            {
                                id: 1,
                                label: 'Id',
                                input: 'externalLinkId',
                            },
                            {
                                id: 2,
                                label: 'Name',
                                input: 'externalLinkName',
                            },
                            {
                                id: 3,
                                label: 'URL',
                                input: 'url',
                                component: (item) =>
                                (
                                  <span>
                                    {item && item.url && item.url.match(urlRegex) ? (
                                      <a
                                        href={item.url}
                                        target='_blank'
                                        rel='noreferrer'
                                      >
                                        {item.url}
                                        {' '}
                                      </a>
                                        ) : (
                                            item && item.url
                                        )}
                                  </span>
                                )
                            },
                            {
                                id: 4,
                                label: 'Description',
                                input: 'description',
                            },
                        ]}
                        // onPageIndexChanged={onPageIndexChanged}
                        // onPageSizeChanged={onPageSizeChanged}
                  defaultActions={getDefaultActionsWithPermissions()}
                  itemsPerPage={filter.pageSize}
                  activePage={filter.pageIndex}
                  actionsOptions={{
                            onActionClicked: tableActionClicked,
                        }}
                  parentTranslationPath={parentTranslationPath}
                  totalItems={allExternalLinks ? allExternalLinks.totalCount : 0}
                />
                )
            }

        {isDialogOpen && (
        <ExternalLinksManangementDialog
          isDialogOpen={isDialogOpen}
          activeItem={activeItem}
          onSave={() => {
                        reloadData();
                        setIsDialogOpen(false);
                        setActiveItem(null);
                    }}
          onClose={() => {
                        setActiveItem(null);
                        setIsDialogOpen(false);
                    }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
            )}
        {isDeleteDialogOpen && (
        <DeleteDialog
          activeItem={activeItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          reloadData={reloadData}
          onCloseClicked={() => {
                        setIsDeleteDialogOpen(false);
                        setActiveItem(null);
                    }}
          onCancelClicked={() => {
                        setIsDeleteDialogOpen(false);
                        setActiveItem(null);
                    }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
            )}
      </div>
    );
};

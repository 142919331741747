/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';
import { ButtonBase, Tooltip } from '@material-ui/core';
import {
  CheckboxesComponent,
  LoadableImageComponant,
  ProgressComponet,
 PopoverComponent
} from '../../../../../Components';
import {
  AMLStatusEnum,
  ActionsEnum, ContactTypeEnum, LoadableImageEnum
} from '../../../../../Enums';
import { getDownloadableLink } from '../../../../../Helper';
import { formatCommasForPriceUnit } from '../../../../../Helper/formatCommas.Helper';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';

export const UnitsCardsComponent = ({
  data,
  activeCard,
  isExpanded,
  onCardClicked,
  onFooterActionsClicked,
  parentTranslationPath,
  translationPath,
  notExpandedMax,
  withCheckbox,
  checkedDetailedCards,
  onCardCheckboxClick,
  selectedDetailsUnitItem,
  displyOpenFileButton,
  displyEditButton,
  displyMatchingButton,
  handleRemoveFavorite,
  folderIdValue,
}) => {
  const { t } = useTranslation([parentTranslationPath]);
  const [buttonWidth, setButtonWidth] = useState('w-50');
  const [portalBtn, setPortalBtn] = useState(null);
  const [itemIndex, setItemIndex] = useState(-1);

  const handlePopoverOpen = (event, item, indx) => {
    setItemIndex(indx);
    setPortalBtn(event.currentTarget);
  };

  const handleClose = () => {
    setPortalBtn(null);
  };
  const getDefaultUnitImage = useCallback(
    (unitType) => ContactTypeEnum[unitType] && ContactTypeEnum[unitType].defaultImg,
    []
  );
  const getIsSelectedCard = useCallback(
    (itemIndex) => checkedDetailedCards.findIndex((item) => item === itemIndex) !== -1,
    [checkedDetailedCards]
  );
  useEffect(() => {
    if (displyOpenFileButton && displyMatchingButton)
      setButtonWidth('w-50');
    else if (displyOpenFileButton && !displyMatchingButton)
      setButtonWidth('w-100');
    else if (!displyOpenFileButton && displyMatchingButton)
      setButtonWidth('w-100');
    else if (!displyOpenFileButton && !displyMatchingButton)
      setButtonWidth('w-100');
  }, [displyOpenFileButton, displyEditButton, displyMatchingButton]);

  return (
    <div className='units-cards-wrapper childs-wrapper'>
      {data.result &&
        data.result.map((item, index) => (
          <div
            className={`units-card-wrapper${isExpanded ? ' is-expanded' : ''}${(((activeCard && activeCard.id === item && item.id) ||
              (selectedDetailsUnitItem && selectedDetailsUnitItem.id === item.id)) &&
              ' is-open') ||
              ''
              }`}
            key={`unitsCardItemRef${index + 1}`}
            id={item.id}
          >

            {withCheckbox && (
              <div className='card-checkbox-wrapper'>
                <CheckboxesComponent
                  idRef={`unitsCheckboxItemRef${index + 1}`}
                  singleChecked={getIsSelectedCard(index)}
                  onSelectedCheckboxClicked={(event) => {
                    event.preventDefault();
                    onCardCheckboxClick(index, item);
                  }}
                />
              </div>
            )}

            <div className={`ribbon-wrapper my-2 ${withCheckbox && 'top-ribbon'}`}>
              {item && item.isPublishUnitLease && (
                <Tooltip title={(item.lastSalePublishDate && moment(item.lastSalePublishDate).format('DD/MM/YYYY HH:mm A')) || `${t('Shared:not-available')}`}>
                  <div className='mb-1 ribbon  green '>Published</div>
                </Tooltip>
              )}

              {item && item.isBulkUpload && (
                <div className='mb-1 ribbon with-bulk '>Bulk Upload</div>
              )}
              {item && item.portals && (
                <>
                  <ButtonBase
                    onMouseOver={(event) => handlePopoverOpen(event, item, index)}
                    className='mb-1 ribbon portals-ribbon '
                  >
                    Web Portal
                  </ButtonBase>

                  {index === itemIndex && (
                    <PopoverComponent
                      idRef='tableFilterOperatorPopRef'
                      handleClose={handleClose}
                      attachedWith={portalBtn}
                      popoverClasses='table-filter-operator-popover units-portals-popOver'
                      component={item.portals.map((el) => (
                        <ButtonBase
                          key={`portalRibbonRef${index + 1}${item.unitId}`}
                        >
                          <span className={`my-2 logos ${el.PortalName}`} />
                          {el.PortalName}
                        </ButtonBase>
                      ))}
                    />
                  )}
                </>
              )}

{item && item.isFavorite && (
                          <div
                            className={
                              folderIdValue
                                ? "is-Fav-heart lead"
                                : "is-Fav-heart lead"
                            }
                            onClick={() => handleRemoveFavorite(item)}
                          >
                            <span className="mdi mdi-heart"></span>
                          </div>
                        )}
            </div>

            {item && (
              <div className='container-wrapper'>
                <a onClick={onCardClicked && onCardClicked(item, index)} className='cards-wrapper'>
                  <div className='cards-body-wrapper'>
                    <div className='card-body-section'>
                      <div className='body-item-wrapper'>
                        <div className={`flex-wrapper ${!isExpanded ? 'w-100' : ''}`}>
                          <div className='body-images-wrapper'>
                            <div className='body-image-item-wrapper'>
                              <div className='body-image-item'>
                                <LoadableImageComponant
                                  classes='cover-image'
                                  type={LoadableImageEnum.div.key}
                                  alt={t(`${translationPath}unit-image`)}
                                  src={
                                    (item && item.allunitImages && getDownloadableLink(item.allunitImages.fileId , 200,true)) ||
                                    item && getDefaultUnitImage(item.type)
                                  }
                                />
                              </div>
                            </div>
                            <div className='body-image-item-wrapper'>
                              <div className='body-title-wrapper'>
                                <span className='body-title'>
                                  {item.name}
                                </span>
                                <div
                                className={`body-status ${(item.unitStatus && item.unitStatus.classes) || 'bg-warning'}`}
                              >
                                <div className='body-status-type'>
                                  {t(`${translationPath}status`)}
                                </div>
                                {' : '}
                                {(item.unitStatus &&
                                  item.unitStatus.value &&
                                  t(
                                    `${translationPath}${item.unitStatus.value === 'sale' ?
                                      'sold' :
                                      item.unitStatus.value
                                    }`
                                  )) ||
                                  'N/A'}
                              </div>
                              </div>
                            </div>
                          </div>
                          {isExpanded && item && item.details && (
                            <div className='card-body-section'>
                              {item && item.details.map((subItem) =>
                              (
                                <div
                                  key={`unitsCardItemRef${+1}`}
                                  className='details-item-wrapper'
                                >
                                  {/* Add your icon rendering here if needed */}
                                  <div className='details-item'>
                                    <div className='wr-fl d-flex'>
                                      <div className='details-text '>
                                        {t(`${translationPath}${subItem && subItem.title || ''}`)}
                                        :
                                        {'  '}
                                      </div>
                                      <div className='details-text '>
                                        {t(`${translationPath}${subItem && subItem.value || ''}`)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className='w-100 d-flex-h-between'>

                          <div className='price-wrapper'>
                            <div className={`for-lable ${item.unitOperationType}`}>
                              {t(`${translationPath}for`)}
                            </div>
                            <div className={item.unitOperationType}>
                              {`  ${item.unitOperationType}`}
                              :
                            </div>
                            <div className='unit-price'>
                              {(item.rent_price_fees && item.rent_price_fees !== 'N/A') ? `${formatCommasForPriceUnit(item.rent_price_fees)} AED` : 'N/A'}
                            </div>
                          </div>
                          <div className='contact-id-wrapper'>
                            {t(`${translationPath}ref-#`)}
                            :
                            <Tooltip title={t(`${translationPath}copy`)}>
                              <CopyToClipboardComponents
                                data={item.refNo}
                                childrenData={item.refNo}
                              />
                            </Tooltip>
                          </div>
                        </div>
                        <div className='flat-contents-wrapper'>
                          {item.flatContent
                            .filter(
                              (filterItem, filterIndex) =>
                                (!isExpanded && filterIndex < notExpandedMax) || isExpanded
                            )
                            .map((subItem, subIndex) => (
                              <div
                                key={`flatContentsItemRef${subIndex + 1}`}
                                className='flat-content-item'
                              >
                                <span className={`flat-content-icon ${subItem.iconClasses} mdi-18px`} />
                                <span className='px-1'>{subItem.value}</span>
                                <span>
                                  {subItem.title && (
                                    <span className='flat-content-text'>
                                      {t(`${translationPath}${subItem.title}`)}
                                    </span>
                                  )}
                                </span>
                              </div>
                            ))}
                        </div>
                        <div className='id-date-wrapper pl-2 pr-2'>
                          <div className='created-on'>
                            <span className='details-icon mdi mdi-calendar mdi-16px' />
                            <span>
                              <span className='details-text'>
                                {t(`${translationPath}created`)}
                                :
                              </span>
                              <span className='px-1'>
                                {(item.createdOn &&
                                  moment(item.createdOn)
                                    .locale(i18next.language)
                                    .format('DD/MM/YYYY')) ||
                                  'N/A'}
                              </span>
                            </span>
                          </div>
                          {item.lease_rating && <span className={`card-tooltip card-badge ${item.lease_rating}`}>{item.lease_rating}</span>}
                          
                        </div>
                        {/* <div className='body-item d-flex-v-center mb-1'>
                      <span className='card-tooltip bg-secondary-light c-primary'>
                        <span>{t(`${translationPath}match-with`)}</span>
                        <span className='px-1'>10</span>
                        <span>{t(`${translationPath}buyers`)}</span>
                      </span>
                      <ButtonBase className='btns fw-simi-bold'>
                        <span>{t(`${translationPath}view`)}</span>
                        <span className='px-1 mdi mdi-chevron-right' />
                      </ButtonBase>
                    </div> */}
                      </div>
                    </div>
                    <div className='flex-section'>
                      <div className={`card-body-section${isExpanded ? ' is-expanded' : ''}`}>
                        {item.details
                          .filter(
                            (filterItem, filterIndex) =>
                              (!isExpanded && filterIndex < notExpandedMax) || isExpanded
                          )
                          .map(
                            (subItem, subIndex) =>
                              subIndex < notExpandedMax && (
                                <div
                                  key={`unitsCardItemRef${subIndex + 1}`}
                                  className='details-item-wrapper'
                                >
                                  <span className='details-item'>
                                    <span className='details-text'>
                                      {t(`${translationPath}${subItem.title}`)}
                                      :
                                    </span>
                                    <span className='px-1' title={subItem.value}>
                                      {subItem.value}
                                    </span>
                                  </span>
                                </div>
                              )
                          )}
                      </div>
                    </div>
                  </div>
                  {/* </div>
              } */}
                  <div className='cards-progress-wrapper'>
                    <ProgressComponet
                      value={item.progress}
                      progressText={`${item.progress}%`}
                      themeClasses='theme-gradient'
                    />
                  </div>
                  {
                    (displyOpenFileButton || displyMatchingButton) &&
                    (
                      <div className='cards-footer-wrapper'>

                        {displyOpenFileButton && (
                          <ButtonBase
                            className={`btns theme-transparent mx-0  ${buttonWidth === 'w-50' && item.matchUnit !== 0 ? 'maxWidth_50' : 'maxWidth_100'}`}
                            onClick={onFooterActionsClicked(ActionsEnum.folder.key, item, index)}
                            id='ActionsClickedfolder'
                          >
                            <span className='mdi mdi-folder-outline' />
                            <span className='px-1'>{t('open-file')}</span>
                          </ButtonBase>
                        )}
                        {
                          displyMatchingButton && item.matchUnit !== 0 && (
                            <ButtonBase
                              disabled={item.matchUnit === 0}
                              className={`btns theme-transparent mx-0  ${buttonWidth === 'w-50' ? 'maxWidth_50' : 'maxWidth_100'}`}
                              onClick={onFooterActionsClicked(ActionsEnum.matching.key, item, index)}
                            >
                              <span className={ActionsEnum.matching.icon} />
                              <span className='px-1'>{item.matchUnit}</span>
                              <span>{t(ActionsEnum.matching.label)}</span>
                            </ButtonBase>
                          )
                        }
                        {/* {
                        displyEditButton && (
                          <ButtonBase
                           // className={`btns theme-transparent mx-0 ${buttonWidth}`}
                            className={`btns theme-transparent ${buttonWidth ==='w-30' ? 'maxWidth_30' : (buttonWidth === 'w-50'? 'maxWidth_50' :'maxWidth_100' ) }`}
                            onClick={onFooterActionsClicked(ActionsEnum.reportEdit.key, item, index)}
                          >
                            <span className='mdi mdi-pencil-outline' />
                            <span className='px-1'>{t('edit')}</span>
                            <Tooltip title={moment(item.updateDate).format('DD/MM/YYYY HH:mm A') || 'N/A'}>
                              <span className='edit-power-icon mdi mdi-clock-time-four-outline c-warning pr-2 pl-2' />
                            </Tooltip>
                          </ButtonBase>
                        )
                      } */}

                      </div>
                    )
                  }

                </a>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

UnitsCardsComponent.propTypes = {
  data: PropTypes.shape({ result: PropTypes.instanceOf(Array), totalCount: PropTypes.number })
    .isRequired,
  activeCard: PropTypes.instanceOf(Object),
  selectedDetailsUnitItem: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool.isRequired,
  withCheckbox: PropTypes.bool.isRequired,
  onCardClicked: PropTypes.func.isRequired,
  onFooterActionsClicked: PropTypes.func.isRequired,
  onCardCheckboxClick: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  notExpandedMax: PropTypes.number,
  checkedDetailedCards: PropTypes.arrayOf(PropTypes.number).isRequired,
  folderIdValue: PropTypes.number,
handleRemoveFavorite: PropTypes.func,
};
UnitsCardsComponent.defaultProps = {
  notExpandedMax: 4,
  selectedDetailsUnitItem: undefined,
  activeCard: undefined,
  handleRemoveFavorite: () => {},
  folderIdValue: undefined,
};


 
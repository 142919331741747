import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    infoItemWrapper: {
      display: "flex",
      gap: "16px",
    },
    label: {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    value: {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 600,
    }
  }
});

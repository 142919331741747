import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import './SellerInfoStyle.scss';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { GlobalHistory } from '../../../../../../Helper';
import { GetSensitiveFieldsForUser } from '../../../../../../Services';
import { AgentTypesEnum, FormsIdsEnum, LeadOwnerDataKeys } from '../../../../../../Enums';
import { ActiveItemActions } from '../../../../../../store/ActiveItem/ActiveItemActions';

export const SellerInfo = ({ parentTranslationPath, leadOwnerData, unitDetails }) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const dispatch = useDispatch();
  const [sensitiveFieldsForUser, setSensitiveFieldsForUser] = useState(null);
  const [leadSensitiveFields, setLeadSensitiveFields] = useState(null);
  const [contactSensitiveFields, setContactSensitiveFields] = useState(null);
  const [dataWithSensitiveFields, setDataWithSensitiveFields] = useState(null);

  const getSensitiveFieldsForUser = useCallback(async (formType, formId , sensitiveFieldsForUserObj)  => {
    const response = await GetSensitiveFieldsForUser(formId  ,sensitiveFieldsForUserObj);

    if (!(response && response.status && response.status !== 200)) {
      if (formType === "Contact") setContactSensitiveFields(response);
      if (formType === "Lead") setLeadSensitiveFields(response);
    } else {
      if (formType === "Contact") setContactSensitiveFields(response);
      if (formType === "Lead") setLeadSensitiveFields(response);
    }
  }, []);

  const checkIsUnitOwnerSensitive = () => {
    return unitDetails.unit?.lead_owner?.name === "********";
  }

  const checkIsFieldSensitive = (fieldKey) => {
    const isKeyFound = sensitiveFieldsForUser.find(item => fieldKey === item.key)
    const isFieldSensitive = isKeyFound ? true : false;
    return isFieldSensitive;
  }

  const HandleSensitiveFields = () => {

    if (checkIsUnitOwnerSensitive()) {
      const newLeadOwnerData = {};

      LeadOwnerDataKeys[leadOwnerData.contactsTypeId].forEach(item => {
        const isFieldSensitive = checkIsFieldSensitive(item.fieldkey)
        if (isFieldSensitive) {
          newLeadOwnerData[item.name] = '********';
        } else {
          newLeadOwnerData[item.name] = leadOwnerData[item.name];
        }
      });
      setDataWithSensitiveFields(newLeadOwnerData);
    } else setDataWithSensitiveFields(leadOwnerData);

  }


  const moveToContact = () => {
    if (leadOwnerData) {
      dispatch(ActiveItemActions.activeItemRequest({ name: leadOwnerData.contactName, id: leadOwnerData.contactId }));
      GlobalHistory.push(
        `/home/Contacts-CRM/contact-profile-edit?formType=${leadOwnerData.contactsTypeId}&id=${leadOwnerData.contactId}`
      );
    }
  };

  const moveToLead = () => {
    if (leadOwnerData)
      dispatch(ActiveItemActions.activeItemRequest({ id: leadOwnerData.leadId, name: leadOwnerData.contactName }));
    const leadClass = leadOwnerData && leadOwnerData.leadClass && (leadOwnerData.leadClass === 'Seller' || leadOwnerData.leadClass === 'Landlord') ? 1 : 2;
    GlobalHistory.push(
      `/home/lead-sales/lead-profile-edit?formType=${leadClass}&id=${leadOwnerData.leadId}&leadClass=${leadOwnerData.leadClass}`
    );
  };

  useEffect(() => {
    const leadFormType = "Lead";
    const isSeekerLead = leadOwnerData.leadClass === AgentTypesEnum.Buyer.value;
    const leadFormId = isSeekerLead ? FormsIdsEnum.leadsSeeker.id : FormsIdsEnum.leadsOwner.id;

    if (leadFormId) getSensitiveFieldsForUser(leadFormType, leadFormId);


    const contactFormType = "Contact";
    const contactFormId = leadOwnerData.contactsTypeId;

    if (contactFormId) getSensitiveFieldsForUser(contactFormType, contactFormId , leadOwnerData ? {key : 'contact'  , id : leadOwnerData.contactId   } : null);
  }, [leadOwnerData]);


  useEffect(() => {
    if (contactSensitiveFields && leadSensitiveFields)
      setSensitiveFieldsForUser([...contactSensitiveFields, ...leadSensitiveFields]);
  }, [contactSensitiveFields, leadSensitiveFields]);

  useEffect(() => {
    if (leadOwnerData && sensitiveFieldsForUser && unitDetails) HandleSensitiveFields();
  }, [leadOwnerData, sensitiveFieldsForUser, unitDetails]);



  return (
    <>{sensitiveFieldsForUser && <div className='sellerInfo'>
      <div className='card'>
        <div>
          <span className='titleSection'>
            {t('sellerInformation')}
          </span>
        </div>
        <div className='sellerInfoContant'>
          <div className='sectionItem'>
            <span className='mdi mdi-account-tie  mr-1' />
            <span className='headtitle mr-1'>{`${t('lead-name')} : `}</span>
            {' '}
            {dataWithSensitiveFields && dataWithSensitiveFields.contactName ? (
              <a href='# ' onClick={() => moveToContact()}>
                <span className='navbar-item' activeClassName='is-active'>
                  {(dataWithSensitiveFields && dataWithSensitiveFields.contactName)}
                </span>
              </a>
            ) : 'N/A'}

          </div>

          <div className='sectionItem'>
            <span className='mdi mdi-clipboard-account mr-1' />
            <span className='headtitle mr-1'>{`${t('lead-ID')} : `}</span>
            {' '}
            {dataWithSensitiveFields && dataWithSensitiveFields.leadId ? (
              <a href='# ' onClick={() => moveToLead()}>
                <span className='navbar-item' activeClassName='is-active'>
                  {(dataWithSensitiveFields && dataWithSensitiveFields.leadId)}
                  {' '}
                </span>
              </a>
            ) : 'N/A'}
          </div>

          <div className='sectionItem'>
            <span className='mdi mdi-phone  mr-1' />
            <span className='headtitle mr-1'>{`${t('lead-phone')} : `}</span>
            {' '}
            {(dataWithSensitiveFields && dataWithSensitiveFields.phone) || 'N/A'}
          </div>
          <div className='sectionItem'>
            <span className='mdi mdi-email-outline  mr-1'> </span>
            <span className='headtitle mr-1'>{`${t('lead-email')} : `}</span>
            {' '}
            {(dataWithSensitiveFields && dataWithSensitiveFields.email) || 'N/A'}
          </div>

        </div>

      </div>
    </div>}
    </>
  );
};

SellerInfo.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  leadOwnerData: PropTypes.instanceOf(Object).isRequired,
};

import { ContactPolicies   } from  './ContactPolicies';
import { PropertiesPolicies } from './PropertiesPolicies' ; 
import { UnitsPolicies } from './UnitsPolicies' ; 
import { LeadsPolicies } from './LeadsPolicies' ; 
import { ActivitiesPolicies }  from './ActivitiesPolicies' ; 
import {  Agents } from './AgentsPolicies' ; 

export const PoliciesTabs = [
    {   
     label: 'Contacts',
     component: ContactPolicies  ,
    } , 
    {   
        label: 'Properties',
       component: PropertiesPolicies  ,
    } ,
    {   
        label: 'Units',
        component: UnitsPolicies  ,
    } ,
    {   
        label: 'Leads',
        component: LeadsPolicies  ,
    } ,
    {   
         label: 'Activities',
         component: ActivitiesPolicies  ,
    } ,
    {   
        label: 'Agents',
        component: Agents  ,
   } ,

]
  


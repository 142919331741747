
import React, { useState , useCallback  , useEffect  , useReducer }  from 'react';
import { useTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import { PageHeading } from "../../../../../SharedComponents";
import { Spinner } from '../../../../../Components' ; 
import { showError  , showSuccess } from '../../../../../Helper' ;
import { GetParkingDistributionScoresAPI , UpdateParkDistributionScoresAPI   } from '../../../../../Services' ;  
import { ParkingDistributionContent   } from './ParkingDistributionContent' ; 

  export const ParkingDistributionView = ({parentTranslationPath , translationPath  , ratingPercentage} ) => { 
    const { t } = useTranslation(parentTranslationPath);

    const[isLoading ,setIsLoading] = useState(false);
    const [defaultSelected , setDefaultSelected ] = useState(
      {
        basementCarPark  : null   , 
        podiumParking :  null , 
        coveredParking :  null , 
        valetParking : null ,  
        standardParkingDistributionperUnits  : null  ,
        visitorsParkingAvailability : []
      });

    const [selectedValues, setSelectValues] = useState(defaultSelected);
    const [parkingDistributionRatingPercentage ,setParkingDistributionRatingPercentage]  = useState((ratingPercentage && ratingPercentage.find((f)=> f.propertyRatingTabName === 'Parking distribution')?.score) || 0) ;
    const reducer = (state, action) => {
        if (action.id !== 'edit') return { ...state, [action.id]: action.value };
        if (action.id === 'edit') {
          return {
            ...action.value,
          };
        }
        return undefined;
      };

      const [state, setState] = useReducer(reducer, {
        listOfParkingDistributionLookupInfo : []
      });

      const getSelectedValues = (values , key , type ) => 
        {
          if(key)
          {
            const fieldIndex = values.findIndex((f) => f.lookupItemName?.toLowerCase()?.includes(key.toLowerCase()));
            if(fieldIndex !== -1)
              return values[fieldIndex] ;  
          }
          else if(type && type === 'VisitorsParkingAvailability') 
            return values.filter((f)=> f.type === 'VisitorsParkingAvailability') ;  

          return null ;
        };

    const GetAllParkingDistributionScores= useCallback(async () => 
    {
        setIsLoading(true);
        const res = await GetParkingDistributionScoresAPI();
        if (!((res && res.data && res.data.ErrorId) || !res))
        {
          setState({ id: 'edit', value:(res)}); 
          const listOfParkingDistribution = (res?.listOfParkingDistributionLookupInfo)|| [] ; 
          if(listOfParkingDistribution)
          setSelectValues((item)=> ({
            ...item , 
            basementCarPark  : getSelectedValues(listOfParkingDistribution, 'Basement CarPark')   , 
            podiumParking :  getSelectedValues(listOfParkingDistribution, 'Podium Parking') , 
            coveredParking :  getSelectedValues(listOfParkingDistribution ,'Covered Parking') , 
            valetParking : getSelectedValues(listOfParkingDistribution, 'valet Parking') ,  
            standardParkingDistributionperUnits  : getSelectedValues(listOfParkingDistribution ,'Standard Parking Distribution per Units' )  ,
            visitorsParkingAvailability : getSelectedValues(listOfParkingDistribution ,null, 'VisitorsParkingAvailability'  )
          }));

        }
        else {
          setState({ id: 'edit', value: {listOfParkingDistributionLookupInfo : []}});
          setSelectValues(defaultSelected) ; 
         }
         setIsLoading(false);
      });

    const onChangeSelectedItem = (key , value ) => 
    {
          const newSelectedList = [...state.listOfParkingDistributionLookupInfo] ;
          const fieldIndex = newSelectedList.findIndex((f) => f.lookupItemName.toLowerCase()?.includes(key.toLowerCase()));
          if(fieldIndex !== -1)
            newSelectedList[fieldIndex].score = value ; 
          setState({ id: 'edit', value: { listOfParkingDistributionLookupInfo : newSelectedList }});
     
      };

    const reloadData = () => 
      {
            GetAllParkingDistributionScores(); 
     
      };
      
    const saveHandler = async () => {
      setIsLoading(true);
      const res = await UpdateParkDistributionScoresAPI(state); 
      if (!((res && res.status && res.status !== 200) || !res)) {
        showSuccess(t(`${translationPath}parking-distribution-scores-updated-successfully`));
          reloadData();
      }
      else  showError(t(`${translationPath}parking-distribution-scores-updated-failed`));
       
      setIsLoading(false) ;
  };
  
 
    useEffect(() => {
      reloadData();
      if(ratingPercentage)
        {
          const rating = ratingPercentage.find((f) => f.propertyRatingTabName === "Parking distribution");
          setParkingDistributionRatingPercentage((rating && rating.score) || 0 ) ; 
        }
    }, [ratingPercentage]);

    return (
      <div className="w-100 mt-4">
        <Spinner isActive={isLoading} isAbsolute />
        <div className="d-flex-v-center-h-between">
          <PageHeading
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            headerTitle={"parking-distribution"}
            wrapperClasses={"px-4 pt-2 w-75"}
            headerTitleClasses={"fz-30"}
            subTitleClasses={"fz-12 m-0"}
            subTitle={''}
            titleWithPercentage={`${parkingDistributionRatingPercentage}%`}
          />
          
          <div className="w-25 px-4 d-flex-center fj-end">
          <Button
              className="btns theme-propx outlined"
              id="btn-cancel"
              onClick={() => {
                reloadData()
              }}
            >
              {t(`${translationPath}cancel`)}
            </Button>
            <Button
              className="btns theme-propx solid"
              id="btn-save"
              onClick={() => saveHandler()}
            >
              {t(`${translationPath}save-changes`)}
            </Button>
           
          </div>
         </div>
        <div className="property-rating-container">
          <div className='parking-distribution-view mt-2'>
            <ParkingDistributionContent
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChangeSelectedItem={onChangeSelectedItem}
                selectedValues={selectedValues}
                setSelectValues={setSelectValues}
              /> 
             <hr className='hr-line' />
          </div>   
        </div>
      </div>
    );
  } ; 
  
import React, {  useRef } from "react";
import { useTranslation } from "react-i18next";
import { Inputs } from "../../../../Components";

export const BathroomsFilter = ({
  resaleChangeFilters,
  parentTranslationPath,
  translationPath,
  selected , 
  setSelected ,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  return (
    <div className="w-50 px-2">
      <fieldset className="styled-fieldset d-flex-column-v-center">
        <legend className="styled-legend">
          {t(`${translationPath}bathrooms`)}
        </legend>
        <div className="w-100 m-1 px-2">
          <Inputs
            idRef="bathroomsFromRef"
            labelValue={t(`${translationPath}from`)}
            type={"number"}
            withNumberFormat
            value={(selected?.bathrooms?.from) || ''}
            onKeyUp={(e) => {
              const { value } = e.target;
              let bathrooms = null ; 
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => 
              {
                const bathroomsNoFromKey = "bathrooms_from";
                const bathroomsNoFromValue = (value || value == 0)? value.replace(/,/g, '') : null;
                const searchType = 3;
                resaleChangeFilters(
                  bathroomsNoFromKey,
                  bathroomsNoFromValue,
                  false ,
                  searchType
                );
                bathrooms = { from : bathroomsNoFromValue , to :(selected?.bathrooms?.to) };
                setSelected({id :'bathrooms'  , value : bathrooms });

               
              }, 600);
            }}
            min={0}
          />
        </div>
        <div className="w-100 m-1 px-2">
          <Inputs
            idRef="bathroomsToRef"
            labelValue={t(`${translationPath}to`)}
            type={"number"}
            withNumberFormat
            value={(selected?.bathrooms?.to) || ''}
            onKeyUp={(e) => {
              const { value } = e.target;
              let bathrooms = null ; 
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                const bathroomsNoToKey = "bathrooms_to";
                const bathroomsNoToValue = (value || value == 0)? value.replace(/,/g, '') : null;
                const searchType = 4;
                resaleChangeFilters(
                bathroomsNoToKey,
                bathroomsNoToValue,
                  false ,
                  searchType
                );
                bathrooms= { from : (selected?.bathrooms?.from) , to :bathroomsNoToValue };
                setSelected({id :'bathrooms'  , value : bathrooms })


              }, 600);
            }}
            min={0}
          />
        </div>
      </fieldset>
    </div>
  );
};

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  filtersContainer: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
  quickFilters: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "24px",
  },
  fieldWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
  }
}));

import React, {
  useCallback,
  useReducer,
  useState,
  useRef,
} from "react";
import PropTypes from "prop-types";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import {
  AutocompleteComponent,
  Inputs,
  Spinner,
} from "../../../../../../../Components";
import { showError, showSuccess } from "../../../../../../../Helper";
import { LeadsStatusEnum } from "../../../../../../../Enums";
import {
  ShareScopeDocuments,
  OrganizationUserSearch,
  GetLeads,
} from "../../../../../../../Services";

export const ShareDocumentsDialog = ({
  shareViaExternalAPI,
  activeItem,
  theme,
  onSave,
  open,
  close,
  contactId,
  unitId 
}) => {
  const parentTranslationPath = "UnitsProfileManagementView";
  const translationPath = "";
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const searchTimer = useRef(null);
  const [isLoadingSpinner, setisLoadingSpinner] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [selected, setSelected] = useReducer(reducer, {
    emailSubject: null,
    emailBody: null,
    leads: [],
    systemUsers: [],
  });

  const [data, setData] = useReducer(reducer, {
    leads: [],
    systemUsers: [],
  });

  const getLeadsWithMissingEmails = () => {
    const leadList = selected.leads? 
    selected.leads.filter( item => !item.contactEmailAddress ) : [];

    if(leadList.length != 0){
      const leadNamesList = leadList.map(item => item.lead?.contact_name?.name );
      const leadNamesString = leadNamesList.reduce((result, item)=> (result + item + ' , ') , "");
      return leadNamesString;
    } 
  }
  const getUsersWithMissingEmails = () => {
    const usersList = selected.systemUsers? 
    selected.systemUsers.filter( item => !item.email ) : [];

    if(usersList.length != 0){
      const userNamesList = usersList.map(item => item.fullName );
      const userNamesString = userNamesList.reduce((result, item)=> (result + item + ' , ') , "");
      return userNamesString;
    } 
  }


  const schema = Joi.object({
    emailSubject: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}emailSubject-is-required`),
        "string.empty": t(`${translationPath}emailSubject-is-required`),
      }),
    emailBody: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}emailBody-is-required`),
        "string.empty": t(`${translationPath}emailBody-is-required`),
      }),
    leads: Joi.any()
      .custom((value, helpers) => {
        if (selected.leads?.length === 0 && selected.systemUsers?.length === 0)
          return helpers.error("state.required");

        if (getLeadsWithMissingEmails())
          return helpers.error("contacts-With-Missing-Emails-error");

        return value;
      })
      .messages({
        "state.required": t(`${translationPath}recipient-is-required-one-at-least-(Leads)`),
        'contacts-With-Missing-Emails-error': `There Are Leads With Missing Email Addresses`,
      }),
    systemUsers: Joi.any()
      .custom((value, helpers) => {
        if (selected.leads?.length === 0 && selected.systemUsers?.length === 0)
          return helpers.error("state.required");

        if (getUsersWithMissingEmails())
          return helpers.error("users-With-Missing-Emails-error");
          
        return value;
      })
      .messages({
        "state.required": t(`${translationPath}recipient-is-required-one-at-least-(Users)`),
        'users-With-Missing-Emails-error': `There Are Users With Missing Email Addresses`,
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);

  const saveHandler = async (event) => {
    event.preventDefault();

    if (schema?.error?.message) {
      showError(t(`${translationPath}${schema.error.message}`));
      return;
    }

    const body = getShareDocsAPIBody();

    if(shareViaExternalAPI) onSave(body);
      else shareScopeDocuments(body);
  };

  const getSystemUsers = async (value) => {
    const res = await OrganizationUserSearch({
      ...filter,
      name: value || "",
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({
          id: "systemUsers",
          value: localValue,
        });
      } else {
        setData({
          id: "systemUsers",
          value: [],
        });
      }
    }
  };

  const getLeads = async (value) => {
    const response = await GetLeads({
      ...filter,
      search: value || "",
      leadStatus: LeadsStatusEnum.Open.status,
    });
    if (!(response && response.status && response.status !== 200))
      setData({ id: "leads", value: (response && response.result) || [] });
    else setData({ id: "leads", value: [] });
  };

  const getShareDocsAPIBody = () => {
    const mappedSystemUsers = selected.systemUsers && selected.systemUsers.map(item => item?.id) || [];
    const mappedLeads = selected.leads && selected.leads.map(item => item?.leadId) || [];
  
    const mappedFiles = activeItem && activeItem.documentName && activeItem.documentId ? [{
      fileName: activeItem.documentName,
      uuid: activeItem.documentId,
    }] : [];
  
    return {
      systemUsersId: mappedSystemUsers,
      leadsId: mappedLeads,
      files: mappedFiles,
      emailSubject: selected.emailSubject,
      emailBody: selected.emailBody,
      categoryId: activeItem?.categoryId,
      contactId,
      unitId
    };
  };

  const shareScopeDocuments = useCallback(async (body) => {
    setisLoadingSpinner(true);

    const res = await ShareScopeDocuments(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}document-sent-successfully`);
      if (onSave) onSave();
    } else showError(t`${translationPath}sending-document-failed`);

    setisLoadingSpinner(false);
  });

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          close();
        }}
        className="Parking-management-dialog-wrapper"
      >
        <Spinner isActive={isLoadingSpinner} isAbsolute />
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id="alert-dialog-slide-title">
            {t(`${translationPath}share-document`)}
          </DialogTitle>
          <DialogContent>
            <div className="dialog-content-wrapper">
              <div className="dialog-content-item-parking  d-flex mx-2">
                <Inputs
                  idRef="emailSubjectNoRef"
                  labelValue="emailSubject"
                  value={selected.emailSubject || ""}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    setSelected({
                      id: "emailSubject",
                      value: event.target.value,
                    });
                  }}
                />
              </div>
              <div className="dialog-content-item  d-flex mx-2">
                <Inputs
                  idRef="emailBodyRef"
                  labelValue="email-Body"
                  value={selected.emailBody || ""}
                  multiline
                  rows={4}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    setSelected({ id: "emailBody", value: event.target.value });
                  }}
                />
              </div>

              <fieldset className="styled-fieldset d-flex mx-3">
                <legend className="styled-legend">
                  {t(`${translationPath}send-to`)}
                </legend>
                <div className="dialog-content-item d-flex-column-center">
                  <AutocompleteComponent
                    idRef="System-usersRef"
                    inputPlaceholder={t(`${translationPath}select`)}
                    labelValue={t(`${translationPath}System-users`)}
                    selectedValues={selected.systemUsers || []}
                    wrapperClasses="mr-2 my-2"
                    data={data.systemUsers || []}
                    chipsLabel={(option) => option.fullName || ""}
                    withoutSearchButton
                    multiple
                    displayLabel={(option) => option.fullName || ""}
                    renderOption={(option) =>
                      ((option.userName || option.fullName) &&
                        `${option.fullName} (${option.userName})`) ||
                      ""
                    }
                    onChange={(event, newValue) => {
                      setSelected({
                        id: "systemUsers",
                        value: newValue,
                      });
                    }}
                    onOpen={() => {
                      if (data.systemUsers && data.systemUsers.length == 0)
                        getSystemUsers();
                    }}
                    onInputKeyUp={(e) => {
                      const { value } = e.target;
                      if (searchTimer) clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        getSystemUsers(value);
                      }, 1200);
                    }}
                    onKeyDown={() => {
                      setSelected({
                        id: "systemUsers",
                        value: null,
                      });
                    }}
                  />
                  <AutocompleteComponent
                    idRef="leadsRef"
                    inputPlaceholder={t(`${translationPath}select`)}
                    labelValue={t(`${translationPath}leads`)}
                    selectedValues={selected.leads || []}
                    wrapperClasses="mr-2 my-2"
                    data={data.leads || []}
                    chipsLabel={(option) =>
                      option?.lead?.contact_name?.name || ""
                    }
                    withoutSearchButton
                    multiple
                    displayLabel={(option) =>
                      option?.lead?.contact_name?.name || ""
                    }
                    renderOption={(option) =>
                      ((option.userName || option?.lead?.contact_name?.name) &&
                        `${option?.lead?.contact_name?.name}`) ||
                      ""
                    }
                    onChange={(event, newValue) => {
                      setSelected({
                        id: "leads",
                        value: newValue,
                      });
                    }}
                    onOpen={() => {
                      if (data.leads && data.leads.length == 0) getLeads();
                    }}
                    onInputKeyUp={(e) => {
                      const { value } = e.target;
                      if (searchTimer) clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        getLeads(value);
                      }, 1200);
                    }}
                    onKeyDown={() => {
                      setSelected({
                        id: "leads",
                        value: null,
                      });
                    }}
                  />
                </div>
              </fieldset>
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonBase
              onClick={() => close()}
              className={`btns ${theme=="primary"? "theme-solid bg-cancel" :  "theme-propx outlined"}`}
            >
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase className={`btns ${theme=="primary"? "theme-solid" :  "theme-propx solid"}`} 
             type="submit">
              {t(`${translationPath}send-email`)}
            </ButtonBase>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
ShareDocumentsDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isedit: PropTypes.bool.isRequired,
  shareViaExternalAPI: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['primary', 'propx']),
};
ShareDocumentsDialog.defaultProps = {
  activeItem: null,
  shareViaExternalAPI: false,
  theme: "primary",
};

import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Inputs } from "../../../../Components";

export const BedroomsFilter = ({
  resaleChangeFilters,
  parentTranslationPath,
  translationPath,
  selected , 
  setSelected ,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  return (
    <div className="w-50 px-2">
      <fieldset className="styled-fieldset d-flex-column-v-center">
        <legend className="styled-legend">
          {t(`${translationPath}bedrooms`)}
        </legend>
        <div className="w-100 m-1 px-2">
          <Inputs
            idRef="bedroomsFromRef"
            labelValue={t(`${translationPath}from`)}
            type={"number"}
            withNumberFormat
            value={(selected?.bedrooms?.from) || ''}
            onKeyUp={(e) => {
              const { value } = e.target;
              let bedrooms = null ; 

              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                const bedroomsNoFromKey = "bedrooms_from";
                const bedroomsNoFromValue = (value || value == 0)? value.replace(/,/g, '') : null;
                const searchType = 3;
                resaleChangeFilters(
                  bedroomsNoFromKey,
                  bedroomsNoFromValue,
                  false ,
                  searchType
                );
                bedrooms = { from : bedroomsNoFromValue , to :(selected?.bedrooms?.to) };
                setSelected({id :'bedrooms'  , value : bedrooms })
              }, 600);
             
            }}
            min={0}
          />
        </div>
        <div className="w-100 m-1 px-2">
          <Inputs
            idRef="bedroomsToRef"
            labelValue={t(`${translationPath}to`)}
            type={"number"}
            withNumberFormat
            value={(selected?.bedrooms?.to) || ''}
            onKeyUp={(e) => {
              const { value } = e.target;
              let bedrooms = null ;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                const bedroomsNoToKey = "bedrooms_to";
                const bedroomsNoToValue = (value || value == 0)? value.replace(/,/g, '') : null;
                const searchType = 4;
                resaleChangeFilters(
                  bedroomsNoToKey,
                  bedroomsNoToValue,
                  false ,
                  searchType
                );
                bedrooms = { from : (selected?.bedrooms?.from) , to :bedroomsNoToValue };
                setSelected({id :'bedrooms'  , value : bedrooms })
              }, 600);
            }}
            min={0}
          />
        </div>
      </fieldset>
    </div>
  );
};

import React, { useRef, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../../../../../SharedComponents";
import { Avatar } from "@material-ui/core";
import { Inputs, Tables } from "../../../../../../Components";
import {
  getDownloadableLink,
  getFirstLastNameLetters,
  GlobalHistory,
} from "../../../../../../Helper";

export const ActiveUsersView = ({
  parentTranslationPath,
  translationPath,
  usersData,
  totalCount,
  setFilter,
  filter,
  onPageSizeChanged,
  onPageIndexChanged
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const getTableActionsWithPermissions = (rowData) => {
    const actionsList = [];

      const viewInformationAction = (
       <span
        className="fw-simi-bold pointer c-propx"
          onClick={() =>
            rowData.id
              ? GlobalHistory.push(`/home/Users/edit?id=${rowData.id}`)
              : null
          }
        >
          {t(`${translationPath}show-details`)}
        </span>
      );
      actionsList.push(viewInformationAction);

    return actionsList;
  };


  return (
    <div className="w-80">
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"ActiveUsers"}
          subTitle={"Active-Users-heading"}
          wrapperClasses={"px-4 w-50"}
          headerTitleClasses={"fz-16 mb-1"}
          subTitleClasses={"fz-14 m-0 ml-0"}
        />
        <div className="w-50 px-4 d-flex-v-center-h-between">
          <div className="w-100 d-flex-center fj-end">
          <Inputs
                idRef="user-nameRef"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.userName || ''}
                onInputChanged={(event) => {
                  const userName = event?.target?.value;
                    setFilter((items) => ({ ...items, 
                      userName: userName || null,
                      userId: null,
                    }));
                }}
                inputPlaceholder={t(`${translationPath}name`)}
              />
          </div>
        </div>
      </div>

      <div className="propx-view-container sm m-3">
        <Tables
          data={usersData || []}
          headerData={[
            {
              id: 1,
              label: "user",
              component: (item) =>
                (item?.name ? (
                  <div className="d-flex-v-center">
                    <div className="campaign-team-f1">
                      <Avatar
                        className="avatars-wrapper team-avatar fz-12 sm-theme"
                        src={
                          item?.profileImage
                            ? getDownloadableLink(item?.profileImage)
                            : ""
                        }
                      >
                        {getFirstLastNameLetters(item?.name || "")}
                      </Avatar>
                    </div>
                    <div className="d-flex-column">
                      <span className="fz-12 fw-simi-bold">{item?.name || "-"}</span>
                      <span className="fz-10 text-tertiary">
                        {item?.email || "-"}
                      </span>
                    </div>
                  </div>
                ) : (
                  "-"
                )) || "",
            },
            {
              id: 2,
              label: "application",
              input: "application",
            },
            {
              id: 3,
              label: "activation-date",
              input: "activationDate",
              component: (item) =>
                (
                  <span>
                    {item.activationDate
                      ? moment(item.activationDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 4,
              label: "last-activity-date",
              input: "lastActivityDate",
              component: (item) =>
                (
                  <span>
                    {item.lastActivityDate
                      ? moment(item.lastActivityDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 5,
              label: "actions",
              component: (item) => getTableActionsWithPermissions(item) || "",
            },
          ]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          totalItems={totalCount ?? 0}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          defaultActions={[]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { DatePickerComponent, Inputs, PermissionsComponent, Spinner, Tables } from '../../../../../../Components';
import { GetAllFormFieldTabsByFormId } from '../../../../../../Services';
import { useTranslation } from "react-i18next";
import { useTitle } from '../../../../../../Hooks';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import { LeadsAdvanceSearchTest, UpdateSourceFile, GetSourceFileByContactId, CreateSourceFile } from '../../../../../../Services';
import { GetParams, GlobalHistory, bottomBoxComponentUpdate } from '../../../../../../Helper';
import PropTypes from 'prop-types';
import { showSuccess ,showError } from '../../../../../../Helper';
import { ContactsPermissions } from '../../../../../../Permissions';
const translationPath = '';

export const ContactProfileSourceFileComponent = ({
    parentTranslationPath,
    isOpen,
}) => {
 
    const contactId = +GetParams('id');
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [leadArrayInfo, setLeadArrayInfo] = useState([]);
    const [dataFiled, setdataFiled] = useState({});
    const [filter, setFilter] = useState({
        contactId: +GetParams('id'),
        sourceFileName: "",
        sourceFileYear: "",
        communitySourceFile: "",
        propertySourceFile: "",
        sourceBy: "",
        sourceFileDate: "",
    });
  
    const [selected, setSelected] = useState({ 
        sourceFileName: "",
        sourceFileYear: "",
        communitySourceFile: "",
        propertySourceFile: "",
        sourceBy: "",
        sourceFileDate: "", 
    })
 
    const { t } = useTranslation(parentTranslationPath);
    useTitle(t("File-Source"));


    const leadTableData = useCallback(async (leadId) => {
        setIsLoading(true);
        const isForLog = true;

        let body = {
            criteria: {
                contactId: [{ searchType: 1, value: leadId }]
            },
            filterBy: 'createdOn',
            orderBy: 2,
        };

        const res = await LeadsAdvanceSearchTest({ pageIndex: 0, pageSize: 100, isForLog }, body);
        if (!(res && res.status && res.status !== 200)) {
            if (res && res.totalCount > 0) {
                const leadsData = res.result.map(lead => {
                    const { leadJson, leadId } = lead;
                    const { lead: leadDetails } = JSON.parse(leadJson);
                    return { leadId, lead: leadDetails };
                });
                setLeadArrayInfo(leadsData);
           
            }
            else{
                setLeadArrayInfo(null);
            }
        }
        setIsLoading(false);
    });
    const cancelHandler = () => {
        const parentContact = localStorage.getItem('parentContact')
        if (GetParams('isAssociated') && parentContact) {
            const parsParentContact = JSON.parse(parentContact);
            parsParentContact.id && parsParentContact.userTypeId({
                id: JSON.stringify(parsParentContact.id),
                userTypeId: JSON.stringify(parsParentContact.userTypeId),
            });
        }
        GlobalHistory.goBack();
    };

    const saveHandler = useCallback(async () => {
        const res = await UpdateSourceFile({
            contactId: contactId,
            body: filter
        })
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t(`save-source-file-successfully`));
        }
        else{
            showError(t(`failed-to-save-source-file`));
        }
    })

    const getDataFiled = useCallback(async () => {
        const res = await GetSourceFileByContactId({contactId})
        if (!(res && res.status && res.status !== 200)) {
            setdataFiled(res)
        }
    })

    useEffect(() => {
        if (contactId && activeTab === 0)
            getDataFiled(contactId)
    }, [activeTab]);


    useEffect(() => {
        if (contactId && activeTab === 0)
            leadTableData(contactId);
    }, [activeTab]);

    useEffect(() => {
       if (dataFiled){
        setSelected({
            sourceFileName: dataFiled.sourceFileName || "",
            sourceFileYear: dataFiled.sourceFileYear || "",
            communitySourceFile: dataFiled.communitySourceFile || "",
            propertySourceFile: dataFiled.propertySourceFile || "",
            sourceBy: dataFiled.sourceBy || "",
            sourceFileDate: dataFiled.sourceFileDate || null,
            contactId: +GetParams('id')
          });
          setFilter({
            sourceFileName: dataFiled.sourceFileName || "",
            sourceFileYear: dataFiled.sourceFileYear || "",
            communitySourceFile: dataFiled.communitySourceFile || "",
            propertySourceFile: dataFiled.propertySourceFile || "",
            sourceBy: dataFiled.sourceBy || "",
            sourceFileDate: dataFiled.sourceFileDate || null,
            contactId: +GetParams('id')
          });
       }
    }, [dataFiled]);

    useEffect(() => {
        if (!isOpen) {
            bottomBoxComponentUpdate(
                <div className='d-flex-v-center-h-end flex-wrap'>
                    <ButtonBase className='btns theme-transparent mb-2' onClick={cancelHandler}>
                        <span>{t('Shared:cancel')}</span>
                    </ButtonBase>

                    <PermissionsComponent
                    permissionsList={Object.values(ContactsPermissions)}
                    permissionsId={ContactsPermissions.EditContactSourceFile.permissionsId}
                  >
                    <ButtonBase className='btns theme-solid mb-2' onClick={() => saveHandler()}>
                        <span>{t('Shared:save')}</span>
                    </ButtonBase>
                    </PermissionsComponent>
                </div>
            );
        }
    });

    return (
        <div className=" my-3 childs-wrapper">
            <Spinner isActive={isLoading} isAbsolute />
            <div className='title-section'>
                {t('File-Source')}
            </div>
            <PermissionsComponent
            permissionsList={Object.values(ContactsPermissions)}
            permissionsId={ContactsPermissions.EditContactSourceFile.permissionsId}
          >
            <div className="tabs-wrapper">
                <div className="w-24">
                    <div className="mb-3">
                        <span className="b-400">
                            {t(`${translationPath}Source-File-name`)}
                        </span>
                    </div>
                    <div className="mt-2 mb-1 pt-1">
                        <Inputs
                        // isDisabled={}
                            value={selected.sourceFileName}
                            idRef="SourceFilenameRef"
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            onInputChanged={(event) => {
                                const SourceFilename = event?.target?.value;
                                    setSelected({ ...selected, "sourceFileName": SourceFilename })
                                    setFilter({ ...filter, "sourceFileName": SourceFilename })
                            }}
                        />
                    </div>
                </div>
                <div className="w-24">
                    <div className="mb-3">
                        <span className="b-400">
                            {t(`${translationPath}Source-File-Year`)}
                        </span>
                    </div>
                    <div className="mt-2 mb-1 pt-1">
                        <Inputs
                        // isDisabled={}
                        value={selected.sourceFileYear}
                            idRef="SourceFileYearRef"
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            isWithError
                            onInputChanged={(event) => {
                                const SourceFileYear = event?.target?.value;
                                    setSelected({ ...selected, "sourceFileYear": SourceFileYear })
                                    setFilter({ ...filter, "sourceFileYear": SourceFileYear })
                            }}
                        />
                    </div>
                </div>
                <div className="w-24">
                    <div className="mb-3">
                        <span className="b-400">
                            {t(`${translationPath}Source-file-date`)}
                        </span>
                    </div>
                    <div className=" mt-2 mb-1 pt-1">
                        <DatePickerComponent
                        // isDisabled={}
                            idRef="SourceFselectedileDateRef"
                            placeholder="DD/MM/YYYY"
                            value={selected.sourceFileDate || null}
                            onDateChanged={(newValue) => {
                                const sourceFileDate = (newValue && moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) || null
                                setSelected({ ...selected, "sourceFileDate": sourceFileDate })
                                setFilter({ ...filter, "sourceFileDate": sourceFileDate })
                            }
                            }
                            
                        />
                    </div>
                </div>
                <div className="w-24">
                    <div className="mb-3">
                        <span className="b-400">
                            {t(`${translationPath}Source-by`)}
                        </span>
                    </div>
                    <div className=" mt-2 mb-1 pt-1">
                        <Inputs
                        // isDisabled={}
                        value={selected.sourceBy}
                            idRef="SourceByRef"
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            isWithError
                            onInputChanged={(event) => {
                                const SourceBy = event?.target?.value;
                              
                                    setSelected({ ...selected, "sourceBy": SourceBy })
                            
                                setFilter({ ...filter, "sourceBy": SourceBy })
                            }}
                        />
                    </div>
                </div>
                <div className="w-24">
                    <div className="mb-3">
                        <span className="b-400">
                            {t(`${translationPath}Comunity-Source-file`)}
                        </span>
                    </div>
                    <div className=" mt-2 mb-1 pt-1">
                        <Inputs
                        // isDisabled={}
                        value={selected.communitySourceFile}
                            idRef="CommunitySourceFileRef"
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            isWithError
                            onInputChanged={(event) => {
                                const CommunitySourceFile = event?.target?.value;
                              
                                    setSelected({ ...selected, "communitySourceFile": CommunitySourceFile })
                              
                                setFilter({ ...filter, "communitySourceFile": CommunitySourceFile })
                            }}
                        />
                    </div>
                </div>
                <div className="w-24">
                    <div className="mb-3">
                        <span className="b-400">
                            {t(`${translationPath}Property-Source-File`)}
                        </span>
                    </div>
                    <div className=" mt-2 mb-1 pt-1">
                        <Inputs
                        // isDisabled={}
                            value={selected.propertySourceFile}
                            idRef="PropertySourceFileRef"
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            isWithError
                            onInputChanged={(event) => {
                                const PropertySourceFile = event?.target?.value;
                                    setSelected({ ...selected, "propertySourceFile": PropertySourceFile })
                                setFilter({ ...filter, "propertySourceFile": PropertySourceFile })
                            }}
                        />
                    </div>
                </div>
            </div>
            </PermissionsComponent>
            <div className="dialog-content-item w-100 px-4 py-3">
                <Tables
                    data={leadArrayInfo || []}
                    headerData={[
                        {
                            id: 1,
                            label: "Lead ID",
                            input: "leadId",
                            key: 0
                        },
                        {
                            id: 2,
                            label: "GCLID",
                            input: "lead.google_gclid",
                            key: 1
                        },
                        {
                            id: 3,
                            label: "Campaign Name",
                            input: "lead.campaign_name.name",
                            key: 2
                        },
                    ]}
                    // itemsPerPage={filter.pageSize}
                    // activePage={filter.pageIndex}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                />
            </div>

        </div>
    );
};
ContactProfileSourceFileComponent.defaultProps = {
    isOpen: false,
};
ContactProfileSourceFileComponent.propTypes = {
    isOpen: PropTypes.bool,
};
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    iconWrapper: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      width: "24px",
      height: "24px",
      background: theme.palette.utility.utility_gray_200,
      borderRadius: theme.borderRadius[6],
    },
    actionsWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
    divider: {
      backgroundColor: theme.palette.border.secondary,
      marginTop: "8px",
      marginBottom: "8px",
    },
    textArea: {
      minHeight: "75px",
      borderColor: theme.palette.border.primary,
      borderRadius: "8px",
      padding: "12px 14px",
      fontSize: "16px",
      lineHeight: "24px",
    }
  }
});

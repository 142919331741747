import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import { Spinner } from "../../../../../Components";
import { GetGeographicCriteriaWithScores } from "../../../../../Services";
import { ReactComponent as Minimize } from "../../../../../assets/images/defaults/minimize.svg";

export const DistrictGeographicCriteria = ({
  parentTranslationPath,
  translationPath,
  districtGeographicCriteriaWithScores,
  setDistrictGeographicCriteriaWithScores,
  setCommunityGeographicCriteriaWithScores,
  communityGeographicCriteriaWithScores,
  setActiveDistrict,
  activeCity,
  setActiveCommunity,
  setActiveSubCommunity,
  propertyRatingLocationTypeScoreId,
  activeDistrict,
  displayAllReletedLocation,
  setDisplayAllReletedLocation,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [displayDistrictSelected, setDisplayDistrictSelected] = useState([]);

  const GetGeographicCriteriaWithScoresAPI = useCallback(async (search) => {
    setIsLoading(true);
    const res = await GetGeographicCriteriaWithScores({
      parentLookupItemId: search,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      const communities =
        (res &&
          res.locationTypeScores &&
          res.locationTypeScores.length > 0 &&
          res.locationTypeScores[0] &&
          res.locationTypeScores[0].geographicCriteriaScores &&
          res.locationTypeScores[0].geographicCriteriaScores.map((el) => ({
            ...el,
            propertyRatingLocationTypeScoreId:
              propertyRatingLocationTypeScoreId,
          }))) ||
        [];
      let newList = [...communityGeographicCriteriaWithScores, ...communities];
      let filterArray = newList.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.lookupsId === value.lookupsId)
      );
      setCommunityGeographicCriteriaWithScores([...filterArray]);
    } else {
      setCommunityGeographicCriteriaWithScores([]);
    }
    setIsLoading(false);
  });

  useEffect(() => {
    if (activeDistrict && activeDistrict.lookupsId) {
      setActiveSubCommunity(null);
      setActiveCommunity(null);
      GetGeographicCriteriaWithScoresAPI(activeDistrict.lookupsId);
    }
    setDisplayAllReletedLocation((e) => ({
      ...e,
      displayAllCommunitySubCommunities: null,
    }));
  }, [activeDistrict]);

  useEffect(() => {
    if (
      displayAllReletedLocation &&
      displayAllReletedLocation.displayAllDistrictCommunities
    ) {
      const selectedDistrict = districtGeographicCriteriaWithScores.filter(
        (item) =>
          item.lookupsId ===
          displayAllReletedLocation.displayAllDistrictCommunities.id
      );
      setDisplayDistrictSelected([...selectedDistrict]);
    } else setDisplayDistrictSelected([]);
  }, [displayAllReletedLocation, districtGeographicCriteriaWithScores]);

  return (
    <>
      {" "}
      {activeCity && (
        <>
          <Spinner isActive={isLoading} isAbsolute />
          <div>
            <span className="fw-bold"> {t(`${translationPath}District`)} </span>
          </div>
          <div className="w-100 mt-2 mb-2 sores-part">
            {displayAllReletedLocation &&
              !displayAllReletedLocation.displayAllDistrictCommunities &&
              districtGeographicCriteriaWithScores &&
              districtGeographicCriteriaWithScores
                .filter(
                  (d) =>
                    d.parentLookupItemId ===
                    (activeCity && activeCity.lookupsId)
                )
                .map((item, index) => (
                  <div
                    className="d-flex-inline b-bottom mt-3 mb-3"
                    key={`districtCardItemRef${item.lookupsId + 1}`}
                    onClick={() => setActiveDistrict(item)}
                  >
                    <div className="city-location">
                      {" "}
                      <div className="d-flex-inline">
                        <span className="fw-bold">{item.lookupItemName}</span>
                      </div>
                      <div className="d-flex-inline">
                        <Rating
                          size="large"
                          name={`districtItemRef${item.lookupTypeName}-${item.lookupsId}}`}
                          max={10}
                          value={item.score}
                          precision={1}
                          onChange={(event, newValue) => {
                            GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                            let updatelist = [
                              ...districtGeographicCriteriaWithScores,
                            ];
                            const itemIndex = updatelist.indexOf(
                              updatelist.find(
                                (f) => f.lookupsId === item.lookupsId
                              )
                            );
                            if (itemIndex !== -1)
                              updatelist[itemIndex] = {
                                ...updatelist[itemIndex],
                                score: newValue ||0,
                              };

                            setDistrictGeographicCriteriaWithScores([
                              ...updatelist,
                            ]);
                          }}
                        />
                        <span
                          onClick={() => {
                            setDisplayAllReletedLocation((e) => ({
                              ...e,
                              displayAllDistrictCommunities: {
                                id: item.lookupsId,
                                isSelected: true,
                              },
                            }));
                          }}
                        >
                          <Minimize />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            {displayAllReletedLocation &&
              displayAllReletedLocation.displayAllDistrictCommunities &&
              displayAllReletedLocation.displayAllDistrictCommunities
                .isSelected &&
              displayDistrictSelected.map((item, index) => (
                <div
                  className="d-flex-inline b-bottom mt-3 mb-3"
                  key={`DistrictCardItemRef${item.lookupsId + 1}`}
                  onClick={() => setActiveDistrict(item)}
                >
                  <div className="city-location">
                    {" "}
                    <div className="d-flex-inline">
                      <span>{item.lookupItemName}</span>
                    </div>
                    <div className="d-flex-inline">
                      <Rating
                        size="large"
                        name={`districtItemSelectedRef${item.lookupTypeName}-${item.lookupsId}}`}
                        max={10}
                        value={item.score}
                        precision={1}
                        onChange={(event, newValue) => {
                          GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                          let updatelist = [
                            ...districtGeographicCriteriaWithScores,
                          ];
                          const itemIndex = updatelist.indexOf(
                            updatelist.find(
                              (f) => f.lookupsId === item.lookupsId
                            )
                          );
                          if (itemIndex !== -1)
                            updatelist[itemIndex] = {
                              ...updatelist[itemIndex],
                              score: newValue ||0,
                            };

                          setDistrictGeographicCriteriaWithScores([
                            ...updatelist,
                          ]);
                        }}
                      />
                      <span
                        onClick={() => {
                          setDisplayAllReletedLocation((e) => ({
                            ...e,
                            displayAllDistrictCommunities: null,
                          }));
                        }}
                      >
                        <Minimize />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};

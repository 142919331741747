import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Box, Divider } from "@material-ui/core";
import { CustomButton } from "../../../CustomButton";
import { ContactComment, ContactItemList, InfoItem, InfoSection } from "..";
import { useSelectedTheme, useTranslate } from "../../../../../Hooks";
import { CreateMainEntitiesComment, GetMainEntitiesComments } from "../../../../../Services";
import { ContactCommentSkeleton } from "../../../SkeletonLoader";

// Icons
import { EmailIcon, PhoneIcon, SMSIcon, WhatsappIcon } from "../../../../../assets/icons";

// Styles
import useStyles from "./styles";

function ContactProfileDetails({ activeItem }) {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const [isCommentsLoading, setIsCommentsLoading] = useState(true);

  const [comments, setComments] = useState([]);

  const getContactComments = useCallback(async () => {
    setIsCommentsLoading(true);

    try {
      const response = await GetMainEntitiesComments(1, activeItem.id);

      setComments(response ?? []);
    } catch (error) {
      console.error("Failed to fetch activity:", error);
    } finally {
      setIsCommentsLoading(false);
    }
  }, [activeItem.id]);

  useEffect(() => {
    if (activeItem.id) {
      getContactComments();
    }
  }, [activeItem.id]);

  const { loginResponse } = useSelector((state) => state.login);

  const { translate } = useTranslate("NewContactsView");

  const [comment, setComment] = useState("");

  const styles = useStyles();

  const handleSubmitComment = async () => {
    const body = {
      dfmType: 1,
      entityId: activeItem.id,
      comment,
    }
    try {
      const response = await CreateMainEntitiesComment(body);

      setComments([
        {
          createdOn: new Date(),
          creatorFullName: loginResponse.fullName,
          creatorProfileImg: loginResponse.profileImg ?? null,
          comment,
        },
        ...comments,
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setComment("");
    }
  }

  // Sort comments by createdOn date in descending order
  const sortedComments = comments.slice().sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
  
  return (
    <>
      <InfoSection title={translate("CONTACT_INFORMATION")}>
        <InfoItem label={translate("NATIONALITY")} value="Jordanian" />
        <InfoItem
          label={translate("LANGUAGE")}
          value={activeItem?.language?.lookupItemName || "N/A"}
        />
        <InfoItem
          label={translate("COUNTRY")}
          value={activeItem?.country?.lookupItemName || "N/A"}
        />
        <InfoItem
          label={translate("CITY")}
          value={activeItem?.city?.lookupItemName || "N/A"}
        />
        <InfoItem
          label={translate("COMMUNITY")}
          value={activeItem?.community?.lookupItemName || "N/A"}
        />
        <InfoItem
          label={translate("SUB_COMMUNITY")}
          value={
            activeItem?.allDetails?.["Residence Address"]?.find(
              (activeItem) => activeItem?.title === "sub_community"
            )?.value
          }
        />
        <InfoItem
          label={translate("DATE_OF_BIRTH")}
          value={
            moment(activeItem?.date_of_birth).format("DD-MM-YYYY") || "N/A"
          }
        />
        <InfoItem
          label={translate("CREATED_BY")}
          value={activeItem?.createdBy}
        />
        <InfoItem
          label={translate("CREATED_DATE")}
          value={moment(activeItem?.createdOn).format("DD-MM-YYYY")}
        />
      </InfoSection>

      <InfoSection title={translate("CONTACTS")}>
        <ContactItemList
          labelIcon={
            <Box className={styles.iconWrapper}>
              <PhoneIcon
                width="20"
                height="20"
                fill={palette.utility.utility_gray_500}
              />
            </Box>
          }
          label={translate("PHONE")}
          values={[
            activeItem?.mobile?.phone ||
              activeItem?.landline_number?.phone ||
              "",
            ...(activeItem?.other_contact_mobile_no?.others ||
              activeItem?.landline_number?.others ||
              []),
          ]}
        />
        <ContactItemList
          labelIcon={
            <Box className={styles.iconWrapper}>
              <EmailIcon
                width="20"
                height="20"
                fill={palette.utility.utility_gray_500}
              />
            </Box>
          }
          label={translate("EMAIL")}
          values={[
            activeItem?.email_address?.email ||
              activeItem?.general_email?.email ||
              "",
            ...(activeItem?.general_email?.others ||
              activeItem?.email_address?.others ||
              []),
          ]}
        />
        <ContactItemList
          labelIcon={
            <Box className={styles.iconWrapper}>
              <WhatsappIcon
                width="20"
                height="20"
                fill={palette.utility.utility_gray_500}
              />
            </Box>
          }
          label={translate("WHATSAPP")}
          values={[
            activeItem?.whatsapp_mobile?.phone || "",
            ...(activeItem?.whatsapp_mobile?.others || []),
          ]}
        />
        <ContactItemList
          labelIcon={
            <Box className={styles.iconWrapper}>
              <SMSIcon
                width="20"
                height="20"
                fill={palette.utility.utility_gray_500}
              />
            </Box>
          }
          label={translate("SMS")}
          values={[
            activeItem?.mobile?.phone ||
              activeItem?.landline_number?.phone ||
              "",
            ...(activeItem?.other_contact_mobile_no?.others ||
              activeItem?.landline_number?.others ||
              []),
          ]}
        />
      </InfoSection>
      <InfoSection 
        title={translate("comments")}
        subTitle={translate("join_conversation")}
        fullWidthDivider
      >
        <textarea
          className={styles.textArea}
          placeholder={translate("comments_placeholder")}
          onChange={(event) => setComment(event.target.value)}
          value={comment}
        />

        <Box className={styles.actionsWrapper}>
          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="contained"
            color="primary"
            disabled={!comment}
            onClick={handleSubmitComment}
          >
            {translate("post_comment")}
          </CustomButton>
        </Box>

        {(!!comments.length || isCommentsLoading) &&
          <Divider className={styles.divider} light />
        }

        {isCommentsLoading && 
          Array.from({ length: 3 }, (_, index) => ( <ContactCommentSkeleton key={index} /> )) 
        }

        
        {sortedComments.map((comment, idx) => {
          return (
            <ContactComment
              key={idx}
              comment={comment}
            />
          );
        })}
      </InfoSection>
    </>
  );
}

ContactProfileDetails.propTypes = {
  activeItem: PropTypes.objectOf(PropTypes.any),
};

ContactProfileDetails.defaultProps = {
  activeItem: {},
};

export default ContactProfileDetails;

import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from "react";
import {
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
  SelectComponet,
} from "../../../../Components";
import { useTranslation } from "react-i18next";
import { GetLookupItemsByLookupTypeName } from "../../../../Services";
import { ButtonBase } from "@material-ui/core";
import moment from "moment";

function OpportunitiesSearchFilters({
  state,
  setState,
  filter,
  initialState,
  onFilterReset,
  reloadData,
  translationPath,
  parentTranslationPath,
}) {
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const initialSelected = {
    newStage: null,
    oldStage: null,
    fromDate: null,
  };
  const [selected, setSelected] = useReducer(reducer, initialSelected);
  const [data, setData] = useReducer(reducer, {
    leadStages: null,
  });

  const getLeadStages = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "LeadStage",
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "leadStages", value: res?.result || [] });
    } else setData({ id: "leadStages", value: [] });
  };

  const filterByChanged = (value) => {
    setState({ id: "filterBy", value });
  };
  const orderByChanged = (value) => {
    setState({ id: "orderBy", value });
  };

  const clearFilterHandler = () => {
    setState({ id: "edit", value: initialState });
    setSelected({ id: "edit", value: initialSelected });
    onFilterReset();
    reloadData();
  };

  useEffect(() => {
    getLeadStages();
  }, []);

  return (
    <div>
      <div className="d-flex my-2 w-100 ">
        <div className="w-33 mx-1">
          <Inputs
            idRef="leadIdRef"
            inputPlaceholder={t("lead-id")}
            value={state?.leadId || ""}
            onInputChanged={(event) => {
              setState({ id: "leadId", value: event.target.value });
            }}
            onKeyUp={(e, newValue) => {
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <div className="w-33 mx-1">
          <AutocompleteComponent
            idRef="oldStageRef"
            data={data?.leadStages || []}
            inputPlaceholder={t(`${translationPath}old-stage`)}
            selectedValues={selected?.oldStage || ""}
            displayLabel={(option) => option?.lookupItemName || ""}
            multiple={false}
            withoutSearchButton
            onChange={(e, newValue) => {
              setSelected({
                id: "oldStage",
                value: newValue,
              });
              setState({
                id: "oldStageId",
                value: newValue?.lookupItemId,
              });
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
          />
        </div>
        <div className="w-33 mx-1">
          <AutocompleteComponent
            idRef="newStageRef"
            data={data?.leadStages || []}
            inputPlaceholder={t(`${translationPath}new-stage`)}
            selectedValues={selected?.newStage || ""}
            displayLabel={(option) => option?.lookupItemName || ""}
            multiple={false}
            withoutSearchButton
            onChange={(e, newValue) => {
              setSelected({
                id: "newStage",
                value: newValue,
              });
              setState({
                id: "newStageId",
                value: newValue?.lookupItemId,
              });
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
          />
        </div>
      </div>

      <div className="d-flex my-2 w-100">
        <div className="w-25 mx-1">
          <Inputs
            idRef="oldLeadNameRef"
            inputPlaceholder={t("old-lead-name")}
            value={state?.oldLeadName || ""}
            onInputChanged={(event) => {
              setState({ id: "oldLeadName", value: event.target.value });
            }}
            onKeyUp={(e, newValue) => {
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <div className="w-25 mx-1">
          <Inputs
            idRef="newLeadNameRef"
            inputPlaceholder={t("new-lead-name")}
            value={state?.newLeadName || ""}
            onInputChanged={(event) => {
              setState({ id: "newLeadName", value: event.target.value });
            }}
            onKeyUp={(e, newValue) => {
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>

        {/* //datepicker */}
        <div className="w-25 mx-1">
          <Inputs
            idRef="createdByRef"
            inputPlaceholder={t("changed-by")}
            value={state?.createdBy || ""}
            onInputChanged={(event) => {
              setState({ id: "createdBy", value: event.target.value });
            }}
            onKeyUp={(e, newValue) => {
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <div className="w-25 mx-1">
          <DatePickerComponent
            idRef="datePickerRef"
            placeholder="DD/MM/YYYY"
            format="YYYY-MM-DD"
            parentTranslationPath={parentTranslationPath}
            value={selected?.fromDate ?? moment().format('YYYY-MM-DD')}
            maxDate={moment().format('YYYY-MM-DD')}
            onDateChanged={(newValue) => {
              const formattedValue = moment(newValue).format("YYYY-MM-DD");
              setState({
                id: "createdOn",
                value: formattedValue,
              });
              setSelected({
                id: "fromDate",
                value: formattedValue || null,
              });
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
          />
        </div>
      </div>

      <div className="d-flex px-2">
        <span className="mx-2 mt-1">{t(`${translationPath}leads`)}</span>
        <span className="separator-v s-primary s-reverse s-h-25px mt-1" />
        <span className="px-2 d-flex">
          <span className="texts-large mt-1">
            {t(`${translationPath}order-by`)}:
          </span>
          <div className="px-2">
            <SelectComponet
              idRef="filterByRef"
              data={[
                { id: "createdOn", filterBy: "created-on" },
                { id: "updateOn", filterBy: "last-updated" },
              ]}
              value={state.filterBy}
              onSelectChanged={filterByChanged}
              wrapperClasses="mb-3"
              isRequired
              valueInput="id"
              textInput="filterBy"
              emptyItem={{
                value: null,
                text: "select-filter-by",
                isDisabled: false,
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <div className="px-2">
            <SelectComponet
              idRef="orderByRef"
              data={[
                { id: 1, orderBy: "ascending" },
                { id: 2, orderBy: "descending" },
              ]}
              value={state.orderBy}
              onSelectChanged={orderByChanged}
              wrapperClasses="mb-3"
              isRequired
              valueInput="id"
              textInput="orderBy"
              emptyItem={{
                value: null,
                text: "select-sort-by",
                isDisabled: false,
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <div className="mt-1">
            <ButtonBase
              className="btns theme-solid"
              onClick={reloadData}
              id="action_apply"
            >
              <span>{t(`${translationPath}apply`)}</span>
            </ButtonBase>
          </div>
          <div className="mt-1">
            <ButtonBase
              className="btns theme-solid bg-danger clear-all-btn"
              onClick={clearFilterHandler}
              id="clearFiltersRef"
            >
              {t(`${translationPath}clear-filters`)}
            </ButtonBase>
          </div>
        </span>
      </div>
    </div>
  );
}

export default OpportunitiesSearchFilters;

import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  TabsComponent,
  RadiosGroupComponent,
  CompletedDataComponent,
  LeadsStagesBreadcrumb,
} from "../../../../Components";
import { GetParams, returnPropsByPermissions } from "../../../../Helper";
import {
  LeadsAdvanceSearchTest,
  GetSensitiveFieldsForUser,
} from "../../../../Services";
import { PermissionDeniedLayout } from "../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout";

import { LeadsVerticalTabsData } from "../../Common/OpenFileView/OpenFileUtilities/OpenFileData";
import { LeadTypeIdEnum, FormsIdsEnum } from "../../../../Enums";
import { LeadsLeasePermissions } from "../../../../Permissions";

const parentTranslationPath = "LeadsProfileManagementView";
const translationPath = "";
export const LeadsLeaseProfileManagementView = () => {
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const leadId = +GetParams("id");

  const [
    displayDetailsToUserDependsOnPolicy,
    setDisplayDetailsToUserDependsOnPolicy,
  ] = useState(null);
  const [leadWithPolicy, setLeadWithPolicy] = useState(null);
  const [sensitiveFieldsForUser, setSensitiveFieldsForUser] = useState([]);
  const [isSensitiveLoading, setIsSensitiveLoading] = useState(0);
  const [SensitiveLoading, setSensitiveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tabsArray, setTabsArray] = useState(LeadsVerticalTabsData.rent);
  const localActiveItem = localStorage.getItem("activeItem");
  const [viewType, setViewType] = useState(1);
  const [contactType, setContactType] = useState(null);
  const [leadEmptyFields, setLeadEmptyFields] = useState(null);
  const [targetedStageId, setTargetedStageId] = useState(null);

  // filterBy
  const [filter, setFilterBy] = useState({
    id: null,
    formType: null,
  });

  const GetSensitiveFieldsForUserAPI = useCallback(async () => {
    setSensitiveLoading(true);
    const allSensitiveFields = await GetSensitiveFieldsForUser(
      leadWithPolicy &&
        leadWithPolicy.lead &&
        leadWithPolicy.lead.lead_type_id === 1
        ? FormsIdsEnum.leadsOwner.id
        : FormsIdsEnum.leadsSeeker.id
    );

    if (
      !(
        allSensitiveFields &&
        allSensitiveFields.status &&
        allSensitiveFields.status !== 200
      )
    ) {
      setSensitiveFieldsForUser(allSensitiveFields);
      setIsSensitiveLoading(1);
    } else {
      setSensitiveFieldsForUser([]);
      setIsSensitiveLoading(2);
    }

    setSensitiveLoading(false);
  }, [leadWithPolicy]);

  useEffect(() => {
    if (GetParams("formType") == LeadTypeIdEnum.Owner.leadTypeId)
      setTabsArray(
        LeadsVerticalTabsData.rent &&
          LeadsVerticalTabsData.rent.filter(
            (tab) =>
              tab.label !== "matching" && tab.label !== "assign-agents-tracking"
          )
      );

    const pathName = window.location.pathname
      .split("/home/")[1]
      .split("/view")[0];
    if (pathName === "lead-lease/lead-profile-edit" && !GetParams("matching"))
      setActiveTab(0);
    else setActiveTab(GetParams("matching") === "true" ? 2 : 1);
  }, []);

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };
  useEffect(() => {
    setFilterBy({
      formType: GetParams("formType"),
      id: GetParams("id"),
    });
  }, []);

  const userHasPolicyToDisplayData = useCallback(async (leadId) => {
    setIsLoading(true);
    const isForLog = true;

    let body = {
      // OperationType : "Lease" ,
      criteria: {
        Ids: [{ searchType: 2, value: leadId }],
      },
      filterBy: "createdOn",
      orderBy: 2,
    };

    const res = await LeadsAdvanceSearchTest(
      { pageIndex: 0, pageSize: 2, isForLog },
      body
    );
    if (!(res && res.status && res.status !== 200)) {
      if (res && res.totalCount > 0) {
        if (res && res.result && res.result.length && res.result[0].leadJson) {
          const LeadJson = JSON.parse(
            res && res.result && res.result.length && res.result[0].leadJson
          );
          setContactType(LeadJson?.lead?.contact_name?.type);
          setLeadEmptyFields(
            JSON.parse(
              res && res.result && res.result[0] && res.result[0].leadJson
            )
          );
          const lead = {
            ...LeadJson.lead,
            bedrooms:
              (LeadJson.lead &&
                LeadJson.lead.bedrooms &&
                LeadJson.lead.bedrooms.length === 0) ||
              !(LeadJson && LeadJson.lead && LeadJson.lead.bedrooms)
                ? ["Any"]
                : LeadJson.lead.bedrooms,
            bathrooms:
              (LeadJson.lead &&
                LeadJson.lead.bathrooms &&
                LeadJson.lead.bathrooms.length === 0) ||
              !(LeadJson && LeadJson.lead && LeadJson.lead.bathrooms)
                ? ["Any"]
                : LeadJson.lead.bathrooms,
          };
          setLeadWithPolicy({ ...LeadJson, lead: lead });
          localStorage.setItem(
            "leadStatus",
            JSON.stringify(LeadJson && LeadJson.lead && LeadJson.lead.status)
          );
        }
        setDisplayDetailsToUserDependsOnPolicy("allowed");
      } else {
        setLeadWithPolicy(null);
        setLeadEmptyFields(null);
        setDisplayDetailsToUserDependsOnPolicy("notAllowed");
      }
    } else {
      setLeadWithPolicy(null);
      setLeadEmptyFields(null);
      setDisplayDetailsToUserDependsOnPolicy("notAllowed");
    }
    setIsLoading(false);
  });


  
  const reloadData = () => {
    userHasPolicyToDisplayData(+GetParams("id"));
    GetSensitiveFieldsForUserAPI();
  };

  useEffect(() => {
    const leadId = +GetParams("id");
    if (leadId && activeTab === 0) userHasPolicyToDisplayData(leadId);
  }, [activeTab]);

  useEffect(() => {
    const leadId = +GetParams("id");
    if (
      leadId &&
      displayDetailsToUserDependsOnPolicy === "allowed" &&
      activeTab === 0
    )
      GetSensitiveFieldsForUserAPI();
  }, [activeTab, displayDetailsToUserDependsOnPolicy, activeTab]);

  return (
    <div className="leads-profile-wrapper view-wrapper">
      {displayDetailsToUserDependsOnPolicy === "allowed" &&
        activeTab === 0 &&
        tabsArray &&
        tabsArray.length &&
        tabsArray[0].label === "lead-information" &&
        returnPropsByPermissions(
          LeadsLeasePermissions.ViewLeadDetails.permissionsId
        ) && (
          <div className="d-flex">
            <RadiosGroupComponent
              idRef="viewDataRef"
              data={[
                {
                  key: 1,
                  value: "all-data",
                },
                {
                  key: 2,
                  value: "missing-data",
                },
              ]}
              value={viewType}
              labelValue="view"
              labelInput="value"
              valueInput="key"
              themeClass="theme-line"
              parentTranslationPath={parentTranslationPath}
              translationPathForData={translationPath}
              translationPath={translationPath}
              onSelectedRadioChanged={onViewTypeChangedHandler}
              operationType="lease"
            />
            <CompletedDataComponent
              completedData={
                activeItem && activeItem.progress
                  ? activeItem.progress
                  : JSON.parse(localActiveItem) &&
                    JSON.parse(localActiveItem).progress
              }
              typeId="lead"
              formType={filter.formType}
              operationType="lease"
              activeItem={activeItem}
            />
          </div>
        )}

      {displayDetailsToUserDependsOnPolicy === "allowed" && (
        <>
          <LeadsStagesBreadcrumb
            currentStage={leadWithPolicy?.lead?.lead_stage?.lookupItemId}
            setActiveTab={setActiveTab}
            reload={reloadData}
            leadId={+GetParams("id")}
            updateStageId={targetedStageId}
            clearUpdateStageId={() => setTargetedStageId(null)}
            contactId={leadWithPolicy?.lead?.contact_name?.id}
            contactType={contactType}
            />
          <TabsComponent
            data={tabsArray}
            labelInput="label"
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            themeClasses="theme-solid"
            currentTab={activeTab}
            onTabChanged={onTabChanged}
            dynamicComponentProps={{
              viewType,
              parentTranslationPath,
              translationPath,
              sensitiveFieldsForUser,
              leadWithPolicy,
              leadDataIsLoading: isLoading,
              isSensitiveLoading,
              SensitiveLoading,
              isLoading,
              reloadData,
              leadEmptyFields,
              taskRelatedTo: "Lead",
              formType: "leaseLead",
              setTargetedStageId,
            }}
          />{" "}
        </>
      )}

      {displayDetailsToUserDependsOnPolicy === "allowed" && (
        <div className="tabs-content-wrapper" />
      )}
      {displayDetailsToUserDependsOnPolicy === "notAllowed" && (
        <PermissionDeniedLayout />
      )}
    </div>
  );
};

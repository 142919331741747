import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteComponent,
  DialogComponent,
  Inputs,
  Spinner,
} from "../../../../../../Components";
import { ButtonBase, DialogActions } from "@material-ui/core";
import "./UnitsBulkStatusUpdateDialog.scss";
import {
  BulkUpdateUnitsMoveToDraft,
  GetAllListingAgentsServices,
  SaveShareUnits,
  UpdateBulkUnitPublishStatus,
  lookupItemsGetId,
} from "../../../../../../Services";
import {
  GlobalTranslate,
  getDownloadableLink,
  getErrorByName,
  showError,
  showSuccess,
} from "../../../../../../Helper";
import { ShareUnitsDialog } from "../../../../UnitsView/UnitsUtilities/Dialogs";
import { MultipleTypesDocumentsUploaderWithPublish } from "../../../../../../SharedComponents/MultipleTypesDocumentsUploaderWithPublish/MultipleTypesDocumentsUploaderWithPublish";
import Joi from "joi";
import { StaticLookupsIds } from "../../../../../../assets/json/StaticLookupsIds";
import { ConfirmBulkStatusUpdateDialog } from "./ConfirmBulkStatusUpdateDialog";
import { UnitsOperationTypeEnum } from "../../../../../../Enums";
import UnitBulkPublishDialog from "./UnitBulkPublishDialog";
import {
  GetShareSystems,
  SearchApplicationUsersForShareEntity,
  SearchBranchesForShareEntity,
} from "../../../../../../Services/ShareEntity/ShareUnit";

export const UnitsBulkStatusUpdateDialog = ({
  onUnitsBulkCloseDialogHandler,
  unitsIdUpdateArray,
  setUnitsIdUpdateArray,
  viewType,
  isPublishedUnits,
  reloadData,
  checkedCards,
  onClearedAllFiltersClick,
  unitOperationSource,
  isNeedApprovalMessageInMarketing,
  setTypeOfRequest,
  setNeedsApprovalDialog,
  fieldsNeedsApproval,
  setFieldsNeedsApprovalDialog
}) => {
  const parentTranslationPath = "UnitsView";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [categories, setCategories] = useState([]);
  const isLease = window.location.pathname === "/home/units-lease/view";
  const [publishedImages, setPublishedImages] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditDialog, setIsEditDialog] = useState(false);
  const [
    confirmBulkStatusUpdateDialogIsOpen,
    setConfirmBulkStatusUpdateDialogIsOpen,
  ] = useState(false);
  const [schemaErrors, setSchemaErrors] = useState([]);
  const [selected, setSelected] = useReducer(reducer, {
    price: null,
    agent: null,
    category: {},
    systemName: null,
    specificUser: [],
    branchName: [],
  });

  const [operationTypeForDialog, setOperationTypeForDialog] = useReducer(
    reducer,
    {
      viewType: "",
      OperationType: null,
    }
  );
  const [isUploading, setIsUploading] = useState(false);

  const [loading, setLoading] = useReducer(reducer, {
    isLoading: false,
    isUpLoading: false,
    systemName: false,
    branchName: false,
    specificUser: false,
  });

  const [data, setData] = useReducer(reducer, {
    price: null,
    agent: null,
    category: null,
    marketingTitle: null,
    marketingDescription: null,
    unitsIds: unitsIdUpdateArray,
    SystemNameId: null,
    branches: [
      {
        branchId: null,
        name: null,
      },
    ],
    users: [
      {
        userId: null,
        name: null,
      },
    ],
  });

  const [unitData, setUnitData] = useState(() => {
    if (isLease === true) {
      return checkedCards.map((unit) => ({
        unitId: unit.id,
        sellingPrice: 0,
        rentPerYearPrice: unit.rent_price_fees,
      }));
    } else if (isLease === false) {
      return checkedCards.map((unit) => ({
        unitId: unit.id,
        sellingPrice: unit.selling_price_agency_fee,
        rentPerYearPrice: 0,
      }));
    }
  });

  const schema = Joi.object({
    marketingTitle: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}marketing-title-is-required`),
        "string.empty": t(`${translationPath}marketing-title-is-required`),
      }),

    marketingDescription: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}marketing-description-is-required`),
        "string.empty": t(
          `${translationPath}marketing-description-is-required`
        ),
      }),

    agent: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}listing-agent-is-required`),
        "number.empty": t(`${translationPath}listing-agent-is-required`),
      }),

    category: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}gallery-category-is-required`),
        "number.empty": t(`${translationPath}gallery-category-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(data);

  const schema2 = Joi.object(
    isLease
      ? {
          rentPerYearPrice: Joi.number()
            .min(1)
            .required()
            .messages({
              "number.base": t(`${translationPath}please-enter-rent-price`),
              "number.min": t(`${translationPath}please-enter-rent-price`),
            }),
        }
      : {
          sellingPrice: Joi.number()
            .min(1)
            .required()
            .messages({
              "number.base": t(`${translationPath}please-enter-sell-price`),
              "number.min": t(`${translationPath}please-enter-sell-price`),
            }),
        }
  ).options({
    abortEarly: false,
    allowUnknown: true,
  });
  const schema3 = Joi.object({
    SystemNameId: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}System-name-is-required`),
      }),
    branches: Joi.array()
      .custom((value, helpers) => {
        if (data.branches?.length === 0 && data.users?.length === 0)
          return helpers.error("custom-select");
        return value;
      })
      .messages({
        "custom-select": t(
          `${translationPath}select-at-least-branchName-or-spacific-user`
        ),
      }),
    users: Joi.array()
      .custom((value, helpers) => {
        if (data.branches?.length === 0 && data.users?.length === 0)
          return helpers.error("custom-select");
        return value;
      })
      .messages({
        "custom-select": t(
          `${translationPath}select-at-least-branchName-or-spacific-user`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(data);
  const getAllGalaryCategory = useCallback(async () => {
    setLoading({ id: "loading", value: true });

    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.UnitImageCategory,
    });

    if (res?.status !== 200) {
      setCategories(res);
    } else {
      setCategories([]);
    }

    setLoading({ id: "loading", value: false });
  }, []);

  const [listingAgents, setListingAgents] = useState({
    result: [],
    totalCount: 0,
  });

  const [allBranchName, setAllBranchName] = useState({
    result: [],
    totalCount: 0,
  });
  const [allSpecificUser, setAllSpecificUser] = useState({
    result: [],
    totalCount: 0,
  });
  const [allSystemName, setAllSystemName] = useState({
    res: [],
    totalCount: 0,
  });

  const getAllAgents = useCallback(async (search) => {
    setLoading({ id: "isLoading", value: true });
    const res = await GetAllListingAgentsServices({
      pageIndex: 0,
      pageSize: 50,
      search: search,
    });

    if (!(res && res.status && res.status !== 200))
      setListingAgents({
        result: res.result,
        totalCount: res.totalCount,
      });
    else
      setListingAgents({
        result: [],
        totalCount: 0,
      });

    setLoading({ id: "isLoading", value: false });
  }, []);

  const handlePublishChange = (uuid, isChecked) => {
    const changeIsPublishForPublishedImages = publishedImages?.map((item) => {
      if (item.fileId === uuid) {
        return { ...item, isPublished: isChecked };
      }
      return item;
    });
    setPublishedImages(changeIsPublishForPublishedImages);
  };

  const onCloseClicked = () => {
    if (viewType) {
      onUnitsBulkCloseDialogHandler();
    }
  };

  const BulkUpdateUnitsMoveToDraftPUTAPI = useCallback(async () => {
    setLoading({ id: "isLoading", value: true });
    setIsButtonDisabled(true);
    const units = unitsIdUpdateArray.map((unitId) => ({ unitId }));
    const updateOption = isLease
      ? UnitsOperationTypeEnum.rent.key
      : UnitsOperationTypeEnum.sale.key;

    const body = {
      units,
      operationType: updateOption,
    };

    try {
      const result = await BulkUpdateUnitsMoveToDraft(body);
      if (result?.status !== 200) {
        if (result && result.success && result.success.length > 0) {
          const count = result.success.length;
          const successMessage = t("updated-successfully-with-count", {
            count,
          });
          showSuccess(successMessage);
          reloadData();
          setUnitsIdUpdateArray([]);
          onClearedAllFiltersClick();
        }
        if (result && result.failure && result.failure.length > 0) {
          const count = result.failure.length;
          const errorMessage = t("failed-to-update-with-count", { count });
          showError(errorMessage);
        }
      } else {
        showError(t(`${translationPath}failed-to-update`));
      }
    } catch (error) {
      showError(t(`${translationPath}failed-to-update`));
    }
    setLoading({ id: "isLoading", value: false });
    setIsButtonDisabled(false);
    onUnitsBulkCloseDialogHandler();
  }, [isLease]);

  const shareUnit = async () => {
    setIsLoading(true);
    const body = [
      {
        unitsIds: data.unitsIds || [],
        //array of object
        users: data.users || [],
        //array of object
        branches: data.branches || [],
        targetSystemId: data.SystemNameId || 0,
        unitOperationSource: unitOperationSource,
      },
    ];
    const res = await SaveShareUnits(body);
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      setConfirmBulkStatusUpdateDialogIsOpen(true);
      showSuccess(t(`${translationPath}Shared-unit-successfully`));
      onUnitsBulkCloseDialogHandler();
      reloadData();
      setUnitsIdUpdateArray([]);
      onClearedAllFiltersClick();
      setUnitData([]);
    } else {
      showError(t(`${translationPath}failed-Shared-unit`));
    }
  };

  const BulkUpdateUnitPublishedStatusPUTAPI = useCallback(
    async (updateOption) => {
      setIsButtonDisabled(true);
      setLoading({ id: "isLoading", value: true });

      const body = isLease
        ? {
            unitData,
            isPublishedForLease: isLease,
            isForLease: isLease,
            isPublished: isPublishedUnits,
            UpdateOption: updateOption,
          }
        : {
            unitData,
            isPublishedForLease: isLease,
            isForLease: isLease,
            isPublished: isPublishedUnits,
            UpdateOption: updateOption,
          };

      try {
        const result = await UpdateBulkUnitPublishStatus(body);
        if (result?.status !== 200) {
          showSuccess(t(`${translationPath}updated-successfully`));
          reloadData();
          setUnitsIdUpdateArray([]);
          onClearedAllFiltersClick();
          setUnitData([]);
        } else {
          showError(t(`${translationPath}failed-to-update`));
        }
      } catch (error) {
        
        showError(t(`${translationPath}failed-to-update`));
      }
      setLoading({ id: "isLoading", value: false });
      setIsButtonDisabled(false);
      onUnitsBulkCloseDialogHandler();
    },
    [unitsIdUpdateArray, selected, isLease, isPublishedUnits]
  );

  const onSaveClicked = async () => {
    if (viewType === "mark-as-available") {
      setIsSubmitted(true);
      if (schema.error) {
        showError(GlobalTranslate.t("Shared:please-fix-all-errors"));
        return;
      } else {
        setOperationTypeForDialog({ id: "viewType", value: viewType ?? "" });
        setOperationTypeForDialog({ id: "OperationType", value: 1 });
        setConfirmBulkStatusUpdateDialogIsOpen(true);
      }
    } else if (viewType === "move-to-draft") {
      BulkUpdateUnitsMoveToDraftPUTAPI();
    } else if (viewType === "publish-to-website") {
      const validationErrors = [];

      unitData.forEach((data, index) => {
        const { error } = schema2.validate(data);
        if (error) {
          validationErrors.push({ index, error: error.details[0].message });
          
        }
      });
      setSchemaErrors(validationErrors);
      if (validationErrors.length > 0) {
        showError(GlobalTranslate.t("Shared:please-fix-all-errors"));
        return;
      }
      setIsSubmitted(true);
      setOperationTypeForDialog({ id: "viewType", value: viewType ?? "" });
      setOperationTypeForDialog({ id: "OperationType", value: 1 });
      setConfirmBulkStatusUpdateDialogIsOpen(true);
    } else if (viewType === "unpublish") {
      const needsApproval = await isNeedApprovalMessageInMarketing(false);
      if (needsApproval === true) {
        setTypeOfRequest(2);
        setNeedsApprovalDialog(true);
        setIsButtonDisabled(false);
        setLoading({ id: "isLoading", value: false });
        return;
      }

      BulkUpdateUnitPublishedStatusPUTAPI(1);
    } else if (viewType === "share-Unit") {
      // setIsSubmitted(true);

      if (schema3?.error?.message) {
        showError(schema3.error.message);
        return;
      }
      shareUnit();
    }
  };

  const onFillClicked = () => {
    if (viewType === "mark-as-available") {
      setIsSubmitted(true);
      if (schema.error) {
        showError(GlobalTranslate.t("Shared:please-fix-all-errors"));
        return;
      } else {
        setOperationTypeForDialog({ id: "viewType", value: viewType ?? "" });
        setOperationTypeForDialog({ id: "OperationType", value: 2 });
        setConfirmBulkStatusUpdateDialogIsOpen(true);
      }
    }
  };

  const closeConfirmBulkStatusUpdateDialog = () => {
    setConfirmBulkStatusUpdateDialogIsOpen(false);
  };

  const searchBranchesForShareEntity = useCallback(
    async ({ systemId, branchName }) => {
      if (!systemId || systemId === null) {
        systemId = data.SystemNameId;
      }
      setLoading({
        id: "branchName",
        value: true,
      });
      const body = {
        sourceSystemId: systemId || data.SystemNameId,
        branchName: branchName,
        pageIndex: 1,
        pageSize: 15,
      };
      const res = await SearchBranchesForShareEntity({ body });
      if (!(res && res.status && res.status !== 200)) {
        if (res.result.length > 0) {
          setAllBranchName({
            result: res.result,
            totalCount: res.totalCount,
          });
        } else
          setAllBranchName({
            result: [],
            totalCount: res.totalCount,
          });
      }
      setLoading({
        id: "branchName",
        value: false,
      });
    },
    [data.SystemNameId]
  );
  const searchApplicationUsersForShareEntity = useCallback(
    async ({ systemId, userName }) => {
      if (!systemId || systemId === null) {
        systemId = data.SystemNameId;
      }
      setLoading({
        id: "specificUser",
        value: true,
      });

      const body = {
        sourceSystemId: systemId,
        usersId: null,
        name: null,
        userName: userName,
        whatsAppNumber: null,
        phoneNumber: null,
        email: null,
        pageIndex: 1,
        pageSize: 15,
      };
      const res = await SearchApplicationUsersForShareEntity({ body });
      if (!(res && res.status && res.status !== 200)) {
        if (res.result.length > 0) {
          setAllSpecificUser({
            result: res.result,
            totalCount: res.totalCount,
          });
        } else
          setAllSpecificUser({
            result: [],
            totalCount: res.totalCount,
          });
      }
      setLoading({
        id: "specificUser",
        value: false,
      });
    },
    [data.SystemNameId]
  );
  const getAllShareSystems = useCallback(async () => {
    setLoading({
      id: "systemName",
      value: true,
    });
    const res = await GetShareSystems();
    if (!(res && res.status && res.status !== 200)) {
      const dataResponse = res;
      const updatedSystems = dataResponse.map((system) =>
        system.isCurrentSystem
          ? { ...system, name: "current system", id: system.id }
          : system
      );

      setAllSystemName({
        res: updatedSystems,
        totalCount: res.totalCount,
      });
    } else {
      setAllSystemName({
        res: [],
        totalCount: res.totalCount,
      });
    }

    setLoading({
      id: "systemName",
      value: false,
    });
  }, []);

  useEffect(() => {
    getAllGalaryCategory();
  }, []);
  useEffect(() => {
    getAllShareSystems();
  }, []);

  const resetRelatedTo = () => {
    setData({ id: "branches", value: [] });
    setData({ id: "users", value: [] });
    setSelected({ id: "specificUser", value: [] });
    setSelected({ id: "branchName", value: [] });
  };

  return (
    <>
      <Spinner isActive={loading.isLoading || isUploading} isAbsolute />
      <div className="mx-3 my-4">
        {viewType === "mark-as-available" && (
          <>
            <div className="MarketingAndAgentContainer">
              <div className="w-50 px-2 mb-2">
                <Inputs
                  idRef="marketingTitleRef"
                  labelValue="marketing-title"
                  labelClasses="Requierd-Color"
                  value={data.marketingTitle || ""}
                  isWithError
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    const { value } = event.target;
                    setData({ id: "marketingTitle", value: value });
                  }}
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, "marketingTitle").message}
                  error={getErrorByName(schema, "marketingTitle").error}
                />
              </div>

              <div className="w-50 px-2 mb-2">
                <AutocompleteComponent
                  idRef="listingAgentIdRef"
                  labelValue="listing-agent"
                  labelClasses="Requierd-Color"
                  multiple={false}
                  selectedValues={selected.agent || null}
                  data={(listingAgents && listingAgents.result) || []}
                  chipsLabel={(option) => (option && option.agentName) || ""}
                  displayLabel={(option) => (option && option.agentName) || ""}
                  withoutSearchButton
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setSelected({ id: "agent", value: newValue });
                    setData({
                      id: "agent",
                      value: newValue && newValue.usersId,
                    });
                  }}
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllAgents(value);
                    }, 300);
                  }}
                  isWithError
                  isLoading={loading.isLoading}
                  withLoader
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, "agent").message}
                  error={getErrorByName(schema, "agent").error}
                />
              </div>
            </div>
            <div className="w-100 px-2 mb-3">
              <Inputs
                idRef="marketingDescriptionRef"
                labelValue="marketing-description"
                labelClasses="Requierd-Color"
                value={data.marketingDescription || ""}
                isWithError
                multiline
                rows={6}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setData({ id: "marketingDescription", value });
                }}
                isSubmitted={isSubmitted}
                helperText={
                  getErrorByName(schema, "marketingDescription").message
                }
                error={getErrorByName(schema, "marketingDescription").error}
              />
            </div>
            <div className="w-100 px-2 mb-3">
              <AutocompleteComponent
                idRef="galleryCategoryIdRef"
                labelValue="Category"
                labelClasses="Requierd-Color"
                data={categories}
                selectedValues={selected.category.lookupItemId}
                displayLabel={(option) => option.lookupItemName}
                renderOption={(option) => option.lookupItemName || ""}
                onChange={(event, newValue) => {
                  setData({
                    id: "category",
                    value: newValue && newValue?.lookupItemId,
                  });
                  setSelected({
                    id: "category",
                    value: newValue ?? {},
                  });
                }}
                multiple={false}
                isLoading={loading.isLoading}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                isWithError
                isSubmitted={isSubmitted}
                helperText={getErrorByName(schema, "category").message}
                error={getErrorByName(schema, "category").error}
              />
            </div>
            <MultipleTypesDocumentsUploaderWithPublish
              idRef="publishImagesRef"
              labelValue={t("select-published-images")}
              labelClasses="Requierd-Color"
              initUploadedFiles={publishedImages || []}
              setIsUploading={setIsUploading}
              viewUploadedFilesCount={false}
              accept="image/*"
              multiple={true}
              uploadedChanged={(files) => {
                const filesToUpload = files.filter(
                  (item) => item.uuid !== null
                );
                const newPublishedImages = filesToUpload.map((item, index) => ({
                  fileId: item.uuid,
                  fileName: item.fileName,
                  isPublished: false,
                  videoLink: item.videoLink,
                }));
                setPublishedImages(newPublishedImages);
              }}
              chipHandler={(value) => () => {
                const link = document.createElement("a");
                link.setAttribute("download", value.fileName);
                link.href = getDownloadableLink(value.uuid);
                document.body.appendChild(link);
                link.click();
                link.remove();
              }}
              isDocuments
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              data={data}
              setData={setData}
              handlePublishChange={handlePublishChange}
            />
          </>
        )}
        {viewType === "move-to-draft" && (
          <>
            <h3>{t("draft-message")}</h3>
          </>
        )}

        {viewType === "publish-to-website" && (
          <>
            <UnitBulkPublishDialog
              selected={selected}
              setSelected={setSelected}
              isSubmitted={isSubmitted}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              unitData={unitData}
              setUnitData={setUnitData}
              checkedCards={checkedCards}
              isLease={isLease}
              schema2={schema2}
              schemaErrors={schemaErrors}
            />
          </>
        )}
        {viewType === "unpublish" && (
          <>
            <h3>{t("unpublish-message")}</h3>
          </>
        )}
        {viewType === "share-Unit" && (
          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            <Spinner isActive={isLoading} isAbsolute />
            <div className="w-50 px-2 mb-2">
              <AutocompleteComponent
                isLoading={loading.systemName}
                idRef="SystemNameRef"
                labelValue={t(`${translationPath}System-Name`)}
                isSubmitted={isSubmitted}
                isWithError
                selectedValues={selected.systemName || null}
                data={allSystemName.res}
                displayLabel={(option) => option.name || ""}
                withoutSearchButton
                multiple={false}
                isDisabled={isEditDialog}
                helperText={getErrorByName(schema3, "SystemNameId").message}
                error={getErrorByName(schema3, "SystemNameId").error}
                onChange={(event, newValue) => {
                  setData({
                    id: "SystemNameId",
                    value: (newValue && newValue.id) || null,
                  });
                  setSelected({
                    id: "systemName",
                    value: newValue,
                  });
                  setData({
                    id: "branches",
                    value: null,
                  });
                  setData({
                    id: "users",
                    value: null,
                  });
                  setSelected({
                    id: "branchName",
                    value: null,
                  });
                  setSelected({
                    id: "specificUser",
                    value: null,
                  });
                  resetRelatedTo();
                  searchBranchesForShareEntity({
                    systemId: (newValue && newValue.id) || null,
                  });
                  searchApplicationUsersForShareEntity({
                    systemId: (newValue && newValue.id) || null,
                  });
                }}
                chipsLabel={(option) => t(`${option.name || ""}`)}
              />
            </div>
            <div className="w-50 px-2 mb-2">
              <AutocompleteComponent
                isLoading={loading.branchName}
                idRef="BranchNameRef"
                labelValue={t(`${translationPath}Branch-Name`)}
                selectedValues={selected.branchName || []}
                data={allBranchName.result}
                displayLabel={(option) => option.branchName || ""}
                withoutSearchButton
                isWithError
                helperText={getErrorByName(schema3, "branches").message}
                error={getErrorByName(schema3, "branches").error}
                multiple={true}
                isDisabled={data.SystemNameId === null}
                onChange={(event, newValue) => {
                  const updatedBranches = newValue.map((item) => ({
                    branchId: item.branchId,
                    name: item.branchName,
                  }));

                  setData({
                    id: "branches",
                    value: updatedBranches,
                  });
                  setSelected({
                    id: "branchName",
                    value: newValue,
                  });
                }}
                onKeyDown={() => {
                  setSelected({ id: "branchName", value: null });
                }}
                onInputKeyUp={(e) => {
                  const searchText = e?.target?.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    searchBranchesForShareEntity({ branchName: searchText });
                  }, 700);
                }}
                renderOption={(option) =>
                  ((option.branchName || option.branchId) &&
                    `${option.branchName}`) ||
                  ""
                }
                chipsLabel={(option) => t(`${option.branchName || ""}`)}
                isSubmitted={isSubmitted}
              />
            </div>
            <div className="w-50 px-2 mb-2">
              <AutocompleteComponent
                isLoading={loading.specificUser}
                idRef="SpecificUseRef"
                labelValue={t(`${translationPath}Specific-User`)}
                selectedValues={selected.specificUser || []}
                data={allSpecificUser.result}
                displayLabel={(option) => option.userName || ""}
                multiple={true}
                isWithError
                helperText={getErrorByName(schema3, "users").message}
                error={getErrorByName(schema3, "users").error}
                withoutSearchButton
                isDisabled={data.SystemNameId === null}
                chipsLabel={(option) => t(`${option.userName || ""}`)}
                onChange={(event, newValue) => {
                  const updatedUsers = newValue.map((item) => ({
                    userId: item.id,
                    name: item.firstName,
                  }));

                  setData({
                    id: 'users',
                    value: updatedUsers,
                  });
                  setSelected({
                    id: "specificUser",
                    value: newValue,
                  });
                }}
                onKeyDown={() => {
                  setSelected({ id: "specificUser", value: null });
                }}
                onInputKeyUp={(e) => {
                  const searchText = e?.target?.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    searchApplicationUsersForShareEntity({
                      userName: searchText,
                    });
                  }, 700);
                }}
                renderOption={(option) =>
                  ((option.userName || option.id) && `${option.userName}`) || ""
                }
                isSubmitted={isSubmitted}
              />
            </div>
          </div>
        )}
      </div>
      <DialogActions>
        <div className="buttons_container">
          <ButtonBase
            className="MuiButtonBase-root btns theme-solid bg-warning"
            onClick={onCloseClicked}
            disabled={isButtonDisabled}
          >
            {(viewType === "mark-as-available" ||
              viewType === "share-Unit" ||
              viewType === "publish-to-website") && (
              <>{t(`${translationPath}cancel`)}</>
            )}
            {(viewType === "move-to-draft" || viewType === "unpublish") && (
              <>{t(`${translationPath}reject`)}</>
            )}

            <span className="MuiTouchRipple-root" />
          </ButtonBase>
          {viewType === "mark-as-available" && (
            <ButtonBase
              className="MuiButtonBase-root btns theme-solid bg-cancel p-2 "
              onClick={onFillClicked}
              disabled={isButtonDisabled}
            >
              {t(`${translationPath}fill-empty-data`)}
              <span className="MuiTouchRipple-root" />
            </ButtonBase>
          )}
          <ButtonBase
            className="MuiButtonBase-root btns  theme-solid bg-save"
            onClick={onSaveClicked}
            disabled={isButtonDisabled}
          >
            {(viewType === "move-to-draft" || viewType === "unpublish") && (
              <>{t(`${translationPath}confirm`)}</>
            )}
            {(viewType === "mark-as-available" ||
              viewType === "publish-to-website") && (
              <>{t(`${translationPath}update-all`)}</>
            )}
            {viewType === "share-Unit" && (
              <>{t(`${translationPath}share-Units`)}</>
            )}
            <span className="MuiTouchRipple-root" />
          </ButtonBase>
        </div>
      </DialogActions>

      {confirmBulkStatusUpdateDialogIsOpen && (
        <DialogComponent
          isOpen={confirmBulkStatusUpdateDialogIsOpen}
          titleText={t("confirm-status-update")}
          maxWidth="lg"
          dialogContent={
            <>
              <ConfirmBulkStatusUpdateDialog
                viewType={viewType}
                onCloseClicked={closeConfirmBulkStatusUpdateDialog}
                unitsIdUpdateArray={unitsIdUpdateArray}
                setUnitsIdUpdateArray={setUnitsIdUpdateArray}
                isLease={isLease}
                selected={selected}
                data={data}
                publishedImages={publishedImages}
                operationTypeForDialog={operationTypeForDialog}
                loading={loading}
                setLoading={setLoading}
                onUnitsBulkCloseDialogHandler={onUnitsBulkCloseDialogHandler}
                reloadData={reloadData}
                onClearedAllFiltersClick={onClearedAllFiltersClick}
                isPublishedUnits={isPublishedUnits}
                unitData={unitData}
                setUnitData={setUnitData}
                isNeedApprovalMessageInMarketing={
                  isNeedApprovalMessageInMarketing
                }
                setNeedsApprovalDialog={setNeedsApprovalDialog}
                setTypeOfRequest={setTypeOfRequest}
                fieldsNeedsApproval={fieldsNeedsApproval}
                setFieldsNeedsApprovalDialog={setFieldsNeedsApprovalDialog}
              />
            </>
          }
        />
      )}
    </>
  );
};

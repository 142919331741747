// i want to create react context functional component?

import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { RetrieveLeadsFromPool, lookupItemsGetId } from '../../../Services';
import { showError } from '../../../Helper';
import { StaticLookupsIds } from '../../../assets/json/StaticLookupsIds';
import { LeadsPoolViewEnum } from './components/LeadsPoolViewEnum';
import {
  LeadsPoolCallCenterPermissions,
  LeadsPoolLeasePermissions,
  LeadsPoolSalesPermissions,
} from '../../../Permissions';

const _leadTypes = [
  {
    id: 3,
    name: 'Buyer',
    leadTypeCategoryId: 2,
    unitType: LeadsPoolViewEnum.sales,
    rolesName: 'Sale Agent',
  },
  {
    id: 1,
    name: 'Seller',
    leadTypeCategoryId: 1,
    unitType: LeadsPoolViewEnum.sales,
    rolesName: 'Sale Listing Agent',
  },
  {
    id: 4,
    name: 'Tenant',
    leadTypeCategoryId: 2,
    unitType: LeadsPoolViewEnum.lease,
    rolesName: 'Lease Agent',
  },
  {
    id: 2,
    name: 'Landlord',
    leadTypeCategoryId: 1,
    unitType: LeadsPoolViewEnum.lease,
    rolesName: 'Lease Listing Agent',
  },
];

const leadTypes = {
  [LeadsPoolViewEnum.sales]: _leadTypes.filter(
    (item) => item.unitType === LeadsPoolViewEnum.sales
  ),
  [LeadsPoolViewEnum.lease]: _leadTypes.filter(
    (item) => item.unitType === LeadsPoolViewEnum.lease
  ),
};
const leadCategories = [
  {
    id: 2,
    name: 'Seeker',
    initialLeadTypeId: 3,
  },
  {
    id: 1,
    name: 'Owner',
    initialLeadTypeId: 1,
  },
];

export const LeadsPoolContext = createContext({
  filter: {
    pageSize: 25,
    pageIndex: 1,
    operationLeadType: '',
  },
  setFilter: () => {},
  data: { result: [], totalCount: 0 },
  setData: () => {},
  viewType: '',
  isDataLoading: false,
  resetFilter: () => {},
  nationalities: [],
  languages: [],
  setLanguages: () => {},
  setNationalities: () => {},
  isLoading: {
    nationalities: false,
    languages: false,
  },
  leadTypes: _leadTypes,
  leadCategories,
  activeAction: '',
  setActiveAction: () => {},
  checkedCards: [],
  setCheckedCards: () => {},
  checkedCardsIds: [],
  permissions: LeadsPoolSalesPermissions,
});

const operationTypes = {
  sales: 'Sale',
  lease: 'Lease',
  contact: undefined,
};
const leadPoolPermissions = {
  sales: LeadsPoolSalesPermissions,
  lease: LeadsPoolLeasePermissions,
  contact: LeadsPoolCallCenterPermissions,
};
const LeadsPoolProvider = ({ children }) => {
  const location = useLocation();
  const viewType = location.pathname.split('/')[2].split(':')[0];
  const initialFilter = {
    pageSize: !isNaN(parseInt(localStorage.getItem('Pagination')))
      ? parseInt(localStorage.getItem('Pagination'))
      : 25,
    pageIndex: 1,
    operationLeadType: operationTypes[viewType],
  };
  const [filter, setFilter] = useState(initialFilter);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [activeAction, setActiveAction] = useState(null);
  const [checkedCards, setCheckedCards] = useState([]);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isLoading, setIsLoading] = useState({
    nationalities: false,
    languages: false,
  });
  const [data, setData] = useState({ result: [], totalCount: 0 });
  const [lookupData, setLookupData] = useState({
    nationalities: [],
    languages: [],
  });
  const getNationalities = async () => {
    try {
      setIsLoading((loadings) => ({ ...loadings, nationalities: true }));
      const res = await lookupItemsGetId({
        lookupTypeId: StaticLookupsIds.Country,
      });
      setLookupData((data) => ({ ...data, nationalities: res }));
      setIsLoading((loadings) => ({ ...loadings, nationalities: false }));
    } catch (error) {
      showError('something wen wrong');
    }
  };

  const checkedCardsIds = useMemo(
    () => checkedCards.map((card) => card.leadId),
    [checkedCards]
  );
  const getLanguages = async () => {
    try {
      setIsLoading((loadings) => ({ ...loadings, languages: true }));
      const res = await lookupItemsGetId({
        lookupTypeId: StaticLookupsIds.Languages,
      });
      setLookupData((data) => ({ ...data, languages: res }));
      setIsLoading((loadings) => ({ ...loadings, languages: false }));
    } catch (error) {
      showError('something went wrong');
    }
  };
  const retrieveLeadsFromPool = async () => {
    setIsDataLoading(true);
    try {
      const res = await RetrieveLeadsFromPool(filter);
      if (!(res.status && res.status !== 200)) {
        setData(res);
      }
    } catch (error) {
      showError('something went wrong');
    } finally {
      setIsDataLoading(false);
    }
  };
    const resetFilter = () => {
      setFilter(initialFilter)
      setIsClearFiltersClicked(true)
    }
  useEffect(() => {
    getNationalities();
    getLanguages();
  }, []);
  useEffect(() => {
    retrieveLeadsFromPool();
  }, [filter]);

  const categorizedLeadsTypes = useMemo(
    () =>
      filter.leadCategoryId
        ? _leadTypes.filter(
            (item) => item.leadTypeCategoryId === filter.leadCategoryId
          )
        : _leadTypes,
    [filter.leadCategoryId]
  );
  return (
    <LeadsPoolContext.Provider
      value={{
        filter,
        setFilter,
        data,
        setData,
        viewType,
        isDataLoading,
        resetFilter,
        isLoading,
        languages: lookupData.languages,
        nationalities: lookupData.nationalities,
        leadTypes: leadTypes[viewType] ?? categorizedLeadsTypes,
        leadCategories,
        activeAction,
        setActiveAction,
        checkedCards,
        setCheckedCards,
        checkedCardsIds,
        permissions: leadPoolPermissions[viewType],
        isClearFiltersClicked,
        setIsClearFiltersClicked
      }}
    >
      {children}
    </LeadsPoolContext.Provider>
  );
};

export default LeadsPoolProvider;

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { GetAdvanceSearchContacts, GetAllUserSearchCriteria } from '../../../Services';
import { ContactsMapper } from '../../../Views/Home/ContactsView/ContactsUtilities';
import { SearchCriteriaEnum } from '../../../Enums';

// Create the context
export const ContactLayoutContext = createContext();

// Create the provider component
export const AdvancedSearchProvider = ({ children }) => {
  
  const [advancedSearchBody, setAdvancedSearchBody] = useState({
    criteria: {},
    filterBy: 'createdOn',
    orderBy: 2,
    LeadClasses: [],
    contactClasses: [],
  });

  const [contactsData, setContactsData] = useState({
    result: [],
    totalCount: 0,
  });

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 25,
  });

  const [isLoading, setIsLoading] = useState(true);
  
  const [savedFiltersCriteria, setSavedFiltersCriteria] = useState([]);
  const [isCriteriaLoading, setIsCriteriaLoading] = useState([]);

  const fetchContacts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await GetAdvanceSearchContacts(
        {
          pageIndex: pagination.currentPage - 1,
          pageSize: pagination.itemsPerPage,
        },
        advancedSearchBody
      );

      if (!(response && response.status && response.status !== 200)) {
        setContactsData({
          result: ((response && response.result) || []).map(
            (item) =>
              item.contactJson &&
              ContactsMapper(item, JSON.parse(item.contactJson).contact)
          ),
          totalCount: (response && response.totalCount) || 0,
        });
      } else {
        setContactsData({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    } finally {
      setIsLoading(false);
    }
  }, [pagination, advancedSearchBody]);

  const getSearchCriteria = useCallback(async () => {
    setIsCriteriaLoading(true);

    try {
      const res = await GetAllUserSearchCriteria(SearchCriteriaEnum.Contact.typeId);

      if (!(res && res.status && res.status !== 200)) {
        setSavedFiltersCriteria(res);
      } else {
        setSavedFiltersCriteria([]);
      }
    } catch (error) {
      console.error("Failed to fetch search criteria:", error);
    } finally {
      setIsCriteriaLoading(false);
    }    
  }, []);

  useEffect(() => {
    fetchContacts();
  }, [pagination, advancedSearchBody]);

  useEffect(() => {
    getSearchCriteria();
  }, []);

  return (
    <ContactLayoutContext.Provider value={{
      advancedSearchBody,
      setAdvancedSearchBody,
      contactsData,
      pagination,
      setPagination,
      isLoading,
      savedFiltersCriteria,
      isCriteriaLoading,
    }}>
      {children}
    </ContactLayoutContext.Provider>
  );
};

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  AutocompleteComponent,
  SelectComponet,
  Inputs,
} from "../../../Components";
import { LocationFieldsComponent } from "../../../SharedComponents";
import {
  ClassificationsContactEnum,
  UnitsFilterStatusEnum,
} from "../../../Enums";
import {
  getProperties,
  GetLookupItemsByLookupTypeName,
  GetContacts,
  GetUnitsRefNumbers,
} from "../../../Services";
import { DateRangePickerComponent } from "../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import { InputAdornment } from "@material-ui/core";
import { StaticLookupsIds } from "../../../assets/json/StaticLookupsIds.jsx";

export const PrimaryUnitsFilterComponent = ({
  getUnitsData,
  isClearFiltersClicked,
}) => {
  const parentTranslationPath = "UnitsView";
  const translationPath = "";
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
    selectedDateType: 1,
  };

  const primaryDateFilter = [
    {
      key: 1,
      value: `${translationPath}created-date`,
      filterKeyFrom: null,
      filterKeyTo: null,
    },
    {
      key: 11,
      value: `${translationPath}handover-date`,
      filterKeyFrom: "handover_date_from",
      filterKeyTo: "handover_date_to",
    },
  ];

  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [SearchedItemUnitReferenceNumber, setSearchedItemUnitReferenceNumber] =
    useState("");

  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const { t } = useTranslation(parentTranslationPath);
  const [selected, setSelected] = useReducer(reducer, {});
  const [data, setData] = useReducer(reducer, {
    properties: [],
    unitTypes: [],
    paymentPlans: [],
    propertyPlans: [],
    developers: [],
    unitRef: [],
  });

  const [dateFilter, setDateFilter] = useState(
    (orderFilter &&
      orderFilter.UnitsSalesDate &&
      orderFilter.UnitsSalesDate.startDate &&
      orderFilter.UnitsSalesDate.endDat !== null && {
        startDate: new Date(
          (orderFilter &&
            orderFilter.UnitsSalesDate &&
            orderFilter.UnitsSalesDate.startDate) ||
            null
        ),
        endDate: new Date(
          (orderFilter &&
            orderFilter.UnitsSalesDate &&
            orderFilter.UnitsSalesDate.endDate) ||
            null
        ),
        key: new Date(
          (orderFilter &&
            orderFilter.UnitsSalesDate &&
            orderFilter.UnitsSalesDate.key) ||
            null
        ),
        selectedDateType:
          (orderFilter &&
            orderFilter.UnitsSalesDate &&
            orderFilter.UnitsSalesDate.selectedDateType) ||
          1,
      }) ||
      dateRangeDefault
  );
  const [filterCriteria, setFilterCriteria] = useState({});

  const getPropertiesOptions = async (searchValue) => {
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchValue || "",
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "properties", value: res?.result || [] });
    else setData({ id: "properties", value: [] });
  };

  const getUnitTypeLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "UnitType",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "unitTypes", value: res.result || [] });
    } else setData({ id: "unitTypes", value: [] });
  };

  const getPaymentPlanLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "PlanType",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "paymentPlans", value: res.result || [] });
    } else setData({ id: "paymentPlans", value: [] });
  };
  const getPropertyPlanLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Property Plan",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "propertyPlans", value: res.result || [] });
    } else setData({ id: "propertyPlans", value: [] });
  };

  const getDeveloperOptions = async (searchValue) => {
    const res = await GetContacts({
      pageIndex: 0,
      pageSize: 10,
      search: searchValue || "",
      classificationId: ClassificationsContactEnum[0].Id,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "developers", value: res?.result || [] });
    else setData({ id: "developers", value: [] });
  };

  const getUnitStatusOptions = () =>
    Object.values(UnitsFilterStatusEnum.sale).filter(
      (item) => item.value === "Draft" || item.value === "Available"
    );

  const filterCriteriaChangeHandler = (filterKey, filterValue, searchType) => {
    if (filterValue) {
      const newFilterItem = {
        [filterKey]: [
          {
            searchType,
            value: filterValue,
          },
        ],
      };
      const newFilterCriteria = {
        ...(filterCriteria || {}),
        ...newFilterItem,
      };
      setFilterCriteria(newFilterCriteria);
    } else {
      const newFilterCriteria = {
        ...(filterCriteria || {}),
      };
      delete newFilterCriteria[filterKey];
      setFilterCriteria(newFilterCriteria);
    }
  };

  const numericFilterValueHandler = (selectedKey) =>
    selected[selectedKey] || selected[selectedKey] == "0"
      ? selected[selectedKey]
      : "";

      const GetAllUnitsRef = useCallback(async (search) => {
        setSearchedItemUnitReferenceNumber(true);
        const res = await GetUnitsRefNumbers({
          unitRefNo: search,
          pageIndex: 1,
          pageSize: 10,
          operationType: StaticLookupsIds.Sales
        });
        if (!(res && res.status && res.status !== 200))
          setData({ id: "unitRef", value: res.result || [] });
        else setData({ id: "unitRef", value: [] });
       setSearchedItemUnitReferenceNumber(false);
      }, []);

  useEffect(() => {
    if (isClearFiltersClicked) {
      setFilterCriteria({});
      setSelected({ id: "edit", value: {} });
      setDateFilter(dateRangeDefault);
    }
  }, [isClearFiltersClicked]);

  useEffect(() => {
    getUnitsData(filterCriteria, dateFilter);
  }, [filterCriteria, dateFilter]);

  return (
    <div className="d-flex-column-center mb-3">
      <div className="d-flex-center  w-100">
        <AutocompleteComponent
          idRef="unitStatusRef"
          wrapperClasses="w-min-unset m-2 mr-1"
          inputPlaceholder={t(`${translationPath}Unit_Status`)}
          selectedValues={selected.unitStatus || null}
          data={getUnitStatusOptions()}
          displayLabel={(option) => option?.value || ""}
          multiple={false}
          withoutSearchButton
          onChange={(_, newValue) => {
            setSelected({ id: "unitStatus", value: newValue });

            const statusKey = "StringStatus";
            const statusValue = newValue?.value || null;
            const searchType = 1;
            filterCriteriaChangeHandler(statusKey, statusValue, searchType);
          }}
        />
        <AutocompleteComponent
          idRef="propertiesRef"
          inputPlaceholder={t(`${translationPath}property`)}
          wrapperClasses="w-min-unset m-2"
          selectedValues={selected.property || null}
          multiple={false}
          data={data.properties || []}
          displayLabel={(option) =>
            option?.property?.property_name || option?.name || ""
          }
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(_, newValue) => {
            setSelected({ id: "property", value: newValue });

            const propertyKey = "property_name";
            const propertyValue = newValue?.property?.property_name || null;
            const searchType = 1;

            filterCriteriaChangeHandler(propertyKey, propertyValue, searchType);
          }}
          onOpen={() => {
            if (data.properties && data.properties.length == 0)
              getPropertiesOptions();
          }}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getPropertiesOptions(value);
            }, 600);
          }}
        />

        <AutocompleteComponent
          idRef="unitTypeRef"
          wrapperClasses="w-min-unset m-2 mr-1"
          inputPlaceholder={t(`${translationPath}unit-Type`)}
          selectedValues={selected.unitType || null}
          data={data.unitTypes || []}
          displayLabel={(option) => option.lookupItemName || ""}
          multiple={false}
          withoutSearchButton
          onChange={(_, newValue) => {
            setSelected({ id: "unitType", value: newValue });
            const unitTypeKey = "unit_type";
            const unitTypeValue = newValue?.lookupItemName || null;
            const searchType = 1;

            filterCriteriaChangeHandler(unitTypeKey, unitTypeValue, searchType);
          }}
          onOpen={() => {
            if (data.unitTypes && data.unitTypes.length == 0)
              getUnitTypeLookups();
          }}
        />
        <AutocompleteComponent
          idRef="paymentPlanRef"
          wrapperClasses="w-min-unset m-2 mr-1"
          inputPlaceholder={t(`${translationPath}payment-plan`)}
          selectedValues={selected.paymentPlan || null}
          data={data.paymentPlans || []}
          displayLabel={(option) => option.lookupItemName || ""}
          multiple={false}
          withoutSearchButton
          onChange={(_, newValue) => {
            setSelected({ id: "paymentPlan", value: newValue });
            const paymentPlanKey = "PaymentPlanType";
            const paymentPlanValue = newValue?.lookupItemName || null;
            const searchType = 1;

            filterCriteriaChangeHandler(
              paymentPlanKey,
              paymentPlanValue,
              searchType
            );
          }}
          onOpen={() => {
            if (data.paymentPlans && data.paymentPlans.length == 0)
              getPaymentPlanLookups();
          }}
        />
      </div>
      <div className="d-flex-center w-100">
        <AutocompleteComponent
          idRef="developerRef"
          inputPlaceholder={t(`${translationPath}developer`)}
          selectedValues={selected.developer || null}
          wrapperClasses="w-min-unset m-2 mr-1"
          data={data.developers || []}
          displayLabel={(option) =>
            option.contact.company_name ||
            (option.contact &&
              (option.contact.first_name || option.contact.last_name) &&
              `${option.contact.first_name} ${option.contact.last_name}`) ||
            ""
          }
          multiple={false}
          withoutSearchButton
          onChange={(_, newValue) => {
            setSelected({
              id: "developer",
              value: {
                contactsId: newValue?.contactsId,
                contact: {
                  first_name: newValue?.contact && newValue?.contact.first_name,
                  last_name: newValue?.contact && newValue?.contact.last_name,
                  company_name:
                    newValue?.contact && newValue?.contact.company_name,
                },
              },
            });

            const developerKey = "DeveloperName";
            const developerValue =
              newValue?.contact?.company_name ||
              (newValue?.contact &&
                (newValue?.contact?.first_name ||
                  newValue?.contact?.last_name) &&
                `${newValue?.contact?.first_name} ${newValue?.contact?.last_name}`) ||
              "";
            const searchType = 2;

            filterCriteriaChangeHandler(
              developerKey,
              developerValue,
              searchType
            );
          }}
          onOpen={() => {
            if (data.developers && data.developers.length == 0)
              getDeveloperOptions();
          }}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getDeveloperOptions(value);
            }, 600);
          }}
        />
        <AutocompleteComponent
          idRef="propertyPlanRef"
          inputPlaceholder={t(`${translationPath}property-Plan`)}
          selectedValues={selected.propertyPlan || null}
          wrapperClasses="w-min-unset m-2 mr-1"
          data={data.propertyPlans || []}
          displayLabel={(option) => option.lookupItemName || ""}
          multiple={false}
          withoutSearchButton
          onChange={(_, newValue) => {
            setSelected({ id: "propertyPlan", value: newValue });
            const propertyPlanValue = newValue?.lookupItemId || null;
            const propertyPlanKey = "PropertyPlanId";
            const searchType = 1;

            filterCriteriaChangeHandler(
              propertyPlanKey,
              propertyPlanValue,
              searchType
            );
          }}
          onOpen={() => {
            if (data.propertyPlans && data.propertyPlans.length == 0)
              getPropertyPlanLookups();
          }}
        />
        <AutocompleteComponent
          inputPlaceholder={t(`${translationPath}Unit-Reference-Number`)}
          wrapperClasses="w-min-unset m-2 mr-1"
          data={data.unitRef}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          withoutSearchButton
          isLoading={SearchedItemUnitReferenceNumber}
          displayLabel={(option) => option?.unitRefNo || ""}
          onInputKeyUp={(event) => {
            const { value } = event.target;
            if (searchTimer.current)
              clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              GetAllUnitsRef(value);
            }, 700);
          }}
          chipsLabel={(option) => option?.unitRefNo || ""}
          multiple={false}
          onChange={(_, newValue) => {
            if (newValue) {
              const propertyPlanValue =
                (newValue && newValue.unitRefNo) || "";
              const propertyPlanKey = "unit_ref_no";
              const searchType = 2;
              filterCriteriaChangeHandler(
                propertyPlanKey,
                propertyPlanValue,
                searchType
              );
            } else {
              setFilterCriteria({});
              setSelected({ id: "edit", value: {} });
              setDateFilter(dateRangeDefault);
              getUnitsData();
            }
          }}
        />
        <div className="w-100 customDatePicker">
          <div className="date-type-select">
            <SelectComponet
              data={primaryDateFilter}
              defaultValue={{ key: 1, value: `${translationPath}created-date` }}
              value={dateFilter.selectedDateType || 1}
              valueInput="key"
              textInput="value"
              onSelectChanged={(value) => {
                setDateFilter((f) => ({ ...f, selectedDateType: value }));
              }}
              wrapperClasses="w-auto"
              themeClass="theme-transparent"
              idRef="Date_Select"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <DateRangePickerComponent
            onClearClicked={() => {
              setDateFilter(dateRangeDefault);
            }}
            ranges={[dateFilter]}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onDateChanged={(selectedDate) => {
              setDateFilter((item) => ({
                ...item,
                startDate:
                  selectedDate.selection && selectedDate.selection.startDate,
                endDate: new Date(
                  moment(
                    selectedDate.selection && selectedDate.selection.endDate
                  ).endOf("day")
                ),
                key: "selection",
              }));
            }}
          />
        </div>
      </div>
      <div className="d-flex-center filter-section">
        <fieldset className="styled-fieldset d-flex mx-2">
          <legend className="styled-legend">
            {t(`${translationPath}price`)}
          </legend>
          <div className="w-50 mr-2">
            <Inputs
              idRef="priceFromRef"
              labelClasses="Requierd-Color"
              inputPlaceholder={t(`${translationPath}from`)}
              value={numericFilterValueHandler("priceFrom")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "priceFrom",
                  value: value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const priceFromKey = "Selling_price_from";
                  const priceFromValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 3;

                  filterCriteriaChangeHandler(
                    priceFromKey,
                    priceFromValue,
                    searchType
                  );
                }, 600);
              }}
              endAdornment={
                <InputAdornment position="start" className="px-2">
                  AED
                </InputAdornment>
              }
              min={0}
            />
          </div>
          <div className="w-50">
            <Inputs
              idRef="priceToRef"
              labelClasses="Requierd-Color"
              labelValue={t(`${translationPath}to`)}
              value={numericFilterValueHandler("priceTo")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "priceTo",
                  value: value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const priceToKey = "Selling_price_to";
                  const priceToValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 4;

                  filterCriteriaChangeHandler(
                    priceToKey,
                    priceToValue,
                    searchType
                  );
                }, 600);
              }}
              endAdornment={
                <InputAdornment position="start" className="px-2">
                  AED
                </InputAdornment>
              }
              min={0}
            />
          </div>
        </fieldset>
        <fieldset className="styled-fieldset d-flex mx-2">
          <legend className="styled-legend">
            {t(`${translationPath}size`)}
          </legend>
          <div className="w-50 mr-2">
            <Inputs
              idRef="sizeFromRef"
              labelClasses="Requierd-Color"
              labelValue={t(`${translationPath}from`)}
              value={numericFilterValueHandler("sizeFrom")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "sizeFrom",
                  value: value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const sizeFromKey = "builtup_area_sqft_from";
                  const sizeFromValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 3;

                  filterCriteriaChangeHandler(
                    sizeFromKey,
                    sizeFromValue,
                    searchType
                  );
                }, 600);
              }}
              min={0}
            />
          </div>
          <div className="w-50">
            <Inputs
              idRef="sizeToRef"
              labelClasses="Requierd-Color"
              labelValue={t(`${translationPath}to`)}
              value={numericFilterValueHandler("sizeTo")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "sizeTo",
                  value: value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const sizeToKey = "builtup_area_sqft_to";
                  const sizeToValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 4;

                  filterCriteriaChangeHandler(
                    sizeToKey,
                    sizeToValue,
                    searchType
                  );
                }, 600);
              }}
              min={0}
            />
          </div>
        </fieldset>
        <fieldset className="styled-fieldset d-flex mx-2">
          <legend className="styled-legend">
            {t(`${translationPath}ROI`)}
          </legend>
          <div className="w-50 mr-2">
            <Inputs
              idRef="ROIFromRef"
              labelClasses="Requierd-Color"
              labelValue={t(`${translationPath}from`)}
              value={numericFilterValueHandler("ROIFrom")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "ROIFrom",
                  value: value > 100 ? 0 : value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const ROIFromKey = "sale_roi_from";
                  const ROIFromValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(
                    ROIFromKey,
                    ROIFromValue,
                    searchType
                  );
                }, 600);
              }}
              endAdornment={
                <InputAdornment position="start" className="px-2">
                  %
                </InputAdornment>
              }
              min={0}
            />
          </div>
          <div className="w-50">
            <Inputs
              idRef="ROIToRef"
              labelClasses="Requierd-Color"
              labelValue={t(`${translationPath}to`)}
              value={numericFilterValueHandler("ROITo")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "ROITo",
                  value: value > 100 ? 0 : value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const ROIToKey = "sale_roi_to";
                  const ROIToValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(ROIToKey, ROIToValue, searchType);
                }, 600);
              }}
              endAdornment={
                <InputAdornment position="start" className="px-2">
                  %
                </InputAdornment>
              }
              min={0}
            />
          </div>
        </fieldset>
        <fieldset className="styled-fieldset d-flex mx-2">
          <legend className="styled-legend">
            {t(`${translationPath}downPayment`)}
          </legend>
          <div className="w-50 mr-2">
            <Inputs
              idRef="downPaymentFromRef"
              labelClasses="Requierd-Color"
              labelValue={t(`${translationPath}from`)}
              value={numericFilterValueHandler("downPaymentFrom")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "downPaymentFrom",
                  value: value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const downPaymentFromKey = "downpayment_from";
                  const downPaymentFromValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 3;

                  filterCriteriaChangeHandler(
                    downPaymentFromKey,
                    downPaymentFromValue,
                    searchType
                  );
                }, 600);
              }}
              min={0}
            />
          </div>
          <div className="w-50">
            <Inputs
              idRef="downPaymentToRef"
              labelClasses="Requierd-Color"
              labelValue={t(`${translationPath}to`)}
              value={numericFilterValueHandler("downPaymentTo")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "downPaymentTo",
                  value: value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const downPaymentToKey = "downpayment_to";
                  const downPaymentToValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 4;

                  filterCriteriaChangeHandler(
                    downPaymentToKey,
                    downPaymentToValue,
                    searchType
                  );
                }, 600);
              }}
              min={0}
            />
          </div>
        </fieldset>
        <fieldset className="styled-fieldset d-flex mx-2">
          <legend className="styled-legend">
            {t(`${translationPath}Service-Charge`)}
          </legend>
          <div className="w-50 mr-2">
            <Inputs
              idRef="serviceChargeFromRef"
              labelClasses="Requierd-Color"
              labelValue={t(`${translationPath}from`)}
              value={numericFilterValueHandler("serviceChargeFrom")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "serviceChargeFrom",
                  value: value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const serviceChargeFromKey = "services_charge_from";
                  const serviceChargeFromValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 3;

                  filterCriteriaChangeHandler(
                    serviceChargeFromKey,
                    serviceChargeFromValue,
                    searchType
                  );
                }, 600);
              }}
              endAdornment={
                <InputAdornment position="start" className="px-2">
                  AED
                </InputAdornment>
              }
              min={0}
            />
          </div>
          <div className="w-50">
            <Inputs
              idRef="serviceChargeToRef"
              labelClasses="Requierd-Color"
              labelValue={t(`${translationPath}to`)}
              value={numericFilterValueHandler("serviceChargeTo")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "serviceChargeTo",
                  value: value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const serviceChargeToKey = "services_charge_to";
                  const serviceChargeToValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 4;

                  filterCriteriaChangeHandler(
                    serviceChargeToKey,
                    serviceChargeToValue,
                    searchType
                  );
                }, 600);
              }}
              endAdornment={
                <InputAdornment position="start" className="px-2">
                  AED
                </InputAdornment>
              }
              min={0}
            />
          </div>
        </fieldset>
        <fieldset className="styled-fieldset d-flex mx-2">
          <legend className="styled-legend">
            {t(`${translationPath}bedrooms`)}
          </legend>
          <div className="w-50 mr-2">
            <Inputs
              idRef="bedroomsNoFromRef"
              labelClasses="Requierd-Color"
              labelValue={t(`${translationPath}from`)}
              value={numericFilterValueHandler("bedroomsNoFrom")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "bedroomsNoFrom",
                  value: value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const bedroomsNoFromKey = "bedrooms_from";
                  const bedroomsNoFromValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 3;

                  filterCriteriaChangeHandler(
                    bedroomsNoFromKey,
                    bedroomsNoFromValue,
                    searchType
                  );
                }, 600);
              }}
              min={0}
            />
          </div>
          <div className="w-50">
            <Inputs
              idRef="bedroomsNoToRef"
              labelClasses="Requierd-Color"
              labelValue={t(`${translationPath}to`)}
              value={numericFilterValueHandler("bedroomsNoTo")}
              type={"number"}
              withNumberFormat
              onInputChanged={(event) => {
                const { value } = event.target;
                setSelected({
                  id: "bedroomsNoTo",
                  value: value,
                });
              }}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  const bedroomsNoToKey = "bedrooms_to";
                  const bedroomsNoToValue =
                    value || value == 0 ? value.replace(/,/g, "") : null;
                  const searchType = 4;

                  filterCriteriaChangeHandler(
                    bedroomsNoToKey,
                    bedroomsNoToValue,
                    searchType
                  );
                }, 600);
              }}
              min={0}
            />
          </div>
        </fieldset>
      </div>
      <div className="d-flex-center  mt-2">
        <div className="d-flex-center ">
          <fieldset className="styled-fieldset d-flex mx-2">
            <legend className="styled-legend">
              {t(`${translationPath}location`)}
            </legend>
            <LocationFieldsComponent
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChangeHandlers={{
                countryHandler: (newValue) => {
                  const countryKey = "country";
                  const countryValue =
                    (newValue && newValue.lookupItemName) || null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(
                    countryKey,
                    countryValue,
                    searchType
                  );
                },
                cityHandler: (newValue) => {
                  const cityKey = "city";
                  const cityValue =
                    (newValue && newValue.lookupItemName) || null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(cityKey, cityValue, searchType);
                },
                districtHandler: (newValue) => {
                  const districtKey = "district";
                  const districtValue =
                    (newValue && newValue.lookupItemName) || null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(
                    districtKey,
                    districtValue,
                    searchType
                  );
                },
                communityHandler: (newValue) => {
                  const communityKey = "community";
                  const communityValue =
                    (newValue && newValue.lookupItemName) || null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(
                    communityKey,
                    communityValue,
                    searchType
                  );
                },
                subCommunityHandler: (newValue) => {
                  const subCommunityKey = "subCommunity";
                  const subCommunityValue =
                    (newValue && newValue.lookupItemName) || null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(
                    subCommunityKey,
                    subCommunityValue,
                    searchType
                  );
                },
              }}
            />
          </fieldset>
        </div>
      </div>
    </div>
  );
};

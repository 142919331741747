import React, { useRef, useEffect, useCallback, useState } from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../../../../../SharedComponents";
import { Avatar } from "@material-ui/core";
import { Inputs, Tables } from "../../../../../../Components";
import { UsersManagementPermissions} from '../../../../../../Permissions' ; 
import {
  getDownloadableLink,
  getFirstLastNameLetters,
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  getIsAllowedPermission
} from "../../../../../../Helper";
import {DeactivatedUserSideDetails} from './DeactivatedUserSideDetails';
import { AddUserDialog } from "../../Dialogs";

export const DeactivatedUsersView = ({
  parentTranslationPath,
  translationPath,
  usersData,
  totalCount,
  reloadData,
  setFilter,
  filter,
  onPageSizeChanged,
  onPageIndexChanged,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [deactivatedUserId, setDeactivatedUserId] = useState(null);

  const getTableActionsWithPermissions = (rowData) => {
    const actionsList = [];
    
   if (getIsAllowedPermission(
      Object.values(UsersManagementPermissions),
      loginResponse,
      UsersManagementPermissions.ReactiveUserButton.permissionsId
    )){
      const reActivateUserAction = (
        <Button className="btns propx-action-btn p-2 theme-propx outlined light-text"
          onClick={() => {
            setDeactivatedUserId(rowData.id);
            setIsAddUserDialogOpen(true);
          }}
        >
          {t(`${translationPath}reactivate`)}
        </Button>);
        actionsList.push(reActivateUserAction);

     }
    const viewInformationAction = (
      <span
        className="fw-simi-bold mx-2 pointer c-propx"
        onClick={() => (rowData.id ? campaignClickHandler(rowData) : null)}
      >
        {t(`${translationPath}show-details`)}
      </span>
    );
    actionsList.push(viewInformationAction);


    return actionsList;
  };

  const campaignClickHandler = useCallback((item) => {
    sideMenuComponentUpdate(
      <DeactivatedUserSideDetails
      taskId={item?.id}
      campaignId={item?.campaignId}
      buttonsGroup={"TasksView"}
      closeSideMenu={() => {
        sideMenuIsOpenUpdate(false);
      }}
      userId={item.id}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      reloadData={() => ''}
    />
    );
    sideMenuIsOpenUpdate(true);
  }, []);

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  return (
    <div className="w-80">
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"DeactivatedUsers"}
          subTitle={"dectivated-users-heading"}
          wrapperClasses={"px-4 w-50"}
          headerTitleClasses={"fz-16 mb-1"}
          subTitleClasses={"fz-14 m-0 ml-0"}
        />
        <div className="w-50 px-4 d-flex-v-center-h-between">
          <div className="w-100 d-flex-column fa-end mr-3">
            <div className="w-75 d-flex fj-end mr-3">
              <Inputs
                idRef="user-nameRef"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.userName || ''}
                onInputChanged={(event) => {
                  const userName = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    userName: userName || null,
                    userId: null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}name`)}
              />
              <Inputs
                idRef="platform-nameRef"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.userName || ''}
                onInputChanged={(event) => {
                  const platformName = event?.target?.value;
                    setFilter({
                      ...filter,
                      platformName: platformName || null,
                    });
                }}
                inputPlaceholder={t(`${translationPath}application`)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="propx-view-container sm  m-3">
        <Tables
          data={usersData || []}
          headerData={[
            {
              id: 1,
              label: "user",
              component: (item) =>
                (item?.name ? (
                  <div className="d-flex-v-center">
                    <div className="campaign-team-f1">
                      <Avatar
                        className="avatars-wrapper team-avatar fz-12 sm-theme"
                        src={
                          item?.profileImage
                            ? getDownloadableLink(item?.profileImage)
                            : ""
                        }
                      >
                        {getFirstLastNameLetters(item?.name || "")}
                      </Avatar>
                    </div>
                    <div className="d-flex-column">
                      <span className="fz-12 fw-simi-bold">{item?.name || "-"}</span>
                      <span className="fz-10 text-tertiary">
                        {item?.email || "-"}
                      </span>
                    </div>
                  </div>
                ) : (
                  "-"
                )) || "",
            },
            {
              id: 2,
              label: "branch",
              input: "branch",
            },
            {
              id: 3,
              label: "activation-date",
              input: "activationDate",
              component: (item) =>
                (
                  <span>
                    {item.activationDate
                      ? moment(item.activationDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 4,
              label: "deactivation-date",
              input: "deActivationDate",
              component: (item) =>
                (
                  <span>
                    {item.deActivationDate
                      ? moment(item.deActivationDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 5,
              label: "actions",
              component: (item) =>
              (<span className="d-flex-center fj-start"> {getTableActionsWithPermissions(item) || ""} </span>)
            },
          ]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          totalItems={totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          defaultActions={[]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      {isAddUserDialogOpen && (
        <AddUserDialog
          isDialogOpen={isAddUserDialogOpen}
          reactiveUserId={deactivatedUserId}
          onSave={() => {
            setIsAddUserDialogOpen(false);
            reloadData();
          }}
          onClose={() => {
            setIsAddUserDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};

import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConvertJsonV2Component } from '../../../../Components';
import { LookupsRules } from '../../../../Rule';

export const NewValuesSection = ({
  errors,
  formData,
  propertyDetails,
  isSubmitted,
  setFormData,
  setPropertyDetails,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [loadings, setLoadings] = useState([]);
  const onItemChanged =
    (item, index) => (newValue, itemIndex, itemKey, parentItemKey) => {
      setFormData((elements) => {
        if (parentItemKey && elements[0][itemIndex]) {
          if (itemIndex !== undefined)
            elements[0][itemIndex][parentItemKey][itemKey] = newValue;
          else elements[0][index][parentItemKey][itemKey] = newValue;
        } else if (itemIndex && elements[0][itemIndex])
          elements[0][itemIndex][itemKey] = newValue;
        return [...elements];
      });
    };
  const onValueChanged = (item) => (newValue, itemIndex) => {
    if (newValue !== '') {
      setPropertyDetails((items) => {
        if ((itemIndex || itemIndex === 0) && itemIndex !== -1)
          items[
            formData &&
              formData.flat() &&
              formData.flat().field &&
              formData.flat().field.id
          ] = newValue;
        else items[item.field.id] = newValue;
        return { ...items };
      });
    }
  };
  const onLoadingsChanged = useCallback((value, key) => {
    setLoadings((items) => {
      const itemIndex = items.findIndex((item) => item.key === key);
      if (value) {
        const addItem = {
          key,
          value,
        };
        if (itemIndex !== -1) items[itemIndex] = addItem;
        else items.push(addItem);
      } else if (itemIndex !== -1) items.splice(itemIndex, 1);
      return [...items];
    });
  }, []);

  const lookupInit = useCallback(() => {
    LookupsRules(formData.flat(), propertyDetails, onLoadingsChanged);
  }, [formData, onLoadingsChanged, propertyDetails]);

  useEffect(() => {
    if (formData && formData.flat() && formData.flat().length > 0) lookupInit();
  }, [formData, lookupInit]);
  return (
    <>
      <div className='bulk-header-section'>
        {t(`${translationPath}new-values`)}
      </div>
      <div className='bulk-sub-header-section'>
        {t(`${translationPath}add-new-value-to-the-following-fields`)}
      </div>
      <div className='bulked-units-section new-value-section mt-3'>
        {formData &&
          formData.length > 0 &&
          formData.flat().length > 0 &&
          formData
            .flat()
            .filter((item) => item)
            .map((item, index) => (
              <div className='mb-3' key={`propertiesItemRef${index + 1}`}>
                <ConvertJsonV2Component
                  item={item}
                  isSubmitted={isSubmitted}
                  allItems={formData.flat().filter((element) => element)}
                  key={`propertyForms${index + 2}`}
                  allItemsValues={propertyDetails}
                  onItemChanged={onItemChanged(item, index)}
                  itemValue={
                    (item &&
                      item.field &&
                      item.field.id &&
                      propertyDetails[item.field.id]) ||
                    ''
                  }
                  onValueChanged={onValueChanged(item, index)}
                  isLoading={
                    loadings.findIndex(
                      (element) =>
                        item && element.key === item.field.id && element.value
                    ) !== -1
                  }
                  onLoadingsChanged={onLoadingsChanged}
                  helperText={
                    (errors.find(
                      (element) => item && element.key === item.field.id
                    ) &&
                      errors.find(
                        (element) => item && element.key === item.field.id
                      ).message) ||
                    ''
                  }
                  error={
                    errors.findIndex(
                      (element) => item && element.key === item.field.id
                    ) !== -1
                  }
                  hideDeposit={true}
                />
              </div>
            ))}
      </div>
    </>
  );
};

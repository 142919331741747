import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import moment from 'moment';
import { Button, ButtonBase, Tooltip } from '@material-ui/core';
import {
  getErrorByName,
  GetParams,
  showError,
  showSuccess,
  bottomBoxComponentUpdate,
  returnPropsByPermissions,
} from '../../../../../Helper';
import { GlobalHistory, GlobalTranslate } from '../../../../../Helper/Middleware.Helper';
import {
  EditOrganizationUserProfile,
  lookupItemsGetId,
  GetAllRolesByUserId,
  OrganizationUserSearch,
  GetUserId,
  GetUserPolicyService,
  GetlookupTypeItems,
  GetAllSystemTemplates,
  RunSystemTemplateForUnit,
  GetLookupItemsByLookupTypeName,
  CheckExistEmail,
  CheckExistStaffId,
  GetContacts,
  getProperties,
  GetApplicationUserDetailsByUserId
} from '../../../../../Services';
import {
  GetUserTeamsInfo,
  GetAllTeamsByManagerId,
  GetAllBranches,
  GetApplicationUserById,
  GetInActiveUserRoles,
  GetInActiveUserPolicy
} from '../../../../../Services/userServices';
import { phoneExpression } from '../../../../../Utils/Expressions';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
import {
  AutocompleteComponent,
  Inputs,
  PhonesComponent,
  DatePickerComponent,
  SelectComponet,
} from '../../../../../Components/Controls';
import {
  PermissionsComponent,
  RadiosGroupComponent,
  Spinner,
  SwitchComponent,
  UploaderComponentCircular,
} from '../../../../../Components';
import { TeamDetails } from '../Team/TeamDetails';
import { TeamManager } from '../Team/TeamManager';
import {
  CityTypeIdEnum,
  CommunityTypeIdEnum,
  DistrictTypeIdEnum,
  SubCommunityTypeIdEnum,
  DefaultImagesEnum,
  UserStatusEnum,
  UserOperationTypeEnum,
  ClassificationsContactEnum
} from '../../../../../Enums';
import { useTitle } from '../../../../../Hooks';
import { AssignRoleDialog, AssignPolicyDialog } from '../UserUtilties';
import { UserLoginPermissions, PolicyPermissions } from '../../../../../Permissions';
import { ChangePasswordDialog } from './ChangePasswordDialog';
import { UserLoginDialog } from './UserLoginDialog';
import { UserLeaves } from './UserLeaves/UserLeaves';
import { emailExpression } from '../../../../../Utils/Expressions';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';
const translationPath = 'UsersManagementView.';
export const EditUserView = () => {

  const parentTranslationPath = 'UsersView';
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}edit-user`));
  const typingTimer = useRef(null);
  const URLUserId = GetParams('id');
  const searchTimer = useRef(null);
  const reducer = (select, action) => {
    if (action.id !== 'edit') return { ...select, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  };

  const initialState = {
    firstName: '',
    secondName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    profileImg: null,
    whatsAppNumber: '',
    nationality: null,
    dateOfBirth: null,
    dateOfJoining: null,
    registrationNo: null,
    jobTitleId: null,
    branchId: null,
    reportsToId: null,
    isDefaultBranchUser: null,
    staffCode: null,
    languages: [],
    targetMin: null,
    targetMax: null,
    aboutMe: '',
    totalYearsOfExperience: null,
    userAreaOfFocusSubCommunities: [],
  };
  const [state, setState] = useReducer(reducer, initialState);
  const [selected, setSelected] = useReducer(reducer, {
    reportsTo: null,
    branch: null,
    jobTitle: null,
    templte: null,
    department: null,
    station: null,
    developerSpecialistFor: null,
    propertySpecialistFor: null,
    operationType: null,
    unitSaleType: null,
    carAvailability: false,
    drivingLicenseAvailability: false,
  });
  const [selectedAdress, setSelectedAdress] = useReducer(reducer, {
    country: [],
    city: [],
    district: [],
    community: [],
    subCommunity: [],
  });
  const [data, setData] = useReducer(reducer, {
    users: [],
    templte: [],
    branches: [],
    jobTitles: [],
    languages: [],
    countries: [],
    cities: [],
    districts: [],
    communities: [],
    subCommunities: [],
    departments: [],
    stations: [],
    unitSaleTypes: [],
    developers: [],
    properties: [],
  });
  const [isFieldLoading, setIsFieldLoading]  = useReducer(reducer, {
    developers: false,
    properties: false,
  });
  const [isExist, setIsExist] = useState({
    email: null,
    staffCode: null,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTemplte, setisLoadingTemplte] = useState(false);
  const [appUserId, setAppUserId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [userTeams, setUserTeams] = useState(null);
  const [managerRelatedTeams, setManagerRelatedTeams] = useState(null);
  const [userRoles, setUserRoles] = useState(null);
  const [userPolicy, setUserPolicy] = useState(null);

  const [isOpenPolicyDialog, setIsOpenPolicyDialog] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [isOpenUserLoginDialog, setIsOpenUserLoginDialog] = useState(false);
  const [isOpenRole, setIsOpenRole] = useState(false);

  const schema = Joi.object({
    firstName: Joi.string()
      .regex(/^.*\S*.*$/)
      .trim()
      .required()
      .messages({
        'string.empty': t(`${translationPath}first-name-is-required`),
      }),
    lastName: Joi.string()
      .regex(/^.*\S*.*$/)
      .trim()
      .required()
      .messages({
        'string.empty': t(`${translationPath}last-name-is-required`),
      }),
      email: Joi.string()
      .required()
      .regex(emailExpression)
      .custom(
        (value, helpers) =>
          (isExist?.email && helpers.error("any.invalid")) || value,
        t(`${translationPath}email-is-already-exist`)
      )
      .messages({
        "any.invalid": t(`${translationPath}email-is-already-exist`),
        "string.empty": t(`${translationPath}email-is-required`),
        "string.pattern.base": t(`${translationPath}invalid-email`),
      }),
      staffCode: Joi.any()
      .custom((value, helpers) => {
        return (isExist?.staffCode && helpers.error("any.invalid")) || value;
      }, t(`${translationPath}staffCode-is-already-exist`))
      .messages({
        "any.invalid": t(`${translationPath}staffCode-is-already-exist`),
      }),
    targetMin:
      (state.targetMax &&
        Joi.number()
          .required()
          .messages({
            'number.base': t(`${translationPath}targetMin-is-required`),
            'number.empty': t(`${translationPath}targetMin-is-required`),
          })) ||
      Joi.any(),
    nationality: Joi.object()
      .required()
      .messages({
        // 'any.required':  (`${translationPath}nationality-is-required`),
        'object.base': t(`${translationPath}nationality-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const manageRolesClicked = () => {
    setIsOpenRole(true);
  };
  const displayDataOnly = (permissionsId) => {
    if (URLUserId) return !returnPropsByPermissions(permissionsId);
    return false;
  };

  const getCountries = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Country,
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'countries', value: res || [] });
    else setData({ id: 'countries', value: [] });
  };
  const getAllCities = async (searchItem) => {
    const res = await GetlookupTypeItems({
      lookupTypeId: CityTypeIdEnum.lookupTypeId,
      pageIndex: 0,
      pageSize: 10,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'cities', value: res?.result || [] });
    else setData({ id: 'cities', value: [] });
  };
  const getAllDistricts = async (searchItem) => {
    const res = await GetlookupTypeItems({
      lookupTypeId: DistrictTypeIdEnum.lookupTypeId,
      pageIndex: 0,
      pageSize: 10,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'districts', value: res?.result || [] });
    else setData({ id: 'districts', value: [] });
  };
  const getAllCommunities = async (searchItem) => {
    const res = await GetlookupTypeItems({
      lookupTypeId: CommunityTypeIdEnum.lookupTypeId,
      pageIndex: 0,
      pageSize: 10,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'communities', value: res?.result || [] });
    else setData({ id: 'communities', value: [] });
  };
  const getAllSubCommunities = async (searchItem) => {
    const res = await GetlookupTypeItems({
      lookupTypeId: SubCommunityTypeIdEnum.lookupTypeId,
      pageIndex: 0,
      pageSize: 10,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'subCommunities', value: res?.result || [] });
    else setData({ id: 'subCommunities', value: [] });
  };
  const getJobTitle = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.JobTitle,
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'jobTitles', value: res || [] });
    else setData({ id: 'jobTitles', value: [] });
  };

  const getUnitSaleTypes = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "UnitSaleType",
      pageSize: 100,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "unitSaleTypes", value: res?.result || [] });
    } else setData({ id: "unitSaleTypes", value: [] });
  };

  const getSystemProperties = async (searchValue) => {
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchValue || "",
    });
    if (!(res && res.status && res.status !== 200)) {
      return res?.result || [];
    } else return [];
  };

  const getDevelopers = async (searchValue) => {
    const res = await GetContacts({
      pageIndex: 0,
      pageSize: 10,
      search: searchValue || "",
      classificationId: ClassificationsContactEnum[0].Id,
    });
    if (!(res && res.status && res.status !== 200)) {
      return res?.result || [];
    } else return [];
  };

  const getAllDevelopers = async (searchValue) => {
    setIsFieldLoading({ id: "developers", value: true });

    const res = await getDevelopers(searchValue);

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "developers", value: res || [] });
    } else setData({ id: "developers", value: [] });

    setIsFieldLoading({ id: "developers", value: false });
  }

  const getAllProperties = async (searchValue) => {
    setIsFieldLoading({ id: "properties", value: true });

    const res = await getSystemProperties(searchValue);

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "properties", value: res || [] });
    } else setData({ id: "properties", value: [] });

    setIsFieldLoading({ id: "properties", value: false });
  }



  const getLanguages = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Languages,
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'languages', value: res || [] });
    else setData({ id: 'languages', value: [] });
  };
  const getAllUsers = async ({name , userStatusId}) => {
    const res = await OrganizationUserSearch({
      userStatusId,
      name: name || '',
      pageIndex: 0,
      pageSize: 10,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'users', value: res?.result || [] });
    } else setData({ id: 'users', value: [] });
  };
  const getBranches = async (branchName) => {
    const res = await GetAllBranches({ pageSize: 25, pageIndex: 1, branchName });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'branches', value: res.result || [] });
    } else setData({ id: 'branches', value: [] });
  };
  const getUserDetailsById = async () => {
    const res = await GetApplicationUserById(URLUserId);
    if (!(res && res.status && res.status !== 200)) setUserDetails(res);
  };
  const getAppUserId = async () => {
    const res = await GetUserId(URLUserId);
    if (!(res && res.status && res.status !== 200)) {
      localStorage.setItem('userid', JSON.stringify(res));
      setAppUserId(res);
    }
  };
  const getUserTeams = async () => {
    const res = await GetUserTeamsInfo(URLUserId);
    if (!(res && res.status && res.status !== 200)) {
      localStorage.setItem('userTeamsList', JSON.stringify(res));
      setUserTeams(res);
    }
  };
  const getAllTeamsByManagerId = async () => {
    const res = await GetAllTeamsByManagerId(URLUserId);
    if (!(res && res.status && res.status !== 200)) setManagerRelatedTeams(res?.result || []);
  };
  const getActiveUserRoles = async () => {
    const pageSize = 30;
    const pageIndex = 1;
    const res = await GetAllRolesByUserId(URLUserId, pageIndex, pageSize);
    if (!(res && res.status && res.status !== 200)) setUserRoles(res?.result || []);
  };

  const getInActiveUserRoles = async () => {
    const pageSize = 30;
    const pageIndex = 1;
    const res = await GetInActiveUserRoles({userId: URLUserId, pageIndex, pageSize});
    if (!(res && res.status && res.status !== 200)) setUserRoles(res?.result || []);
    else setUserRoles([]);
  };

  const getActiveUserPolicy = async () => {
    const res = await GetUserPolicyService(URLUserId);
    if (!(res && res.status && res.status !== 200)) setUserPolicy(res);
    else setUserPolicy([]);
  };

  const getInActiveUserPolicy = async () => {
    const res = await GetInActiveUserPolicy(URLUserId);
    if (!(res && res.status && res.status !== 200)) setUserPolicy(res|| []);
    else setUserPolicy([]);
  };

  const getAllTemplates = useCallback(async (searchValue) => {
     setisLoadingTemplte(true);
      const res = await GetAllSystemTemplates({"search":searchValue,"pageIndex":1,"pageSize":25,"filterBy":"CreatedOn","orderBy":2,"SystemTemplateCategoryId":StaticLookupsIds.WithSpecificUser});
      if (!((res && res.data && res.data.ErrorId) || !res)) {
        setData({ id: 'templte', value: res.result || [] });
      } else {
        setData({ id: 'templte', value: [] });
      }
      setisLoadingTemplte(false);
  }, []);

  const getDepartments = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Departments",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "departments", value: res?.result || [] });
    else setData({ id: "departments", value: [] });
  };

  const getStations = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Stations",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "stations", value: res?.result || [] });
    else setData({ id: "stations", value: [] });
  };

  const fillInitDetails = (data) => {
    const unitSaleType = data?.userSaleType?.id ? ({
      lookupItemId: data.userSaleType.id,
      lookupItemName: data.userSaleType.name,
    }) : null;

    const operationType = data?.userOperationTypeId ? ({
      id: data.userOperationTypeId,
      title: data.userOperationTypeName,
    }) : null;

    setState({ id: "unitSaleTypeId", value: data?.userSaleType?.id || null });
    setSelected({ id: "unitSaleType", value: unitSaleType });

    setState({ id: "userOperationType", value: data?.userOperationTypeId || null });
    setSelected({ id: "operationType", value: operationType || null });

    setState({ id: "drivingLicenseAvailability", value: data?.drivingLicenseAvailability || false });

    setState({ id: "carAvailability", value: data?.carAvailability || false });


      if(data.developerId) {
        const developerItem = {
          contactsId: data.developerId || null,
          name: data.developerName || null,
        }
        setSelected({ id: "developerSpecialistFor", value: developerItem });
        setState({ id: "developerId", value: data.developerId });
      } 
      if(data.propertyId) {
        const propertyItem = {
          contactsId: data.propertyId || null,
          name: data.propertyName || null,
        }
        setSelected({ id: "propertySpecialistFor", value: propertyItem });
        setState({ id: "propertyId", value: data.propertyId });
      }
  }

  const getApplicationUserDetailsByUserId = async () => {
    const res = await GetApplicationUserDetailsByUserId(URLUserId);
    if (!(res && res.status && res.status !== 200)){
      if(res) fillInitDetails(res);
    }
  };

  const RunSystemTemplateHtmlFis = useCallback(async (templateId , UserId) => {
     setisLoadingTemplte(true);
    const res = await RunSystemTemplateForUnit({"templateId":templateId,"unitId":null,"leadId":null,"contactId":null,"activityId":null,"userId":null,"UserId":UserId});
    if (!(res && res.status && res.status !== 200)) {
      setState({ id: 'aboutMe', value: res && res.templateContent })
    } 
     setisLoadingTemplte(false);
  }, []);

  const getIsExistEmail = async (email) => {
    if (email && email.match(emailExpression)) {
      const response = await CheckExistEmail(email);
      setIsExist({
        ...isExist,
        email: response?.isExist || false,
      });
    }
  };

  const getIsExistStaffCode = async (staffCode) => {
    const response = await CheckExistStaffId(staffCode);
    setIsExist({
      ...isExist,
      staffCode: response?.isExist || false,
    });
};

  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error) {
      showError(GlobalTranslate.t("Shared:please-fix-all-errors"));
      return;
    }
    setIsLoading(true);
    const languages = [];
    state.languages.map((e) => {
      languages.push({
        languageId: e.lookupItemId || e.lookupsId,
      });
    });
    const saveDto = {
      ...state,
      phoneNumber:
        (state.phoneNumber && !state.phoneNumber.startsWith('+') && `+${state.phoneNumber}`) ||
        state.phoneNumber ||
        '',
      whatsAppNumber:
        (state.whatsAppNumber &&
          !state.whatsAppNumber.startsWith('+') &&
          `+${state.whatsAppNumber}`) ||
        (state.whatsAppNumber &&
          state.whatsAppNumber.match(phoneExpression) &&
          state.whatsAppNumber) ||
        '',
      nationalityId: state.nationality.lookupItemId,
      reportsTo: (state.reportsToId && selected.reportsTo && selected.reportsTo.fullName) || null,
      jobTitle: (state.jobTitleId && selected.jobTitle && selected.jobTitle.lookupItemName) || null,
      userLanguages: languages || null,
      branch: (state.branchId && selected.branch && selected.branch.branchName) || null,
      isDefaultBranchUser: state.isDefaultBranchUser == 1,
      staffCode: state.staffCode,
      departmentId: selected.department?.lookupItemId || null,
      stationId: selected.station?.lookupItemId || null,
    };
    const res = await EditOrganizationUserProfile(URLUserId, saveDto);
    setIsLoading(false);
    if (res && !(res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}user-updated-successfully`));
      if (JSON.parse(localStorage.getItem('session')).userId === URLUserId) {
        const updatedState = JSON.parse(localStorage.getItem('session'));
        const update = { ...updatedState, profileImg: state.profileImg };
        localStorage.setItem('session', JSON.stringify(update));
      }
      GlobalHistory.push('/home/Users');
    } else if (URLUserId) showError(t(`${translationPath}user-update-failed`));
  };

  useEffect(() => {
    if (userDetails) {
      const reportsTo =
        userDetails.reportsToId && userDetails.reportsTo
          ? {
            id: userDetails.reportsToId,
            fullName: userDetails.reportsTo,
          }
          : null;

      const branch =
        userDetails.branchId && userDetails.branch
          ? {
            branchId: userDetails.branchId,
            branchName: userDetails.branch,
          }
          : null;

      const jobTitle =
        userDetails.jobTitleId && userDetails.jobTitle
          ? {
            lookupItemId: userDetails.jobTitleId,
            lookupItemName: userDetails.jobTitle,
          }
          : null;

      const department =
        userDetails.departmentId && userDetails.department
          ? {
            lookupItemId: userDetails.departmentId,
            lookupItemName: userDetails.department,
          }
          : null;

      const station =
        userDetails.stationId && userDetails.station
          ? {
            lookupItemId: userDetails.stationId,
            lookupItemName: userDetails.station,
          }
          : null;

      setSelected({
        id: 'edit',
        value: {
          reportsTo,
          branch,
          jobTitle,
          department,
          station,
        },
      });

      setSelectedAdress({
        id: 'edit',
        value: {
          country: userDetails.userAreaOfFocusCountries || null,
          city: userDetails.userAreaOfFocusCites || null,
          district: userDetails.userAreaOfFocusDistricts || null,
          community: userDetails.userAreaOfFocusCommunities || null,
          subCommunity: userDetails.userAreaOfFocusSubCommunities || null,
        },
      });

      const nationality =
        userDetails.nationalityId && userDetails.nationalityName
          ? {
            lookupItemId: userDetails.nationalityId,
            lookupItemName: userDetails.nationalityName,
          }
          : null;

      setState({
        id: 'edit',
        value: {
          ...userDetails,
          nationality,
          password: '',
          confirmPassword: '',
          whatsAppNumber: userDetails.whatsAppNumber || '',
          phoneNumber: userDetails.phoneNumber || '',
          isDefaultBranchUser: userDetails.isDefaultBranchUser ? 1 : 2,
          userAreaOfFocusCities: userDetails.userAreaOfFocusCites.map(
            (e) => (e = { cityId: e.lookupsId })
          ),
          userAreaOfFocusCommunities: userDetails.userAreaOfFocusCommunities.map(
            (e) => (e = { communityId: e.lookupsId })
          ),
          userAreaOfFocusCountries: userDetails.userAreaOfFocusCountries.map(
            (e) => (e = { countryId: e.lookupsId })
          ),
          userAreaOfFocusDistricts: userDetails.userAreaOfFocusDistricts.map(
            (e) => (e = { districtId: e.lookupsId })
          ),
          userAreaOfFocusSubCommunities: userDetails.userAreaOfFocusSubCommunities.map(
            (e) => (e = { subCommunityId: e.lookupsId })
          ),
        },
      });
    }
  }, [userDetails]);

  useEffect(() => {
    getUserDetailsById();
    getAppUserId();
    getUserTeams();
    getAllTemplates();
    getAllTeamsByManagerId();
    getApplicationUserDetailsByUserId();
  }, []);

  useEffect(() => {
    if(userDetails) {
      if (userDetails.userStatus === "Active") {
        getActiveUserRoles();
        getActiveUserPolicy();
      } else {
        getInActiveUserRoles();
        getInActiveUserPolicy();
      }
    }
  }, [userDetails]);

  useEffect(() => () => typingTimer.current ? clearTimeout(typingTimer.current) : {}, []);

  useEffect(() => {
    bottomBoxComponentUpdate(null);
  }, []);

  return (
    <div className="user-management-view view-wrapper">
      <Spinner isActive={isLoading} />
      <form
        noValidate
        onSubmit={saveHandler}
        className="management-form-content"
      >
        <div className="view-management-header mb-2 px-2">
          <PermissionsComponent
            permissionsList={Object.values(UserLoginPermissions)}
            permissionsId={
              UserLoginPermissions.AdminChangePassword.permissionsId
            }
          >
            <Button
              className="btns theme-solid mx-2 mb-2"
              onClick={() => setOpenChangePassword(true)}
            >
              <span className="mdi mdi-form-textbox-password   mx-1 " />
              <span className=" mx-1 ">
                {" "}
                {t("UsersManagementView.change-password")}{" "}
              </span>
            </Button>
          </PermissionsComponent>

          <Button type="submit" className="btns theme-solid mx-2 mb-2">
            <span className="mdi mdi-content-save-outline mx-1 " />
            <span> {t(`${translationPath}edit-user`)}</span>
          </Button>
          <Button
            className="btns theme-solid bg-cancel mb-2 mx-2"
            onClick={() => {
              GlobalHistory.push("/home/Users");
              localStorage.removeItem("userTeamsList");
            }}
          >
            <span> {t(`${translationPath}cancel`)}</span>
          </Button>
        </div>
        <div className="body-content">
          <div className="container">
            <div className="information-section">
              <div className="information-box-wrapper">
                <div className="information-section-content">
                  <div className="image-wrapper">
                    <UploaderComponentCircular
                      idRef="profileImgRef"
                      circleDefaultImage={DefaultImagesEnum.man.defaultImg}
                      initUploadedFiles={
                        (state &&
                          state.profileImg && [
                            { uuid: state.profileImg, fileName: "user-image" },
                          ]) ||
                        []
                      }
                      uploadedChanged={(files) =>
                        setState({
                          id: "profileImg",
                          value: (files.length > 0 && files[0].uuid) || null,
                        })
                      }
                      isDisabled={displayDataOnly(
                        UserLoginPermissions.ChangeProfileImage
                          .permissionsId
                      )} 
                    />
                  </div>
                  {userDetails && (
                    <div className="information-content-wrapper">
                      <div className="fullName">
                        <div className="fullName-wrapper">
                          <span className="fz-30px">
                            {userDetails.fullName}
                          </span>
                        </div>
                        <div className="endPart">
                          <div className="mb-2 userId">
                            <span className="fw-bold">
                              <span>User Id:</span>
                              <span className="px-2" />
                            </span>
                            <Tooltip title={t(`${translationPath}copy`)}>
                              <CopyToClipboardComponents
                                data={appUserId||''}
                                childrenData={appUserId||''}
                              />
                            </Tooltip>
                          </div>
                          <div className="mb-2 userId">
                            <Button
                              className="btns theme-solid btns theme-solid  mb-2 mx-2"
                              onClick={() => {
                                setIsOpenUserLoginDialog(true);
                              }}
                            >
                              <span> {t(`${translationPath}UserLogin`)}</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2">
                        <span className="fw-bold">
                          <span>{t(`${translationPath}Username`)}:</span>
                          <span className="px-2">{userDetails.userName}</span>
                        </span>
                      </div>
                      <div className="roles-wrapper">
                        <div className="roles-content">
                          {userRoles &&
                            userRoles.map((item, index) => (
                              <span
                                key={`userRolesRef${index + 1}`}
                                className="px-1"
                              >
                                <span>{item.roles?.rolesName || item.rolesName}</span>
                                {index < userRoles.length - 1 && <span>,</span>}
                              </span>
                            ))}
                        </div>
                        <PermissionsComponent
                          permissionsList={Object.values(UserLoginPermissions)}
                          permissionsId={
                            UserLoginPermissions.AddRole.permissionsId
                          }
                        >
                          <ButtonBase
                            className="btns theme-outline roles-button"
                            onClick={manageRolesClicked}
                            id="manage-ref"
                          >
                            <span>{t(`${translationPath}manage-roles`)}</span>
                            <span className="mdi mdi-menu-swap" />
                          </ButtonBase>
                        </PermissionsComponent>
                      </div>
                      <div className="roles-wrapper">
                        <div className="roles-content">
                          <span>{userPolicy?.policyName || ""}</span>
                        </div>
                        <PermissionsComponent
                          permissionsList={Object.values(UserLoginPermissions)}
                          permissionsId={
                            UserLoginPermissions.AssignPolicy.permissionsId
                          }
                        >
                          <ButtonBase
                            className="btns theme-outline roles-button"
                            onClick={() => setIsOpenPolicyDialog(true)}
                            id="AssignPolicy-ref"
                          >
                            <span>{t(`${translationPath}AssignPolicy`)}</span>
                            <span className="mdi mdi-menu-swap" />
                          </ButtonBase>
                        </PermissionsComponent>
                      </div>
                    </div>
                  )}
                </div>
                {userDetails && (
                  <div className="information-section-footer">
                    <div className="separator-h" />
                    <div className="footer-content-wrapper">
                      <div className="footer-section">
                        <div className="section-item">
                          <span className="mdi mdi-cellphone-android" />
                          <span className="px-2">
                            {userDetails.phoneNumber || "N/A"}
                          </span>
                        </div>
                        <div className="section-item">
                          <span className="mdi mdi-email-outline" />
                          <span className="px-2">
                            {userDetails.email || "N/A"}
                          </span>
                        </div>
                        <div className="section-item">
                          <span className="mdi mdi-whatsapp" />
                          <span className="px-2">
                            {userDetails.whatsAppNumber || "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="footer-section">
                        <div className="section-item">
                          <span className="mdi mdi-map-marker-outline" />
                          <span className="px-2">
                            {" "}
                            {t(`${translationPath}nationality`)} :
                          </span>
                          <span>{userDetails.nationalityName || "N/A"}</span>
                        </div>
                        <div className="section-item">
                          <span className="mdi mdi-calendar" />
                          <span className="px-2">
                            {" "}
                            {t(`${translationPath}register`)}:
                          </span>
                          <span>
                            {(userDetails.createdOn &&
                              moment(userDetails.createdOn)
                                .locale(i18next.language)
                                .format("DD, MMM YYYY")) ||
                              "N/A"}
                          </span>
                        </div>
                        <div className="section-item">
                          <span className="mdi mdi-handshake" />
                          <span className="px-2">
                            {t(`${translationPath}data-source`)} :
                          </span>
                          <span>{userDetails.dataSource || "N/A"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {userTeams && userTeams.length > 0 && (
              <div className="team">
                <div className="team-header">
                  {t(`${translationPath}teams`)}
                </div>
                <div className="team-scroll">
                  <div className="team-section">
                    <TeamDetails />
                  </div>
                </div>
              </div>
            )}
            {managerRelatedTeams && managerRelatedTeams.length > 0 && (
              <div className="team">
                <div className="team-header">
                  {t(`${translationPath}team-maneger`)}
                  {/* <span className='i-managerCrown manager-Crown' /> */}
                </div>

                <div className="team-scroll">
                  <div className="team-section">
                    <TeamManager
                      teamsRelatedToManager={managerRelatedTeams}
                      parentTranslationPath={parentTranslationPath}
                      translationPath="UsersManagementView."
                    />
                  </div>
                </div>
              </div>
            )}
            <UserLeaves
              parentTranslationPath={parentTranslationPath}
              translationPath="UsersManagementView."
            />
          </div>

          <div className="account-dialog-section">
            <div className="dialog-header">
              {" "}
              {t(`${translationPath}profile-details`)}
            </div>
            <div className="dialog-body">
              <div className="form-item">
                <Inputs
                  inputPlaceholder="FirstName"
                  idRef="firstNameRef"
                  value={state.firstName || ""}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, "firstName").message}
                  error={getErrorByName(schema, "firstName").error}
                  onInputChanged={(event) =>
                    setState({ id: "firstName", value: event.target.value })
                  }
                  labelValue={t(`${translationPath}FirstName`)}
                />
              </div>
              <div className="form-item">
                <Inputs
                  inputPlaceholder="SecondName"
                  labelValue={t(`${translationPath}SecondName`)}
                  idRef="secondNameRef"
                  value={state.secondName || ""}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, "secondName").message}
                  error={getErrorByName(schema, "secondName").error}
                  onInputChanged={(event) =>
                    setState({ id: "secondName", value: event.target.value })
                  }
                />
              </div>
              <div className="form-item">
                <Inputs
                  inputPlaceholder="LastName"
                  labelValue={t(`${translationPath}LastName`)}
                  idRef="lastNameRef"
                  value={state.lastName || ""}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, "lastName").message}
                  error={getErrorByName(schema, "lastName").error}
                  onInputChanged={(event) =>
                    setState({ id: "lastName", value: event.target.value })
                  }
                />
              </div>
              <div className="form-item">
                <Inputs
                  labelValue={t(`${translationPath}Email`)}
                  inputPlaceholder={t(`${translationPath}Email`)}
                  idRef="emailRef"
                  value={state.email || ""}
                  isWithError
                  isSubmitted={isSubmitted}
                  isDisabled={displayDataOnly(
                    UserLoginPermissions.EditEmailAddressInUserRecord
                      .permissionsId
                  )}  
                  onKeyUp={(event) => {
                    const email = event?.target?.value;
                    if (searchTimer.current)
                      clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getIsExistEmail(email);
                    }, 700);
                  }}    
                   onInputChanged={(event) =>{
                    const email = event?.target?.value;
                    setState({ id: "email", value: email})
                  }}
                  helperText={getErrorByName(schema, "email").message}
                  error={getErrorByName(schema, "email").error}
                  withLoader
                  afterIconClasses={
                    (isExist?.email === true &&
                      "mdi mdi-close-outline c-danger mt-1") ||
                    (!getErrorByName(schema, "email").error &&
                      "mdi mdi-check-outline c-success mt-1") ||
                    null
                  }
                />
              </div>
              <div className="form-item">
                <PhonesComponent
                  country="ae"
                  idRef="MobileRef"
                  labelValue={t(`${translationPath}Mobile`)}
                  value={state.phoneNumber}
                  helperText={getErrorByName(schema, "phoneNumber").message}
                  isDisabled={displayDataOnly(
                    UserLoginPermissions.EditMobileNumberInUserRecord
                      .permissionsId
                  )}
                  error={getErrorByName(schema, "phoneNumber").error}
                  inputPlaceholder="whatsapp"
                  isSubmitted={isSubmitted}
                  onInputChanged={(value) =>
                    setState({ id: "phoneNumber", value })
                  }
                />
              </div>
              <div className="form-item">
                <PhonesComponent
                  country="ae"
                  idRef="whatsappNumberRef"
                  labelValue={t(`${translationPath}whatsapp`)}
                  value={state.whatsAppNumber}
                  helperText={getErrorByName(schema, "whatsAppNumber").message}
                  error={getErrorByName(schema, "whatsAppNumber").error}
                  inputPlaceholder="whatsapp"
                  isSubmitted={isSubmitted}
                  onInputChanged={(value) =>
                    setState({ id: "whatsAppNumber", value })
                  }
                />
              </div>

              <div className="form-item">
                <AutocompleteComponent
                  idRef="nationality2Ref"
                  labelValue={t(`${translationPath}nationality-req`)}
                  selectedValues={state.nationality}
                  inputPlaceholder="nationality"
                  value={state.nationality}
                  data={data.countries || []}
                  chipsLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ""
                  }
                  displayLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ""
                  }
                  multiple={false}
                  withoutSearchButton
                  helperText={getErrorByName(schema, "nationality").message}
                  error={getErrorByName(schema, "nationality").error}
                  isWithError
                  isSubmitted={isSubmitted}
                  onOpen={() => {
                    if (data.countries && data.countries.length == 0)
                      getCountries();
                  }}
                  onChange={(event, newValue) => {
                    setState({ id: "nationality", value: newValue });
                  }}
                />
              </div>
              <div className="form-item">
                <AutocompleteComponent
                  idRef="branch2Ref"
                  labelValue={t(`${translationPath}branch-req`)}
                  selectedValues={selected.branch}
                  inputPlaceholder={t(`${translationPath}InputsBranch`)}
                  data={data.branches || []}
                  chipsLabel={(option) =>
                    (option.branchName && option.branchName) || ""
                  }
                  displayLabel={(option) =>
                    (option.branchName && option.branchName) || ""
                  }
                  multiple={false}
                  withoutSearchButton
                  onInputKeyUp={(event) => {
                    const { value } = event.target;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      getBranches(value);
                    }, 700);
                  }}
                  onOpen={() => {
                    if (data.branches && data.branches.length == 0)
                      getBranches();
                  }}
                  onChange={(event, newValue) => {
                    setState({
                      id: "branchId",
                      value: (newValue && newValue.branchId) || null,
                    });
                    setState({
                      id: "isDefaultBranchUser",
                      value: null,
                    });
                    setSelected({ id: "branch", value: newValue });
                  }}
                />
              </div>
              <div className="form-item">
                <RadiosGroupComponent
                  idRef="DefaultBranchUser2"
                  data={[
                    {
                      key: 1,
                      label: "Yes",
                      value: true,
                    },
                    {
                      key: 2,
                      label: "No",
                      value: false,
                    },
                  ]}
                  onSelectedRadioChanged={(event) =>
                    setState({
                      id: "isDefaultBranchUser",
                      value: +event.target.value || null,
                    })
                  }
                  value={(state.branchId && state.isDefaultBranchUser) || null}
                  labelValue={t(`${translationPath}is-default-branch-user`)}
                  labelInput="label"
                  valueInput="key"
                  themeClass="theme-line"
                  isDisabled={!state.branchId || (userDetails?.userStatus !== UserStatusEnum.Active.label)}
                />
              </div>

              <div className="form-item">
                <AutocompleteComponent
                  idRef="jobTitle2Ref"
                  labelValue={t(`${translationPath}jobTitle-req`)}
                  inputPlaceholder={t(`${translationPath}InputsJobTitle`)}
                  selectedValues={selected.jobTitle}
                  data={data.jobTitles || []}
                  chipsLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ""
                  }
                  displayLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ""
                  }
                  multiple={false}
                  withoutSearchButton
                  onOpen={() => {
                    if (data.jobTitles && data.jobTitles.length == 0)
                      getJobTitle();
                  }}
                  onChange={(event, newValue) => {
                    setState({
                      id: "jobTitleId",
                      value: (newValue && newValue.lookupItemId) || null,
                    });
                    setSelected({ id: "jobTitle", value: newValue });
                  }}
                />
              </div>
              <div className="form-item">
                <AutocompleteComponent
                  idRef="languagesRef2"
                  labelValue={t(`${translationPath}languages`)}
                  inputPlaceholder={t(`${translationPath}languages`)}
                  selectedValues={state.languages}
                  data={data.languages || []}
                  chipsLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ""
                  }
                  displayLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ""
                  }
                  multiple
                  onOpen={() => {
                    if (data.languages && data.languages.length == 0)
                      getLanguages();
                  }}
                  withoutSearchButton
                  onChange={(event, newValue) => {
                    setState({
                      id: "languages",
                      value: newValue,
                    });
                  }}
                />
              </div>
              <div className="form-item">
                <DatePickerComponent
                  idRef="DateOfBirthRef"
                  labelValue="Date Of Birth"
                  placeholder="DD/MM/YYYY"
                  value={state.dateOfBirth || null}
                  onDateChanged={(newValue) => {
                    setState({
                      id: "dateOfBirth",
                      value:
                        (newValue &&
                          moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                        null,
                    });
                  }}
                />
              </div>
              <div className="form-item">
                <DatePickerComponent
                  idRef="DateOfJoiningRef"
                  labelValue="Date of Joining"
                  placeholder="DD/MM/YYYY"
                  value={state.dateOfJoining || null}
                  onDateChanged={(newValue) => {
                    setState({
                      id: "dateOfJoining",
                      value:
                        (newValue &&
                          moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                        null,
                    });
                  }}
                />
              </div>
              <div className="form-item">
                <AutocompleteComponent
                  idRef="RegistrationNo2Ref"
                  labelValue="Reports To"
                  inputPlaceholder="Reports To"
                  selectedValues={selected.reportsTo}
                  value={state.reportsTo}
                  data={data.users || []}
                  chipsLabel={(option) =>
                    (option && option.fullName) ||
                    (option && option.firstName) ||
                    ""
                  }
                  displayLabel={(option) =>
                    (option && option.fullName) ||
                    (option && option.firstName) ||
                    ""
                  }
                  multiple={false}
                  withoutSearchButton
                  onOpen={() => {
                    if (data.users && data.users.length == 0) getAllUsers({userStatusId: UserStatusEnum.Active.key});
                  }}
                  onChange={(event, newValue) => {
                    setState({
                      id: "reportsToId",
                      value: (newValue && newValue.id) || null,
                    });
                    setSelected({ id: "reportsTo", value: newValue });
                  }}
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      getAllUsers({name : value ,userStatusId:UserStatusEnum.Active.key});
                    }, 700);
                  }}
                />
              </div>
              <div className="form-item">
                <Inputs
                  labelValue="Registration No"
                  inputPlaceholder="RegistrationNo"
                  idRef="RegistrationNoRef"
                  value={state.registrationNo}
                  onInputChanged={(event) =>
                    setState({
                      id: "registrationNo",
                      value: event.target.value,
                    })
                  }
                />
              </div>
              <div className="form-item">
                <Inputs
                  idRef="employeeIdRef"
                  labelValue="employeeId"
                  value={state.staffCode || ""}
                  inputPlaceholder="InputsStaffCode"
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, "staffCode").message}
                  error={getErrorByName(schema, "staffCode").error}
                  afterIconClasses={
                    (isExist?.staffCode === true &&
                      "mdi mdi-close-outline c-danger mt-1") ||
                    (state.staffCode && !getErrorByName(schema, "staffCode").error &&
                      "mdi mdi-check-outline c-success mt-1") ||
                    null
                  }
                  withLoader
                  onInputChanged={(event) =>
                    setState({
                      id: "staffCode",
                      value: event.target.value,
                    })
                  }
                  onKeyUp={(event) => {
                    const staffCode = event?.target?.value;
                    if (searchTimer.current)
                      clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      if (staffCode) getIsExistStaffCode(staffCode);
                    }, 500);
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
              <div className="form-item">
                <AutocompleteComponent
                  idRef="departmentRef"
                  labelValue={t(`${translationPath}department`)}
                  wrapperClasses="w-100 mb-2"
                  selectedValues={selected.department || []}
                  data={data.departments || []}
                  chipsLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ""
                  }
                  displayLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ""
                  }
                  multiple={false}
                  withoutSearchButton
                  onOpen={() => {
                    if (data.departments && data.departments.length == 0)
                      getDepartments();
                  }}
                  onChange={(event, newValue) => {
                    setSelected({ id: "department", value: newValue });
                  }}
                />
              </div>
              <div className="form-item">
                <AutocompleteComponent
                  idRef="stationRef"
                  labelValue={t(`${translationPath}station`)}
                  wrapperClasses="w-100 mb-2"
                  selectedValues={selected.station || []}
                  data={data.stations || []}
                  displayLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ""
                  }
                  multiple={false}
                  withoutSearchButton
                  onOpen={() => {
                    if (data.stations && data.stations.length == 0)
                      getStations();
                  }}
                  onChange={(event, newValue) => {
                    setSelected({ id: "station", value: newValue });
                  }}
                />
              </div>
              <div className="form-range-wrapper">
                <div className="item-range">
                  <Inputs
                    idRef="idMinTargetRef2"
                    labelValue="minTarget"
                    value={state.targetMin}
                    type="number"
                    onInputChanged={(e) => {
                      const value = parseInt(e.target.value);
                      setState({ id: "targetMin", value });
                    }}
                    onKeyUp={() => {
                      const maxAllowed = 100000000;
                      if (state.targetMin > maxAllowed)
                        setState({ id: "targetMin", value: maxAllowed });
                      if (state.targetMin > state.targetMax || !state.targetMax)
                        setState({ id: "targetMax", value: state.targetMin });
                    }}
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, "targetMin").message}
                    error={getErrorByName(schema, "targetMin").error}
                    parentTranslationPath={parentTranslationPath}
                    translationPath="UsersManagementView."
                  />
                </div>
                <div className="item-range">
                  <Inputs
                    idRef="idMaxTargetRef2"
                    labelValue="maxTarget"
                    value={state.targetMax}
                    type="number"
                    onInputChanged={(e) => {
                      const value = parseInt(e.target.value);
                      setState({ id: "targetMax", value });
                    }}
                    onKeyUp={() => {
                      const maxAllowed = 100000000;
                      if (state.targetMax > maxAllowed)
                        setState({ id: "targetMax", value: maxAllowed });
                      if (state.targetMax < state.targetMin)
                        setState({ id: "targetMin", value: "" });
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath="UsersManagementView."
                  />
                </div>
              </div>
              <AutocompleteComponent
                idRef="countryIdRef2"
                labelValue="country"
                multiple
                data={data.countries || []}
                wrapperClasses={"mb-2"}
                displayLabel={(option) =>
                  (option && option.lookupItemName) || ""
                }
                isDisabled={displayDataOnly(
                  UserLoginPermissions.EditCountryInUserRecord
                    .permissionsId
                )}  
                chipsLabel={(option) => (option && option.lookupItemName) || ""}
                withoutSearchButton
                selectedValues={selectedAdress.country}
                parentTranslationPath={parentTranslationPath}
                translationPath="UsersManagementView."
                onOpen={() => {
                  if (data.countries && data.countries.length == 0)
                    getCountries();
                }}
                onChange={(e, newValue) => {
                  if(!returnPropsByPermissions(UserLoginPermissions.EditCountryInUserRecord.permissionsId))
                    return ; 
                  let list = [];
                  newValue.map((e) =>
                    list.push({ countryId: e.lookupItemId || e.lookupsId })
                  );
                  setState({
                    id: "userAreaOfFocusCountries",
                    value: [...list],
                  });
                  setSelectedAdress({ id: "country", value: newValue });
                }}
              />
              <AutocompleteComponent
                idRef="cityIdRef2"
                labelValue="city"
                multiple
                isDisabled={displayDataOnly(
                  UserLoginPermissions.EditCityInUserRecord
                    .permissionsId
                )}  
                wrapperClasses={"mb-2"}
                data={data.cities || []}
                displayLabel={(option) =>
                  (option && option.lookupItemName) || ""
                }
                chipsLabel={(option) => (option && option.lookupItemName) || ""}
                withoutSearchButton
                selectedValues={selectedAdress.city}
                parentTranslationPath={parentTranslationPath}
                translationPath="UsersManagementView."
                filterOptions={(options) => {
                  const isFind = (id) =>
                    selectedAdress.city &&
                    selectedAdress.city.findIndex(
                      (w) => w.lookupItemId === id || w.lookupsId === id
                    ) === -1;
                  return options.filter((w) => isFind(w.lookupItemId));
                }}
                onOpen={() => {
                  if (data.cities && data.cities.length == 0) getAllCities();
                }}
                onChange={(e, newValue) => {
                  if(!returnPropsByPermissions(UserLoginPermissions.EditCityInUserRecord.permissionsId))
                    return ; 
                  let list = [];
                  newValue.map((e) =>
                    list.push({ cityId: e.lookupItemId || e.lookupsId })
                  );
                  setState({ id: "userAreaOfFocusCities", value: [...list] });
                  setSelectedAdress({ id: "city", value: newValue });
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (typingTimer.current) clearTimeout(typingTimer.current);
                  typingTimer.current = setTimeout(() => {
                    getAllCities(value);
                  }, 700);
                }}
              />
              <AutocompleteComponent
                idRef="districtIdRef2"
                labelValue="district"
                multiple
                data={data.districts || []}
                wrapperClasses={"mb-2"}
                displayLabel={(option) =>
                  (option && option.lookupItemName) || ""
                }
                isDisabled={displayDataOnly(
                  UserLoginPermissions.EditDistrictInUserRecord
                    .permissionsId
                )}  
                chipsLabel={(option) => (option && option.lookupItemName) || ""}
                withoutSearchButton
                selectedValues={selectedAdress.district}
                parentTranslationPath={parentTranslationPath}
                translationPath="UsersManagementView."
                filterOptions={(options) => {
                  const isFind = (id) =>
                    selectedAdress.district &&
                    selectedAdress.district.findIndex(
                      (w) => w.lookupItemId === id || w.lookupsId === id
                    ) === -1;
                  return options.filter((w) => isFind(w.lookupItemId));
                }}
                onOpen={() => {
                  if (data.districts && data.districts.length == 0)
                    getAllDistricts();
                }}
                onChange={(e, newValue) => {
                  if(!returnPropsByPermissions(UserLoginPermissions.EditDistrictInUserRecord.permissionsId))
                    return ; 
                  let list = [];
                  newValue.map((e) =>
                    list.push({ districtId: e.lookupItemId || e.lookupsId })
                  );
                  setState({
                    id: "userAreaOfFocusDistricts",
                    value: [...list],
                  });
                  setSelectedAdress({ id: "district", value: newValue });
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (typingTimer.current) clearTimeout(typingTimer.current);
                  typingTimer.current = setTimeout(() => {
                    getAllDistricts(value);
                  }, 700);
                }}
              />
              <AutocompleteComponent
                idRef="communityIdRef2"
                labelValue="community"
                multiple
                data={data.communities || []}
                wrapperClasses={"mb-2"}
                displayLabel={(option) =>
                  (option && option.lookupItemName) || ""
                }
                isDisabled={displayDataOnly(
                  UserLoginPermissions.EditCommunityInUserRecord
                    .permissionsId
                )}  
                chipsLabel={(option) => (option && option.lookupItemName) || ""}
                withoutSearchButton
                selectedValues={selectedAdress.community}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                filterOptions={(options) => {
                  const isFind = (id) =>
                    selectedAdress.community &&
                    selectedAdress.community.findIndex(
                      (w) => w.lookupItemId === id || w.lookupsId === id
                    ) === -1;
                  return options.filter((w) => isFind(w.lookupItemId));
                }}
                onOpen={() => {
                  if (data.communities && data.communities.length == 0)
                    getAllCommunities();
                }}
                onChange={(e, newValue) => {
                  if(!returnPropsByPermissions(UserLoginPermissions.EditCommunityInUserRecord.permissionsId))
                    return ; 
                  let list = [];
                  newValue.map((e) =>
                    list.push({ communityId: e.lookupItemId || e.lookupsId })
                  );
                  setState({
                    id: "userAreaOfFocusCommunities",
                    value: [...list],
                  });
                  setSelectedAdress({ id: "community", value: newValue });
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (typingTimer.current) clearTimeout(typingTimer.current);
                  typingTimer.current = setTimeout(() => {
                    getAllCommunities(value);
                  }, 700);
                }}
              />
              <AutocompleteComponent
                idRef="subcommunityIdRef2"
                labelValue="subCommunity"
                multiple
                data={data.subCommunities || []}
                displayLabel={(option) =>
                  (option && option.lookupItemName) || ""
                }
                isDisabled={displayDataOnly(
                  UserLoginPermissions.EditSubCommunityInUserRecord
                    .permissionsId
                )}  
                chipsLabel={(option) => (option && option.lookupItemName) || ""}
                withoutSearchButton
                wrapperClasses={"mb-2"}
                selectedValues={selectedAdress.subCommunity}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                filterOptions={(options) => {
                  const isFind = (id) =>
                    selectedAdress.subCommunity &&
                    selectedAdress.subCommunity.findIndex(
                      (w) => w.lookupItemId === id || w.lookupsId === id
                    ) === -1;
                  return options.filter((w) => isFind(w.lookupItemId));
                }}
                onOpen={() => {
                  if (data.subCommunities && data.subCommunities.length == 0)
                    getAllSubCommunities();
                }}
                onChange={(e, newValue) => {
                  if(!returnPropsByPermissions(UserLoginPermissions.EditSubCommunityInUserRecord.permissionsId))
                    return ; 

                  let list = [];
                  newValue.map((e) =>
                    list.push({ subCommunityId: e.lookupItemId || e.lookupsId })
                  );
                  setState({
                    id: "userAreaOfFocusSubCommunities",
                    value: [...list],
                  });
                  setSelectedAdress({ id: "subCommunity", value: newValue });
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (typingTimer.current) clearTimeout(typingTimer.current);
                  typingTimer.current = setTimeout(() => {
                    getAllSubCommunities(value);
                  }, 700);
                }}
              />
              <Inputs
                idRef="TotalYearOfExperinceRef2"
                inputPlaceholder="TotalYearOfExperince"
                labelValue="TotalYearOfExperince"
                value={state.totalYearsOfExperience}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                isWithError
                wrapperClasses={"mb-2"}
                isSubmitted={isSubmitted}
                type="number"
                withLoader
                max={900000000}
                onInputChanged={(event) =>
                  setState({
                    id: "totalYearsOfExperience",
                    value: +event.target.value,
                  })
                }
              />
              <div className="form-item">
                <AutocompleteComponent
                  idRef="templteRef"
                  labelValue={t(`${translationPath}Template`)}
                  inputPlaceholder={t(`${translationPath}Template`)}
                  selectedValues={selected.templte}
                  data={data.templte || []}
                  isLoading={isLoadingTemplte}
                  chipsLabel={(option) =>
                    (option.templateName && option.templateName) || ""
                  }
                  displayLabel={(option) =>
                    (option.templateName && option.templateName) || ""
                  }
                  multiple={false}
                  withoutSearchButton
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      getAllTemplates(value);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    RunSystemTemplateHtmlFis(
                      newValue && newValue.systemTemplateId,
                      URLUserId
                    );
                    setSelected({ id: "templte", value: newValue });
                  }}
                />
              </div>
              <div className="form-item">
                <AutocompleteComponent
                  idRef="OperationTypeRef"
                  labelValue={t(`${translationPath}Operation-type`)}
                  wrapperClasses="w-100 mb-2"
                  selectedValues={selected.operationType}
                  data={Object.values(UserOperationTypeEnum)}
                  displayLabel={(option) => option.title || ""}
                  multiple={false}
                  withoutSearchButton
                  onChange={(_, newValue) => {
                    setState({ id: "userOperationType", value: newValue?.id || null });
                    setSelected({ id: "operationType", value: newValue });
                  }}
                />
              </div>
              <div className="form-item">
                <AutocompleteComponent
                  idRef="unitSaleTypeRef"
                  labelValue={t(`${translationPath}unitSaleType`)}
                  wrapperClasses="w-100 mb-2"
                  selectedValues={selected.unitSaleType}
                  data={data.unitSaleTypes || []}
                  displayLabel={(option) => option.lookupItemName || ""}
                  multiple={false}
                  withoutSearchButton
                  onOpen={() => {
                    if (data.unitSaleTypes && data.unitSaleTypes.length == 0)
                      getUnitSaleTypes();
                  }}
                  onChange={(_, newValue) => {
                    setState({ id: "unitSaleTypeId", value: newValue?.lookupItemId || null });
                    setSelected({ id: "unitSaleType", value: newValue });
                  }}
                />
              </div>
              <div className="form-item">
                <AutocompleteComponent
                  idRef="developer-specialist-forRef"
                  wrapperClasses="w-min-unset mb-2"
                  selectedValues={selected.developerSpecialistFor}
                  labelValue={t(`${translationPath}developer-specialist-for`)}
                  multiple={false}
                  data={data.developers || []}
                  displayLabel={(option) => option?.contact?.company_name ||
                    (option?.contact?.first_name && `${option?.contact?.first_name} ${option?.contact?.last_name}`) ||
                    option.name || ""
                  }
                  isDisabled={displayDataOnly(
                    UserLoginPermissions.EditSpecialistForDeveloperInUserRecord
                      .permissionsId
                  )}  
                  onOpen={() => {
                    if (data.developers && data.developers.length == 0)
                      getAllDevelopers();
                  }}
                  onChange={(_, newValue) => {
                    setState({ id: "developerId", value: newValue?.contactsId || null });
                    setSelected({ id: "developerSpecialistFor", value: newValue });
                  }}
                  onInputChange={(e) => {
                    const value = e?.target?.value;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllDevelopers(value);
                    }, 700);
                  }}
                  isLoading={isFieldLoading.developers}
                  withoutSearchButton
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className="form-item">
                <AutocompleteComponent
                  idRef="property-specialist-forRef"
                  wrapperClasses="w-min-unset mb-2"
                  selectedValues={selected.propertySpecialistFor}
                  labelValue={t(`${translationPath}property-specialist-for`)}
                  multiple={false}
                  isDisabled={displayDataOnly(
                    UserLoginPermissions.EditSpecialistForPropertyInUserRecord
                      .permissionsId
                  )}  
                  data={data.properties || []}
                  displayLabel={(option) =>  option?.property?.property_name || option.name || ""
                  }
                  onOpen={() => {
                    if (data.properties && data.properties.length == 0)
                      getAllProperties();
                  }}
                  onChange={(_, newValue) => {
                    setState({ id: "propertyId", value: newValue?.propertyId || null });
                    setSelected({ id: "propertySpecialistFor", value: newValue });
                  }}
                  onInputChange={(e) => {
                    const value = e?.target?.value;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllProperties(value);
                    }, 700);
                  }}
                  isLoading={isFieldLoading.properties}
                  withoutSearchButton
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className="form-item">
                <p className='m-0'>{t(`${translationPath}hasCar`)}</p>
                <SwitchComponent
                  idRef="carAvailabilityRef"
                  themeClass="thick-theme"
                  labelInput="hasCar"
                  isChecked={state.carAvailability || false}
                  labelValue={`${state.carAvailability ? "Yes" : "No"}`}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChangeHandler={(_, isChecked) => {
                    setState({ id: "carAvailability", value: isChecked || false });
                  }}
                />
              </div>
              <div className="form-item">
                <p className='m-0'>{t(`${translationPath}hasDrivingLicense`)}</p>
                <SwitchComponent
                  idRef="drivingLicenseRef"
                  themeClass="thick-theme"
                  isChecked={state.drivingLicenseAvailability || false}
                  labelValue={`${state.drivingLicenseAvailability ? "Yes" : "No"}`}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChangeHandler={(_, isChecked) => {
                    setState({ id: "drivingLicenseAvailability", value: isChecked || false });
                  }}
                />
              </div>
              <div className="form-item">
                <Inputs
                  idRef="aboutMeRef2"
                  labelValue="about-me"
                  value={state.aboutMe}
                  isDisabled={isLoadingTemplte}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  isWithError
                  rows={4}
                  multiline
                  isSubmitted={isSubmitted}
                  wrapperClasses={"about-me mb-0"}
                  withLoader
                  onInputChanged={(event) =>
                    setState({ id: "aboutMe", value: event.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      {userDetails && isOpenRole && (
        <AssignRoleDialog
          isOpen={isOpenRole}
          isOpenChanged={() => {
            setIsOpenRole(false);
          }}
          reloadData={() => {
            if (userDetails.userStatus === "Active") getActiveUserRoles();
            else getInActiveUserRoles();
          }}
          userStatus={userDetails.userStatus}
          userFullName={userDetails.fullName || ""}
          userId={URLUserId}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {openChangePassword && (
        <ChangePasswordDialog
          open={openChangePassword}
          close={() => {
            setOpenChangePassword(false);
          }}
          userId={URLUserId}
        />
      )}

      {isOpenUserLoginDialog && (
        <UserLoginDialog
          open={isOpenUserLoginDialog}
          parentTranslationPath={parentTranslationPath}
          close={() => {
            setIsOpenUserLoginDialog(false);
          }}
          userId={URLUserId}
        />
      )}
      {isOpenPolicyDialog && appUserId && (
        <AssignPolicyDialog
          isOpen={isOpenPolicyDialog}
          onClose={() => {
            setIsOpenPolicyDialog(false);
          }}
          onSave={() => {
            setIsOpenPolicyDialog(false);
            if (userDetails.userStatus === "Active")  getActiveUserPolicy();
            else getInActiveUserPolicy();
          }}
          userAPPId={appUserId}
          userGUID={URLUserId}
          userPolicy={userPolicy}
          userStatus={userDetails.userStatus}
        />
      )}
    </div>
  );
};

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  gridContainer: {
    display: "flex",
    flex: "1 1 100%",
    flexWrap: "wrap",
    gap: "18px",
    justifyContent: "center",
  },
  progressWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    "& div": {
      width: "100px",
      display: "flex",
    },

    "& label": {
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  row: {
    height: 100,
  },
  badgeSection: {
    display: "flex",
    gap: "6px",
  },
  socialLinks: {
    height: "28px",
  },
  dateText: {
    display: "flex",
    flexDirection: "column",

    "& label": {
      lineHeight: "20px",
      fontWeight: 500,
    },

    "& span": {
      lineHeight: "20px",
      fontWeight: 400,
      color: theme.palette.text.tertiary,
    },
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",

    "& span": {
      lineHeight: "24px",
      fontSize: "16px",
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
  },
  defaultAvatar: {
    backgroundColor: "#F2F4F7", //avatar background
    border: `1px solid ${theme.palette.border.secondary}`,
    height: "40px",
    width: "40px",

    "& img": {
      height: "24px",
      width: "24px",
    },
  },
  avatar: {
    backgroundColor: "#F2F4F7", //avatar background
    border: `1px solid ${theme.palette.border.secondary}`,
    height: "40px",
    width: "40px",
  },
  selectContainer: {
    display: "flex",

    "& .select-wrapper.MuiFormControl-root.theme-default": {
      maxWidth: "325px",
    },
  },
}));

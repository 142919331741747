// import { config } from '../config';
import { config } from '../config';
import { HttpServices } from '../Helper';

const GetLeads = async ({
  pageSize, pageIndex, search, leadStatus
}) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0) queryList.push(`pageIndex=${pageIndex + 1}`);
  if (search) queryList.push(`search=${search}`);
  if (leadStatus) queryList.push(`leadStatus=${leadStatus}`);
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Leads/GetAllLeads?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAdvanceSearchLeads = async ({ pageSize, pageIndex }, body) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/AdvanceSearch?${queryList.join(
      '&'
    )}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetMyLeadAdvanceSearch = async ({ pageSize, pageIndex }, body) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/MyLead/GetMyLeadAdvanceSearch?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllMReferredLeadAdvanceSearch = async (
  { pageSize, pageIndex },
  body
) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/MyLead/GetAllMReferredLeadAdvanceSearch?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllSaleAvailableUnitsAdvanceSearch = async (
  { pageSize, pageIndex },
  body
) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllSaleAvailableUnitsAdvanceSearch?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAvailableLeaseUnitsAdvanceSearch = async (
  { pageSize, pageIndex },
  body
) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/MyLead/GetAvailableLeaseUnitsAdvanceSearch?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetManagedLeadsAdvanceSearch = async ({ pageSize, pageIndex }, body) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Leads/GetManagedLeadsAdvanceSearch?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAdvanceSearchSaleLeads = async ({ pageSize, pageIndex }, body) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Leads/GetAllSellerOrBuyerLeadsAdvanceSearch?${queryList.join(
      '&'
    )}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const LeadsAdvanceSearchTest = async (
  { pageSize, pageIndex, isForLog },
  body
) => {
  const queryList = [];
  if (isForLog) queryList.push(`isForLog=${isForLog}`);
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Leads/LeadsAdvanceSearchTest?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAdvanceSearchLeaseLeads = async ({ pageSize, pageIndex }, body) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Leads/GetAllTenantOrLandlordLeadAdvanceSearch?${queryList.join(
      '&'
    )}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const UpdateSourceFile = async ({ contactId, body }) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/SourceFile/UpdateSourceFile/${contactId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetSourceFileByContactId = async ({ contactId }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/SourceFile/GetSourceFileByContactId/${contactId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreateSourceFile = async ({ body }) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/SourceFile/CreateSourceFile`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const leadDetailsGet = async ({ id }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Leads/GetLeadById/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllPropertyManagementLeads = async ({
  pageSize,
  pageIndex,
  search,
}) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  if (search) queryList.push(`search=${search}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Leads/GetAllPropertyManagementLeads?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const leadDetailsPut = async ({ id, body }) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Leads/${id}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const CloseLead = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Leads/CloseLead`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const CreateMyLead = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/MyLead/CreateLead`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const UpdateLeadQualification = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/UpdateLeadQualification`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const CreateLeadWithReferredToId = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/MyLead/CreateLeadWithReferredToId`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};
const SharedVirtualTour = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/MyLead/SharedVirtualTour`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};
const leadPost = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};
const CloneLeads = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/CloneLeads`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};
const BulkLeadsAutoRotation = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/BulkLeadsAutoRotation${
      body.isFromPool ? '?isFromPool=true' : ''
    }`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const BulkLeadsAutoRotationQualifiedByQa = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/BulkLeadsAutoRotation?isQualifiedByQa=true`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const MergeLeads = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/MergeLead`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const CloneLead = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/CloneLead`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};
const leadContactsGet = async (payload) => {
  // const { contactId, token } = payload;

  // await base('get', `v1/leads/contactId/${contactId}`, token, null);
  const result = {};
  return result;
};
const leadUnitOrPropertyGet = async (payload) => {
  // const { leadId, token } = payload;
  // await base('get', `v1/leads/unitOrProperty/${leadId}`, token, null);
  const result = {};
  return result;
};
const leadByContentIdGet = async (payload) => {
  // const { contactId, token } = payload;
  // await base('get', `v1/leads/contactId/${contactId}`, token, null);
  const resuilt = {};
  return resuilt;
};
const archiveLeadsPut = async (LeadsId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Leads/ArchiveLead/${LeadsId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllLeadsByContactId = async (
  { pageIndex, pageSize, leadStatus, leadClass, referredTo },
  contactId
) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  if (leadStatus) queryList.push(`leadStatus=${leadStatus}`);
  if (leadClass) queryList.push(`leadClass=${leadClass}`);
  if (referredTo) queryList.push(`referredTo=${referredTo}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Leads/GetAllLeadsByContactId/${contactId}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllSaleLeadsByContactId = async (
  { pageIndex, pageSize, leadStatus },
  contactId
) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Leads/GetAllSaleLeadsByContactId/${contactId}?leadStatus=${leadStatus}&pageIndex=${pageIndex}&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const SendUnitProposalToLeadAPI = async (
  leadIds,
  unitIds,
  ApiKey,
  templateId,
  sendKey,
  serverName
) => {
  const queryList = [];
  if (sendKey) queryList.push(`sendKey=${sendKey}`);
  if (serverName) queryList.push(`serverName=${serverName}`);
  if (ApiKey) queryList.push(`ApiKey=${ApiKey}`);

  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Template/SendUnitProposalToLead?${queryList.join('&')}`,
    { leadIds, unitIds }
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const GetAllLeaseLeadsByContactId = async (
  { pageIndex, pageSize, leadStatus },
  contactId
) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Leads/GetAllLeaseLeadsByContactId/${contactId}?leadStatus=${leadStatus}&pageIndex=${pageIndex}&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllMatchingLeadsByUnitId = async (
  unitId,
  pageIndex,
  pageSize,
  operationType
) => {
  if (pageIndex === 0) pageIndex = 1;
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Leads/GetAllMatchingLeadsByUnitId/${unitId}/${operationType}?pageIndex=${pageIndex}&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CloseListOfLeads = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/CloseListOfLeads`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const UnqualifiedLeadsApi = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/UnqualifiedLeads`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const GetAllContactLeadsAdvanceSearch = async (
  leadTab,
  { pageSize, pageIndex },
  body
) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  if (leadTab) queryList.push(`leadTab=${leadTab}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Leads/GetAllContactLeadsAdvanceSearch?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const ReassignLeads = async (body, isQualifiedByQa) => {
  const result = await HttpServices.put(
    `${
      config.server_address
    }/CrmDfm/Leads/UpdateLeadsReferredTo?isQualifiedByQa=${isQualifiedByQa}${
      body.isFromPool ? '&isFromPool=true' : ''
    }`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const GetAgentLeadAssigmentHistory = async (
  leadId,
  { pageSize, pageIndex }
) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Leads/GetAgentLeadAssigmentHistory?leadId=${leadId}&pageIndex=${pageIndex}&pageSize=${pageSize}`
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const GetAllLostLeads = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/GetAllLostLeads`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const MyLeadUpdateLead = async ({ id, body }) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/MyLead/UpdateLead?leadId=${id}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetQualifiedLeadDetails = async (leadId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/MyLead/GetQualifiedLeadDetails/${leadId}`
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

const GetAllUnqualifiedLeads = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/GetAllUnqualifiedLeads`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAngryBirdLeadsByLeadId = async (leadId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Leads/GetAngryBirdLeadsByLeadId/${leadId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateContactToAngryBird = async (leadId) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Leads/UpdateContactToAngryBird/${leadId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CheckLeadAssociatedWithPendingTransaction = async (leadId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Leads/CheckLeadAssociatedWithPendingTransaction/${leadId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

const GetAllFilteredLeadStageLogsAPI = async ({pageIndex, pageSize}, body )  => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Leads/GetAllFilteredLeadStageLogs?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};


const UpdateContactDetailsForLeadStage = async ({ leadId , body }) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Leads/UpdateContactDetailsForLeadStage/${leadId}`, body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;

}

export const CheckLeadStageMandatoryActivities = async (params) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Leads/CheckLeadStageMandatoryActivities`,
      { params }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const UpdateLeadStage = async (body) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/Leads/UpdateLeadStage`,
      body
    );
    return result
    ;
  } catch (error) {
    return error.response;
  }
};

export const GetAllLeads = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Leads/GetAllLeads`,body
    )
    return result
  } catch (error) {
    return error.response
  }
};

export {
  CreateMyLead,
  GetAvailableLeaseUnitsAdvanceSearch,
  GetAllSaleAvailableUnitsAdvanceSearch,
  GetAllMReferredLeadAdvanceSearch,
  GetMyLeadAdvanceSearch,
  GetAllMatchingLeadsByUnitId,
  GetLeads,
  GetAllPropertyManagementLeads,
  CloseLead,
  GetAdvanceSearchLeads,
  leadDetailsGet,
  leadDetailsPut,
  leadPost,
  CloneLeads,
  BulkLeadsAutoRotation,
  BulkLeadsAutoRotationQualifiedByQa,
  leadContactsGet,
  leadUnitOrPropertyGet,
  leadByContentIdGet,
  MergeLeads,
  GetManagedLeadsAdvanceSearch,
  GetAllSaleLeadsByContactId,
  GetAllLeaseLeadsByContactId,
  archiveLeadsPut,
  SharedVirtualTour,
  GetAllLeadsByContactId,
  GetAdvanceSearchLeaseLeads,
  CreateLeadWithReferredToId,
  CloneLead,
  GetAdvanceSearchSaleLeads,
  LeadsAdvanceSearchTest,
  SendUnitProposalToLeadAPI,
  CloseListOfLeads,
  GetAllContactLeadsAdvanceSearch,
  ReassignLeads,
  GetAgentLeadAssigmentHistory,
  GetAllLostLeads,
  MyLeadUpdateLead,
  GetAllUnqualifiedLeads,
  UpdateLeadQualification,
  UnqualifiedLeadsApi,
  GetQualifiedLeadDetails,
  GetAngryBirdLeadsByLeadId,
  UpdateContactToAngryBird,
  CheckLeadAssociatedWithPendingTransaction,
  UpdateSourceFile,
  GetSourceFileByContactId,
  CreateSourceFile,
  GetAllFilteredLeadStageLogsAPI,
  UpdateContactDetailsForLeadStage
};

import React, { createContext, useState, useContext } from 'react';

// Create the context
const VerticalNavContext = createContext();

// Provider Component
export const VerticalNavProvider = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoverExpanded, setHoverExpanded] = useState(false);
  const [isMobileSideNavOpen, setIsMobileSideNavOpen] = useState(false);

  const onTogglePinned = () => {
    setIsExpanded(!isExpanded);
    setHoverExpanded(false); // Ensure hover is disabled when toggling pinned
  };

  const onToggleMobileSideNav = () => {
    setIsMobileSideNavOpen(!isMobileSideNavOpen);
  };
  

  // Context value that will be supplied to any consuming component
  const contextValue = {
    isExpanded,
    setIsExpanded,
    hoverExpanded,
    setHoverExpanded,
    onTogglePinned,
    sideNavWidth: {
      expanded: '312px',
      collapsed: '80px',
    },
    navNotCollapsed: isExpanded || hoverExpanded,
    isMobileSideNavOpen,
    onToggleMobileSideNav,
  };

  return (
    <VerticalNavContext.Provider value={contextValue}>
      {children}
    </VerticalNavContext.Provider>
  );
};

// Custom hook to use the context
export const useVerticalNav = () => useContext(VerticalNavContext);

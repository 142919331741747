import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ActionsButtonsComponent,
  AutocompleteComponent,
  PermissionsComponent,
  SelectComponet,
} from "../../../../Components";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LeadsPoolContext } from "../LeadsPoolContext";
import { ActionsButtonsEnum } from "../../../../Enums";
import { LeadsReassignDialog } from "../../LeadsSalesView/LeadsSalesUtilities/Dialogs/LeadsReassignDialog/LeadsReassignDialog";
import { SendToRoationDialog } from "../../LeadsView/LeadsUtilities/Dialogs/SendToRoationDialog/SendToRoationDialog";
import {
  getIsAllowedPermission,
  showError,
  showSuccess,
} from "../../../../Helper";
import {
  CheckAgentLeadsInPoolWithoutRecentActivity,
  ReassignLeads,
} from "../../../../Services";
import { LeadsPoolViewEnum } from "./LeadsPoolViewEnum";
import { useSelector } from "react-redux";
import { AssignToMeLeadTypeDialog } from "./AssignToMeLeadTypeDialog";
import FailedToPassDialog from "./FailedToPassDialog";
const generateUniqueKey = () => parseInt(Math.random() * 1000);

export const LeadsPoolFilter = ({ parentTranslationPath, translationPath }) => {
  const {
    setFilter,
    resetFilter,
    isDataLoading,
    filter,
    nationalities,
    languages,
    isLoading,
    leadTypes,
    leadCategories,
    activeAction,
    setActiveAction,
    checkedCards,
    checkedCardsIds,
    setCheckedCards,
    viewType,
    permissions,
    isClearFiltersClicked,
    setIsClearFiltersClicked,
  } = useContext(LeadsPoolContext);
  const { t } = useTranslation(parentTranslationPath);
  const { loginResponse } = useSelector((state) => state.login);

  const [searchData, setSearchData] = useState([]);
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [isOpenLeadsReassignDialog, setIsOpenLeadsReassignDialog] =
    useState(false);
  const [isOpenSendToRotationDialog, setIsOpenSendToRotationDialog] =
    useState(false);
  const [isForOwnerLeads, setIsForOwnerLeads] = useState(false);
  const [actionButtonsKey, setActionButtonsKey] = useState(generateUniqueKey());
  const [isAssignSelectOpen, setIsAssignSelectOpen] = useState(false);
  const [openCheckFailedDialog, setOpenCheckFailedDialog] = useState(false);
  const [referredToId, setReferedToId] = useState(null);
  const [assignClicked, setAssignClicked] = useState(1);
  const [checkData, setCheckData] = useState({});
  const [sessionData, setSessionData] = useState(
    JSON.parse(localStorage.getItem("session"))
  );
  const [isPass, setIsPass] = useState(null);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: 0,
    orderBy: 0,
  });
  const [isLoadingReassign, setIsLoadingReassign] = useState(false);
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: "createdOn", orderBy: 2 }
  );
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitHandler = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setFilter((filter) => ({ ...filter, ...selectedOrderBy }));
  };

  const clearFilterHandler = () => {
    resetFilter();
    setFilterSearchDto(null);
    setSearchData([]);
    setSelectedOrderBy({
      filterBy: 0,
      orderBy: 0,
    });
  };

  const getOwnerLeads = () =>
    setFilter((filter) => ({
      ...filter,
      leadCategoryId: leadCategories[1].id,
      leadTypeId:
        leadTypes.find(
          (item) => item.leadTypeCategoryId === leadCategories[1].id
        )?.id ?? leadCategories[1].initialLeadTypeId,
    }));
  const getSeekerLeads = () =>
    setFilter((filter) => ({
      ...filter,
      leadCategoryId: leadCategories[0].id,
      leadTypeId:
        leadTypes.find(
          (item) => item.leadTypeCategoryId === leadCategories[0].id
        )?.id ?? leadCategories[0].initialLeadTypeId,
    }));

  async function CheckAgentLeadsInPoolWithoutRecentActivityAPICall(
    action,
    referToId
  ) {
    const checkingId =
      action === "assignToMe" ? sessionData?.userId : referToId;
    const res = await CheckAgentLeadsInPoolWithoutRecentActivity(checkingId);
    if (!(res?.status && res.status !== 200)) {
      if (action === "assignToMe") {
        setIsPass(res?.isPass);
        if (res?.isPass === true) {
          setIsAssignSelectOpen(true);
        } else {
          setCheckData(res);
          setOpenCheckFailedDialog(true);
        }
      } else if (action === "sendOrReassign") {
        setIsPass(res?.isPass);
        if (res?.isPass === true) {
        } else {
          setCheckData(res);
          setOpenCheckFailedDialog(true);
        }
      }
    }
    return res?.isPass;
  }

  const resetActionButton = () => setActionButtonsKey(generateUniqueKey());

  const checkBulkDisabled = (enums) => {
    if (
      [
        ActionsButtonsEnum[6].id,
        ActionsButtonsEnum[8].id,
        ActionsButtonsEnum[14].id,
        ActionsButtonsEnum[23].id,
        ActionsButtonsEnum[24].id,
      ].includes(enums)
    )
      return !(checkedCards && checkedCards.length >= 1);
    return false;
  };
  const applyFilter = (field, value) => {
    if (field === "leadCategoryId") {
      setFilter((filter) => ({ ...filter, [field]: value, leadTypeId: null }));
    } else if (
      [LeadsPoolViewEnum.lease, LeadsPoolViewEnum.sales].includes(viewType) &&
      field === "leadTypeId"
    ) {
      setFilter((filter) => ({
        ...filter,
        [field]: value,
        leadCategoryId: null,
      }));
    } else {
      setFilter((filter) => ({ ...filter, [field]: value }));
    }
  };
  const onActionsButtonClicked = useCallback(
    (activeAction) => {
      if (
        activeAction === ActionsButtonsEnum[6].id ||
        activeAction === ActionsButtonsEnum[14].id
      ) {
        setIsOpenLeadsReassignDialog(true);
      }
      if (
        activeAction === ActionsButtonsEnum[8].id ||
        activeAction === ActionsButtonsEnum[23].id
      ) {
        setIsOpenSendToRotationDialog(true);
      }

      if (activeAction === ActionsButtonsEnum[24].id && isPass) {
        setIsOpenLeadsReassignDialog(true);
      }
    },
    [checkedCards, checkedCardsIds]
  );

  const onActionButtonChanged = (activeAction) => {
    setActiveAction(activeAction);
    if (
      activeAction === ActionsButtonsEnum[14].id ||
      activeAction === ActionsButtonsEnum[23].id
    ) {
      getOwnerLeads();
      setIsForOwnerLeads(true);
    } else if (
      activeAction === ActionsButtonsEnum[8].id ||
      activeAction === ActionsButtonsEnum[6].id
    ) {
      setIsForOwnerLeads(false);
      getSeekerLeads();
    }
    if (activeAction === ActionsButtonsEnum[24].id) {
      CheckAgentLeadsInPoolWithoutRecentActivityAPICall("assignToMe");
    }
    setCheckedCards([]);
  };

  const reassignHandler = async (reassignItem) => {
    setIsLoadingReassign(true);
    try {
      await ReassignLeads({
        leadIds: checkedCardsIds,
        isFromPool: true,
        ...reassignItem,
      });
      resetFilter();
      setCheckedCards([]);
      setIsOpenLeadsReassignDialog(false);
      showSuccess(t(`${translationPath}leads-reassigned-successfully`));
      resetActionButton();
      setActiveAction(null);
    } catch (error) {
      showError(t(`${translationPath}leads-reassigned-failed`));
    } finally {
      setIsLoadingReassign(false);
    }
  };
  useEffect(() => {
    if (filterSearchDto) {
      setFilter((filter) => ({ ...filter, ...filterSearchDto, pageIndex: 0 }));
    } else {
      resetFilter();
    }
  }, [filterSearchDto]);

  const assignToMeRoles = useMemo(
    () =>
      leadTypes.filter((item) =>
        loginResponse.roles.find((role) => role.rolesName === item.rolesName)
      ),
    []
  );
  const assignSelectCloseHandler = () => {
    setIsAssignSelectOpen(false);
    resetActionButton();
    setActiveAction(null);
  };

  const onAssignSaveHandler = (selectedLeadType) => {
    setFilter((filter) => ({ ...filter, leadTypeId: selectedLeadType }));
    setIsAssignSelectOpen(false);
  };

  useEffect(() => {
    if (
      activeAction === ActionsButtonsEnum[24].id &&
      assignToMeRoles.findIndex((role) => role.id === filter.leadTypeId) === -1
    ) {
      resetActionButton();
      setActiveAction(null);
    }
  }, [filter]);

  useEffect(() => {
    setCheckedCards([]);
  }, [filter.leadTypeId]);

  useEffect(() => {
    if (openCheckFailedDialog && activeAction === "assign-to-me") {
      setActiveAction(null);
    }
  }, [openCheckFailedDialog]);

  return (
    <>
      <PermissionsComponent
        permissionsList={Object.values(permissions)}
        permissionsId={permissions.ViewLeadsPool.permissionsId}
      >
        <div className="filter-section">
          <div className="section1">
            <ActionsButtonsComponent
              key={actionButtonsKey}
              enablereassignOwnerLeads={getIsAllowedPermission(
                Object.values(permissions),
                loginResponse,
                permissions.ReassignLeads.permissionsId
              )}
              enablereassignSeekerLeads={getIsAllowedPermission(
                Object.values(permissions),
                loginResponse,
                permissions.ReassignLeads.permissionsId
              )}
              enableToSendToRotation={getIsAllowedPermission(
                Object.values(permissions),
                loginResponse,
                permissions.SendToRotation.permissionsId
              )}
              enableSendToOwnerRotation={getIsAllowedPermission(
                Object.values(permissions),
                loginResponse,
                permissions.SendToRotation.permissionsId
              )}
              checkDisable={checkBulkDisabled}
              onActionButtonChanged={onActionButtonChanged}
              onActionsButtonClicked={onActionsButtonClicked}
              // enableAssignToMe={getIsAllowedPermission(
              //   Object.values(permissions),
              //   loginResponse,
              //   permissions.AssignToMe.permissionsId
              // )}
              isDisabled={
                !activeAction || activeAction === ActionsButtonsEnum[1].id
              }
            />
            <PermissionsComponent
              permissionsList={Object.values(permissions)}
              permissionsId={permissions.AssignToMe.permissionsId}
            >
              {activeAction === ActionsButtonsEnum[24].id &&
              checkedCards.length > 0 &&
              checkedCardsIds.length > 0 ? (
                <ButtonBase
                  className="MuiButtonBase-root btns mt-2 mb-2 btn-assign-to-me-second-click"
                  onClick={() => {
                    onActionsButtonClicked(ActionsButtonsEnum[24].id);
                  }}
                >
                  {t(`${translationPath}assign-to-me`)}
                </ButtonBase>
              ) : (
                <ButtonBase
                  className="MuiButtonBase-root btns theme-solid theme-solid mt-2 mb-2 btn-assign-to-me-first-click"
                  onClick={() => {
                    onActionButtonChanged(ActionsButtonsEnum[24].id);
                  }}
                >
                  {t(`${translationPath}assign-to-me`)}
                </ButtonBase>
              )}
            </PermissionsComponent>
          </div>
          <div className="section filter-components">
            <div className="w-auto">
              <AutocompleteComponent
                data={nationalities}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                displayLabel={(option) => option?.lookupItemName ?? ""}
                chipsLabel={(option) => option?.lookupItemName ?? ""}
                withoutSearchButton
                label="nationality"
                inputLabel="nationality"
                isLoading={isLoading.nationalities}
                onChange={(_e, value) =>
                  applyFilter("nationality", value?.lookupItemName)
                }
                multiple={false}
                selectedValues={
                  nationalities.find(
                    (n) => n.lookupItemName === filter.nationality
                  ) ?? null
                }
              />
            </div>
            <div className="w-auto">
              <AutocompleteComponent
                data={languages}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                displayLabel={(option) => option?.lookupItemName ?? ""}
                chipsLabel={(option) => option?.lookupItemName ?? ""}
                withoutSearchButton
                label="language"
                inputLabel="language"
                isLoading={isLoading.languages}
                onChange={(e, value) =>
                  applyFilter("language", value?.lookupItemName)
                }
                multiple={false}
                selectedValues={
                  languages.find((n) => n.lookupItemName === filter.language) ??
                  null
                }
              />
            </div>
            {viewType === LeadsPoolViewEnum.contact && (
              <div className="w-25">
                <SelectComponet
                  data={leadCategories}
                  emptyItem={{
                    value: null,
                    text: "select-category-type",
                    isDisabled: false,
                  }}
                  value={filter.leadCategoryId ?? null}
                  valueInput="id"
                  textInput="name"
                  id="Lead_Type"
                  onSelectChanged={(value) => {
                    applyFilter("leadCategoryId", value);
                    resetActionButton();
                    setActiveAction(null);
                  }}
                  wrapperClasses="w-100"
                  themeClass="theme-default"
                  idRef="Lead_Type"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
            )}
            <div className="w-25 ">
              <SelectComponet
                data={leadTypes}
                emptyItem={{
                  value: null,
                  text: "select-lead-type",
                  isDisabled: false,
                }}
                value={filter.leadTypeId ?? null}
                valueInput="id"
                textInput="name"
                id="Lead_Type"
                onSelectChanged={(value) => {
                  applyFilter("leadTypeId", value);
                  if (viewType !== LeadsPoolViewEnum.contact) {
                    resetActionButton();
                    setActiveAction(null);
                  }
                }}
                wrapperClasses="w-100"
                themeClass="theme-default"
                idRef="Lead_Type"
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
          </div>
          <div className="view-search-wrapper px-2">
            <ButtonBase
              onClick={() => {
                clearFilterHandler();
              }}
              id="onClearedAllFiltersref"
              disabled={isDataLoading}
              className="btns theme-solid bg-danger clear-all-btn"
            >
              <span className="mdi mdi-filter-remove m-1" />
              {t(`${translationPath}clear-filters`)}
            </ButtonBase>
          </div>
        </div>
        <div className="d-flex px-2">
          <span className="mx-2 mt-1">{t(`${translationPath}leads`)}</span>
          <span className="separator-v s-primary s-reverse s-h-25px mt-1" />
          <span className="px-2 d-flex">
            <span className="texts-large mt-1">
              {t(`${translationPath}order-by`)}:
            </span>
            <div className="px-2">
              <SelectComponet
                idRef="filterByRef"
                data={[
                  { id: "lead-id", filterBy: "LeadId" },
                  { id: "bedrooms", filterBy: "Bedrooms" },
                  { id: "lead-name", filterBy: "LeadName" },
                  { id: "language", filterBy: "Language" },
                  { id: "nationality", filterBy: "Nationality" },
                  { id: "property-name", filterBy: "PropertyName" },
                  { id: "remarks", filterBy: "Remarks" },
                  { id: "price-from", filterBy: "PriceFrom" },
                  { id: "price-to", filterBy: "PriceTo" },
                ]}
                value={selectedOrderBy.filterBy}
                onSelectChanged={filterByChanged}
                wrapperClasses="mb-3"
                isRequired
                valueInput="filterBy"
                textInput="id"
                emptyItem={{
                  value: null,
                  text: "select-filter-by",
                  isDisabled: false,
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
            <div className="px-2">
              <SelectComponet
                idRef="orderByRef"
                data={[
                  { id: 1, orderBy: "ascending" },
                  { id: 2, orderBy: "descending" },
                ]}
                value={selectedOrderBy.orderBy}
                onSelectChanged={orderByChanged}
                wrapperClasses="mb-3"
                isRequired
                valueInput="id"
                textInput="orderBy"
                emptyItem={{
                  value: null,
                  text: "select-sort-by",
                  isDisabled: false,
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
            <div className="mt-1">
              <ButtonBase
                className="btns theme-solid"
                id="action_apply"
                onClick={orderBySubmitHandler}
                disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
              >
                <span>{t(`${translationPath}apply`)}</span>
              </ButtonBase>
            </div>
          </span>
        </div>

        {isOpenLeadsReassignDialog && (
          <LeadsReassignDialog
            isOpen={isOpenLeadsReassignDialog}
            leadType={
              leadTypes?.find((item) => item.id === filter.leadTypeId)?.name
            }
            value={
              activeAction === ActionsButtonsEnum[24].id &&
              loginResponse.fullName
            }
            isLoadingReassign={isLoadingReassign}
            isAssignToMe={activeAction === ActionsButtonsEnum[24].id}
            onSave={(reassignItem) => {
              reassignHandler(reassignItem);
            }}
            parentTranslationPath="ContactProfileManagementView"
            translationPath=""
            onClose={() => {
              setIsOpenLeadsReassignDialog(false);
            }}
            CheckAgentLeadsInPoolWithoutRecentActivityAPICall={
              CheckAgentLeadsInPoolWithoutRecentActivityAPICall
            }
            isPass={isPass}
          />
        )}

        {isOpenSendToRotationDialog && (
          <SendToRoationDialog
            isOpen={isOpenSendToRotationDialog}
            checkedCardsIds={checkedCardsIds}
            isForOwnerLeads={isForOwnerLeads}
            onClose={() => {
              setIsOpenSendToRotationDialog(false);
              resetFilter();
              setCheckedCards([]);
              resetActionButton();
              setActiveAction(null);
            }}
            isFromPool
            isActive={true}
          />
        )}

        {isAssignSelectOpen && (
          <AssignToMeLeadTypeDialog
            isOpen
            assignData={assignToMeRoles}
            onClose={assignSelectCloseHandler}
            onSave={onAssignSaveHandler}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}

        {openCheckFailedDialog && (
          <FailedToPassDialog
            t={t}
            isOpen={openCheckFailedDialog}
            onClose={(e) => setOpenCheckFailedDialog(false)}
            checkData={checkData}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        )}
      </PermissionsComponent>
    </>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";

// styles
import useStyles from "./styles";

function InfoItem({ label, value }) {
  const styles = useStyles();

  return (
    <Box className={styles.infoItemWrapper}>
      <Typography className={styles.label}>{label}</Typography>
      <Typography className={styles.value}>{value}</Typography>
    </Box>
  );
}

InfoItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

InfoItem.defaultProps = {
  label: "",
  value: "",
};

export default InfoItem;

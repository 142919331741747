import React, { useEffect, useRef, useState } from "react";
import {
    ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../../../../../../Components";

export const PreviewPropretyDialog = ({
    isOpen,
    onClose,
    parentTranslationPath,
    translationPath,
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [urlQuery, setUrlQuery] = useState('');
    const item = JSON.parse(localStorage.getItem("localActiveItem") || "{}");

    useEffect(() => {
        const formatString = (str) => (str ? str.replace(/\s+/g, '-') : '');
        let query = `https://psinv.net/en/projects`;

        if (item) {
            if (item.city) {
                const city = formatString(item.city?.lookupItemName);
                query += `/${city}`;
            }
            if (item.community) {
                const community = formatString(item.community?.lookupItemName);
                query += `/${community}`;
            }
            if (item.sub_community) {
                const subCommunity = formatString(item.sub_community?.lookupItemName);
                query += `/${subCommunity}`;
            }
            if (item.property_name) {
                const propertyName = formatString(item.property_name);
                query += `/${propertyName}`;
            }
        }

        setUrlQuery(query);
    }, []);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>{t(`${translationPath}Preview-Proprety-Dialog`)}</DialogTitle>
            <DialogContent>
                <iframe
                    src={urlQuery}
                    width="100%"
                    height="600px"
                    title="Property Preview"
                    style={{ border: 'none' }} 
                ></iframe>
            </DialogContent>
            <DialogActions>
                <ButtonBase
                    onClick={onClose}
                    className="btns theme-solid bg-cancel"
                >
                    {t(`${translationPath}cancel`)}
                </ButtonBase>
            </DialogActions>
        </Dialog>
    );
};
import React, { useEffect, useState } from 'react';
import { GetAllShareDocumentLog } from '../../../Services';
import { Tables } from '../../../Components';
import ShareDocumentLogHeader from './sharedDocumentLogHeaderData';
import "./SharedDocumentLog.scss"

const parentTranslationPath = 'SharedDocumentLogView';
const translationPath = '';
export const SharedDocumentLogView = () => {
  const [sharedDocumentData, setSharedDocumentData] = useState([]);
  const getAllShareDocumentLog = async () => {
    const res = await GetAllShareDocumentLog({
      pageIndex: 1,
      pageSize: 25,
    });
    if (!(res && res.status && res.status !== 200)) {
      setSharedDocumentData(res.result);
    }
  };

  useEffect(() => {
    getAllShareDocumentLog();
  }, []);
  return (
    <div className='mt-4'>
      <Tables
        data={sharedDocumentData || []}
        headerData={ShareDocumentLogHeader || []}
        defaultActions={[]}
        activePage={0}
        itemsPerPage={100}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </div>
  );
};

import React, { useState, useEffect, useContext } from "react";
import countryList from "react-select-country-list";
import { Box } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

import {
  VIEW_MODES,
  CustomSelect,
  CustomBadge,
  CustomInput,
  CustomAutocomplete,
} from "../../../Components";
import { useSelectedTheme } from "../../../Hooks";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
import {
  ContactClassTypesEnum,
  FormsIdsEnum,
  LeadsClassTypesEnum,
  NewTableFilterOperatorsEnum,
} from "../../../Enums";

// Icons
import { FilterLinesICon, CloseXIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

function ContactsFilterBar({
  viewMode,
  filterItems,
  setFilterItems,
  tableColumns,
  data,
  columnsDetails,
}) {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const styles = useStyles();

  const [step, setStep] = useState(1); // Step 1: Select field type, Step 2: Select operation, Step 3: Select value
  const [currentId, setCurrentId] = useState(
    filterItems[filterItems?.length - 1]?.id
  );

  const [isSavedFiltersUpdate, setIsSavedFiltersUpdate] = useState(false);
  const { savedFiltersCriteria, isCriteriaLoading, setAdvancedSearchBody } =
    useContext(ContactLayoutContext);

  const handleOnDelete = (id) => {
    setStep(1);
    const item = filterItems.find((item) => item?.id === id);

    if (item?.element?.length === 3) {
      setAdvancedSearchBody((prevBody) => {
        if (item?.field === "leadTypes") {
          return {
            ...prevBody,
            LeadClasses: [],
          };
        } else {
          const criteria = { ...prevBody.criteria };

          delete criteria[item?.field];

          return {
            ...prevBody,
            criteria,
          };
        }
      });
    }

    setFilterItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  return (
    <Box
      className={styles.filtersSection}
      style={
        viewMode === VIEW_MODES.GRID
          ? { marginBottom: "24px", borderRadius: "8px" }
          : {}
      }
    >
      <Box className={styles.flexRow}>
        <FilterLinesICon
          width="20"
          height="20"
          fill={palette.button.secondary_fg}
        />
        <label style={{ marginRight: "12px" }}>Filters</label>

        {filterItems?.length > 0 &&
          filterItems?.map((item) => (
            <div id={item.id} key={item.id}>
              <CustomBadge
                label={item.element}
                BorderColor={palette.utility.brand_600}
                BackgroundColor={palette.utility.brand_50}
                Color={palette.utility.brand_700}
                isFilterBadge
                DeleteIcon={
                  <CloseXIcon
                    width="12"
                    onClick={() => {
                      handleOnDelete(item?.id);
                    }}
                    id={item?.id}
                    height="12"
                    fill={palette.utility.brand_400}
                    style={{ alignSelf: "center" }}
                  />
                }
              />
            </div>
          ))}

        <DynamicFilterSelect
          filterItems={filterItems}
          setFilterItems={setFilterItems}
          tableColumns={tableColumns}
          data={data}
          columnsDetails={columnsDetails}
          step={step}
          setStep={setStep}
          isSavedFiltersUpdate={isSavedFiltersUpdate}
          setIsSavedFiltersUpdate={setIsSavedFiltersUpdate}
          currentId={currentId}
          setCurrentId={setCurrentId}
        />
      </Box>

      <CustomSelect
        style={{ width: "187px" }}
        emptyItem={{
          searchCriteria: "",
          searchCriteriaName: "Saved Searches",
          isDisabled: false,
        }}
        getOptionLabel={(option) => option.searchCriteriaName}
        getOptionValue={(option) => option.searchCriteria}
        onValueChange={(e) => {
          if (e) {
            const data = JSON.parse(e);
            const { search } = data;

            setAdvancedSearchBody((prevBody) => {
              const criteria = {};

              // Iterate over the search object and set the criteria
              Object.keys(search).forEach((key) => {
                const searchObj = search[key];
                criteria[key] = [
                  {
                    searchType: searchObj.operator,
                    value: searchObj.value,
                  },
                ];
              });

              return {
                ...prevBody,
                criteria,
                LeadClasses: [],
                contactClasses: [], // TODO: check this
              };
            });

            setIsSavedFiltersUpdate(true);
          } else {
            setFilterItems([]);
            setAdvancedSearchBody({
              criteria: {},
              filterBy: "createdOn",
              orderBy: 2,
              LeadClasses: [],
              contactClasses: [],
            });
          }
        }}
        options={savedFiltersCriteria}
        disabled={isCriteriaLoading}
      />
    </Box>
  );
}

export default ContactsFilterBar;

function DynamicFilterSelect({
  filterItems,
  setFilterItems,
  tableColumns,
  data,
  columnsDetails,
  step,
  setStep,
  isSavedFiltersUpdate,
  setIsSavedFiltersUpdate,
  currentId,
  setCurrentId,
}) {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const { advancedSearchBody, setAdvancedSearchBody } =
    useContext(ContactLayoutContext);

  const [selectedValue, setSelectedValue] = useState("");

  const TableFilterTypesEnum = {
    text: {
      key: 1,
      defaultOperators: [
        { key: "Equal", id: 1 },
        { key: "Contains", id: 2 },
        { key: "Starts With", id: 5 },
        { key: "Ends With", id: 6 },
        { key: "Not Equal", id: 7 },
        { key: "Not Contains", id: 8 },
        { key: "Is Blank", id: 9 },
        { key: "Is Not Blank", id: 10 },
      ],
      defaultSelectedOperator: "Contains",
    },
    datePicker: {
      key: 2,
      defaultOperators: [
        { key: "Equal Date", id: 1 },
        { key: "Greater Than Date", id: 3 },
        { key: "Less Than Date", id: 4 },
        { key: "Not Equal Date", id: 7 },
        { key: "Is Blank", id: 9 },
        { key: "Is Not Blank", id: 10 },
      ],
      defaultSelectedOperator: "Equal Date",
    },
    numberInput: {
      key: 3,
      defaultOperators: [
        { key: "Equal", id: 1 },
        { key: "Greater Than", id: 3 },
        { key: "Less Than", id: 4 },
        { key: "Not Equal", id: 7 },
        { key: "Is Blank", id: 9 },
        { key: "Is Not Blank", id: 10 },
      ],
      defaultSelectedOperator: "Equal",
    },
    selectOption: {
      key: 4,
      defaultOperators: [
        { key: "Equal", id: 1 },
        { key: "Is Blank", id: 7 },
        { key: "Is Not Blank", id: 10 },
      ],
      defaultSelectedOperator: "Equal",
    },
  };

  const [obj, setObj] = useState({
    fieldTypes: [],
    operations: [],
    values: [],
  });

  useEffect(() => {
    if (tableColumns?.length > 0) {
      const newFieldTypes = tableColumns
        .filter((item) => item.headerName !== "Progress")
        .map((item) => ({
          id: item.id,
          name: item.headerName || "",
          fieldType: item?.isDate ? "datePicker" : item.fieldType,
        }));

      setObj((prevObj) => ({
        ...prevObj,
        fieldTypes: newFieldTypes,
        operations: [],
        values: [],
      }));
    }
  }, [tableColumns]);

  const handleFieldTypeChange = (newValue) => {
    setSelectedValue(newValue);

    const displayPath = columnsDetails.find(
      (item) => item?.headerName === newValue
    )?.displayPath;

    const element = tableColumns.find((item) => item?.headerName === newValue);

    const newOperations = TableFilterTypesEnum[
      element?.isDate ? "datePicker" : "text"
    ]?.defaultOperators?.map((op, index) => ({
      id: op?.id,
      name: op?.key,
    }));

    let values = [];

    if (
      element?.fieldType === "select" &&
      displayPath !== "nationality.lookupItemName"
    ) {
      values = [
        { id: 1, name: "Yes" },
        { id: 2, name: "No" },
      ];
    } else if (
      displayPath === "leadTypes" ||
      displayPath === "contact_class.lookupItemName" ||
      displayPath === "contactTypeStr" ||
      displayPath === "nationality.lookupItemName"
    ) {
      switch (displayPath) {
        case "leadTypes":
          values = Object.values(LeadsClassTypesEnum);
          break;

        case "contact_class.lookupItemName":
          values = Object.values(ContactClassTypesEnum);
          break;

        case "contactTypeStr":
          values = Object.values(FormsIdsEnum).splice(0, 2);
          break;

        case "nationality.lookupItemName":
          const updatedCountries = countryList()
            ?.getData()
            ?.map((country, index) => ({
              id: index + 1,
              name: country.label,
            }));
          values = updatedCountries;
          break;

        default:
          break;
      }
    } else {
      const uniqueData = data?.filter((item, index, self) => {
        return (
          self.findIndex((t) => t[displayPath] === item[displayPath]) === index
        );
      });

      values =
        uniqueData
          ?.filter((item) => item[displayPath] && item[displayPath] !== "N/A")
          ?.map((item, index) => ({
            id: index + 1,
            name: item[displayPath],
          })) || [];

      if (values?.length === 0) {
        values = [{ id: 1, name: "Empty" }];
      }
    }

    setObj((prevObj) => ({
      ...prevObj,
      operations: newOperations || [],
      values,
    }));

    setFilterItems((prevItems) => {
      const existingItem = prevItems.find((item) => item.id === currentId);
      if (existingItem) {
        return prevItems.map((item) =>
          item.id === currentId ? { ...item, element: [newValue] } : item
        );
      } else {
        return [
          ...prevItems,
          {
            columnDetails: element,
            id: currentId,
            field: element?.fieldKey || element?.field,
            element: [newValue],
          },
        ];
      }
    });

    setStep(2);
  };

  const handleOperationChange = (newValue) => {
    setFilterItems((prevItems) =>
      prevItems.map((item) =>
        item?.name === newValue || item.id === currentId
          ? { ...item, element: [...item.element, newValue] }
          : item
      )
    );
    setStep(3);
  };

  const handleValueChange = (newValue) => {
    setStep(0);

    setFilterItems((prevItems) =>
      prevItems.map((item) =>
        item.id === currentId
          ? { ...item, element: [...item.element, newValue] }
          : item
      )
    );
  };

  const getOptions = () => {
    switch (step) {
      case 1:
        return obj?.fieldTypes || [];
      case 2:
        return obj?.operations || [];
      case 3:
        return obj?.values || [];
      default:
        return [];
    }
  };

  const handleChange = (newValue) => {
    if (step === 1) {
      handleFieldTypeChange(newValue);
    } else if (step === 2) {
      handleOperationChange(newValue);
    } else if (step === 3) {
      handleValueChange(newValue);
    }
  };

  useEffect(() => {
    const { criteria } = advancedSearchBody;

    if (criteria) {
      setFilterItems((prevItems) => {
        let obj = [];

        Object.keys(criteria).forEach((key) => {
          let headerName = columnsDetails.find(
            (el) =>
              el?.fieldKey?.toLowerCase() === key?.toLowerCase() ||
              el?.field?.toLowerCase() === key?.toLowerCase()
          )?.headerName;

          if (!headerName && key === "All") {
            // handle the All field
            headerName = "All";
          }

          criteria[key].forEach((searchObj, index) => {
            const operation = Object.values(
              NewTableFilterOperatorsEnum("")
            )?.find((op) => op.id === searchObj.searchType);

            columnsDetails.find((el) => el.field === searchObj.searchType);

            obj = [
              ...obj,
              {
                id: uuidv4(), // Unique id considering the previous items and current obj length
                field: key,
                element: [headerName, operation.name, searchObj.value],
              },
            ];
          });
        });

        return obj;
      });

      setIsSavedFiltersUpdate(false);
    }
  }, [advancedSearchBody?.criteria]);

  useEffect(() => {
    if (step === 0) {
      setTimeout(() => {
        setStep(1);
      }, 100);

      const item = filterItems[filterItems?.length - 1];

      const findFilterOperatorIdByName = (item) => {
        return TableFilterTypesEnum[
          item?.columnDetails?.isDate ? "datePicker" : "text"
        ]?.defaultOperators?.find((op) => op.key === item.element[1])?.id;
      };

      setAdvancedSearchBody((prevBody) => {
        if (item?.field === "leadTypes") {
          const foundKey = Object.values(LeadsClassTypesEnum).find(
            (type) => type.name === item?.element?.[2]
          )?.key;

          return {
            ...prevBody,
            LeadClasses: [...new Set([...prevBody.LeadClasses, foundKey])],
          };
        } else {
          const criteria = { ...prevBody.criteria };

          // Update the existing array if the key exists, otherwise create a new array
          if (criteria[item.field]) {
            criteria[item.field] = [
              {
                searchType: findFilterOperatorIdByName(item),
                value: item.element[2],
              },
            ];
          } else {
            criteria[item.field] = [
              {
                searchType: findFilterOperatorIdByName(item),
                value: item.element[2],
              },
            ];
          }

          return {
            ...prevBody,
            criteria,
          };
        }
      });
    }
  }, [step]);

  return (
    <Box style={{ marginLeft: "8px" }}>
      <CustomAutocomplete
        showCloseIcon={false}
        DatePickerField={
          step === 3 &&
          tableColumns?.find((item) => item?.headerName === selectedValue)
            ?.isDate ? (
            <CustomInput
              style={{ width: "233px", margin: "0 10px" }}
              hasSearchIcon={false}
              type="date"
              onChange={(e) => {
                handleValueChange(e.target.value);
              }}
              handleClick={(e) => {
                e.stopPropagation();
              }}
            />
          ) : null
        }
        showDropdownIcon={false}
        hasNoBorder={true}
        open={step > 1}
        onChange={handleChange}
        options={
          step === 3 &&
          tableColumns?.find((item) => item?.headerName === selectedValue)
            ?.isDate
            ? []
            : getOptions()
        }
      />
    </Box>
  );
}

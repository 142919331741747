import { ButtonBase } from '@material-ui/core';
import Joi from 'joi';
import React, { useEffect, useReducer, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Spinner, StepperComponent } from '../../../Components';
import { UnitsStatusEnum, AgentTypesEnum } from '../../../Enums';
import {
  bottomBoxComponentUpdate,
  getErrorByName,
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
  returnPropsByPermissions,
} from '../../../Helper';
import { UnitsSalesPermissions } from '../../../Permissions';
import {
  SetUnitAsReserveOrSaleExtended,
  unitDetailsGet,
  GetUnitSaleDetails,
  GetTransactionUnitDetails,
  CreateLeadForUnitLeadOwner,
} from '../../../Services';
import { UnitMapper } from '../UnitsSalesView/UnitSalesMapper';
import { UnitsSaleRelatedSteps } from './StepsData';

const parentTranslationPath = 'UnitsStatusManagementView';
const translationPath = '';
export const UnitTransactionsView = () => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  const [unitParams, setUnitParams] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLeadCreated, setIsLeadCreated] = useState(false);
  const [unitData, setUnitData] = useState(null);
  const [cantEditReservedSaleTransaction, setCantEditReservedSaleTransaction] =
    useState(null);
  const [unitSaleDetails, setUnitSaleDetails] = useState(null);
  const [unitTransactionDetails, setUnitTransactionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [unitTransactionId, setUnitTransactionId] = useState(null);
  const [
    reciveNextButtonStatusMoveStepper,
    setReciveNextButtonStatusMoveStepper,
  ] = useState(false);
  const EnableNextButtonTotalOwnership = (value) => {
    setReciveNextButtonStatusMoveStepper(value);
  };
  const loginResponse = useSelector(
    (state) => state.login && state.login.loginResponse
  );
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [selected, setSelected] = useReducer(reducer, {
    agents: null,
    listingAgents: null,
    buyerAgent: null,
    otherBuyerAgents: null,
    sellerAgent: null,
    otherSellerAgents: null,
    withListingAgents: false,
    referral: null,
    sellerReferral: null,
    reservationType: null,
    transactedBy: null,
    externalAgency: null,
    rentFreePeriod: null,
    tableContacts: [],
    invoices: [],
    user: null,
    leasingType: null,
    periodOfStay: null,
    unitNumber: null,
    numberOfBedrooms: 0,
    numberOfBathrooms: 0,
    builtUpAreaSize: 0,
    listingAgent: null,
    leadOwner: null,
    unitTransactionDocuments: [],
  });
  const [state, setState] = useReducer(reducer, {});

  const getUnitById = useCallback(async () => {
    setIsLoading(true);
    const res = await unitDetailsGet({ id: unitParams.id });
    if (!(res && res.status && res.status !== 200))
      setUnitData(UnitMapper(res));
    else setUnitData(null);
    setIsLoading(false);
  }, [unitParams]);

  const getUnitSaleDetails = useCallback(async () => {
    if (unitParams && unitParams.id) {
      const res = await GetUnitSaleDetails(unitParams.id);
      if (!(res && res.status && res.status !== 200)) setUnitSaleDetails(res);
      else setUnitSaleDetails(null);
    }
  }, [unitParams]);

  const getTransactionUnitDetails = async () => {
    const res = await GetTransactionUnitDetails(unitParams.id);
    if (!(res && res.status && res.status !== 200)) {
      setUnitTransactionDetails(res);
    }
  };


  const getStateInit = useCallback(
    () => ({
      agents: null,
      listingAgents: null,
      buyerAgent: null,
      contractRatified: false,
      titleDeedTransferred: false,
      deedTransferDate: null,
      referralId: null,
      contractRatifiedDate: null,
      mortgage: false,
      referralPercentage: 0,
      contractRefNo: null,
      financeComp: null,
      transactionEntryDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
      reservationTypeId: null,
      paymentToExternalAgency: false,
      externalAgencyId: null,
      transactedById: null,
      observations: null,
      closingDate: null,
      externalAgencyCommission: 0,
      commission: 0,
      contacts: [],
      basePrice: 0,
      premium: 0,
      premiumPercentageOfBasePrice: 0,
      agencyFeeSeller: 0,
      agencyFeeSellerPercentageOfBasePriceAndPremium: 0,
      discount: 0,
      discountPercentageOfBasePrice: 0,
      sellingPrice: 0,
      admRegistrationFees: 0,
      admRegistrationFeesPercentageOfSellingPrice: 0,
      agencyFeeBuyer: 0,
      agencyFeeBuyerPercentageOfSellingPrice: 0,
      transferFeeBuyer: 0,
      transferFeeBuyerPercentageOfBasePrice: 0,
      transferFeeSeller: 0,
      transferFeeSellerPercentageOfSellingPrice: 0,
      transferFeeCompletedAndPaidProp: 0,
      transferFeeCompletedAndPaidPropOfSellingPrice: 0,
      sellerHasPaid: 0,
      sellerHasPaidPercentageOfBasePrice: 0,
      amountDueToDeveloper: 0,
      downPayment: 0,
      downPaymentPercentageOfSellingPrice: 0,
      loanTermByYears: 0,
      interestRate: 0,
      mortgagePerMonth: 0,
      downPaymentForPlanOne: 0,
      downPaymentForPlanOnePercentageOfSellingPrice: 0,
      monthlyInstallment: 0,
      monthlyInstallmentPercentageOfSellingPrice: 0,
      handoverPaymentForPlanOne: 0,
      handoverPaymentForPlanOnePercentageOfSellingPrice: 0,
      downPaymentForPlanTwo: 0,
      downPaymentForPlanTwoPercentageOfSellingPrice: 0,
      handoverPaymentForPlanTwo: 0,
      handoverPaymentForPlanTwoPercentageOfSellingPrice: 0,
      isPriceOnApplication: true,
      isNegotiable: true,
      sellingPricePerSQFT: 0,
      originalPricePerSQFT: 0,
      invoicesIds: [],
      note: null,
      unitNumber: null,
      numberOfBedrooms: 0,
      numberOfBathrooms: 0,
      builtUpAreaSize: 0,
      listingAgent: null,
      leadOwner: null,
      unitTransactionDocuments: [],
    }),
    [loginResponse]
  );

  const handleSellerListingAgentInit = () => {
    const stateSellerAgent = unitTransactionDetails.listingAgent && {
      userId: unitTransactionDetails.listingAgent?.listingAgentId,
      listingAgentName: unitTransactionDetails.listingAgent?.listingAgentName,
      isListingAgent: true,
      listingAgentType: AgentTypesEnum.Seller.value,
      listingAgentCommission: 0,
    };

    const selectedListingAgent = unitTransactionDetails.listingAgent && {
      id: unitTransactionDetails.listingAgent?.listingAgentId,
      fullName: unitTransactionDetails.listingAgent?.listingAgentName,
      userName: unitTransactionDetails.listingAgent?.listingAgentName,
      isListingAgent: true,
      listingAgentType: AgentTypesEnum.Seller.value,
      listingAgentCommission: 0,
    };

    if (stateSellerAgent)
      setState({ id: 'listingAgents', value: [stateSellerAgent] });
    if (selectedListingAgent) {
      setSelected({ id: 'listingAgents', value: [selectedListingAgent] });
      setSelected({ id: 'sellerAgent', value: selectedListingAgent });
      setSelected({ id: 'withListingAgents', value: true });
    }
  };

  const handleBuyerAgentInit = () => {
    const stateBuyerAgent = {
      userId: loginResponse.userId,
      agentCommission: 0,
      isOriginalAgent: true,
      agentType: AgentTypesEnum.Buyer.value,
      agentName: loginResponse.fullName,
    };

    const selectedBuyerAgent = {
      id: loginResponse.userId,
      fullName: loginResponse.fullName,
      isOriginalAgent: true,
      agentType: AgentTypesEnum.Buyer.value,
    };

    const transactedBy = {
      id: loginResponse.userId,
      fullName: loginResponse.fullName,
    };
    setState({ id: 'buyerAgent', value: stateBuyerAgent });
    setState({ id: 'agents', value: [stateBuyerAgent] });
    setState({
      id: 'transactedById',
      value: loginResponse.userId || null,
    });

    setSelected({ id: 'agents', value: [selectedBuyerAgent] });
    setSelected({ id: 'buyerAgent', value: selectedBuyerAgent });
    setSelected({ id: 'withListingAgents', value: true });
    setSelected({ id: 'transactedBy', value: transactedBy });
  };

  const getSaleRelatedSchema = () => ({
    buyerAgent: Joi.object()
      .required()
      .messages({
        'object.required': t(`${translationPath}agent-is-required`),
        'object.empty': t(`${translationPath}agent-is-required`),
        'object.base': t(`${translationPath}agent-is-required`),
      }),
    transactionEntryDate: Joi.any()
      .custom((value, helpers) => {
        if (!value) return helpers.error('state.transactionEntryDate');
        return value;
      })
      .messages({
        'state.transactionEntryDate': t(
          `${translationPath}transaction-entry-date-is-required`
        ),
      }),
    reservationTypeId: Joi.number()
      .required()
      .messages({
        'any.required': t(`${translationPath}reservation-type-is-required`),
        'number.base': t(`${translationPath}reservation-type-is-required`),
      }),
    transactedById: Joi.string()
      .required()
      .messages({
        'any.required': t(`${translationPath}transacted-by-is-required`),
        'string.base': t(`${translationPath}transacted-by-is-required`),
        'string.empty': t(`${translationPath}transacted-by-is-required`),
      }),

    basePrice: Joi.number()
      .required()
      .greater(0)
      .messages({
        'any.required': t(`${translationPath}base-price-is-required`),
        'number.base': t(`${translationPath}base-price-is-required`),
      }),
    unitNumber: Joi.string()
      .required()
      .messages({
        'any.required': t(`${translationPath}unitNumber-is-required`),
        'string.base': t(`${translationPath}unitNumber-is-required`),
      }),
    numberOfBedrooms: Joi.number()
      .required()
      .greater(0)
      .messages({
        'any.required': t(`${translationPath}numberOfBedrooms-is-required`),
        'number.base': t(`${translationPath}numberOfBedrooms-is-required`),
      }),
    numberOfBathrooms: Joi.number()
      .required()
      .greater(0)
      .messages({
        'any.required': t(`${translationPath}numberOfBathrooms-is-required`),
        'number.base': t(`${translationPath}numberOfBathrooms-is-required`),
      }),
    builtUpAreaSize: Joi.number()
      .required()
      .greater(0)
      .messages({
        'any.required': t(`${translationPath}builtUpAreaSize-is-required`),
        'number.base': t(`${translationPath}builtUpAreaSize-is-required`),
      }),
    contacts: Joi.array()
      .min(1)
      .required()
      .messages({
        'array.min': t(`${translationPath}please-select-at-least-one-contact`),
      }),
  });

  const schema = Joi.object(getSaleRelatedSchema())
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const stepClickedHandler = useCallback(
    (newStepIndex) => () => {
      setActiveStep(newStepIndex);
    },
    []
  );
  const isCompletedHandler = useCallback(
    (stepIndex) => {
      if (stepIndex === 0) {
        if (
          getErrorByName(schema, 'unitNumber').error ||
          getErrorByName(schema, 'numberOfBedrooms').error ||
          getErrorByName(schema, 'numberOfBathrooms').error ||
          getErrorByName(schema, 'builtUpAreaSize').error ||
          getErrorByName(schema, 'numberOfBedrooms').error
        )
          return false;
      } else if (stepIndex === 2) {
        if (
          getErrorByName(schema, 'agents').error ||
          getErrorByName(schema, 'transactionEntryDate').error ||
          getErrorByName(schema, 'reservationTypeId').error ||
          getErrorByName(schema, 'transactedById').error ||
          getErrorByName(schema, 'contractDate').error ||
          getErrorByName(schema, 'startDate').error ||
          getErrorByName(schema, 'endDate').error
        )
          return false;
      } else if (stepIndex === 3) {
        if (getErrorByName(schema, 'contacts').error) return false;
      } else if (stepIndex === 4) {
        if (
          getErrorByName(schema, 'basePrice').error ||
          getErrorByName(schema, 'rentPerYear').error ||
          getErrorByName(schema, 'leasingTypeId').error ||
          getErrorByName(schema, 'contractIssuedById').error
        )
          return false;
      }
      return true;
    },
    [schema]
  );
  const isDisabledHandler = useCallback(
    (stepIndex) => {
      let isDisabled = false;
      for (let index = 0; index < stepIndex; index += 1) {
        isDisabled = !isCompletedHandler(index);
        if (isDisabled) break;
      }
      if (!isDisabled) isDisabled = !isCompletedHandler(activeStep);
      return isDisabled;
    },
    [activeStep, isCompletedHandler]
  );
  const backHandler = () => {
    setActiveStep((item) => item - 1);
  };

  const createLeadForUnitLeadOwner = async () => {
    let isCreatedSuccessfully = false;

    const res = await CreateLeadForUnitLeadOwner(unitParams.id);
    if (!(res && res.status && res.status !== 200)) {
      isCreatedSuccessfully = true;
      setIsLeadCreated(true);
    }

    return isCreatedSuccessfully;
  };

  const nextHandler = useCallback(async () => {
    if (!isDisabledHandler(activeStep) && activeStep === 0 && !isLeadCreated) {
      const isCreatedSuccessfully = await createLeadForUnitLeadOwner();
      if (!isCreatedSuccessfully) return;
    }

    if (!isDisabledHandler(activeStep)) {
      setIsSubmitted(false);
      setActiveStep((item) => item + 1);
    } else setIsSubmitted(true);
    if (activeStep === 3 && getErrorByName(schema, 'contacts').error)
      showError(getErrorByName(schema, 'contacts').message);
  }, [activeStep, isDisabledHandler, schema, unitParams]);

  const cancelHandler = useCallback(() => {
    if (unitParams.id && unitParams.from && +unitParams.from === 2)
      GlobalHistory.push(
        `/home/units-sales/unit-profile-edit?formType=1&id=${unitParams.id}`
      );
    else GlobalHistory.push('/home/units-sales/view');
  }, [unitParams]);

  const onStateChangedHandler = (newValue) => {
    setState(newValue);
  };
  const onSelectedChangedHandler = (newValue) => {
    setSelected(newValue);
  };

  const calculatePercentage = (value, price) => {
    return value ? (value / (price || 0)) * 100 : 0;
  };

  const fillInitialSaleDetails = () => {
    setState({
      id: 'edit',
      value: {
        ...state,
        ...unitSaleDetails,
        ...unitTransactionDetails,
        isPriceOnApplication: unitSaleDetails.priceOnApplication,
        isNegotiable: unitSaleDetails.negotiable,
        sellingPricePerSQFT: unitSaleDetails.sellingPricePerSquareFeet,
        originalPricePerSQFT: unitSaleDetails.originalPricePerSquareFeet,
      },
    });
  };

  const saveHandler = useCallback(async () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const rowVersion = params.get('rowVersion')?.replace(/ /g, '+');
    setIsSubmitted(true);
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }

    setIsLoading(true);
    const res = await SetUnitAsReserveOrSaleExtended({
      unitId: unitParams.id,
      status: unitParams.status,
      saleTransactionDetails: {
        ...state,
        listingAgents: state.listingAgents ? state.listingAgents : [],
      },
      note: state.note,
      unitTransactionContacts: state.contacts,
      unitNumber: state.unitNumber,
      numberOfBedrooms: state.numberOfBedrooms || null,
      numberOfBathrooms: state.numberOfBathrooms || null,
      builtUpAreaSize: state.builtUpAreaSize || null,
      unitTransactionDocuments: state.unitTransactionDocuments,
      transactionDocuments: state.transactionDocuments,
      invoicesIds: state.invoicesIds,
      rowVersion,
    });

    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}unit-status-updated-successfully`);
      cancelHandler();
      localStorage.removeItem('saleTransactionDetails');
    } else showError(t`${translationPath}unit-status-update-failed`);
  }, [cancelHandler, schema.error, state, t, unitParams]);

  useEffect(() => {
    if (unitParams) {
      bottomBoxComponentUpdate(
        <div className="bottom-box-two-sections">
          <div className=" d-flex-h-between">
            <ButtonBase
              className="btns theme-transparent mb-2"
              onClick={cancelHandler}
            >
              <span className="mdi mdi-chevron-left" />
              <span className="px-2 c-warning">
                {t(`${translationPath}cancel`)}
              </span>
            </ButtonBase>

            {unitData?.sale_type && (
              <span className="text-bold">
                <strong>{t(`sale-type`, { ns: 'UnitsView' })}: </strong>{' '}
                {unitData?.sale_type?.lookupItemName ?? 'N/A'}
              </span>
            )}
          </div>
          <div className="d-flex-v-center flex-wrap">
            <ButtonBase
              className="btns theme-transparent mb-2"
              disabled={activeStep === 0}
              onClick={backHandler}
            >
              <span>{t('Shared:back')}</span>
            </ButtonBase>
            {!(activeStep === getTransactionsSteps()?.length - 1) && (
              <ButtonBase
                className="btns theme-solid mb-2"
                onClick={nextHandler}
                disabled={activeStep === 1 && reciveNextButtonStatusMoveStepper}
              >
                <span>{t('Shared:next')}</span>
              </ButtonBase>
            )}
            {activeStep === getTransactionsSteps()?.length - 1 && (
              <ButtonBase
                className="btns theme-solid mb-2"
                onClick={saveHandler}
              >
                <span>{t('Shared:save')}</span>
              </ButtonBase>
            )}
          </div>
        </div>
      );
    }
  }, [activeStep, cancelHandler, nextHandler, saveHandler, t, unitParams]);

  useEffect(() => {
    if (unitParams && unitParams.id) {
      getUnitById();
      getUnitSaleDetails();
      getTransactionUnitDetails();
    }
  }, [unitParams]);

  useEffect(() => {
    if (unitSaleDetails && unitTransactionDetails && loginResponse) {
      fillInitialSaleDetails();
      handleSellerListingAgentInit();
      handleBuyerAgentInit();
    }
  }, [unitSaleDetails, unitTransactionDetails, loginResponse]);

  useEffect(() => {
    if (unitParams && unitParams.id)
      setState({ id: 'edit', value: getStateInit() });
  }, [getStateInit, unitParams]);

  useEffect(() => {
    const status = GetParams('status');
    const id = GetParams('id');
    const from = GetParams('from');
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const rowVersion = params.get('rowVersion')?.replace(/ /g, '+');
    if (status && id && from) {
      setUnitParams({
        status: +status,
        id: +id,
        from: +from,
        rowVersion,
      });
    }
  }, []);

  useEffect(() => {
    if (
      unitData &&
      unitData.unitStatus &&
      unitData.unitStatus.key === UnitsStatusEnum.ReservedSale.key &&
      unitParams &&
      unitParams.status === UnitsStatusEnum.Sale.key
    )
      setCantEditReservedSaleTransaction(
        !returnPropsByPermissions(
          UnitsSalesPermissions.EditReservedSaleTransaction.permissionsId
        )
          ? true
          : false
      );
    else setCantEditReservedSaleTransaction(null);
  }, [unitData]);

  const getTransactionsSteps = () => {
    return unitData?.sale_type?.lookupItemName === 'Resale'
      ? UnitsSaleRelatedSteps
      : UnitsSaleRelatedSteps.filter(
          (item) => item.label !== 'invoice-description'
        );
  };

  return (
    <div className="units-status-management-wrapper view-wrapper">
      <Spinner isActive={isLoading} />
      <StepperComponent
        steps={getTransactionsSteps()}
        onStepperClick={stepClickedHandler}
        completed={isCompletedHandler}
        isDisabled={isDisabledHandler}
        labelInput="label"
        hasError={schema.error !== undefined}
        isSubmitted={isSubmitted}
        activeStep={activeStep}
        isValidateOnlyActiveIndex
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        dynamicComponentProps={{
          state,
          selected,
          unitData,
          unitTransactionId,
          unitSaleDetails,
          schema,
          onStateChanged: onStateChangedHandler,
          onSelectedChanged: onSelectedChangedHandler,
          isSubmitted,
          parentTranslationPath,
          translationPath,
          EnableNextButtonTotalOwnership,
          cantEditReservedSaleTransaction,
        }}
      />
    </div>
  );
};

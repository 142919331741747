import React, { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { Menu, Box, Typography, ButtonBase, MenuItem, ListItemIcon, Collapse, List, Icon, Divider } from "@material-ui/core";
import UserAvatar from "./UserAvatar";
import { makeStyles } from "@material-ui/core/styles";
import { useIsAr, useTranslate } from "../../../../Hooks";
import { GlobalHistory, showError, showSuccess, languageChange } from "../../../../Helper";
import { uploadFile, UpdateMyProfileImage, OrganizationUserSearch } from "../../../../Services";
import { UPDATE, LOGOUt } from "../../../../store/login/Actions";
import { ActiveItemActions } from "../../../../store/ActiveItem/ActiveItemActions";
import { GlobalOrderFilterActions } from "../../../../store/GlobalOrderFilter/GlobalOrderFilterActions";

import { DialogComponent, Spinner } from '../../..';
import { ChangePasswordView } from "../../../../Views/Account";
import { SET_SELECTED_THEME, TOGGLE_THEME } from "../../../../store/theme/Actions";

const PARENT_TRANSLATION_PATH = 'HeaderView';

const useStyles = makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    customMenu: {
      top: "60px !important",
      boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
      borderRadius: "8px",
      border: `1px solid ${theme.palette.border.secondary}`,
    },
    menuContainer: {
      maxWidth: "270px",
    },
    userButton: {
      display: "flex",
      alignItems: "center",
      textTransform: "none",
      color: theme.palette.text.primary,
      borderRadius: "6px",
      paddingInlineStart: "16px",
    },
    userDetails: {
      display: "flex",
      flexDirection: "column",
      textAlign: isAr ? "right" : "left",
    },
    dropdownIcon: {
      color: theme.palette.text.tertiary,
      ...(isAr ? { marginRight: "8px" } : { marginLeft: "8px" }),
      transform: "none !important",
      fontSize: "20px",
    },
    userName: {
      fontSize: "14px",
      fontWeight: 600,
      color: theme.palette.text.secondary,
      lineHeight: "20px",
    },
    userMail: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
      color: theme.palette.text.tertiary,
    },
    customMenuList: {
      padding: 0,
    },
    menuItem: {
      padding: "9px 10px",
      display: "flex",
      alignItems: "center",
      borderRadius: "6px",
      marginTop: "1px",
      marginBottom: "1px",
      '&:hover': {
        background: theme.palette.background.primary_hover,
      },
    },
    menuItemWrapper: {
      padding: "4px 6px",
    },
    listItemIconWrapper: {
      minWidth: "16px",
      ...(isAr ? { marginLeft: "8px" } : { marginRight: "8px" }),
    },
    userMenuIcon: {
      fontSize: "16px",
      color: theme.palette.foreground.quarterary,
    },
    uploadAvatarIcon: {
      fontSize: "10px",
      color: theme.palette.border.brandSolidAlt,
    },
    menuItemText: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color: theme.palette.text.secondary,
      whiteSpace: "normal",
      wordWrap: "break-word",
      textAlign: "start"
    },
    nested: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.secondary,
    },
    menuItemLanguage: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
    },
    collapseIcon: {
      fontSize: "16px",
      color: theme.palette.foreground.quarterary,
    },
    divider: {
      backgroundColor: theme.palette.border.secondary,
      margin: "0px",
    },
    userMenuAvatar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 16px",
    },
    userImgContainer: {
      position: "relative",
      width: "40px",
      height: "40px",
      ...(isAr ? { marginLeft: "9px" } : { marginRight: "9px" }),
    },
    userBtn: {
      position: "absolute",
      bottom: "-5px",
      right: "-5px",
      padding: "4px",
      borderRadius: "50%",
      backgroundColor: theme.palette.background.secondary,
    },
  };
});

function UserMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageOpen, setLanguageOpen] = useState(false);
  const { translate } = useTranslate(PARENT_TRANSLATION_PATH);
  const [isLoading, setIsLoading] = useState(false);
  const [ChangePasswordDialog, setChangePasswordDialog] = useState(false);
  const [isOpenDialogReldo, setisOpenDialogReldo] = useState(false);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const isDarkMode = useSelector((store) => store.theme.isDarkMode);

  const theme = useSelector((store) => store.theme);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const styles = useStyles();
  const dispatch = useDispatch();

  const uploaderRef = useRef(null);

  const { isAr } = useIsAr(theme.themeDirection);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageClick = () => {
    setLanguageOpen(!languageOpen);
  };

  const languageClicked = useCallback(languageChange);

  const anchorOrigin = isAr
    ? { vertical: "bottom", horizontal: "left" }
    : { vertical: "bottom", horizontal: "right" };

  const transformOrigin = isAr
    ? { vertical: "top", horizontal: "left" }
    : { vertical: "top", horizontal: "right" };

  const dropdownIcon = anchorEl ? "icon-up-arrow" : "icon-down-arrow";

  const CollapseIcon = languageOpen ? "icon-up-arrow" : "icon-down-arrow";

  // const fileChanged = useCallback(async (event) => {
  //   if (!event.target.value) return;
  //   const response = await uploadFile({ file: event.target.files[0] });
  //   if (response) {
  //     const profileImageRes = await UpdateMyProfileImage(response.uuid);
  //     if (profileImageRes) {
  //       if (JSON.parse(localStorage.getItem("session")).userId === profileImageRes.id) {
  //         const updatedState = JSON.parse(localStorage.getItem("session"));
  //         const update = {
  //           ...updatedState,
  //           profileImg: profileImageRes.profileImg,
  //         };
  //         localStorage.setItem("session", JSON.stringify(update));
  //         dispatch(UPDATE(update));
  //       }
  //       if (
  //         localStorage.getItem("activeUserItem") &&
  //         JSON.parse(localStorage.getItem("activeUserItem")).id === profileImageRes.id
  //       ) {
  //         const updateActiveUser = JSON.parse(localStorage.getItem("activeUserItem"));
  //         localStorage.setItem(
  //           "activeUserItem",
  //           JSON.stringify({
  //             ...updateActiveUser,
  //             profileImg: profileImageRes.profileImg,
  //           })
  //         );
  //         if (window.location.pathname.includes("home/Users/edit"))
  //           window.location.href = `/home/Users/edit?id=${profileImageRes.id}`;
  //       }
  //       showSuccess("Image changed successfully");
  //     } else showError("Save image failed");
  //   } else {
  //     showError("Upload image failed");
  //   }
  // }, [dispatch]);

  const getOrganizationUserSearch = useCallback(async () => {
    setIsLoading(true);
    const response = await OrganizationUserSearch({
      userName: loginResponse.userName,
      pageIndex: 0,
      pageSize: 25,
    });
    if (response && response.result) {
      setIsLoading(false);
      dispatch(
        ActiveItemActions.activeItemRequest(
          response.result.find((item) => item.id === loginResponse.userId),
        ),
      );
      localStorage.setItem(
        'activeUserItem',
        JSON.stringify(
          response.result.find((item) => item.id === loginResponse.userId),
        ),
      );
      if (window.location.pathname.includes('home/Users/edit'))
        window.location.href = `/home/Users/edit?id=${loginResponse.userId}`;
      else GlobalHistory.push(`/home/Users/edit?id=${loginResponse.userId}`);
    } else setIsLoading(false);
  }, [dispatch, loginResponse.userId, loginResponse.userName]);

  const handleEditProfile = () => {
    getOrganizationUserSearch();
  };

  const handleActivity = () => {
    const activityCallCenter = Math.random();
    localStorage.setItem('activityCallCenter', activityCallCenter);
    GlobalHistory.push(`/home/Activities?user=${loginResponse.userName}`);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        activitesCallCenterFilter: {
          ...orderFilter.activitesCallCenterFilter,
          agent:
            (loginResponse && {
              id: loginResponse && loginResponse.userId,
              fullName: loginResponse && loginResponse.fullName,
            }) ||
            null,
        },
      }),
    );
  }

  const handleChangePassword = () => {
    setChangePasswordDialog(true);
  };

  const openhelpHandlerDialogReldo = () => {
    setisOpenDialogReldo(true);
  };

  const handleHardReload = () => {
    localStorage.removeItem('session');
    window.localStorage.clear();
    dispatch(LOGOUt());
    setTimeout(() => {
      GlobalHistory.push('/account/login');
    }, 100);
    window.location.reload();
  };

  const handleReleaseNote = () => {
    window.open('/share/release-note', '_blank');
  };

  const handleSupportTickets = () => {
    window.open('/share/my-support-tickets', '_blank');
  };

  const handleLogout = () => {
    localStorage.removeItem('session');
    dispatch(LOGOUt());
    setTimeout(() => {
      GlobalHistory.push('/account/login');
    }, 100);
  }

  const handleToggleTheme = () => {
    dispatch(TOGGLE_THEME()); 
    dispatch(SET_SELECTED_THEME({ isDarkMode: !isDarkMode }));
  };

  return (
    <>
      <ButtonBase
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        className={styles.userButton}
        disableRipple
      >
        <UserAvatar />
        <Box className={styles.userDetails}>
          <Typography variant="body2" className={styles.userName}>{loginResponse && loginResponse.fullName}</Typography>
          <Typography variant="caption" className={styles.userMail}>{loginResponse && loginResponse.email}</Typography>
        </Box>
        <Icon className={clsx(dropdownIcon, styles.dropdownIcon)} />
      </ButtonBase>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        getContentAnchorEl={null}
        PaperProps={{
          className: styles.customMenu,
        }}
        MenuListProps={{
          className: styles.customMenuList,
        }}
      >
        <Box className={styles.menuContainer}>
          {/* <MenuItem className={styles.userMenuAvatar}>
            <Box className={styles.userImgContainer}>
              {loginResponse && loginResponse.fullName && !loginResponse.profileImg && (
                <Avatar className={styles.avatar}>
                  {loginResponse.fullName.match(/\b(\w)/gm).join('')}
                </Avatar>
              )}
              {loginResponse && loginResponse.profileImg && (
                <img
                  src={getDownloadableLink(loginResponse.profileImg)}
                  alt="User Image"
                  className={styles.userImg}
                />
              )}
              <input
                type="file"
                style={{ display: "none" }}
                onChange={fileChanged}
                accept="image/*"
                ref={uploaderRef}
              />
              <ButtonBase className={styles.userBtn} onClick={() => uploaderRef.current.click()}>
                <Icon className={clsx("icon-user-menu-camera", styles.uploadAvatarIcon)} />
              </ButtonBase>
            </Box>
            <Box className={styles.userDetails}>
              <Typography variant="body2" className={styles.userName}>
                {loginResponse && loginResponse.fullName}
              </Typography>
              <Typography variant="caption" className={styles.userMail}>
                {loginResponse && loginResponse.email}
              </Typography>
            </Box>
          </MenuItem>
          <Divider className={styles.divider} /> */}
          <Box 
            component="div" 
            className={styles.menuItemWrapper}
          >
            <MenuItem onClick={handleEditProfile} className={styles.menuItem} disableRipple>
              <ListItemIcon className={styles.listItemIconWrapper}>
                <Icon className={clsx("icon-user-menu-user", styles.userMenuIcon)} />
              </ListItemIcon>
              <Typography variant="inherit" className={styles.menuItemText}>
                {translate("userMenuView.edit-profile")}
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleActivity} className={styles.menuItem} disableRipple>
              <ListItemIcon className={styles.listItemIconWrapper}>
                <Icon className={clsx("icon-header-menu", styles.userMenuIcon)} />
              </ListItemIcon>
              <Typography variant="inherit" className={styles.menuItemText}>
                {translate("userMenuView.my-activities")}
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleChangePassword} className={styles.menuItem} disableRipple>
              <ListItemIcon className={styles.listItemIconWrapper}>
                <Icon className={clsx("icon-user-menu-change-pass", styles.userMenuIcon)} />
              </ListItemIcon>
              <Typography variant="inherit" className={styles.menuItemText}>
                {translate("userMenuView.change-password")}
              </Typography>
            </MenuItem>
          </Box>
          <Divider className={styles.divider} />
          <Box
            component="div" 
            className={styles.menuItemWrapper}
          >
            <MenuItem onClick={openhelpHandlerDialogReldo} className={styles.menuItem} disableRipple>
              <ListItemIcon className={styles.listItemIconWrapper}>
                <Icon className={clsx("icon-user-menu-refresh", styles.userMenuIcon)} />
              </ListItemIcon>
              <Typography variant="inherit" className={styles.menuItemText}>
                {translate("userMenuView.hard-refresh-and-clear-caches")}
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleReleaseNote} className={styles.menuItem} disableRipple>
              <ListItemIcon className={styles.listItemIconWrapper}>
                <Icon className={clsx("icon-user-menu-release-note", styles.userMenuIcon)} />
              </ListItemIcon>
              <Typography variant="inherit" className={styles.menuItemText}>
                {translate("userMenuView.release-note")}
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleSupportTickets} className={styles.menuItem} disableRipple>
              <ListItemIcon className={styles.listItemIconWrapper}>
                <Icon className={clsx("icon-user-menu-support", styles.userMenuIcon)} />
              </ListItemIcon>
              <Typography variant="inherit" className={styles.menuItemText}>
                {translate("userMenuView.my-support-tickets")}
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleToggleTheme} className={styles.menuItem} disableRipple>
              <ListItemIcon className={styles.listItemIconWrapper}>
                <Icon className={clsx(isDarkMode ? "icon-user-menu-light-mode" : "icon-user-menu-dark-mode", styles.userMenuIcon)} />
              </ListItemIcon>
              <Typography variant="inherit" className={styles.menuItemText}>
                {translate(`userMenuView.${isDarkMode ? 'light-mode' : 'dark-mode'}`)}
              </Typography>
            </MenuItem>
            <MenuItem className={styles.menuItem} onClick={handleLanguageClick} disableRipple>
              <Box className={styles.menuItemLanguage}>
                <Box display="flex" alignItems="center">
                  <ListItemIcon className={styles.listItemIconWrapper}>
                    <Icon className={clsx("icon-user-menu-languages", styles.userMenuIcon)} />
                  </ListItemIcon>
                  <Typography variant="inherit" className={styles.menuItemText}>
                    {translate("userMenuView.language")}
                  </Typography>
                </Box>
                <Icon className={clsx(CollapseIcon, styles.collapseIcon)} />
              </Box>
            </MenuItem>
            <Collapse in={languageOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <MenuItem onClick={() => languageClicked("en")} className={clsx(styles.menuItem, styles.nested)}>
                  <Typography variant="inherit">English</Typography>
                </MenuItem>
                <MenuItem onClick={() => languageClicked("ar")} className={clsx(styles.menuItem, styles.nested)}>
                  <Typography variant="inherit">عربي</Typography>
                </MenuItem>
                <MenuItem onClick={() => languageClicked("ru")} className={clsx(styles.menuItem, styles.nested)}>
                  <Typography variant="inherit">Russian</Typography>
                </MenuItem>
                <MenuItem onClick={() => languageClicked("cn")} className={clsx(styles.menuItem, styles.nested)}>
                  <Typography variant="inherit">Chinese</Typography>
                </MenuItem>
              </List>
            </Collapse>
          </Box>
          <Divider className={styles.divider} />
          <Box
            component="div" 
            className={styles.menuItemWrapper}
          >
            <MenuItem onClick={handleLogout} className={styles.menuItem} disableRipple>
              <ListItemIcon className={styles.listItemIconWrapper}>
                <Icon className={clsx("icon-user-menu-log-out", styles.userMenuIcon)} />
              </ListItemIcon>
              <Typography variant="inherit" className={styles.menuItemText}>
                {translate("userMenuView.logout")}
              </Typography>
            </MenuItem>
          </Box>
        </Box>
      </Menu>
      <DialogComponent
        isOpen={ChangePasswordDialog}
        onCloseClicked={() => setChangePasswordDialog(false)}
        titleText={translate("userMenuView.change-password")}
        maxWidth='sm'
        dialogContent={
          <ChangePasswordView
            onCancelClicked={() => setChangePasswordDialog(false)}
            translationPath={"userMenuView."}
          />
        }
      />
      {isOpenDialogReldo && (
        <DialogComponent
          titleText={`${translate(`userMenuView.confirm-message`)}`}
          saveText={`${translate(`userMenuView.confirm`)}`}
          saveType='button'
          maxWidth='sm'
          dialogContent={
            <div className='d-flex-column-center'>
              <Spinner isActive={isLoading} isAbsolute />
              <span className='mdi mdi-help-rhombus-outline c-primary mdi-48px' />
              <span
                className='pt-3'
                style={{ textAlignVertical: 'center', textAlign: 'center' }}
              >{`${translate(`userMenuView.Relode-description`)}`}</span>
            </div>
          }
          saveClasses='btns theme-solid c-info: w-100 mx-2 mb-2'
          isOpen={isOpenDialogReldo}
          onSaveClicked={handleHardReload}
          onCloseClicked={() => setisOpenDialogReldo(false)}
          onCancelClicked={() => setisOpenDialogReldo(false)}
        />
      )}
    </>
  );
}

export default UserMenu;

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment } from "@material-ui/core";
import { Inputs } from "../../../../Components";

export const PriceFilter = ({
  setResaleFilters,
  resaleFilters,
  resaleChangeFilters,
  parentTranslationPath,
  translationPath,
  selected , 
  setSelected , 
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  return (
    <div className="w-50 px-2">
      <fieldset className="styled-fieldset d-flex-column-v-center">
        <legend className="styled-legend">
          {t(`${translationPath}price`)}
        </legend>
        <div className="w-100 m-1 px-2">
          <Inputs
            idRef="priceFromRef"
            labelValue={t(`${translationPath}from`)}
            type={"number"}
            withNumberFormat
            value={(selected?.price?.from) || ''}
            onKeyUp={(e) => {
              let price = null ; 
              const { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                const priceFromKey = "Selling_price_from";
                const priceFromValue =
                  value || value == 0 ? value.replace(/,/g, "") : null;
                const searchType = 3;

                resaleChangeFilters(
                  priceFromKey,
                  priceFromValue,
                  false , 
                  searchType
                );
               price = { from : priceFromValue , to : (selected?.price?.to)  }
               setSelected({id :'price'  , value : price })

              }, 600);  
            }}
            endAdornment={
              <InputAdornment position="start" className="px-2">
                AED
              </InputAdornment>
            }
            min={0}
          />
        </div>
        <div className="w-100 m-1 px-2">
          <Inputs
            idRef="priceToRef"
            labelValue={t(`${translationPath}to`)}
            type={"number"}
            withNumberFormat
            value={(selected?.price?.to) || ''}
            onKeyUp={(e) => {
              const { value } = e.target;
              let price =  null ; 
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                const priceToKey = "Selling_price_to";
                const priceToValue =
                  value || value == 0 ? value.replace(/,/g, "") : null;
                const searchType = 4;

                resaleChangeFilters(
                  priceToKey,
                  priceToValue,
                  false ,
                  searchType
                );
                price = { from : (selected?.price?.from), to :priceToValue }
                setSelected({id :'price'  , value : price })

              }, 600);
            }}
            endAdornment={
              <InputAdornment position="start" className="px-2">
                AED
              </InputAdornment>
            }
            min={0}
          />
        </div>
      </fieldset>
    </div>
  );
};

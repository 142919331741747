import React, { useState, useCallback } from "react";
import { PropTypes } from "prop-types";
import { ButtonBase } from "@material-ui/core";
import { TableActions } from "../../../../Enums/TableActions.Enum";
import { Tables } from "../../../../Components";

const translationPath = "";
export const ShareUnitTableComponent = ({
  filter,
  data,
  openFile,
  parentTranslationPath,
  translationPath,
  setIsOpenReassign,
  setActiveItem,
  activeItem,
  checkedDetailedCards,
  withCheckbox,
  getIsSelected,
  onSelectClicked,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [activityHistory, setActivityHistory] = useState();
  // const [activeItem, setActiveItem] = useState(null);
  const [openReply, setOpenReply] = useState(false);
  const [edit, setisEdit] = useState(false);
  const [currentActions, setCurrentActions] = useState(() => []);
  const [open, setOpen] = useState(false);
  const pathName = window.location.pathname.split("/home/")[1].split("/QA")[0];

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.preventDefault();
      setActiveItem(item);
      if (actionEnum === TableActions.openFile.key) {
        setIsOpenReassign(true);
      }
    },
    [pathName]
  );
  const focusedRowChanged = (activeRow) => {
    const item = data && data[activeRow];
    if (!item) return;
    setCurrentActions(getTableActionsWithPermissions(item.reassignIsAble));
  };
  const getTableActionsWithPermissions = (reassignIsAble) => {
    const list = [];
    if (reassignIsAble) {
      list.push({
        enum: TableActions.openFile.key,
      });
    } else {
      list.push({
        enum: TableActions.openFile.key,
      });
    }
    return list;
  };
  return (
    <div>
      <Tables
        data={data}
        headerData={[
          {
            id: 1,
            label: "unitId",
            input: "unitId",
          },
          {
            id: 2,
            label: "shareUnitId",
            input: "shareUnitId",
          },
          {
            id: 3,
            label: "property_name",
            input: "property_name",
          },
          {
            id: 4,
            label: "operation_type",
            input: "operation_type",
          },
          {
            id: 4,
            label: "unit_ref_no",
            input: "unit_ref_no",
          },
          {
            id: 5,
            label: "furnished",
            input: "furnished",
          },
          {
            id: 6,
            label: "unit_type",
            input: "unit_type",
          },
          {
            id: 7,
            label: "Sharing-date",
            input: "sharedOn",
          },
          {
            id: 8,
            label: "Sharing-by",
            input: "sharedBy",
          },
        ]}
        selectAllOptions={
          (withCheckbox &&
            data &&
            data.length && {
              selectedRows: checkedDetailedCards,
              getIsSelected,
              disabledRows: [],
              onSelectClicked,
            }) ||
          undefined
        }
        focusedRowChanged={focusedRowChanged}
        defaultActions={[
          {
            enum: TableActions.openFile.key,
          },
        ]}
        actionsOptions={{
          actions: currentActions,
          classes: "",
          isDisabled: false,
          onActionClicked: tableActionClicked,
        }}
        itemsPerPage={filter.pageSize}
        isSellectAllDisabled
        activePage={filter.pageIndex}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
    </div>
  );
};

ShareUnitTableComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
};

import React from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";

export const SubCommunityGeographicCriteria = ({
  parentTranslationPath,
  translationPath,
  subCommunityGeographicCriteriaWithScores,
  setSubCommunityGeographicCriteriaWithScores,
  activeCommunity,
  setActiveSubCommunity,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  return (
    <>
      <div>
        <span className="fw-bold"> {t(`${translationPath}SubCommunity`)} </span>
      </div>
      <div className="w-100 mt-2 mb-2 sores-part">
        {subCommunityGeographicCriteriaWithScores &&
          subCommunityGeographicCriteriaWithScores
            .filter(
              (d) =>
                d.parentLookupItemId ===
                (activeCommunity && activeCommunity.lookupsId)
            )
            .map((item, index) => (
              <div
                className="d-flex-inline b-bottom mt-3 mb-3"
                key={`SubCommunityCardItemRef${item.lookupsId + 1}`}
                onClick={() => setActiveSubCommunity(item)}
              >
                <div className="city-location">
                  {" "}
                  <div className="d-flex-inline">
                    <span className="fw-bold">{item.lookupItemName}</span>
                  </div>
                  <Rating
                    size="large"
                    name={`community${item.lookupsId}ItemRef${item.lookupTypeName}-${item.lookupsId}}`}
                    max={10}
                    value={item.score}
                    precision={1}
                    onChange={(event, newValue) => {
                      let updatelist = [
                        ...subCommunityGeographicCriteriaWithScores,
                      ];
                      const itemIndex = updatelist.indexOf(
                        updatelist.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex !== -1)
                        updatelist[itemIndex] = {
                          ...updatelist[itemIndex],
                          score: newValue ||0,
                        };
                      setSubCommunityGeographicCriteriaWithScores([
                        ...updatelist,
                      ]);
                    }}
                  />
                </div>
              </div>
            ))}
      </div>
    </>
  );
};
